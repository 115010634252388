import { Location } from "react-router-dom";
import { Signal, batch, computed, effect, signal } from "@preact/signals-react";
import { JSONPath } from "jsonpath-plus";
import { get, has, isArray, isEmpty, isNil, isObject, set, some, isEqual } from "lodash-es";
import { Result } from "../models/result";
import { executeAction } from "./actions";
import { getAst, getExprValue, isStateVar, setExprValue } from "./interpreter.js";
import { client } from "./nats-client";
import { createOptionalDebouncedFunction, isNullOrEmpty, makeSlug, removeAllBodyThemes } from "./utils.js";
import { log } from "./logger.js";
import { RuntimeContext } from "./NGFieldExtensions.js";
import tracing from "./tracing.js";
import { ItemNode, buildMetadataMap, createItemNode, getTypename, isComponentGuiRoute } from "./metadataUtils.js";
import { designerState } from "./designer.ts";
import { guiActions } from "../sampleData/editor-gui/editor-gui.ts";
import { C } from "vitest/dist/reporters-5f784f42.js";
import { CustomLabel, SiteConfiguration } from "../../resolvers-types.ts";
import { permissionMap } from "./security.ts";

type Binding = { [key: string]: string };

interface Filter {
  Name: string;
  Value: string | number | boolean; // Adjust based on expected types
  Operator: string;
}

// Define the Aggregate type
interface Aggregate {
  [key: string]: string[]; // Each key maps to an array of strings
}

// Define the main QueryRequest type
export interface IQuery {
  Filter?: Filter[];
  GroupBy?: string[];
  OrderBy?: string[];
  Aggregate?: Aggregate;
  OData?: { [key: string]: string };
}

type Field = {
  Name: string;
  Type: string;
  Value: any;
  Required?: boolean;
  Binding?: Binding | string;
  Bindings?: Binding;
  Skip: boolean;
};

export const NGNextPage: Signal<string | null> = signal(null);

// DO NOT EXPORT!
let globalState: any = {
  Permissions: signal(null),
  Menu: signal(null),
  User: signal(null),
  NGService: {},
  NGSite: signal(null),
  NGTheme: signal(null),
  NGTemplate: signal(null),
};

export let iframeState = {};

const metadata = signal({});
const metadataMap = new Map<string, ItemNode>();

function getNode(id) {
  return metadataMap.get(id) ?? null;
}

function getParent(id) {
  return getNode(getNode(id)?.parent) ?? null;
}

export const meta = {
  version: signal(0),
  size: () => metadataMap.size,
  addNode: (config, context) => {
    let node = getNode(config.Id);
    if (node) {
      node.context = context;
      return node;
    }

    let parentCtx = context.Path[context.Path.length - 2] ?? null;
    if (parentCtx?.__typename === "Index") parentCtx = context.Path[context.Path.length - 3] ?? null;

    const parent = metadataMap.get(parentCtx?.Id);

    if (parent == null) node = createItemNode(config, null, 0, [0], [config.Id], context);
    else node = createItemNode(config, parent, parent.level, parent.path, parent.pathIds, context);

    metadataMap.set(config.Id, node);

    return node;
  },
  getAll: () => metadataMap.entries(),
  getNode,
  getParent,
  clear: () => metadataMap.clear(),
  build: (page: any) => {
    if (page == null) return;
    metadataMap.clear();
    buildMetadataMap(metadataMap, page, null, 0, [0], [page.Id]);
    meta.version.value = meta.version.peek() + 1;
  },
  addReference: (reference, component: any) => {
    const refNode = metadataMap.get(reference.Id);
    if (refNode == null) {
      log.error("meta", "Reference not found");
      return;
    }
    buildMetadataMap(metadataMap, component, reference, refNode.level, refNode.path, refNode.pathIds);
    meta.version.value = meta.version.peek() + 1;
  },
};

export function GetAllCustomLabelsFromSite() {
  const site = GetSite();

  if (isNil(site) || isNil(site.CustomLabels)) {
    return [];
  }

  return site.CustomLabels as CustomLabel[];
}

export function GetCustomLabelFromSite(customLabelName: string) {
  const site = GetSite();

  if (isNil(site) || isNil(site.CustomLabels) || isNil(site.CustomLabels[customLabelName])) {
    return null;
  }

  return site.CustomLabels[customLabelName];
}

export function GetFormatFromSite(formatName: string) {
  const site = GetSite();

  if (isNil(site) || isNil(site.Formats) || isNil(site.Formats[formatName])) {
    log.error("GetFormat", `The format '${formatName}' cannot be found in the current site`);
    return null;
  }

  return site.Formats[formatName];
}

export function GetFormatsFromSite() {
  const site = GetSite();

  return site?.Formats ?? {};
}

function lowercaseFirstLetter(s) {
  if (!s) return ""; // Handle empty or null
  return s?.charAt(0).toLowerCase() + s.slice(1);
}

let _LabelsForListFromSiteSettings: { [key: string]: string } | null = null;

export function GetConvertedLabelsForListFromSiteSettings(): { [key: string]: string } {
  if (_LabelsForListFromSiteSettings != null) return _LabelsForListFromSiteSettings;

  const site = GetSite();
  _LabelsForListFromSiteSettings = {};

  if (!isNil(site) && !isNil(site.Settings) && !isNil(site.Settings.ListAndChartLabels)) {
    Object.entries(site.Settings.ListAndChartLabels).forEach(([key, value]) => {
      if (_LabelsForListFromSiteSettings) _LabelsForListFromSiteSettings[lowercaseFirstLetter(key)] = value as any;
    });
  }

  return _LabelsForListFromSiteSettings as { [key: string]: string };
}

export function GetCustomLabelForListFromSiteSettings(customLabelName: string) {
  const site = GetSite();

  if (
    isNil(site) ||
    isNil(site.Settings) ||
    isNil(site.Settings.ListAndChartLabels) ||
    isNil(site.Settings.ListAndChartLabels[customLabelName])
  ) {
    return customLabelName;
  }

  return site.Settings.ListAndChartLabels[customLabelName];
}

export function GetSettingFromSite(settingName: string) {
  const site = GetSite();

  if (isNil(site) || isNil(site.Settings) || isNil(site.Settings[settingName])) {
    log.error("GetSetting", `The setting '${settingName}' cannot be found in the current site`);
    return null;
  }

  return site.Settings[settingName];
}

export function GetSettingsFromSite() {
  const site = GetSite();

  return site?.Settings ?? {};
}

export function ClearSite() {
  batch(() => {
    globalState.NGTheme.value = null;
    globalState.NGSite.value = null;
  });
}
export function ClearTheme() {
  batch(() => {
    globalState.NGTheme.value = null;
    globalState.NGSite.value = { ...globalState.NGSite.value, Theme: null, ParentTheme: null };
  });
}
export function GetTheme(): any {
  return globalState.NGTheme.value;
}
export function SetTheme(theme: any) {
  globalState.NGTheme.value = theme;
}

export function ClearTemplate() {
  batch(() => {
    globalState.NGTemplate.value = null;
    globalState.NGSite.value = { ...globalState.NGSite.value, Template: null };
  });
}
export function GetTemplate(): any {
  return globalState.NGTemplate.value;
}
export function SetTemplate(template: any) {
  globalState.NGTemplate.value = template;
}

export function GetSite(): SiteConfiguration {
  return globalState.NGSite.value;
}
export function SetSite(site: any) {
  batch(() => {
    globalState.NGSite.value = site;
    SetTheme(site.Theme);
  });
}

function setThemeMode(themeMode: string) {
  localStorage.setItem("app-theme", themeMode);
  removeAllBodyThemes();
  document.body.classList.add(`${themeMode}-theme`);
}

async function setupUserTheme(userThemeMode, defaultThemeMode) {
  const newThemeMode = userThemeMode ?? defaultThemeMode;

  const appliedTheme = localStorage.getItem("app-theme");
  const isAlreadyApplied = appliedTheme === newThemeMode;
  if (isAlreadyApplied) return;

  setThemeMode(newThemeMode);
  return userThemeMode;
}

export async function GetSiteFromServer() {
  const tag = "GetSiteFromServer";
  if (!isNil(GetSite())) return GetSite();

  log.info(tag, "Site get request", "site.get");

  try {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const siteId = globalState.User.value?.Details?.Site ?? urlParams.get("site");

    const resp = (await client.request("site.get", {
      Url: window.location.href,
      Id: siteId,
    })) as Result<any>;

    console.log("Site get response", resp);

    if (!resp?.success) {
      log.error(tag, "Service site.get failed", resp?.reasons);
      return false;
    }

    if (!isNil(resp?.data.Site)) SetSite(resp?.data.Site);

    const userThemeMode = globalState.User.value?.Details?.ThemeMode ?? null;
    const defaultThemeMode = globalState.NGTheme.value?.ThemeMode ?? null;
    if (!isNil(defaultThemeMode) || !isNil(userThemeMode)) setupUserTheme(userThemeMode, defaultThemeMode);

    return resp?.data.Site;
  } catch (error) {
    log.error(tag, "Service site.get failed", error);
    return false;
  }
}

export async function GetThemeFromServer() {
  const tag = "GetThemeFromServer";

  // if we have it in cache, fetch it from there to avoid a network call
  if (!isNil(GetTheme())) return GetTheme();

  const site = await GetSiteFromServer();

  if (isNil(site)) {
    log.error(tag, "Site not found.  Ensure there is a default site in the project.");
    return null;
  }

  if (isNil(site.Theme) && isNil(site.ThemeName)) {
    log.error(tag, "Site does not have a theme.  Ensure there is a default theme in this site: '" + site.Id + "'");
    return null;
  }

  if (isObject(site.Theme)) {
    SetTheme(site.Theme);
    return site.Theme;
  }

  log.info(tag, "Theme request", "project.theme.get", site.ThemeName);

  const themeSlug = makeSlug(site.ThemeName);

  const resp = (await client.request("project.theme.get", {
    Id: themeSlug,
  })) as Result<any>;
  if (!resp?.success) {
    log.error(tag, "Service project.theme.get failed", resp?.reasons);
    return false;
  }

  if (!isNil(resp?.data.Entity)) SetTheme(resp?.data.Entity);

  return resp?.data.Entity;
}

export async function GetTemplateFromServer() {
  const tag = "GetTemplateFromServer";

  // if we have it in cache, fetch it from there to avoid a network call
  if (!isNil(GetTemplate())) return GetTemplate();

  const site = await GetSiteFromServer();

  if (isNil(site)) {
    log.error(tag, "Site not found.  Ensure there is a default site in the project.");
    return null;
  }

  if (isNil(site.Template) && isNil(site.TemplateName)) {
    log.error(
      tag,
      "Site does not have a template.  Ensure there is a default template in this site: '" + site.Id + "'"
    );
    return null;
  }

  if (isObject(site.Template)) {
    SetTemplate(site.Template);
    return site.Template;
  }

  log.info(tag, "Template request", "project.template.get", site.TemplateName);

  const templateSlug = makeSlug(site.TemplateName);

  const resp = (await client.request("project.template.get", {
    Id: templateSlug,
  })) as Result<any>;
  if (!resp?.success) {
    log.error(tag, "Service project.template.get failed", resp?.reasons);
    return false;
  }

  if (!isNil(resp?.data.Entity)) SetTemplate(resp?.data.Entity);

  return resp?.data.Entity;
}

export function clearState(url) {
  globalState = {
    Permissions: globalState.Permissions ?? signal(null),
    NGService: {},
    NGTemplate: globalState.NGTemplate ?? signal(null),
    NGTheme: globalState.NGTheme ?? signal(null),
    NGSite: globalState.NGSite ?? signal(null),
    User: globalState.User ?? signal(null),
    Menu: globalState.Menu ?? signal(null),
  };
  iframeState = {};
  initStateFromUrl(url);
}

function mapBindings(f: Field, scope: any, acc: any, isValid: boolean) {
  if (f.Bindings) {
    for (const [k, b] of Object.entries(f.Bindings)) {
      if (b == "Form" && !isNil(scope.Form)) {
        set(f, k, getFormData(scope.Form));
      } else {
        set(f, k, getExprValue(b, scope, null));
      }
      // f.Value = getExprValue(b, scope, null);
      acc[f.Name] = f.Value;
    }
    isValid &&= f.Required ? !isEmpty(f.Value) : true;
  } else {
    acc[f.Name] = f.Value;
  }
  const hasParam = f.Required ? acc[f.Name] !== null && acc[f.Name] !== undefined : true;
  isValid &&= hasParam;
  return isValid;
}

export function mapFields(fields: Field[], scope): [IQuery, boolean] {
  let isValid = true;

  function applySkip(x) {
    return x.Skip != undefined ? !getExprValue(x.Skip, scope, false) : true;
  }

  const params = fields.filter(applySkip).reduce((acc, f) => {
    if (f.Name == "Filter" && isArray(f.Value)) {
      const filterParams = f.Value.filter(applySkip).map((f) => {
        const newVal = {};
        isValid = mapBindings(f, scope, newVal, isValid);
        return {
          Name: f.Name,
          Value: newVal[f.Name],
          Operator: f.Operator ?? "eq",
        };
      });
      acc["Filter"] = filterParams;
    } else {
      isValid = mapBindings(f, scope, acc, isValid);
    }
    return acc;
  }, {});
  // console.log("callService params", params, isValid);
  return [params, isValid];
}

export const defaultContext = {
  path: [],
};

export function getParams(s, scope): any {
  let [params, isValid] = mapFields(s.Fields, scope);
  if (s.Require) {
    isValid &&= getExprValue(s.Require, scope, null);
  }
  return [params, isValid];
}

export function getScope(context: RuntimeContext, config: any, state: any, form: any, event: any, parent: any) {
  const scope = {
    Global: globalState,
    State: state,
    Event: event,
    Form: form,
    Config: config,
    Repeater: context.Repeater,
    FormData: context.Form?.Data.value,
    Parent: parent,
  };
  return scope;
}

export function mapServiceResult(config, scope, data) {
  scope.Result = data;
  for (const [k, v] of Object.entries(config.Bindings)) {
    const src = getExprValue(v, scope, null);
    setExprValue(k, scope, src);
  }
}

export function getServiceResult(config, scope, data) {
  scope.Result = data;
  const result = {};
  for (const [k, v] of Object.entries(config.Bindings)) {
    const src = getExprValue(v, scope, null);
    result[k] = src;
  }
  return result;
}

export async function callServiceLight(
  config,
  params,
  context
): Promise<{ success: boolean; data?: any; error?: any }> {
  const service = { Loading: signal(false), State: signal(null) };
  return callServiceInternal(config, params, service, context);
}

export async function callServiceInternal(
  config,
  params,
  service,
  context
): Promise<{ success: boolean; data?: any; error?: any }> {
  const tag = "service";
  function executeServiceAction(trigger: string, data: any) {
    const endAction = config.Actions?.filter((x) => x.Trigger == trigger);
    if (!isNil(endAction) && endAction.length > 0) {
      for (const a of endAction) {
        // On start action, the data that is passed to the event is the input parameters
        executeAction(a, null, data, context);
      }
    }
  }

  const tracingHeaders = tracing.getHeaders();
  const start = performance.now();
  const isValid = true; // its true because we got here

  log.groupInfo(tag, config.Name, config.Service, isValid ? "Start" : "Skip");
  log.info(tag, "params", params);
  log.info(tag, "paramsValid", isValid);
  log.info(tag, "state", globalState);
  // log.info(tag, "state", state);
  // log.info(tag, "form", form);
  log.info(tag, "tracingHeaders", tracingHeaders);
  log.info(tag, "meta", metadataMap);
  log.groupEnd();

  executeServiceAction("onStart", params);
  service.State.value = "requesting";

  const result = { success: true, data: null };
  if (config.UseSampleData) {
    result.data = config.SampleData;
  } else if (config.Type == "External") {
    try {
      const baseHeaders = config.Headers ?? {};
      const headers = {
        ...baseHeaders,
        ...tracingHeaders,
      };

      const url = new URL(config.Service);
      url.search = new URLSearchParams(params).toString();
      const resp = await fetch(url, {
        headers,
      });
      result.data = await resp.json();
    } catch (e) {
      console.error("Service", config.Name, config.Service, ` failed`, e);
      logFailure(start, e, tracingHeaders);
      service.Loading.value = false;
      service.State.value = "error";
      executeServiceAction("onFailure", e);
      return { success: false };
    }
  } else {
    const resp = (await client.request(config.Service, params, tracingHeaders)) as Result<any>;
    if (!resp?.success) {
      logFailure(start, resp?.reasons, tracingHeaders);
      service.Loading.value = false;
      service.State.value = "error";
      executeServiceAction("onFailure", resp?.reasons);
      return { success: false, error: resp };
    }
    result.data = resp?.data;
  }

  executeServiceAction("onSuccess", { Result: result.data });
  const end = performance.now();
  log.groupInfo(tag, config.Name, config.Service, "End", ` took: ${end - start}ms`);
  log.info(tag, "params", params);
  log.info(tag, "result", result.data);
  log.info(tag, "state", globalState);
  log.info(tag, "tracingHeaders", tracingHeaders);
  // log.info(tag, "state", state);
  // log.info(tag, "form", form);
  log.info(tag, "meta", metadataMap);
  // log.info(tag, "metadata", metadata);
  log.groupEnd();

  return result;

  function logFailure(start: number, error, headers) {
    const end = performance.now();
    log.groupError(tag, config.Name, config.Service, "Failed", ` took: ${end - start}ms`);
    log.info(tag, "params", params);
    log.info(tag, "error", error);
    log.info(tag, "state", globalState);
    log.info(tag, "headers", "RequestId:", headers["NG-RequestId"], "SessionId:", headers["NG-SessionId"]);
    log.info(tag, "meta", metadataMap);
    log.groupEnd();
  }
}

const optionallyDebouncedCallServiceInternal = createOptionalDebouncedFunction(callServiceInternal);

export async function callService(
  config,
  context,
  force = false,
  event: any = null,
  state: any = null,
  form: any = null,
  scope: any = null,
  service: any = null
) {
  if (config.Trigger == "Action" && !force) return false;

  const tag = "service";

  const start = performance.now();

  if (scope == null) {
    const contextState = getState(context);
    state = contextState.state;
    form = contextState.form;
    scope = getScope(context, config, state, form, event, contextState.parentState);

    if (isNil(service)) service = getService(context, config.Name);
  }

  if (config.ConfigBindings) {
    mapConfigBindings(config, scope);
    console.log("ConfigBindings", config);
  }

  const [params, isValid] = getParams(config, scope);

  const tracingHeaders = tracing.getHeaders();

  if (!config.UseSampleData && !isValid) {
    log.groupInfo(tag, config.Name, config.Service, isValid ? "Start" : "Skip");
    log.info(tag, "params", params);
    log.info(tag, "paramsValid", isValid);
    log.info(tag, "state", globalState);
    // log.info(tag, "state", state);
    // log.info(tag, "form", form);
    log.info(tag, "tracingHeaders", tracingHeaders);
    log.info(tag, "meta", metadataMap);
    log.groupEnd();

    return false;
  }

  service.Loading.value = true;
  // await delay(1000);
  // console.log(`callService request:  Service name '${s.Name}', service subject '${s.Service}' `);

  const shouldDebounce = !!config.Debounce;
  const delay = config.Debounce;
  const result = await optionallyDebouncedCallServiceInternal(shouldDebounce, delay, config, params, service, context);
  if (!result.success) {
    const error: any = result.error;
    mapServiceResult(config, scope, error);
    return false;
  }
  let data: any = result.data;

  service.Loading.value = false;
  service.State.value = "success";
  service.Data.value = data;

  // transforms
  if (config.Mappings) {
    const srcTransform = mapJSONPath(config.Mappings.Source, data, {});
    const tgtTransform = mapJSONPath(config.Mappings.Target, srcTransform, {});
    data = { ...srcTransform, ...tgtTransform };
  }

  if (config.Bindings) {
    mapServiceResult(config, scope, data);
  } else if (!config.Form && data != null) {
    // update state
    console.warn("[DEPRECATED]: Bindings for the service are required");
    for (const [k, v] of Object.entries(data)) {
      if (state[k]) {
        state[k].value = v;
      } else {
        state[k] = signal(v);
      }
    }
  }

  return true;
}

export function logState(tag, name, context, componentId, formId) {
  // const { state, form } = getState(context, componentId, formId);
  log.groupInfo(tag, name);
  log.info(tag, "state", globalState);
  // log.info(tag, "state", state);
  // log.info(tag, "form", form);
  log.info(tag, "meta", metadataMap);
  // log.info(tag, "metadata", metadata);
  log.groupEnd();
}

function mapConfigBindings(config: any, scope: any) {
  for (const [k, v] of Object.entries(config.ConfigBindings)) {
    const src = getExprValue(v, scope, null);
    config[k] = src;
  }
}

export function initStateFromUrl(url: string) {
  batch(() => {
    const scope = { State: globalState, Event: null, Form: null, Config: null };

    if (isNullOrEmpty(url)) return;

    const queryParams = new URL(url).searchParams;

    for (const [key, value] of queryParams.entries()) {
      setExprValue(`State.${key}`, scope, value);
    }
  });
}

export function getDefaultContext(): RuntimeContext {
  return { Path: [] };
}

function getPathId(context) {
  return context.path.map((x) => x.__typename + "[" + x.Id + "]").join(".");
}

export function setupPage(config, context: RuntimeContext | null = null) {
  config ||= {};
  metadata.value = config;
  meta.build(config);
  context ||= getDefaultContext();

  return setupServices(config, context);
}

const serviceIntervals: { [key: string]: NodeJS.Timer } = {};

export function setupInitialState(config: any, context: RuntimeContext) {
  if (isNil(config.SampleData)) return;

  if (context.InDesignMode && designerState?.IgnoreSampleDataInEditor.value) {
    clearSampleDataFromState(config, context, "SampleData");
    return;
  }

  if (!context.InDesignMode || designerState?.IgnoreSampleDataInEditor.value)
    if (isNil(config.UseSampleDataToInitializeState) || !config.UseSampleDataToInitializeState) return;

  initializeState(config, context, "SampleData");
}

function clearSampleDataFromState(config, context, from) {
  config.State ||= {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { global, state, form, parentState } = getState(context);

  for (const [k, v] of Object.entries(config[from])) {
    delete state[k];
  }
}

function initializeState(config, context, from) {
  config.State ||= {};

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { global, state, form, parentState } = getState(context);
  const scope = getScope(context, config, state, form, null, parentState);

  for (const [k, v] of Object.entries(config[from])) {
    if (isSignal(state[k])) {
      state[k].value = v;
    } else {
      state[k] = signal(v);
    }
  }
  return { state, scope, form };
}

export function setupServices(config: any, context) {
  config.Services ||= [];
  const { state, scope, form } = initializeState(config, context, "State");
  resolveBindings(config, scope);

  // var id = getPathId(context);
  // set(metadata, , meta);

  const tagService = "service";
  const cleanup = () =>
    Object.keys(serviceIntervals).forEach((s) => {
      clearInterval(serviceIntervals[s]);
      delete serviceIntervals[s];
    });

  state["NGService"] ||= {};
  for (const s of config.Services) {
    if (!isNil(state["NGService"][s.Name])) continue;

    if (typeof s !== "object" || !s.Name) {
      log.error(tagService, "Error: Each element in meta.Services should be an object with a Name property.");
      continue;
    }

    // const ms = (metadata as any).Services.findIndex((x) => x.Name == s.Name);
    // if (ms < 0) (metadata as any).Services.push(s);

    state["NGService"][s.Name] = {
      Config: signal(s),
      Loading: signal(false),
      State: signal("initialized"),
      Progress: signal([]),
      Data: signal({}),
    };
    effect(() => {
      if (s.Trigger == "Subscribe") {
        log.debug(tagService, "Subscribing to", s.Service, s.Name);
        client.subscribe(s.Service, (msg: any) => {
          log.groupInfo(tagService, s.Name, s.Service, ` received message`);
          log.info(tagService, "result", msg);
          log.info(tagService, "state", state);
          log.info(tagService, "form", form);
          log.groupEnd();
          mapServiceResult(s, scope, msg.data);
        });
      } else if (s.Trigger == "Polling") {
        if (serviceIntervals[s.Service]) {
          log.debug(tagService, "Clearing ", s.Service);
          clearInterval(serviceIntervals[s.Service]);
        }
        callService(s, context);
        const unsub = setInterval(() => {
          callService(s, context);
        }, s.RefreshInterval);
        serviceIntervals[s.Service] = unsub;
      } else {
        callService(s, context);
      }
    });
  }

  return cleanup;
}

export function resolveBindings(config: any, scope: any) {
  if (!isObject(config.Bindings)) return;
  // const { state, form, parent } = getState(context);
  // const scope = getScope(context, config, state, form, {}, parent);
  for (const [k, v] of Object.entries(config.Bindings)) {
    if (isNil(v)) continue;
    const src = getExprValue(v, scope, null, false);
    setExprValue(k, scope, src);
  }
}

export function setupForm(context: RuntimeContext) {
  const extractNamesRecursive = (items) => {
    const uniqueNames = new Set();

    const nonControls = ["Container", "Layout"];
    const controls = ["StyleEditor"];

    items.forEach((item) => {
      const type = getTypename(item);
      const isControl = controls.includes(type) || !nonControls.some((x) => type.includes(x));

      if (isControl) {
        if (item.Name) uniqueNames.add(item.Name);
        // if (item.Bindings) {
        //   for (var [k, v] of Object.entries(item.Bindings)) {
        //     collectFormProperties(v).forEach((name) => uniqueNames.add(name));
        //   }
        // }
      }
      if (item.Items) extractNamesRecursive(item.Items).forEach((name) => uniqueNames.add(name));
    });

    return [...uniqueNames];
  };

  effect(() => {
    const { form, formParent } = getState(context);
    // const formData = initAndGetFormDataById(formCtx, form);
    const stateData = context.Form?.Data.value;
    const controls = context.Form?.Config.Items;

    if (stateData == null && formParent?.state[formParent?.id] != null) {
      formParent.state[formParent.id] = {};
    }

    if (!stateData || !controls || isNil(form)) return;

    // Whenever form binding value changes, map it to the child values
    batch(() => {
      // const keys = formCtx.Form.Items.filter((x) => x.Name).map((x) => x.Name);

      // Get full set of keys across mapped object and controls.
      // We need to create a signal for each otherwise Form.Prop won't work properly.
      // const keys = union(extractNamesRecursive(controls), Object.keys(stateData));
      // const keys = union(Object.keys(stateData), extractNamesRecursive(controls));
      // const keys = extractNamesRecursive(state);
      const keys = extractNamesRecursive(controls);
      const formData = form;

      // AA-TODO: inspect metadata and map based on references
      for (const k of keys as string[]) {
        formData[k] ||= signal(null);
        const v = get(stateData, k as string);
        if (!isNil(v)) {
          formData[k].value = v;
        }
        // else if (isSignal(formData[k])) {
        //   formData[k].value = isArray(formData[k].peek()) ? [] : null;
        // }
      }

      // Create any missing properties based on control values
      // const newState = cloneDeep(stateData);
      // for (const k of keys) {
      //   const v = get(newState, k);
      //   if (v == undefined) {
      //     set(newState, k, null);
      //   }
      // }

      // Map state data into signal object
      // formParent.state[formParent.id] = objToSignalRec(newState);

      // for (const k of keys) {
      //   const v = get(stateData, k);
      //   if (v !== undefined) {
      //     form[k] ||= signal(v);
      //     form[k].value = v;
      //     continue;
      //   }

      //   const cv = controls.find((x) => x.Name == k);
      //   if (cv !== undefined) {
      //     form[k] ||= signal(cv.Value);
      //     form[k].value = cv.Value;
      //     continue;
      //   }

      //   if (isSignal(form[k])) {
      //     form[k].value = isArray(form[k].peek()) ? [] : null;
      //   }
      // }

      log.debug("state", "setupForm", context.Form, form, globalState);
    });
  });
}

export function setupIframe(iframeId, iframeRef) {
  iframeState[iframeId] = signal({
    ContentWindow: iframeRef.contentWindow,
    BoundingClientRect: iframeRef.getBoundingClientRect(),
  });
}

export function clearFormById(formId) {
  const form = getFormState(formId);
  if (form) clearForm(form.object?.[formId]);
}

export function clearForm(form) {
  batch(() => {
    for (const [k, v] of Object.entries(form)) {
      if (form[k]) {
        form[k].value = isArray((v as any).value) ? [] : null;
      }
    }
  });
}

// export function getFormData(form) {
//   if (isNil(form)) return {};

//   return Object.entries(form)
//     .filter(([k, v]) => !isNil(v) && has(v, "v"))
//     .reduce((acc, [k, v]: [string, any]) => {
//       set(acc, k, v.value);
//       return acc;
//     }, {});
// }

export function isSignal(obj: any): boolean {
  return !isNil(obj) && has(obj, "v");
}

export function getFormData(form) {
  if (isNil(form)) return {};

  const result = {};

  for (const [k, v] of Object.entries(form)) {
    if (!isNil(v) && has(v, "v")) {
      let value = (v as any).value;

      if (isArray(value)) {
        // map values looking for signals
        value = value.map((x) => (isSignal(x) ? getFormData(x.value) : x));
      } else if (isObject(value)) {
        const entries = Object.entries(value);
        if (entries.length > 0) {
          const [fk, fv] = entries[0];
          const hasSignals = isSignal(fv);
          value = hasSignals ? getFormData(value) : value;
        }
      }

      // if you've changed it, we will set it every time
      // if you haven't touched it, we add the value only if it's not null or empty
      if (!(isNullOrEmpty(value) && (v as any).i === 0)) {
        if (value === "") set(result, k, null);
        else set(result, k, value);
      }
    }
  }

  return result;
}

function objToSignalRec(value) {
  // Check if the value is a primitive or null
  if (value === null || typeof value !== "object") {
    return signal(value);
  }

  // If it's an array, map over the array and apply the function recursively
  if (Array.isArray(value)) {
    return value.map((item) => objToSignalRec(item));
  }

  // If it's an object, apply the function recursively to each property
  const result = {};
  for (const key in value) {
    result[key] = objToSignalRec(value[key]);
  }
  return result;
}

// function objToSignalRec(value) {
//   // Check if the value is a primitive, null, or an instance of signal (to avoid double wrapping)
//   if (value === null || typeof value !== "object" || value instanceof signal) {
//     return signal(value);
//   }

//   // If it's an array, map over the array and apply the function recursively
//   if (Array.isArray(value)) {
//     return signal(value.map((item) => objToSignalRec(item).value)); // Ensure each item is wrapped, then wrap the whole array
//   }

//   // If it's an object, apply the function recursively to each property
//   const result = {};
//   for (const key in value) {
//     result[key] = objToSignalRec(value[key]).value; // Wrap each property, but use .value to avoid double wrapping
//   }
//   return signal(result); // Finally, wrap the whole object
// }

function signalToObjRec(signalObj) {
  // Check if the value is a signal by checking for a .value property
  if (isSignal(signalObj)) {
    return signalToObjRec(signalObj.value);
  }

  // If it's an array, map over the array and apply the function recursively
  if (Array.isArray(signalObj)) {
    return signalObj.map((item) => signalToObjRec(item));
  }

  // If it's an object, apply the function recursively to each property
  if (signalObj !== null && typeof signalObj === "object") {
    const unwrappedObj = {};
    for (const key in signalObj) {
      unwrappedObj[key] = signalToObjRec(signalObj[key]);
    }
    return unwrappedObj;
  }

  // If it's a primitive value, just return it
  return signalObj;
}

function setValue(obj, path, newValue) {
  const keys = path.split(".").map((k) => k.replace(/\[(\d+)\]/, ".$1")); // Convert brackets to dot notation
  let current = obj;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (i === keys.length - 1) {
      // Last key, update the value
      if (current[key] && typeof current[key].value !== "undefined") {
        current[key].value = newValue; // Assuming the new value is not wrapped
      } else {
        // If the key does not exist or is not a signal, wrap the new value
        current[key] = signal(newValue);
      }
    } else {
      // Navigate to the next level
      if (current[key] && typeof current[key].value !== "undefined") {
        current = current[key].value;
      } else {
        console.error("Path not found or not a signal:", path);
        return; // Path not valid or not a signal
      }
    }
  }
}

function isNumeric(str) {
  return !isNaN(str) && !isNaN(parseFloat(str)) && isFinite(str);
}

function getValue(obj, path, defaultValue) {
  const keys = path.split(".").map((k) => k.replace(/\[(\d+)]/, ".$1")); // Keep brackets notation converted to dot notation
  let current = obj;

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const isLastKey = i === keys.length - 1;

    if (isLastKey) {
      // Target property reached
      if (current[key] === undefined) {
        // Initialize with defaultValue
        current[key] = signal(defaultValue);
      }
      return current[key]; // Return the signal
    } else {
      // Intermediate navigation
      if (current[key] === undefined) {
        // Determine if the next key suggests an array or an object
        const nextKeyIsNumeric = isNumeric(keys[i + 1]);
        current[key] = signal(nextKeyIsNumeric ? [] : {});
      }
      current = current[key].value; // Proceed to next level
    }
  }

  return current; // Fallback return, should not be reached
}

export function setupLocalState(config, init, context: RuntimeContext, key: string | null = null) {
  if (isNil(config)) return { ...init };

  const tag = "state.setupLocal";
  const start = performance.now();

  // TODO: Check if this needs to do component as well
  if (config.IgnoreLocalState || context.InDesignMode || config.__typename == "Page") {
    for (const [k, v] of Object.entries(init || {})) {
      if (isSignal(v) && config[k] != null) {
        v.value = config[k];
      }
    }
  }

  const localState = { ...init };

  const { global, state, component, form, parentState } = getState(context);
  // const data = form ?? state;

  // For certain types map local state to global state as it needs to be shared
  const globalTypes = ["Dialog", "ContextMenu", "Snackbar"];
  if (globalTypes.includes(config.__typename)) {
    const group = "NG" + config.__typename;
    state[group] ||= {};
    state[group][config.Id] ||= {};
    const current = state[group][config.Id];
    for (const [k, v] of Object.entries(init || {})) {
      if (current[k] == undefined) {
        current[k] = v;
      } else {
        localState[k] = current[k];
      }
    }
  }

  const scope = getScope(context, config, state, form, {}, parentState);

  // Auto create a binding for form field if it's missing from config
  if (form && config?.Name) {
    const key = getTypename(config) == "Repeater" ? "Rows" : "Value";
    if (form[config.Name] == undefined) {
      form[config.Name] = init[key];
    } else if (isNullOrEmpty(form[config.Name]?.value) && !isNullOrEmpty(init[key]?.value)) {
      form[config.Name] = init[key];
    }
    localState[key] = form[config.Name];
  }

  for (const [k, v] of Object.entries((config.Bindings as Binding) || {})) {
    const defaultValue = init[k]?.value ?? init.DefaultValue?.value;
    const expr = v;

    if (isNullOrEmpty(v)) continue;

    let result = null;
    try {
      const ast: any = getAst(expr);

      // The only time we want to return a signal if we are just accessing top level State property
      // TODO: We may need this for Form as well
      const returnSignal = shouldReturnSignal(ast);

      result = returnSignal
        ? getExprValue(expr, scope, defaultValue, returnSignal)
        : computed(() => getExprValue(expr, scope, defaultValue));

      /* if (!returnSignal && config.CreateSignal && k == "DefaultValue") {
        localState["Value"] = signal(result.value);
      } */

      // Set form state to default value only if value hasn't been altered
      // or if value is bound to an expr
      if (form && !returnSignal && config?.Name) {
        if ((k == "DefaultValue" && !init.Value?.value) || k == "Value") {
          localState["Value"] = form[config.Name] = signal(result.value);
        }
      }
      localState[k] = result;
    } catch (e: any) {
      console.error("interpret error:", expr, e);
      result = null;
    }
  }
  const end = performance.now();

  // TODO: AA temporary disable this to unblock until correct solution is found.
  // applySecurity(localState, context, config);

  log.groupDebug(
    tag,
    (config.Typename ?? config.__typename ?? "") + " - ",
    config.UniqueName ?? config.Name ?? config.Label ?? config.Title ?? config.Id,
    `, took: ${end - start}ms`,
    window.location.href
  );
  log.debug(tag, "state", globalState);
  // log.debug(tag, "state", state);
  // log.debug(tag, "component", component);
  // log.debug(tag, "form", form);
  log.debug(tag, "local", localState);
  log.debug(tag, "meta", metadataMap);
  log.groupEnd();

  return localState;
}

const handlerNames: { [key: string]: string } = {
  processRowUpdate: "onRowUpdate",
};

type State = {
  global: any;
  state: any;
  component: any;
  form: any;
  current: any;
  formParent: any;
  parentState: any;
};

export function shouldReturnSignal(ast: any) {
  return (
    ast.type == "MemberExpression" &&
    ast.object?.type == "Identifier" &&
    isStateVar(ast.object?.name) &&
    ast.property?.type == "Identifier"
  );
}

function flattenPath(path: any[]): string {
  return "" + path.length + " " + path.map((x) => `${x.__typename}:${x.Id}`).join("^");
}

function printCallStack() {
  const error = new Error();
  return error.stack || "No stack trace available";
}

// export function addToContextPath(context: RuntimeContext, config: any, extra: any | null = null) {
//   const tag = "addToContextPath";
//   const tn = getTypename(config);

//   if (isNil(context)) {
//     log.error(tag, "Path no context", config, printCallStack());
//     return context;
//   }

//   const l = context.Path.length;
//   if (l > 0 && context.Path[l - 1].Id === config.Id && context.Path[l - 1].__typename === tn) {
//     context.Path[l - 1] = { ...context.Path[l - 1], ...extra };
//   }

//   return context;
// }

export function updateItemContext(
  context: RuntimeContext,
  config: any,
  extra: any | null = null,
  index: number | null = null
): RuntimeContext {
  const tag = "updateItemContext";
  const tn = getTypename(config);

  const toAdd = {
    Id: config.Id,
    __typename: tn,
    Config: config,
    ...extra,
  };

  if (isNil(context)) {
    log.error(tag, "Path no context", config, printCallStack());
    return context;
  }

  if (isNil(context.Path)) context.Path = [];

  const l = context.Path.length;

  if (l > 0 && context.Path[l - 1].Id === config.Id && context.Path[l - 1].__typename === tn) {
    if (!(context.Path[l - 2]?.__typename == "Feed" && tn == "Component")) {
      log.error(tag, "Duplicate item in path", context, config);
    }
    return context;
  }

  const newContext: RuntimeContext = {
    ...context,
    Path: [...context.Path, toAdd],
  };

  if (!isNil(index))
    newContext.Path.push({
      Id: index.toString(),
      __typename: "Index",
      Config: config,
    });

  // console.log("path", flattenPath(newContext.path));
  meta.addNode(config, newContext);

  return newContext;
}

// export function getComponent(id: string): any {
//   const components = getComponentState(id);
//   return components ? components[id] : null;
// }

// function getComponentState(id: string): any {
//   let currentState = globalState;

//   const components = currentState["NGComponent"];
//   if (!components) return null;
//   if (id in components) return components;

//   for (const v of Object.values(components)) {
//     currentState = v;
//     const components = currentState["NGComponent"];
//     if (components && id in components) return components;
//   }

//   return null;
// }
// actions
// - actions-save
//   - form-x
// - actions-requested
//   - actions-requested-sub
//      - actions-sub-sub

// export function getForm(id: string): any {
//   const forms = getFormState(id);
//   return forms ? forms[id] : null;
// }

// function findNGObjectById(obj: any, objectId: string, objectType: string): any | null {
//   if (obj?.[objectType] && obj[objectType].hasOwnProperty(objectId)) {
//     return obj;
//   }

//   if (obj?.NGComponent) {
//     for (const key of Object.keys(obj.NGComponent)) {
//       const result = findNGObjectById(obj.NGComponent[key], objectId, objectType);
//       if (result) return result;
//     }
//   }

//   return null;
// }

function findNGObjectByIdWithParent(
  structure: any,
  objectId: string,
  objectType: string
): { object: any; parent: any } | null {
  const stack = [{ node: structure, parent: structure }]; // Include parent information in the stack

  while (stack.length > 0) {
    const { node, parent } = stack.pop()!;

    // Check if this node contains the target object
    if (node?.[objectType] && node[objectType].hasOwnProperty(objectId)) {
      return { object: node[objectType], parent };
    }

    // Traverse only through NGComponent properties, keeping track of the current node as the parent
    if (node?.NGComponent) {
      Object.keys(node.NGComponent).forEach((key) => {
        stack.push({ node: node.NGComponent[key], parent: node });
      });
    }
  }

  return null;
}

const cleanObject = (obj: any, preserveKeys: string[], continueRecursing: string[]): any => {
  if (Array.isArray(obj)) {
    return obj.map((item) => cleanObject(item, preserveKeys, continueRecursing));
  } else if (typeof obj === "object" && obj !== null) {
    Object.keys(obj).forEach((key) => {
      if (!preserveKeys.includes(key)) {
        delete obj[key];
      } else {
        if (continueRecursing.includes(key)) obj[key] = cleanObject(obj[key], preserveKeys, continueRecursing);
      }
    });
  }
  return obj;
};

export function clearModalComponentState(context: RuntimeContext, modalId: string) {
  // const { state } = getState(context, null, null);
  // const componentIdToClear = context.Path.find((c) => c.Id === modalId)?.Config?.ContentContainer?.Items[0]
  //   ?.ReferenceId;
  // if (componentIdToClear && state["NGComponent"] && state["NGComponent"][componentIdToClear]) {
  //   state["NGComponent"][componentIdToClear] = cleanObject(
  //     state["NGComponent"]?.[componentIdToClear],
  //     ["NGService", "NGComponent", "NGForm", "NGDialog", "NGContextMenu"],
  //     ["NGComponent"]
  //   );
  // }
}

// function findNGObjectByIdImperative(structure: any, objectId: string, objectType: string): any | null {
//   const stack = [structure];
//   while (stack.length > 0) {
//     const current = stack.pop();
//     if (current?.[objectType] && current[objectType].hasOwnProperty(objectId)) {
//       return current[objectType];
//     }
//     if (current?.NGComponent) {
//       Object.values(current.NGComponent).forEach((value) => stack.push(value));
//     }
//   }
//   return null;
// }

export function getStateWithFallback(context, id, key, componentId = null, formId = null) {
  const { global, state } = getState(context, componentId, formId);
  if (state[key] && state[key][id]) {
    return state[key][id];
  } else {
    const { object } = getComponentState(componentId) ?? { object: null };
    if (object == null) return null;

    const state2 = object[componentId];
    if (state2[key] && state2[key][id]) {
      return state2[key][id];
    }
  }
  return global[key][id];
}

export function getModalPopup(context, id, componentId = null, formId = null) {
  // const { global, state } = getState(context, componentId, formId);

  if (isNullOrEmpty(componentId)) {
    for (let i = context.Path.length - 1; i >= 0; i--) {
      const tn = context.Path[i].__typename;

      if (tn == "Component") {
        const st = getStateWithFallback(context, id, "NGDialog", context.Path[i].Id, formId);

        if (!isNil(st)) return st;
      }
    }
  }

  return getStateWithFallback(context, id, "NGDialog", componentId, formId);
  // return findNGObjectByIdImperative(globalState, id, "NGDialog");
}

export function getContextMenu(context, id, componentId = null, formId = null) {
  return getStateWithFallback(context, id, "NGContextMenu", componentId, formId);
  // return findNGObjectByIdImperative(globalState, id, "NGContextMenu");
}

export function getService(context, id, componentId = null, formId = null) {
  const service = getStateWithFallback(context, id, "NGService", componentId, formId);

  if (isNil(service)) {
    const services = findNGObjectByIdWithParent(globalState, id, "NGService");

    return services?.object[id];
  }

  return service;
}

function getComponentState(id: string): any {
  return findNGObjectByIdWithParent(globalState, id, "NGComponent");
}

export function getFormState(id: string): any {
  return findNGObjectByIdWithParent(globalState, id, "NGForm");
}

function applySecurity(localState: any, context: RuntimeContext, config: any) {
  if (!localState["Disabled"]?.value) {
    localState["Disabled"] = computed(() => !isActionAllowed(context, config));
  }
}

function isActionAllowed(context, config): boolean {
  if (globalState.Permissions?.value == null) return true;
  const services = extractDistinctServiceNames(context, config);
  return services.every((service) => globalState.Permissions.value?.Services.includes(service) ?? false);
}

function extractDistinctServiceNames(context, config): string[] {
  const services = new Set<string>();
  const { controlToService, actionToService } = permissionMap;

  const control = getTypename(config);
  if (control != null && controlToService[control]) {
    services.add(controlToService[control]);
  }

  config.Actions?.forEach((action) => {
    action.CommandSet?.Commands?.forEach((command) => {
      const instructionName = command.Instruction?.Name;

      if (instructionName === "CallService") {
        // Extract ServiceName parameter for CallService instructions
        const serviceParam = command.Parameters?.find((param) => param.Name === "ServiceName");
        if (serviceParam) {
          const svc = getService(context, serviceParam.Value);
          if (svc != null) {
            services.add(svc.Config.value.Service);
          }
        }
      } else if (actionToService[instructionName]) {
        services.add(actionToService[instructionName]);
      }
    });
  });

  return Array.from(services);
}

export function getState(
  context: RuntimeContext,
  componentId: string | null = null,
  formId: string | null = null,
  force = false
): State {
  const global = globalState;
  let curState = global;
  let component: any = null;
  let form: any = null;
  let formParent: any = null;
  let parentState: any = null;

  for (let i = 0; i < context.Path.length; i++) {
    const { Id: id, __typename, IsForm: isForm, Name: name, Config } = context.Path[i];
    const type = isForm ? "Form" : __typename;
    const group = "NG" + type;

    switch (type) {
      // case "ContextMenu":
      // case "Dialog":
      case "Form":
      case "Component":
        if (type === "Form" && !isNil(form)) curState = component ?? global;

        curState[group] ||= {};
        curState[group][id] ||= {}; // Initialize if not already set.

        // Specific assignments based on the type.
        if (type === "Form") {
          formParent = { id, state: curState[group] };
          form = curState[group][id];
        } else if (type === "Component") {
          parentState = component;
          component = curState[group][id];
        }

        curState = curState[group][id]; // Move to the specific id in the group.
        break;

      case "Repeater":
        {
          let repeater;

          if (form != null && !Config.ExcludeFromForm) {
            // formState[parentFormId] ||= signal({});
            // const parentForm = formState[parentFormId].value;
            const fieldId = name ?? id;
            form[fieldId] ||= signal([]);
            form[fieldId].value ||= [];
            repeater = form[fieldId].value;
            // parentForm[fieldId] ||= signal([]);
            // const repeaterState = parentForm[fieldId].value;
            // repeaterState[repeater.Index] ||= signal({});
            // formData = repeaterState[repeater.Index];
          } else {
            curState[group] ||= {};
            curState[group][id] ||= []; // Initialize as an array for 'Repeater'.
            repeater = curState[group][id];
          }

          const index = context.Path[++i];
          const idx = parseInt(index.Id, 10);
          repeater[idx] ||= {}; // Ensure the specific index is initialized.

          const value = context.Path[++i];
          if (!isNil(value)) {
            const type2 = value.IsForm ? "Form" : value.__typename;
            // Handle the nested switch for 'Component' and 'Form' within 'Repeater'.
            switch (type2) {
              case "Component":
                parentState = component;
                component = repeater[idx];
                break;
              case "Form":
                form = repeater[idx];
                formParent = { id: idx, state: repeater };
                break;
            }
          }
          curState = repeater[idx]; // Move to the specific index in the repeater.
        }
        break;
    }

    if (id === formId) break;
  }

  if (force || (componentId != null && isNil(component))) {
    // if (componentId != null) {
    const result = getComponentState(componentId);
    if (result != null) {
      parentState = result.parent;
      component = result.object[componentId];
    }
  }

  if (force || (formId != null && isNil(form))) {
    // if (formId != null) {
    const result = getFormState(formId);
    if (result != null) {
      parentState = result.parent;
      form = result.object[formId];
    }
  }

  const state = component ?? global;
  parentState ||= global;
  return { global, state, component, form, formParent, parentState };
}

function getHandlerName(trigger: string) {
  return handlerNames[trigger] || trigger;
}

export function setupHandlers(config, context: RuntimeContext, location?: Location<any>) {
  const handlers = {};
  const actions = config.Actions ?? [];
  // Action is array, now check location
  if (isComponentGuiRoute(location)) {
    guiActions.forEach((action) => {
      const exists = some(actions, (item) => isEqual(item, action));
      if (!exists) actions.push(action);
    });
  }

  // this will execute for all parents, no matter their depth. For example, both a grandparent and a parent component will execute its actions if the grandchild triggers it.
  // const parentActions = context.Path.reduce((acc, x) => {
  //   if (x.Id=="Grouping"){ //.StopPropagation === true) {
  //     return acc;
  //   }
  //   if (x.Actions) {
  //     acc = acc.concat(x.Actions.filter((a) => a.Trigger.startsWith("onChild")));
  //   }
  //   return acc;
  // }, []);

  const parentActions: any[] = [];
  for (let i = context.Path.length - 1; i >= 0; i--) {
    const x = context.Path[i];

    if (x.Config.Actions) {
      const end = x.Config.__typename === "PropertiesEditor" ? 2 : 1;
      const pa = {
        Actions: [],
        Context: { ...context, Path: context.Path.slice(0, i + end) },
      };
      //pa.Actions = pa.Actions.concat(x.Config.Actions.filter((a) => a.Trigger.startsWith("onChild")));
      pa.Actions = pa.Actions.concat(x.Config.Actions.filter((a) => a?.ListenTo?.length > 0));
      parentActions.push(pa);
    }
    if (x.Id == "Grouping") break;
  }

  if (!isArray(actions)) return handlers;
  for (const a of actions) {
    const handlerName = getHandlerName(a?.Trigger);

    handlers[handlerName] = (e, e2) => {
      // const sysHandler = context.Handlers?.[handlerName];
      // if (sysHandler) {
      //   const stop = sysHandler(a, e, e2, null, context);
      //   if (stop) return;
      // }

      if (!isNil(config.getData)) {
        const d = config.getData(e, e2);

        if (a.preHandler) a.preHandler(handlerName, a, e, e2, d, context);
        executeAction(a, e, d, context);
        if (a.postHandler) a.postHandler(handlerName, a, e, e2, d, context);
      } else {
        if (a.preHandler) a.preHandler(handlerName, a, e, e2, context);
        executeAction(a, e, e2, context);
        if (a.postHandler) a.postHandler(handlerName, a, e, e2, context);
      }

      // execute parent actions
      //const event = a.Trigger.replace("on", "");
      for (const v of parentActions) {
        for (const pa of v.Actions) {
          //if (pa.Trigger == "onChild" + event) {
          if (pa?.ListenTo?.includes(a.Trigger)) {
            if (pa.preHandler) pa.preHandler(pa.Trigger, pa, e, e2, v.Context);
            executeAction(pa, e, e2, v.Context);
            if (pa.postHandler) pa.postHandler(pa.Trigger, pa, e, e2, v.Context);
          }
        }
      }
    };
  }
  return handlers;
}

function mapJSONPath(o, src, target) {
  return o
    ? Object.entries(o).reduce((acc, [k, v]) => {
        switch (typeof v) {
          case "object":
            const data = JSONPath({ path: v.Selector, json: src, wrap: false });
            if (Array.isArray(data)) {
              acc[k] = data.map((oo) =>
                Object.entries(oo).reduce((accInner, [kk, vv]) => {
                  if (v.Map && v.Map[kk]) {
                    const m = v.Map[kk];
                    accInner[m.To] = m.Selector ? JSONPath({ path: m.Selector, json: vv, wrap: false }) : vv;
                  } else if (v.IncludeAll) {
                    accInner[kk] = vv;
                  }
                  console.log("map inner obj", oo, kk, vv, accInner);
                  return accInner;
                }, {})
              );
            } else {
              acc[k] = Object.entries(v.Map).reduce((accInner, [kk, vv]) => {
                accInner[kk] = JSONPath({ path: vv, json: oo, wrap: false });
                console.log("map inner obj", oo, kk, vv, accInner);
                return accInner;
              }, {});
            }
            break;
          case "string":
          default:
            acc[k] = JSONPath({ path: v, json: src, wrap: false });
            break;
        }
        return acc;
      }, target)
    : target;
}
