const serviceSampleData = {
  "openapi": "3.0.1",
  "info": {
    "title": "Platform API",
    "version": "v1"
  },
  "paths": {
    "/api/account/create": {
      "post": {
        "tags": [
          "Account"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/account/delete": {
      "post": {
        "tags": [
          "Account"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/account/get": {
      "post": {
        "tags": [
          "Account"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/account/query": {
      "post": {
        "tags": [
          "Account"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/account/query-without-users": {
      "post": {
        "tags": [
          "Account"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountQueryWithoutUsersRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountQueryWithoutUsersResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/account/update": {
      "post": {
        "tags": [
          "Account"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/accountbalance/query": {
      "post": {
        "tags": [
          "AccountBalance"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountBalanceQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountBalanceDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/accountfinancialsummary/get": {
      "post": {
        "tags": [
          "AccountFinancialSummary"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountFinancialSummaryGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountFinancialSummaryGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/accountmetric/create": {
      "post": {
        "tags": [
          "AccountMetric"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountMetricCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountMetricCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/accountmetric/delete": {
      "post": {
        "tags": [
          "AccountMetric"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountMetricDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountMetricDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/accountmetric/get": {
      "post": {
        "tags": [
          "AccountMetric"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountMetricGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountMetricGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/accountmetric/query": {
      "post": {
        "tags": [
          "AccountMetric"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountMetricQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountMetricDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/accountmetric/update": {
      "post": {
        "tags": [
          "AccountMetric"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountMetricUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountMetricUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/accountsummary/get": {
      "post": {
        "tags": [
          "AccountSummary"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AccountSummaryGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AccountSummaryGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/action/delete": {
      "post": {
        "tags": [
          "Action"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/ActionDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ActionDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/action/get": {
      "post": {
        "tags": [
          "Action"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/ActionGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ActionGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/action/merge": {
      "post": {
        "tags": [
          "Action"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/ActionMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ActionMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/action/query": {
      "post": {
        "tags": [
          "Action"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/ActionQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ActionDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/adminkpi/query-dashboard": {
      "post": {
        "tags": [
          "AdminKPI"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AdminKPIQueryDashboardRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AdminKPIQueryDashboardResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/adminkpi/query-notification": {
      "post": {
        "tags": [
          "AdminKPI"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AdminKPIQueryNotificationRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AdminKPIQueryNotificationResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/test-cookies": {
      "post": {
        "tags": [
          "Api"
        ],
        "responses": {
          "200": {
            "description": "OK"
          }
        }
      }
    },
    "/api/test-email": {
      "get": {
        "tags": [
          "Api"
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/String<>f__AnonymousType0"
                }
              }
            }
          }
        }
      }
    },
    "/api/swagger": {
      "get": {
        "tags": [
          "Api"
        ],
        "responses": {
          "200": {
            "description": "OK"
          }
        }
      }
    },
    "/api/ingest-users-file": {
      "post": {
        "tags": [
          "Api"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/IngestFileEntityMergeRequest"
              }
            }
          },
          "required": true
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/IngestMergeUserResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/ingest-accounts-file": {
      "post": {
        "tags": [
          "Api"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/IngestFileEntityMergeRequest"
              }
            }
          },
          "required": true
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/IngestMergeAccountResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/ingest-users-to-accounts-file": {
      "post": {
        "tags": [
          "Api"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/IngestFileEntityMergeRequest"
              }
            }
          },
          "required": true
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/IngestMergeUserToAccountResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/ingest-file-manifest": {
      "post": {
        "tags": [
          "Api"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/IngestFileEntityMergeRequest"
              }
            }
          },
          "required": true
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/IngestMergeManifestResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/auth/register": {
      "post": {
        "tags": [
          "Api"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/RegisterUserRequest"
              }
            }
          },
          "required": true
        },
        "responses": {
          "200": {
            "description": "OK"
          }
        }
      }
    },
    "/api/auth/login": {
      "post": {
        "tags": [
          "Api"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/LoginUserRequest"
              }
            }
          },
          "required": true
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/LoginUserResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/auth/post-login": {
      "get": {
        "tags": [
          "Api"
        ],
        "parameters": [
          {
            "name": "isImpersonation",
            "in": "query",
            "schema": {
              "type": "boolean"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PostLoginUserResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/auth/refresh-token": {
      "post": {
        "tags": [
          "Api"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/RefreshTokenRequest"
              }
            }
          },
          "required": true
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/RefreshTokenResponse"
                }
              }
            }
          }
        }
      }
    },
    "/api/test/tenant": {
      "get": {
        "tags": [
          "Api"
        ],
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/StringString<>f__AnonymousType1"
                }
              }
            }
          }
        }
      }
    },
    "/api/files/repository/{fileName}": {
      "get": {
        "tags": [
          "Api"
        ],
        "parameters": [
          {
            "name": "fileName",
            "in": "path",
            "required": true,
            "schema": {
              "type": "string"
            }
          }
        ],
        "responses": {
          "400": {
            "description": "Bad Request"
          },
          "404": {
            "description": "Not Found"
          }
        }
      }
    },
    "/api/files/nats/{bucket}/{fileName}": {
      "get": {
        "tags": [
          "Api"
        ],
        "parameters": [
          {
            "name": "bucket",
            "in": "path",
            "required": true,
            "schema": {
              "type": "string"
            }
          },
          {
            "name": "fileName",
            "in": "path",
            "required": true,
            "schema": {
              "type": "string"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK"
          }
        }
      },
      "put": {
        "tags": [
          "Api"
        ],
        "parameters": [
          {
            "name": "bucket",
            "in": "path",
            "required": true,
            "schema": {
              "type": "string"
            }
          },
          {
            "name": "fileName",
            "in": "path",
            "required": true,
            "schema": {
              "type": "string"
            }
          }
        ],
        "requestBody": {
          "content": {},
          "required": true
        },
        "responses": {
          "200": {
            "description": "OK"
          }
        }
      }
    },
    "/api/files/docs/{title}": {
      "get": {
        "tags": [
          "Api"
        ],
        "parameters": [
          {
            "name": "title",
            "in": "path",
            "required": true,
            "schema": {
              "type": "string"
            }
          },
          {
            "name": "documentIds",
            "in": "query",
            "required": true,
            "schema": {
              "type": "string"
            }
          },
          {
            "name": "download",
            "in": "query",
            "schema": {
              "type": "boolean"
            }
          },
          {
            "name": "timezone",
            "in": "query",
            "schema": {
              "type": "string",
              "default": "UTC"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK"
          }
        }
      }
    },
    "/api/files/drafts/{title}": {
      "get": {
        "tags": [
          "Api"
        ],
        "parameters": [
          {
            "name": "title",
            "in": "path",
            "required": true,
            "schema": {
              "type": "string"
            }
          },
          {
            "name": "documentIds",
            "in": "query",
            "required": true,
            "schema": {
              "type": "string"
            }
          },
          {
            "name": "download",
            "in": "query",
            "schema": {
              "type": "boolean"
            }
          },
          {
            "name": "timezone",
            "in": "query",
            "schema": {
              "type": "string",
              "default": "UTC"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK"
          }
        }
      }
    },
    "/api/files/vault/{fileName}": {
      "get": {
        "tags": [
          "Api"
        ],
        "parameters": [
          {
            "name": "fileName",
            "in": "path",
            "required": true,
            "schema": {
              "type": "string"
            }
          }
        ],
        "responses": {
          "200": {
            "description": "OK"
          }
        }
      }
    },
    "/api/apxpackages/query": {
      "post": {
        "tags": [
          "ApxPackages"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/ApxPackagesQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ApxPackagesDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/auth/create-calc-entitlements": {
      "post": {
        "tags": [
          "Auth"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AuthCreateCalcEntitlementsRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AuthCreateCalcEntitlementsResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/auth/create-impersonate": {
      "post": {
        "tags": [
          "Auth"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AuthCreateImpersonateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AuthCreateImpersonateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/auth/update-request-reset-password": {
      "post": {
        "tags": [
          "Auth"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AuthUpdateRequestResetPasswordRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AuthUpdateRequestResetPasswordResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/auth/update-set-password": {
      "post": {
        "tags": [
          "Auth"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AuthUpdateSetPasswordRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AuthUpdateSetPasswordResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/authorizedemail/create": {
      "post": {
        "tags": [
          "AuthorizedEmail"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AuthorizedEmailCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AuthorizedEmailCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/authorizedemail/delete": {
      "post": {
        "tags": [
          "AuthorizedEmail"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AuthorizedEmailDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AuthorizedEmailDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/authorizedemail/get": {
      "post": {
        "tags": [
          "AuthorizedEmail"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AuthorizedEmailGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AuthorizedEmailGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/authorizedemail/query": {
      "post": {
        "tags": [
          "AuthorizedEmail"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AuthorizedEmailQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AuthorizedEmailDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/authorizedemail/update": {
      "post": {
        "tags": [
          "AuthorizedEmail"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/AuthorizedEmailUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/AuthorizedEmailUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/codeset/get": {
      "post": {
        "tags": [
          "CodeSet"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/CodeSetGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/CodeSetGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/codeset/query": {
      "post": {
        "tags": [
          "CodeSet"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/CodeSetQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/CodeSetDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/component/get": {
      "post": {
        "tags": [
          "Component"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/ComponentGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ComponentGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/document/create": {
      "post": {
        "tags": [
          "Document"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/document/create-bulk": {
      "post": {
        "tags": [
          "Document"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentCreateBulkRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentCreateBulkResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/document/create-share": {
      "post": {
        "tags": [
          "Document"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentCreateShareRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentCreateShareResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/document/create-share-with-all-users": {
      "post": {
        "tags": [
          "Document"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentCreateShareWithAllUsersRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentCreateShareWithAllUsersResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/document/delete": {
      "post": {
        "tags": [
          "Document"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/document/get": {
      "post": {
        "tags": [
          "Document"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/document/query": {
      "post": {
        "tags": [
          "Document"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/document/update": {
      "post": {
        "tags": [
          "Document"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/document/update-bulk": {
      "post": {
        "tags": [
          "Document"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentUpdateBulkRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentUpdateBulkResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/document/update-expire": {
      "post": {
        "tags": [
          "Document"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentUpdateExpireRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentUpdateExpireResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentbatch/create": {
      "post": {
        "tags": [
          "DocumentBatch"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentBatchCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentBatchCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentbatch/delete": {
      "post": {
        "tags": [
          "DocumentBatch"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentBatchDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentBatchDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentbatch/get": {
      "post": {
        "tags": [
          "DocumentBatch"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentBatchGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentBatchGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentbatch/query": {
      "post": {
        "tags": [
          "DocumentBatch"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentBatchQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentBatchDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentbatch/update": {
      "post": {
        "tags": [
          "DocumentBatch"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentBatchUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentBatchUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentdraft/create": {
      "post": {
        "tags": [
          "DocumentDraft"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentDraftCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentDraftCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentdraft/create-publish": {
      "post": {
        "tags": [
          "DocumentDraft"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentDraftCreatePublishRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentDraftCreatePublishResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentdraft/create-share-with-all-users": {
      "post": {
        "tags": [
          "DocumentDraft"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentDraftCreateShareWithAllUsersRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentDraftCreateShareWithAllUsersResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentdraft/delete": {
      "post": {
        "tags": [
          "DocumentDraft"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentDraftDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentDraftDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentdraft/delete-publish": {
      "post": {
        "tags": [
          "DocumentDraft"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentDraftDeletePublishRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentDraftDeletePublishResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentdraft/get": {
      "post": {
        "tags": [
          "DocumentDraft"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentDraftGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentDraftGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentdraft/query": {
      "post": {
        "tags": [
          "DocumentDraft"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentDraftQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentDraftDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentdraft/update": {
      "post": {
        "tags": [
          "DocumentDraft"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentDraftUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentDraftUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentdraft/update-status": {
      "post": {
        "tags": [
          "DocumentDraft"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentDraftUpdateStatusRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentDraftUpdateStatusResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentdraft/update-status-bulk": {
      "post": {
        "tags": [
          "DocumentDraft"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentDraftUpdateStatusBulkRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentDraftUpdateStatusBulkResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentdraftaccount/query": {
      "post": {
        "tags": [
          "DocumentDraftAccount"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentDraftAccountQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentDraftAccountDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentworkflow/query": {
      "post": {
        "tags": [
          "DocumentWorkflow"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentWorkflowQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentWorkflowDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/documentworkflow/query-tlc": {
      "post": {
        "tags": [
          "DocumentWorkflow"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/DocumentWorkflowQueryTlcRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/DocumentWorkflowQueryTlcResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/eventuserentitlement/update-many": {
      "post": {
        "tags": [
          "EventUserEntitlement"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/EventUserEntitlementUpdateManyRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/EventUserEntitlementUpdateManyResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/feed/get": {
      "post": {
        "tags": [
          "Feed"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/FeedGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/FeedGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/finapptoken/create": {
      "post": {
        "tags": [
          "FinAppToken"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/FinAppTokenCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/FinAppTokenCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/finapptoken/delete": {
      "post": {
        "tags": [
          "FinAppToken"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/FinAppTokenDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/FinAppTokenDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/form/get": {
      "post": {
        "tags": [
          "Form"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/FormGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/FormGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/formats/get": {
      "post": {
        "tags": [
          "Formats"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/FormatsGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/FormatsGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/formats/merge": {
      "post": {
        "tags": [
          "Formats"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/FormatsMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/FormatsMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/formats/query": {
      "post": {
        "tags": [
          "Formats"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/FormatsQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/JsonObjectQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holding/create": {
      "post": {
        "tags": [
          "Holding"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holding/delete": {
      "post": {
        "tags": [
          "Holding"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holding/get": {
      "post": {
        "tags": [
          "Holding"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holding/query": {
      "post": {
        "tags": [
          "Holding"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holding/update": {
      "post": {
        "tags": [
          "Holding"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingcurrency/create": {
      "post": {
        "tags": [
          "HoldingCurrency"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingCurrencyCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingCurrencyCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingcurrency/delete": {
      "post": {
        "tags": [
          "HoldingCurrency"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingCurrencyDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingCurrencyDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingcurrency/get": {
      "post": {
        "tags": [
          "HoldingCurrency"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingCurrencyGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingCurrencyGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingcurrency/query": {
      "post": {
        "tags": [
          "HoldingCurrency"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingCurrencyQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingCurrencyDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingcurrency/update": {
      "post": {
        "tags": [
          "HoldingCurrency"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingCurrencyUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingCurrencyUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingsnapshot/create": {
      "post": {
        "tags": [
          "HoldingSnapshot"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingSnapshotCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingSnapshotCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingsnapshot/delete": {
      "post": {
        "tags": [
          "HoldingSnapshot"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingSnapshotDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingSnapshotDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingsnapshot/get": {
      "post": {
        "tags": [
          "HoldingSnapshot"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingSnapshotGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingSnapshotGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingsnapshot/query": {
      "post": {
        "tags": [
          "HoldingSnapshot"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingSnapshotQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingSnapshotDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingsnapshot/update": {
      "post": {
        "tags": [
          "HoldingSnapshot"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingSnapshotUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingSnapshotUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingsnapshothistory/create": {
      "post": {
        "tags": [
          "HoldingSnapshotHistory"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingSnapshotHistoryCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingSnapshotHistoryCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingsnapshothistory/delete": {
      "post": {
        "tags": [
          "HoldingSnapshotHistory"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingSnapshotHistoryDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingSnapshotHistoryDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingsnapshothistory/get": {
      "post": {
        "tags": [
          "HoldingSnapshotHistory"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingSnapshotHistoryGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingSnapshotHistoryGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingsnapshothistory/query": {
      "post": {
        "tags": [
          "HoldingSnapshotHistory"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingSnapshotHistoryQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingSnapshotHistoryDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingsnapshothistory/update": {
      "post": {
        "tags": [
          "HoldingSnapshotHistory"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingSnapshotHistoryUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingSnapshotHistoryUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingtranslation/create": {
      "post": {
        "tags": [
          "HoldingTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingTranslationCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingTranslationCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingtranslation/delete": {
      "post": {
        "tags": [
          "HoldingTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingTranslationDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingTranslationDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingtranslation/get": {
      "post": {
        "tags": [
          "HoldingTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingTranslationGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingTranslationGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingtranslation/query": {
      "post": {
        "tags": [
          "HoldingTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingTranslationQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingTranslationDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/holdingtranslation/update": {
      "post": {
        "tags": [
          "HoldingTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/HoldingTranslationUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/HoldingTranslationUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/impersonationrequest/create": {
      "post": {
        "tags": [
          "ImpersonationRequest"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/ImpersonationRequestCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ImpersonationRequestCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/impersonationrequest/get": {
      "post": {
        "tags": [
          "ImpersonationRequest"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/ImpersonationRequestGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ImpersonationRequestGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/ingest/merge-account": {
      "post": {
        "tags": [
          "Ingest"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/IngestMergeAccountRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/IngestMergeAccountResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/ingest/merge-manifest": {
      "post": {
        "tags": [
          "Ingest"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/IngestMergeManifestRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/IngestMergeManifestResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/ingest/merge-user": {
      "post": {
        "tags": [
          "Ingest"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/IngestMergeUserRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/IngestMergeUserResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/ingest/merge-user-to-account": {
      "post": {
        "tags": [
          "Ingest"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/IngestMergeUserToAccountRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/IngestMergeUserToAccountResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/ingestfileentity/merge": {
      "post": {
        "tags": [
          "IngestFileEntity"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/IngestFileEntityMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/IngestFileEntityMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insuranceauto/create": {
      "post": {
        "tags": [
          "InsuranceAuto"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceAutoCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceAutoCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insuranceauto/delete": {
      "post": {
        "tags": [
          "InsuranceAuto"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceAutoDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceAutoDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insuranceauto/get": {
      "post": {
        "tags": [
          "InsuranceAuto"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceAutoGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceAutoGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insuranceauto/query": {
      "post": {
        "tags": [
          "InsuranceAuto"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceAutoQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceAutoDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insuranceauto/update": {
      "post": {
        "tags": [
          "InsuranceAuto"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceAutoUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceAutoUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancehome/create": {
      "post": {
        "tags": [
          "InsuranceHome"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceHomeCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceHomeCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancehome/delete": {
      "post": {
        "tags": [
          "InsuranceHome"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceHomeDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceHomeDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancehome/get": {
      "post": {
        "tags": [
          "InsuranceHome"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceHomeGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceHomeGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancehome/query": {
      "post": {
        "tags": [
          "InsuranceHome"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceHomeQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceHomeDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancehome/update": {
      "post": {
        "tags": [
          "InsuranceHome"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceHomeUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceHomeUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancelife/create": {
      "post": {
        "tags": [
          "InsuranceLife"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceLifeCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceLifeCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancelife/delete": {
      "post": {
        "tags": [
          "InsuranceLife"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceLifeDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceLifeDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancelife/get": {
      "post": {
        "tags": [
          "InsuranceLife"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceLifeGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceLifeGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancelife/query": {
      "post": {
        "tags": [
          "InsuranceLife"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceLifeQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceLifeDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancelife/update": {
      "post": {
        "tags": [
          "InsuranceLife"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceLifeUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceLifeUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancelongtermcare/create": {
      "post": {
        "tags": [
          "InsuranceLongTermCare"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceLongTermCareCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceLongTermCareCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancelongtermcare/delete": {
      "post": {
        "tags": [
          "InsuranceLongTermCare"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceLongTermCareDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceLongTermCareDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancelongtermcare/get": {
      "post": {
        "tags": [
          "InsuranceLongTermCare"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceLongTermCareGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceLongTermCareGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancelongtermcare/query": {
      "post": {
        "tags": [
          "InsuranceLongTermCare"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceLongTermCareQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceLongTermCareDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancelongtermcare/update": {
      "post": {
        "tags": [
          "InsuranceLongTermCare"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceLongTermCareUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceLongTermCareUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancepolicy/create": {
      "post": {
        "tags": [
          "InsurancePolicy"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsurancePolicyCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsurancePolicyCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancepolicy/delete": {
      "post": {
        "tags": [
          "InsurancePolicy"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsurancePolicyDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsurancePolicyDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancepolicy/get": {
      "post": {
        "tags": [
          "InsurancePolicy"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsurancePolicyGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsurancePolicyGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancepolicy/query": {
      "post": {
        "tags": [
          "InsurancePolicy"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsurancePolicyQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsurancePolicyDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insurancepolicy/update": {
      "post": {
        "tags": [
          "InsurancePolicy"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsurancePolicyUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsurancePolicyUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insuranceumbrella/create": {
      "post": {
        "tags": [
          "InsuranceUmbrella"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceUmbrellaCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceUmbrellaCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insuranceumbrella/delete": {
      "post": {
        "tags": [
          "InsuranceUmbrella"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceUmbrellaDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceUmbrellaDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insuranceumbrella/get": {
      "post": {
        "tags": [
          "InsuranceUmbrella"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceUmbrellaGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceUmbrellaGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insuranceumbrella/query": {
      "post": {
        "tags": [
          "InsuranceUmbrella"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceUmbrellaQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceUmbrellaDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/insuranceumbrella/update": {
      "post": {
        "tags": [
          "InsuranceUmbrella"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/InsuranceUmbrellaUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/InsuranceUmbrellaUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/latestusernotificationsbyclassification/query": {
      "post": {
        "tags": [
          "LatestUserNotificationsByClassification"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/LatestUserNotificationsByClassificationQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/LatestUserNotificationsByClassificationDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/menu/update-assignment": {
      "post": {
        "tags": [
          "Menu"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/MenuUpdateAssignmentRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/MenuUpdateAssignmentResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/migration/create-reset-password-keycloak": {
      "post": {
        "tags": [
          "Migration"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/MigrationCreateResetPasswordKeycloakRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/MigrationCreateResetPasswordKeycloakResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/migration/create-reset-password-keycloak-bulk": {
      "post": {
        "tags": [
          "Migration"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/MigrationCreateResetPasswordKeycloakBulkRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/MigrationCreateResetPasswordKeycloakBulkResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/migration/create-user-action-keycloak": {
      "post": {
        "tags": [
          "Migration"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/MigrationCreateUserActionKeycloakRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/MigrationCreateUserActionKeycloakResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/migration/create-user-keycloak": {
      "post": {
        "tags": [
          "Migration"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/MigrationCreateUserKeycloakRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/MigrationCreateUserKeycloakResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/migration/update-user-keycloak": {
      "post": {
        "tags": [
          "Migration"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/MigrationUpdateUserKeycloakRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/MigrationUpdateUserKeycloakResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/mutableasset/create": {
      "post": {
        "tags": [
          "MutableAsset"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/MutableAssetCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/MutableAssetCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/mutableasset/delete": {
      "post": {
        "tags": [
          "MutableAsset"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/MutableAssetDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/MutableAssetDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/mutableasset/get": {
      "post": {
        "tags": [
          "MutableAsset"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/MutableAssetGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/MutableAssetGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/mutableasset/query": {
      "post": {
        "tags": [
          "MutableAsset"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/MutableAssetQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/MutableAssetDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/mutableasset/update": {
      "post": {
        "tags": [
          "MutableAsset"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/MutableAssetUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/MutableAssetUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/newsconfiguration/create": {
      "post": {
        "tags": [
          "NewsConfiguration"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NewsConfigurationCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NewsConfigurationCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/newsconfiguration/delete": {
      "post": {
        "tags": [
          "NewsConfiguration"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NewsConfigurationDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NewsConfigurationDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/newsconfiguration/get": {
      "post": {
        "tags": [
          "NewsConfiguration"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NewsConfigurationGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NewsConfigurationGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/newsconfiguration/merge": {
      "post": {
        "tags": [
          "NewsConfiguration"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NewsConfigurationMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NewsConfigurationMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/newsconfiguration/query": {
      "post": {
        "tags": [
          "NewsConfiguration"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NewsConfigurationQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NewsConfigurationDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/newsconfiguration/update": {
      "post": {
        "tags": [
          "NewsConfiguration"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NewsConfigurationUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NewsConfigurationUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notification/create": {
      "post": {
        "tags": [
          "Notification"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notification/delete": {
      "post": {
        "tags": [
          "Notification"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notification/get": {
      "post": {
        "tags": [
          "Notification"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notification/query": {
      "post": {
        "tags": [
          "Notification"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notification/update": {
      "post": {
        "tags": [
          "Notification"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationevent/create": {
      "post": {
        "tags": [
          "NotificationEvent"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationEventCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationEventCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationevent/delete": {
      "post": {
        "tags": [
          "NotificationEvent"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationEventDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationEventDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationevent/get": {
      "post": {
        "tags": [
          "NotificationEvent"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationEventGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationEventGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationevent/query": {
      "post": {
        "tags": [
          "NotificationEvent"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationEventQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationEventDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationevent/update": {
      "post": {
        "tags": [
          "NotificationEvent"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationEventUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationEventUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationflat/query": {
      "post": {
        "tags": [
          "NotificationFlat"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationFlatQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationFlatDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrecipient/create": {
      "post": {
        "tags": [
          "NotificationRecipient"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRecipientCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRecipientCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrecipient/delete": {
      "post": {
        "tags": [
          "NotificationRecipient"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRecipientDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRecipientDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrecipient/get": {
      "post": {
        "tags": [
          "NotificationRecipient"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRecipientGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRecipientGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrecipient/query": {
      "post": {
        "tags": [
          "NotificationRecipient"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRecipientQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRecipientDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrecipient/update": {
      "post": {
        "tags": [
          "NotificationRecipient"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRecipientUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRecipientUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrun/create": {
      "post": {
        "tags": [
          "NotificationRun"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRunCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRunCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrun/delete": {
      "post": {
        "tags": [
          "NotificationRun"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRunDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRunDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrun/get": {
      "post": {
        "tags": [
          "NotificationRun"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRunGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRunGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrun/query": {
      "post": {
        "tags": [
          "NotificationRun"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRunQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRunDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrun/update": {
      "post": {
        "tags": [
          "NotificationRun"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRunUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRunUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrunmessage/create": {
      "post": {
        "tags": [
          "NotificationRunMessage"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRunMessageCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRunMessageCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrunmessage/delete": {
      "post": {
        "tags": [
          "NotificationRunMessage"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRunMessageDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRunMessageDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrunmessage/get": {
      "post": {
        "tags": [
          "NotificationRunMessage"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRunMessageGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRunMessageGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrunmessage/query": {
      "post": {
        "tags": [
          "NotificationRunMessage"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRunMessageQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRunMessageDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationrunmessage/update": {
      "post": {
        "tags": [
          "NotificationRunMessage"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationRunMessageUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationRunMessageUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationschedule/create": {
      "post": {
        "tags": [
          "NotificationSchedule"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationScheduleCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationScheduleCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationschedule/delete": {
      "post": {
        "tags": [
          "NotificationSchedule"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationScheduleDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationScheduleDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationschedule/get": {
      "post": {
        "tags": [
          "NotificationSchedule"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationScheduleGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationScheduleGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationschedule/query": {
      "post": {
        "tags": [
          "NotificationSchedule"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationScheduleQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationScheduleDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationschedule/update": {
      "post": {
        "tags": [
          "NotificationSchedule"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationScheduleUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationScheduleUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtask/create-schedule": {
      "post": {
        "tags": [
          "NotificationTask"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTaskCreateScheduleRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTaskCreateScheduleResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtask/create-sender": {
      "post": {
        "tags": [
          "NotificationTask"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTaskCreateSenderRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTaskCreateSenderResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtask/create-worker": {
      "post": {
        "tags": [
          "NotificationTask"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTaskCreateWorkerRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTaskCreateWorkerResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtemplate/create": {
      "post": {
        "tags": [
          "NotificationTemplate"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTemplateCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTemplateCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtemplate/delete": {
      "post": {
        "tags": [
          "NotificationTemplate"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTemplateDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTemplateDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtemplate/get": {
      "post": {
        "tags": [
          "NotificationTemplate"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTemplateGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTemplateGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtemplate/query": {
      "post": {
        "tags": [
          "NotificationTemplate"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTemplateQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTemplateDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtemplate/update": {
      "post": {
        "tags": [
          "NotificationTemplate"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTemplateUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTemplateUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtemplatetranslation/create": {
      "post": {
        "tags": [
          "NotificationTemplateTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTemplateTranslationCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTemplateTranslationCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtemplatetranslation/delete": {
      "post": {
        "tags": [
          "NotificationTemplateTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTemplateTranslationDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTemplateTranslationDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtemplatetranslation/get": {
      "post": {
        "tags": [
          "NotificationTemplateTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTemplateTranslationGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTemplateTranslationGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtemplatetranslation/query": {
      "post": {
        "tags": [
          "NotificationTemplateTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTemplateTranslationQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTemplateTranslationDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtemplatetranslation/update": {
      "post": {
        "tags": [
          "NotificationTemplateTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTemplateTranslationUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTemplateTranslationUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtrigger/create": {
      "post": {
        "tags": [
          "NotificationTrigger"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTriggerCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTriggerCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtrigger/delete": {
      "post": {
        "tags": [
          "NotificationTrigger"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTriggerDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTriggerDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtrigger/get": {
      "post": {
        "tags": [
          "NotificationTrigger"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTriggerGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTriggerGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtrigger/query": {
      "post": {
        "tags": [
          "NotificationTrigger"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTriggerQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTriggerDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/notificationtrigger/update": {
      "post": {
        "tags": [
          "NotificationTrigger"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/NotificationTriggerUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/NotificationTriggerUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/openapi/query": {
      "post": {
        "tags": [
          "OpenApi"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/OpenApiQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/JsonObjectQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/orphaneddocument/query": {
      "post": {
        "tags": [
          "OrphanedDocument"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/OrphanedDocumentQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/OrphanedDocumentDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/page/get": {
      "post": {
        "tags": [
          "Page"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PageGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PageGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/party/merge": {
      "post": {
        "tags": [
          "Party"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PartyMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PartyMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/periodperformancesegment/create": {
      "post": {
        "tags": [
          "PeriodPerformanceSegment"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PeriodPerformanceSegmentCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PeriodPerformanceSegmentCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/periodperformancesegment/delete": {
      "post": {
        "tags": [
          "PeriodPerformanceSegment"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PeriodPerformanceSegmentDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PeriodPerformanceSegmentDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/periodperformancesegment/get": {
      "post": {
        "tags": [
          "PeriodPerformanceSegment"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PeriodPerformanceSegmentGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PeriodPerformanceSegmentGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/periodperformancesegment/query": {
      "post": {
        "tags": [
          "PeriodPerformanceSegment"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PeriodPerformanceSegmentQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PeriodPerformanceSegmentDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/periodperformancesegment/update": {
      "post": {
        "tags": [
          "PeriodPerformanceSegment"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PeriodPerformanceSegmentUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PeriodPerformanceSegmentUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/periodperformancesegmenttranslation/create": {
      "post": {
        "tags": [
          "PeriodPerformanceSegmentTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/periodperformancesegmenttranslation/delete": {
      "post": {
        "tags": [
          "PeriodPerformanceSegmentTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/periodperformancesegmenttranslation/get": {
      "post": {
        "tags": [
          "PeriodPerformanceSegmentTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/periodperformancesegmenttranslation/query": {
      "post": {
        "tags": [
          "PeriodPerformanceSegmentTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/periodperformancesegmenttranslation/update": {
      "post": {
        "tags": [
          "PeriodPerformanceSegmentTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/permission/delete-cache": {
      "post": {
        "tags": [
          "Permission"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PermissionDeleteCacheRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PermissionDeleteCacheResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/permission/get": {
      "post": {
        "tags": [
          "Permission"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PermissionGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PermissionGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/permission/get-evaluate": {
      "post": {
        "tags": [
          "Permission"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PermissionGetEvaluateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PermissionGetEvaluateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/permissionapi/create": {
      "post": {
        "tags": [
          "PermissionApi"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PermissionApiCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PermissionApiCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/permissionapi/delete": {
      "post": {
        "tags": [
          "PermissionApi"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PermissionApiDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PermissionApiDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/permissionapi/get": {
      "post": {
        "tags": [
          "PermissionApi"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PermissionApiGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PermissionApiGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/permissionapi/query": {
      "post": {
        "tags": [
          "PermissionApi"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PermissionApiQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PermissionApiDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/permissionapi/update": {
      "post": {
        "tags": [
          "PermissionApi"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PermissionApiUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PermissionApiUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/permissionmenu/create": {
      "post": {
        "tags": [
          "PermissionMenu"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PermissionMenuCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PermissionMenuCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/permissionmenu/delete": {
      "post": {
        "tags": [
          "PermissionMenu"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PermissionMenuDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PermissionMenuDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/permissionmenu/get": {
      "post": {
        "tags": [
          "PermissionMenu"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PermissionMenuGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PermissionMenuGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/permissionmenu/query": {
      "post": {
        "tags": [
          "PermissionMenu"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PermissionMenuQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PermissionMenuDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/permissionmenu/update": {
      "post": {
        "tags": [
          "PermissionMenu"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PermissionMenuUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PermissionMenuUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/plugin/get": {
      "post": {
        "tags": [
          "Plugin"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "type": "object",
                "additionalProperties": {
                  "$ref": "#/components/schemas/JsonNode"
                }
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/JsonNodePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/portaldisclaimeracceptance/query": {
      "post": {
        "tags": [
          "PortalDisclaimerAcceptance"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PortalDisclaimerAcceptanceQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PortalDisclaimerAcceptanceDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/position/create": {
      "post": {
        "tags": [
          "Position"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PositionCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PositionCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/position/delete": {
      "post": {
        "tags": [
          "Position"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PositionDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PositionDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/position/get": {
      "post": {
        "tags": [
          "Position"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PositionGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PositionGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/position/query": {
      "post": {
        "tags": [
          "Position"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PositionQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PositionDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/position/query-grouped": {
      "post": {
        "tags": [
          "Position"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PositionQueryGroupedRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PositionQueryGroupedResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/position/query-o-data": {
      "post": {
        "tags": [
          "Position"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PositionQueryODataRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PositionQueryODataResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/position/update": {
      "post": {
        "tags": [
          "Position"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PositionUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PositionUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/pricehistory/query": {
      "post": {
        "tags": [
          "PriceHistory"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PriceHistoryQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PriceHistoryDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/pricehistory/query-periods": {
      "post": {
        "tags": [
          "PriceHistory"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PriceHistoryQueryPeriodsRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PriceHistoryQueryPeriodsResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/pricingquotes/get": {
      "post": {
        "tags": [
          "PricingQuotes"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PricingQuotesGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PricingQuotesGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/privateasset/create": {
      "post": {
        "tags": [
          "PrivateAsset"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PrivateAssetCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PrivateAssetCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/privateasset/delete": {
      "post": {
        "tags": [
          "PrivateAsset"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PrivateAssetDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PrivateAssetDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/privateasset/get": {
      "post": {
        "tags": [
          "PrivateAsset"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PrivateAssetGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PrivateAssetGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/privateasset/merge": {
      "post": {
        "tags": [
          "PrivateAsset"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PrivateAssetMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PrivateAssetMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/privateasset/query": {
      "post": {
        "tags": [
          "PrivateAsset"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PrivateAssetQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PrivateAssetDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/privateasset/query-compute-personal-gain-loss": {
      "post": {
        "tags": [
          "PrivateAsset"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PrivateAssetQueryComputePersonalGainLossRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PrivateAssetQueryComputePersonalGainLossResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/privateasset/update": {
      "post": {
        "tags": [
          "PrivateAsset"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/PrivateAssetUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/PrivateAssetUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/score/create": {
      "post": {
        "tags": [
          "Score"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/ScoreCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ScoreCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/score/get": {
      "post": {
        "tags": [
          "Score"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/ScoreGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ScoreGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/score/query": {
      "post": {
        "tags": [
          "Score"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/ScoreQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/ScoreDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/securitymaster/create": {
      "post": {
        "tags": [
          "SecurityMaster"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/SecurityMasterCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/SecurityMasterCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/securitymaster/delete": {
      "post": {
        "tags": [
          "SecurityMaster"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/SecurityMasterDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/SecurityMasterDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/securitymaster/get": {
      "post": {
        "tags": [
          "SecurityMaster"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/SecurityMasterGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/SecurityMasterGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/securitymaster/query": {
      "post": {
        "tags": [
          "SecurityMaster"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/SecurityMasterQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/SecurityMasterDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/securitymaster/query-search-as-you-type": {
      "post": {
        "tags": [
          "SecurityMaster"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/SecurityMasterQuerySearchAsYouTypeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/SecurityMasterQuerySearchAsYouTypeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/securitymaster/update": {
      "post": {
        "tags": [
          "SecurityMaster"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/SecurityMasterUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/SecurityMasterUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/site/get": {
      "post": {
        "tags": [
          "Site"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/SiteGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/SiteGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/styles/get": {
      "post": {
        "tags": [
          "Styles"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/StylesGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/StylesGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/styles/merge": {
      "post": {
        "tags": [
          "Styles"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/StylesMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/StylesMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/styles/query": {
      "post": {
        "tags": [
          "Styles"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/StylesQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/JsonObjectQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/tenant/merge": {
      "post": {
        "tags": [
          "Tenant"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TenantMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TenantMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/tenantcodeset/create": {
      "post": {
        "tags": [
          "TenantCodeSet"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TenantCodeSetCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TenantCodeSetCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/tenantcodeset/delete": {
      "post": {
        "tags": [
          "TenantCodeSet"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TenantCodeSetDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TenantCodeSetDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/tenantcodeset/get": {
      "post": {
        "tags": [
          "TenantCodeSet"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TenantCodeSetGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TenantCodeSetGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/tenantcodeset/query": {
      "post": {
        "tags": [
          "TenantCodeSet"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TenantCodeSetQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TenantCodeSetDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/tenantcodeset/update": {
      "post": {
        "tags": [
          "TenantCodeSet"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TenantCodeSetUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TenantCodeSetUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/tenantcodesettranslation/create": {
      "post": {
        "tags": [
          "TenantCodeSetTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TenantCodeSetTranslationCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TenantCodeSetTranslationCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/tenantcodesettranslation/delete": {
      "post": {
        "tags": [
          "TenantCodeSetTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TenantCodeSetTranslationDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TenantCodeSetTranslationDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/tenantcodesettranslation/get": {
      "post": {
        "tags": [
          "TenantCodeSetTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TenantCodeSetTranslationGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TenantCodeSetTranslationGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/tenantcodesettranslation/query": {
      "post": {
        "tags": [
          "TenantCodeSetTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TenantCodeSetTranslationQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TenantCodeSetTranslationDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/tenantcodesettranslation/update": {
      "post": {
        "tags": [
          "TenantCodeSetTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TenantCodeSetTranslationUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TenantCodeSetTranslationUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transaction/create": {
      "post": {
        "tags": [
          "Transaction"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transaction/delete": {
      "post": {
        "tags": [
          "Transaction"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transaction/get": {
      "post": {
        "tags": [
          "Transaction"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transaction/query": {
      "post": {
        "tags": [
          "Transaction"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transaction/update": {
      "post": {
        "tags": [
          "Transaction"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transactioncurrency/create": {
      "post": {
        "tags": [
          "TransactionCurrency"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionCurrencyCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionCurrencyCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transactioncurrency/delete": {
      "post": {
        "tags": [
          "TransactionCurrency"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionCurrencyDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionCurrencyDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transactioncurrency/get": {
      "post": {
        "tags": [
          "TransactionCurrency"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionCurrencyGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionCurrencyGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transactioncurrency/query": {
      "post": {
        "tags": [
          "TransactionCurrency"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionCurrencyQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionCurrencyDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transactioncurrency/update": {
      "post": {
        "tags": [
          "TransactionCurrency"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionCurrencyUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionCurrencyUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transactiontranslation/create": {
      "post": {
        "tags": [
          "TransactionTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionTranslationCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionTranslationCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transactiontranslation/delete": {
      "post": {
        "tags": [
          "TransactionTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionTranslationDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionTranslationDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transactiontranslation/get": {
      "post": {
        "tags": [
          "TransactionTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionTranslationGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionTranslationGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transactiontranslation/query": {
      "post": {
        "tags": [
          "TransactionTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionTranslationQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionTranslationDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/transactiontranslation/update": {
      "post": {
        "tags": [
          "TransactionTranslation"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TransactionTranslationUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TransactionTranslationUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/tree/merge": {
      "post": {
        "tags": [
          "Tree"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TreeMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TreeMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/treemeta/create": {
      "post": {
        "tags": [
          "TreeMeta"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TreeMetaCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TreeMetaCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/treemeta/delete": {
      "post": {
        "tags": [
          "TreeMeta"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TreeMetaDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TreeMetaDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/treemeta/get": {
      "post": {
        "tags": [
          "TreeMeta"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TreeMetaGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TreeMetaGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/treemeta/query": {
      "post": {
        "tags": [
          "TreeMeta"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TreeMetaQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TreeMetaDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/treemeta/update": {
      "post": {
        "tags": [
          "TreeMeta"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/TreeMetaUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/TreeMetaUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/uimetadata/create": {
      "post": {
        "tags": [
          "UIMetadata"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UIMetadataCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UIMetadataCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/usercredential/merge": {
      "post": {
        "tags": [
          "UserCredential"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserCredentialMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserCredentialMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/usercredentialusergroup/merge": {
      "post": {
        "tags": [
          "UserCredentialUserGroup"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserCredentialUserGroupMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserCredentialUserGroupMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userentitlement/delete": {
      "post": {
        "tags": [
          "UserEntitlement"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserEntitlementDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserEntitlementDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userentitlement/merge": {
      "post": {
        "tags": [
          "UserEntitlement"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserEntitlementMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserEntitlementMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userentitlement/query": {
      "post": {
        "tags": [
          "UserEntitlement"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserEntitlementQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserEntitlementDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/usergroup/merge": {
      "post": {
        "tags": [
          "UserGroup"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserGroupMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserGroupMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/usergroupentitlement/merge": {
      "post": {
        "tags": [
          "UserGroupEntitlement"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserGroupEntitlementMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserGroupEntitlementMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userloginactivity/query": {
      "post": {
        "tags": [
          "UserLoginActivity"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserLoginActivityQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserLoginActivityDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userneverloggedon/query": {
      "post": {
        "tags": [
          "UserNeverLoggedOn"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserNeverLoggedOnQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserNeverLoggedOnDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofile/create": {
      "post": {
        "tags": [
          "UserProfile"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofile/get": {
      "post": {
        "tags": [
          "UserProfile"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofile/merge": {
      "post": {
        "tags": [
          "UserProfile"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileMergeRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileMergeResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofile/query": {
      "post": {
        "tags": [
          "UserProfile"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofile/query-in-entity": {
      "post": {
        "tags": [
          "UserProfile"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileQueryInEntityRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileQueryInEntityResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofile/query-legacy": {
      "post": {
        "tags": [
          "UserProfile"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileQueryLegacyRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileQueryLegacyResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofile/query-without-accounts": {
      "post": {
        "tags": [
          "UserProfile"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileQueryWithoutAccountsRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileQueryWithoutAccountsResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofile/update": {
      "post": {
        "tags": [
          "UserProfile"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofile/update-disclaimer-accepted": {
      "post": {
        "tags": [
          "UserProfile"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileUpdateDisclaimerAcceptedRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileUpdateDisclaimerAcceptedResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofile/update-lock": {
      "post": {
        "tags": [
          "UserProfile"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileUpdateLockRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileUpdateLockResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofileagreement/create": {
      "post": {
        "tags": [
          "UserProfileAgreement"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileAgreementCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileAgreementCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofileagreement/delete": {
      "post": {
        "tags": [
          "UserProfileAgreement"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileAgreementDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileAgreementDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofileagreement/get": {
      "post": {
        "tags": [
          "UserProfileAgreement"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileAgreementGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileAgreementGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofileagreement/query": {
      "post": {
        "tags": [
          "UserProfileAgreement"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileAgreementQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileAgreementDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofileagreement/update": {
      "post": {
        "tags": [
          "UserProfileAgreement"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileAgreementUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileAgreementUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofilemetric/create": {
      "post": {
        "tags": [
          "UserProfileMetric"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileMetricCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileMetricCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofilemetric/delete": {
      "post": {
        "tags": [
          "UserProfileMetric"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileMetricDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileMetricDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofilemetric/get": {
      "post": {
        "tags": [
          "UserProfileMetric"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileMetricGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileMetricGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofilemetric/query": {
      "post": {
        "tags": [
          "UserProfileMetric"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileMetricQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileMetricDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userprofilemetric/update": {
      "post": {
        "tags": [
          "UserProfileMetric"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserProfileMetricUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserProfileMetricUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userrequesthistory/create": {
      "post": {
        "tags": [
          "UserRequestHistory"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserRequestHistoryCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserRequestHistoryCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userrequesthistory/query": {
      "post": {
        "tags": [
          "UserRequestHistory"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserRequestHistoryQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserRequestHistoryDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/userwithaccess/query": {
      "post": {
        "tags": [
          "UserWithAccess"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/UserWithAccessQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/UserWithAccessDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/watchlist/create": {
      "post": {
        "tags": [
          "Watchlist"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WatchlistCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WatchlistCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/watchlist/delete": {
      "post": {
        "tags": [
          "Watchlist"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WatchlistDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WatchlistDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/watchlist/get": {
      "post": {
        "tags": [
          "Watchlist"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WatchlistGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WatchlistGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/watchlist/query": {
      "post": {
        "tags": [
          "Watchlist"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WatchlistQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WatchlistDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/watchlist/update": {
      "post": {
        "tags": [
          "Watchlist"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WatchlistUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WatchlistUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/workflow/create": {
      "post": {
        "tags": [
          "Workflow"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WorkflowCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WorkflowCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/workflow/delete": {
      "post": {
        "tags": [
          "Workflow"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WorkflowDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WorkflowDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/workflow/get": {
      "post": {
        "tags": [
          "Workflow"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WorkflowGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WorkflowGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/workflow/query": {
      "post": {
        "tags": [
          "Workflow"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WorkflowQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WorkflowDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/workflow/query-end-status": {
      "post": {
        "tags": [
          "Workflow"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WorkflowQueryEndStatusRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WorkflowQueryEndStatusResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/workflow/update": {
      "post": {
        "tags": [
          "Workflow"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WorkflowUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WorkflowUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/workflowstep/create": {
      "post": {
        "tags": [
          "WorkflowStep"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WorkflowStepCreateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WorkflowStepCreateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/workflowstep/delete": {
      "post": {
        "tags": [
          "WorkflowStep"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WorkflowStepDeleteRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WorkflowStepDeleteResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/workflowstep/get": {
      "post": {
        "tags": [
          "WorkflowStep"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WorkflowStepGetRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WorkflowStepGetResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/workflowstep/get-evaluate": {
      "post": {
        "tags": [
          "WorkflowStep"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WorkflowStepGetEvaluateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WorkflowStepGetEvaluateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/workflowstep/query": {
      "post": {
        "tags": [
          "WorkflowStep"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WorkflowStepQueryRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WorkflowStepDtoQueryResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    },
    "/api/workflowstep/update": {
      "post": {
        "tags": [
          "WorkflowStep"
        ],
        "requestBody": {
          "content": {
            "application/json": {
              "schema": {
                "$ref": "#/components/schemas/WorkflowStepUpdateRequest"
              }
            }
          }
        },
        "responses": {
          "200": {
            "description": "OK",
            "content": {
              "application/json": {
                "schema": {
                  "$ref": "#/components/schemas/WorkflowStepUpdateResponsePlatformResult"
                }
              }
            }
          }
        }
      }
    }
  },
  "components": {
    "schemas": {
      "AccountBalanceDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "description": "Account Id",
            "nullable": true
          },
          "Classification0": {
            "type": "string",
            "description": "Classification0",
            "nullable": true
          },
          "AccountShortName": {
            "type": "string",
            "description": "Account Short Name",
            "nullable": true
          },
          "AccountBalance": {
            "type": "number",
            "description": "Account Balance",
            "format": "double"
          },
          "GeneralLedgerType": {
            "type": "string",
            "description": "General Ledger Type",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountBalanceDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/AccountBalanceDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountBalanceDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountBalanceDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountBalanceQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "ExcludePrivateAssets": {
            "type": "boolean"
          }
        },
        "additionalProperties": false
      },
      "AccountCreateRequest": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountCloseDate": {
            "type": "string",
            "description": "Date the account was closed",
            "nullable": true
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AccountOwnerName": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountOwnerNameJoint": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable Account Type with standard options for: Checking, Savings, Line of Credit, Mortgage, Credit Card, Brokerage, ROTH IRA, Traditional IRA, SEP IRA, 401k, Student Loan, 529 Plan, Health Savings",
            "nullable": true
          },
          "AdvisorName": {
            "type": "string",
            "description": "Name of the financial advisor responsible for managing the account",
            "nullable": true
          },
          "AdvisorStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the financial advisor",
            "nullable": true
          },
          "AdvisoryTeamName": {
            "type": "string",
            "description": "Name for the advisory team responsible for managing the account",
            "nullable": true
          },
          "AnnualManagementFeePercent": {
            "type": "number",
            "description": "For simple accounts this is the management fee for the account (useful for simple accounts and revenue projection)",
            "format": "double",
            "nullable": true
          },
          "AssetAllocationDescription": {
            "type": "string",
            "description": "Text description of the Asset Allocation of the Account",
            "nullable": true
          },
          "AssetAllocationName": {
            "type": "string",
            "description": "Name of the target asset allocation",
            "nullable": true
          },
          "BaseCurrency": {
            "type": "string",
            "description": "Book Currency of the Account",
            "nullable": true
          },
          "BulletCount": {
            "type": "integer",
            "description": "The number of bullet withdrawls",
            "format": "int32",
            "nullable": true
          },
          "BulletFrequency": {
            "type": "string",
            "description": "The frequencey (i.e. daily, monthly, quarterly) of the bullet payments",
            "nullable": true
          },
          "BulletWithdrawlAmount": {
            "type": "number",
            "description": "The amount of bullet withdrawls",
            "format": "double",
            "nullable": true
          },
          "BusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable",
            "nullable": true
          },
          "CloseReason": {
            "type": "string",
            "description": "Reason the account was clossed",
            "nullable": true
          },
          "Comments": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Free form comment field",
            "nullable": true
          },
          "CustodyCity": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyCountry": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyRegion": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyState": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "DecumulationStartDate": {
            "type": "string",
            "description": "The date at which decumulation starts",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "description": "Text description of the account",
            "nullable": true
          },
          "DiscretionaryTermStartDate": {
            "type": "string",
            "description": "Performance start date for the current advisor or manager",
            "nullable": true
          },
          "ExpectedDecumulationEndDate": {
            "type": "string",
            "description": "Expected decumulation end date",
            "nullable": true
          },
          "ExpectedGrowthRateToTargetMarketValue": {
            "type": "number",
            "description": "Expected growth rate of assets in the account",
            "format": "double",
            "nullable": true
          },
          "ExpectedMonthlyContributionToTargetMarketValue": {
            "type": "number",
            "description": "Amount the owner will contribute monthly to attempt to achieve the target amount",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "GeneralLedgerType": {
            "type": "string",
            "description": "Asset or Liability",
            "nullable": true
          },
          "HasNewAlerts": {
            "type": "boolean",
            "description": "An indicator there is an unread alert associated with the account",
            "nullable": true
          },
          "HasNewDocuments": {
            "type": "boolean",
            "description": "An indicator the account has had new documents loaded to it",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Communify System Id",
            "nullable": true
          },
          "InflationRate": {
            "type": "number",
            "description": "Used in accumulation and decumulation calculations",
            "format": "double",
            "nullable": true
          },
          "InitialBulletWithdrawlDate": {
            "type": "string",
            "description": "The date at which a limited number of bullet withdrawls may begin",
            "nullable": true
          },
          "InstitutionName1": {
            "type": "string",
            "description": "Name of the Financial Instituion that maintains records for the account",
            "nullable": true
          },
          "InstitutionName2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "IsAdvisorDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsClosed": {
            "type": "boolean",
            "description": "Whether the account is closed",
            "nullable": true
          },
          "IsDiscretionary": {
            "type": "boolean",
            "description": "The advisor or manager has discretion over the account",
            "nullable": true
          },
          "IsERISA": {
            "type": "boolean",
            "description": "ERISA Rules apply to this account",
            "nullable": true
          },
          "IsExcludedFromNetWorthReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from net worth reporting for the owner",
            "nullable": true
          },
          "IsExcludedFromRegulatoryReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from regulatory reporting",
            "nullable": true
          },
          "IsHiddenFromAdvisor": {
            "type": "boolean",
            "description": "Hide the account from the advisor's standard data views",
            "nullable": true
          },
          "IsHiddenFromOwner": {
            "type": "boolean",
            "description": "Hide the account from the owner's standard data views",
            "nullable": true
          },
          "IsMarginApproved": {
            "type": "boolean",
            "description": "Whether assets in the account can be leveraged to purchase securities",
            "nullable": true
          },
          "IsMasterAccount": {
            "type": "boolean",
            "description": "The account is a master account that may have sub accounts",
            "nullable": true
          },
          "IsNIGO": {
            "type": "boolean",
            "description": "The account is not in good order",
            "nullable": true
          },
          "IsOptionsApproved": {
            "type": "boolean",
            "description": "Whether the owner of the account can trade options in this account",
            "nullable": true
          },
          "ISOTimeZone": {
            "type": "string",
            "description": "The GMT time for the end of day (end of activity)",
            "nullable": true
          },
          "IsOwnerDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsSubAccount": {
            "type": "boolean",
            "description": "The account represents a portfolio or sleeve within a Master Account (such as a trust)",
            "nullable": true
          },
          "LegalName": {
            "type": "string",
            "description": "Complete legal name for the account",
            "nullable": true
          },
          "LongName": {
            "type": "string",
            "description": "Custom long name for the account for reporting purposes",
            "nullable": true
          },
          "LotReliefMethodDefault": {
            "type": "string",
            "description": "The lot relief method that should be used to sell lots in the event a specific lot id is not provided on a n order (e.g. LIFO, FIFO, HICO)",
            "nullable": true
          },
          "ManagerFriendlyName": {
            "type": "string",
            "description": "A name the Advisor or account manager can set themselves",
            "nullable": true
          },
          "ManagerStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the portfolio manager",
            "nullable": true
          },
          "ModelPortfolioDescription": {
            "type": "string",
            "description": "Text Description of the Model Portfolio being implemented in the account",
            "nullable": true
          },
          "ModelPortfolioName": {
            "type": "string",
            "description": "Name of the model portfolio for the account",
            "nullable": true
          },
          "OnboardingStages": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The current stage of onboarding the account",
            "nullable": true
          },
          "OwnerCountryOfDomicile": {
            "type": "string",
            "description": "The country the owner lives in",
            "nullable": true
          },
          "OwnerFiscalYearStartDate": {
            "type": "string",
            "description": "Used for reporting purposes if the owner",
            "nullable": true
          },
          "OwnerFriendlyName": {
            "type": "string",
            "description": "A name the Owner can set themselves",
            "nullable": true
          },
          "PreferredSecurityIdentifier": {
            "type": "string",
            "description": "A designation of the preferred security master identifier to be used when showing a position from this account",
            "nullable": true
          },
          "ReportingCurrency": {
            "type": "string",
            "description": "Preferred reporting currency of the account",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Short Name test",
            "nullable": true
          },
          "ExternalSystemKeyAccountingExternalSystemKeyAccounting": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A configuragle designation that includes OPEN, CLOSED, HOLD, DO-NOT-TRADE",
            "nullable": true
          },
          "StatusNote": {
            "type": "string",
            "description": "Short not explaining an account status other than Open",
            "nullable": true
          },
          "StrategyDescription": {
            "type": "string",
            "description": "Text description of the investment strategy of the account",
            "nullable": true
          },
          "SubBusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "TargetDescription": {
            "type": "string",
            "description": "Simple description of the Account objective or goal",
            "nullable": true
          },
          "TargetMarketValue": {
            "type": "number",
            "description": "Simple numeric target for the account",
            "format": "double",
            "nullable": true
          },
          "TargetMarketValueDate": {
            "type": "string",
            "description": "Date the Owner would like to achieve the simple market value target",
            "nullable": true
          },
          "TaxTreatmentTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Simplified tax treatment category (Taxable, Tax Deferred or Tax Free)",
            "nullable": true
          },
          "ThirdPartyConsultantNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Name of the 3rd Part Consulatant who reviews the account for the owner",
            "nullable": true
          },
          "UserAccessId": {
            "type": "string",
            "description": "Temporary field for entitlements until entitlements activated",
            "nullable": true
          },
          "IsPrivateAsset": {
            "type": "boolean",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "nullable": true
          },
          "NetWorthMultiplier": {
            "type": "integer",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Indicates the status of the account",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountCreateResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountCloseDate": {
            "type": "string",
            "description": "Date the account was closed",
            "nullable": true
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AccountOwnerName": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountOwnerNameJoint": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable Account Type with standard options for: Checking, Savings, Line of Credit, Mortgage, Credit Card, Brokerage, ROTH IRA, Traditional IRA, SEP IRA, 401k, Student Loan, 529 Plan, Health Savings",
            "nullable": true
          },
          "AdvisorName": {
            "type": "string",
            "description": "Name of the financial advisor responsible for managing the account",
            "nullable": true
          },
          "AdvisorStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the financial advisor",
            "nullable": true
          },
          "AdvisoryTeamName": {
            "type": "string",
            "description": "Name for the advisory team responsible for managing the account",
            "nullable": true
          },
          "AnnualManagementFeePercent": {
            "type": "number",
            "description": "For simple accounts this is the management fee for the account (useful for simple accounts and revenue projection)",
            "format": "double",
            "nullable": true
          },
          "AssetAllocationDescription": {
            "type": "string",
            "description": "Text description of the Asset Allocation of the Account",
            "nullable": true
          },
          "AssetAllocationName": {
            "type": "string",
            "description": "Name of the target asset allocation",
            "nullable": true
          },
          "BaseCurrency": {
            "type": "string",
            "description": "Book Currency of the Account",
            "nullable": true
          },
          "BulletCount": {
            "type": "integer",
            "description": "The number of bullet withdrawls",
            "format": "int32",
            "nullable": true
          },
          "BulletFrequency": {
            "type": "string",
            "description": "The frequencey (i.e. daily, monthly, quarterly) of the bullet payments",
            "nullable": true
          },
          "BulletWithdrawlAmount": {
            "type": "number",
            "description": "The amount of bullet withdrawls",
            "format": "double",
            "nullable": true
          },
          "BusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable",
            "nullable": true
          },
          "CloseReason": {
            "type": "string",
            "description": "Reason the account was clossed",
            "nullable": true
          },
          "Comments": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Free form comment field",
            "nullable": true
          },
          "CustodyCity": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyCountry": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyRegion": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyState": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "DecumulationStartDate": {
            "type": "string",
            "description": "The date at which decumulation starts",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "description": "Text description of the account",
            "nullable": true
          },
          "DiscretionaryTermStartDate": {
            "type": "string",
            "description": "Performance start date for the current advisor or manager",
            "nullable": true
          },
          "ExpectedDecumulationEndDate": {
            "type": "string",
            "description": "Expected decumulation end date",
            "nullable": true
          },
          "ExpectedGrowthRateToTargetMarketValue": {
            "type": "number",
            "description": "Expected growth rate of assets in the account",
            "format": "double",
            "nullable": true
          },
          "ExpectedMonthlyContributionToTargetMarketValue": {
            "type": "number",
            "description": "Amount the owner will contribute monthly to attempt to achieve the target amount",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "GeneralLedgerType": {
            "type": "string",
            "description": "Asset or Liability",
            "nullable": true
          },
          "HasNewAlerts": {
            "type": "boolean",
            "description": "An indicator there is an unread alert associated with the account",
            "nullable": true
          },
          "HasNewDocuments": {
            "type": "boolean",
            "description": "An indicator the account has had new documents loaded to it",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Communify System Id",
            "nullable": true
          },
          "InflationRate": {
            "type": "number",
            "description": "Used in accumulation and decumulation calculations",
            "format": "double",
            "nullable": true
          },
          "InitialBulletWithdrawlDate": {
            "type": "string",
            "description": "The date at which a limited number of bullet withdrawls may begin",
            "nullable": true
          },
          "InstitutionName1": {
            "type": "string",
            "description": "Name of the Financial Instituion that maintains records for the account",
            "nullable": true
          },
          "InstitutionName2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "IsAdvisorDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsClosed": {
            "type": "boolean",
            "description": "Whether the account is closed",
            "nullable": true
          },
          "IsDiscretionary": {
            "type": "boolean",
            "description": "The advisor or manager has discretion over the account",
            "nullable": true
          },
          "IsERISA": {
            "type": "boolean",
            "description": "ERISA Rules apply to this account",
            "nullable": true
          },
          "IsExcludedFromNetWorthReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from net worth reporting for the owner",
            "nullable": true
          },
          "IsExcludedFromRegulatoryReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from regulatory reporting",
            "nullable": true
          },
          "IsHiddenFromAdvisor": {
            "type": "boolean",
            "description": "Hide the account from the advisor's standard data views",
            "nullable": true
          },
          "IsHiddenFromOwner": {
            "type": "boolean",
            "description": "Hide the account from the owner's standard data views",
            "nullable": true
          },
          "IsMarginApproved": {
            "type": "boolean",
            "description": "Whether assets in the account can be leveraged to purchase securities",
            "nullable": true
          },
          "IsMasterAccount": {
            "type": "boolean",
            "description": "The account is a master account that may have sub accounts",
            "nullable": true
          },
          "IsNIGO": {
            "type": "boolean",
            "description": "The account is not in good order",
            "nullable": true
          },
          "IsOptionsApproved": {
            "type": "boolean",
            "description": "Whether the owner of the account can trade options in this account",
            "nullable": true
          },
          "ISOTimeZone": {
            "type": "string",
            "description": "The GMT time for the end of day (end of activity)",
            "nullable": true
          },
          "IsOwnerDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsSubAccount": {
            "type": "boolean",
            "description": "The account represents a portfolio or sleeve within a Master Account (such as a trust)",
            "nullable": true
          },
          "LegalName": {
            "type": "string",
            "description": "Complete legal name for the account",
            "nullable": true
          },
          "LongName": {
            "type": "string",
            "description": "Custom long name for the account for reporting purposes",
            "nullable": true
          },
          "LotReliefMethodDefault": {
            "type": "string",
            "description": "The lot relief method that should be used to sell lots in the event a specific lot id is not provided on a n order (e.g. LIFO, FIFO, HICO)",
            "nullable": true
          },
          "ManagerFriendlyName": {
            "type": "string",
            "description": "A name the Advisor or account manager can set themselves",
            "nullable": true
          },
          "ManagerStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the portfolio manager",
            "nullable": true
          },
          "ModelPortfolioDescription": {
            "type": "string",
            "description": "Text Description of the Model Portfolio being implemented in the account",
            "nullable": true
          },
          "ModelPortfolioName": {
            "type": "string",
            "description": "Name of the model portfolio for the account",
            "nullable": true
          },
          "OnboardingStages": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The current stage of onboarding the account",
            "nullable": true
          },
          "OwnerCountryOfDomicile": {
            "type": "string",
            "description": "The country the owner lives in",
            "nullable": true
          },
          "OwnerFiscalYearStartDate": {
            "type": "string",
            "description": "Used for reporting purposes if the owner",
            "nullable": true
          },
          "OwnerFriendlyName": {
            "type": "string",
            "description": "A name the Owner can set themselves",
            "nullable": true
          },
          "PreferredSecurityIdentifier": {
            "type": "string",
            "description": "A designation of the preferred security master identifier to be used when showing a position from this account",
            "nullable": true
          },
          "ReportingCurrency": {
            "type": "string",
            "description": "Preferred reporting currency of the account",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Short Name test",
            "nullable": true
          },
          "ExternalSystemKeyAccountingExternalSystemKeyAccounting": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A configuragle designation that includes OPEN, CLOSED, HOLD, DO-NOT-TRADE",
            "nullable": true
          },
          "StatusNote": {
            "type": "string",
            "description": "Short not explaining an account status other than Open",
            "nullable": true
          },
          "StrategyDescription": {
            "type": "string",
            "description": "Text description of the investment strategy of the account",
            "nullable": true
          },
          "SubBusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "TargetDescription": {
            "type": "string",
            "description": "Simple description of the Account objective or goal",
            "nullable": true
          },
          "TargetMarketValue": {
            "type": "number",
            "description": "Simple numeric target for the account",
            "format": "double",
            "nullable": true
          },
          "TargetMarketValueDate": {
            "type": "string",
            "description": "Date the Owner would like to achieve the simple market value target",
            "nullable": true
          },
          "TaxTreatmentTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Simplified tax treatment category (Taxable, Tax Deferred or Tax Free)",
            "nullable": true
          },
          "ThirdPartyConsultantNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Name of the 3rd Part Consulatant who reviews the account for the owner",
            "nullable": true
          },
          "UserAccessId": {
            "type": "string",
            "description": "Temporary field for entitlements until entitlements activated",
            "nullable": true
          },
          "IsPrivateAsset": {
            "type": "boolean",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "nullable": true
          },
          "NetWorthMultiplier": {
            "type": "integer",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Indicates the status of the account",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountDeleteResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountCloseDate": {
            "type": "string",
            "description": "Date the account was closed",
            "nullable": true
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AccountOwnerName": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountOwnerNameJoint": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable Account Type with standard options for: Checking, Savings, Line of Credit, Mortgage, Credit Card, Brokerage, ROTH IRA, Traditional IRA, SEP IRA, 401k, Student Loan, 529 Plan, Health Savings",
            "nullable": true
          },
          "AdvisorName": {
            "type": "string",
            "description": "Name of the financial advisor responsible for managing the account",
            "nullable": true
          },
          "AdvisorStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the financial advisor",
            "nullable": true
          },
          "AdvisoryTeamName": {
            "type": "string",
            "description": "Name for the advisory team responsible for managing the account",
            "nullable": true
          },
          "AnnualManagementFeePercent": {
            "type": "number",
            "description": "For simple accounts this is the management fee for the account (useful for simple accounts and revenue projection)",
            "format": "double",
            "nullable": true
          },
          "AssetAllocationDescription": {
            "type": "string",
            "description": "Text description of the Asset Allocation of the Account",
            "nullable": true
          },
          "AssetAllocationName": {
            "type": "string",
            "description": "Name of the target asset allocation",
            "nullable": true
          },
          "BaseCurrency": {
            "type": "string",
            "description": "Book Currency of the Account",
            "nullable": true
          },
          "BulletCount": {
            "type": "integer",
            "description": "The number of bullet withdrawls",
            "format": "int32",
            "nullable": true
          },
          "BulletFrequency": {
            "type": "string",
            "description": "The frequencey (i.e. daily, monthly, quarterly) of the bullet payments",
            "nullable": true
          },
          "BulletWithdrawlAmount": {
            "type": "number",
            "description": "The amount of bullet withdrawls",
            "format": "double",
            "nullable": true
          },
          "BusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable",
            "nullable": true
          },
          "CloseReason": {
            "type": "string",
            "description": "Reason the account was clossed",
            "nullable": true
          },
          "Comments": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Free form comment field",
            "nullable": true
          },
          "CustodyCity": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyCountry": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyRegion": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyState": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "DecumulationStartDate": {
            "type": "string",
            "description": "The date at which decumulation starts",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "description": "Text description of the account",
            "nullable": true
          },
          "DiscretionaryTermStartDate": {
            "type": "string",
            "description": "Performance start date for the current advisor or manager",
            "nullable": true
          },
          "ExpectedDecumulationEndDate": {
            "type": "string",
            "description": "Expected decumulation end date",
            "nullable": true
          },
          "ExpectedGrowthRateToTargetMarketValue": {
            "type": "number",
            "description": "Expected growth rate of assets in the account",
            "format": "double",
            "nullable": true
          },
          "ExpectedMonthlyContributionToTargetMarketValue": {
            "type": "number",
            "description": "Amount the owner will contribute monthly to attempt to achieve the target amount",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "GeneralLedgerType": {
            "type": "string",
            "description": "Asset or Liability",
            "nullable": true
          },
          "HasNewAlerts": {
            "type": "boolean",
            "description": "An indicator there is an unread alert associated with the account",
            "nullable": true
          },
          "HasNewDocuments": {
            "type": "boolean",
            "description": "An indicator the account has had new documents loaded to it",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Communify System Id",
            "nullable": true
          },
          "InflationRate": {
            "type": "number",
            "description": "Used in accumulation and decumulation calculations",
            "format": "double",
            "nullable": true
          },
          "InitialBulletWithdrawlDate": {
            "type": "string",
            "description": "The date at which a limited number of bullet withdrawls may begin",
            "nullable": true
          },
          "InstitutionName1": {
            "type": "string",
            "description": "Name of the Financial Instituion that maintains records for the account",
            "nullable": true
          },
          "InstitutionName2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "IsAdvisorDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsClosed": {
            "type": "boolean",
            "description": "Whether the account is closed",
            "nullable": true
          },
          "IsDiscretionary": {
            "type": "boolean",
            "description": "The advisor or manager has discretion over the account",
            "nullable": true
          },
          "IsERISA": {
            "type": "boolean",
            "description": "ERISA Rules apply to this account",
            "nullable": true
          },
          "IsExcludedFromNetWorthReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from net worth reporting for the owner",
            "nullable": true
          },
          "IsExcludedFromRegulatoryReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from regulatory reporting",
            "nullable": true
          },
          "IsHiddenFromAdvisor": {
            "type": "boolean",
            "description": "Hide the account from the advisor's standard data views",
            "nullable": true
          },
          "IsHiddenFromOwner": {
            "type": "boolean",
            "description": "Hide the account from the owner's standard data views",
            "nullable": true
          },
          "IsMarginApproved": {
            "type": "boolean",
            "description": "Whether assets in the account can be leveraged to purchase securities",
            "nullable": true
          },
          "IsMasterAccount": {
            "type": "boolean",
            "description": "The account is a master account that may have sub accounts",
            "nullable": true
          },
          "IsNIGO": {
            "type": "boolean",
            "description": "The account is not in good order",
            "nullable": true
          },
          "IsOptionsApproved": {
            "type": "boolean",
            "description": "Whether the owner of the account can trade options in this account",
            "nullable": true
          },
          "ISOTimeZone": {
            "type": "string",
            "description": "The GMT time for the end of day (end of activity)",
            "nullable": true
          },
          "IsOwnerDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsSubAccount": {
            "type": "boolean",
            "description": "The account represents a portfolio or sleeve within a Master Account (such as a trust)",
            "nullable": true
          },
          "LegalName": {
            "type": "string",
            "description": "Complete legal name for the account",
            "nullable": true
          },
          "LongName": {
            "type": "string",
            "description": "Custom long name for the account for reporting purposes",
            "nullable": true
          },
          "LotReliefMethodDefault": {
            "type": "string",
            "description": "The lot relief method that should be used to sell lots in the event a specific lot id is not provided on a n order (e.g. LIFO, FIFO, HICO)",
            "nullable": true
          },
          "ManagerFriendlyName": {
            "type": "string",
            "description": "A name the Advisor or account manager can set themselves",
            "nullable": true
          },
          "ManagerStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the portfolio manager",
            "nullable": true
          },
          "ModelPortfolioDescription": {
            "type": "string",
            "description": "Text Description of the Model Portfolio being implemented in the account",
            "nullable": true
          },
          "ModelPortfolioName": {
            "type": "string",
            "description": "Name of the model portfolio for the account",
            "nullable": true
          },
          "OnboardingStages": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The current stage of onboarding the account",
            "nullable": true
          },
          "OwnerCountryOfDomicile": {
            "type": "string",
            "description": "The country the owner lives in",
            "nullable": true
          },
          "OwnerFiscalYearStartDate": {
            "type": "string",
            "description": "Used for reporting purposes if the owner",
            "nullable": true
          },
          "OwnerFriendlyName": {
            "type": "string",
            "description": "A name the Owner can set themselves",
            "nullable": true
          },
          "PreferredSecurityIdentifier": {
            "type": "string",
            "description": "A designation of the preferred security master identifier to be used when showing a position from this account",
            "nullable": true
          },
          "ReportingCurrency": {
            "type": "string",
            "description": "Preferred reporting currency of the account",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Short Name test",
            "nullable": true
          },
          "ExternalSystemKeyAccountingExternalSystemKeyAccounting": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A configuragle designation that includes OPEN, CLOSED, HOLD, DO-NOT-TRADE",
            "nullable": true
          },
          "StatusNote": {
            "type": "string",
            "description": "Short not explaining an account status other than Open",
            "nullable": true
          },
          "StrategyDescription": {
            "type": "string",
            "description": "Text description of the investment strategy of the account",
            "nullable": true
          },
          "SubBusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "TargetDescription": {
            "type": "string",
            "description": "Simple description of the Account objective or goal",
            "nullable": true
          },
          "TargetMarketValue": {
            "type": "number",
            "description": "Simple numeric target for the account",
            "format": "double",
            "nullable": true
          },
          "TargetMarketValueDate": {
            "type": "string",
            "description": "Date the Owner would like to achieve the simple market value target",
            "nullable": true
          },
          "TaxTreatmentTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Simplified tax treatment category (Taxable, Tax Deferred or Tax Free)",
            "nullable": true
          },
          "ThirdPartyConsultantNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Name of the 3rd Part Consulatant who reviews the account for the owner",
            "nullable": true
          },
          "UserAccessId": {
            "type": "string",
            "description": "Temporary field for entitlements until entitlements activated",
            "nullable": true
          },
          "IsPrivateAsset": {
            "type": "boolean",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "nullable": true
          },
          "NetWorthMultiplier": {
            "type": "integer",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Indicates the status of the account",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountDto": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountCloseDate": {
            "type": "string",
            "description": "Date the account was closed",
            "nullable": true
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AccountOwnerName": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountOwnerNameJoint": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable Account Type with standard options for: Checking, Savings, Line of Credit, Mortgage, Credit Card, Brokerage, ROTH IRA, Traditional IRA, SEP IRA, 401k, Student Loan, 529 Plan, Health Savings",
            "nullable": true
          },
          "AdvisorName": {
            "type": "string",
            "description": "Name of the financial advisor responsible for managing the account",
            "nullable": true
          },
          "AdvisorStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the financial advisor",
            "nullable": true
          },
          "AdvisoryTeamName": {
            "type": "string",
            "description": "Name for the advisory team responsible for managing the account",
            "nullable": true
          },
          "AnnualManagementFeePercent": {
            "type": "number",
            "description": "For simple accounts this is the management fee for the account (useful for simple accounts and revenue projection)",
            "format": "double",
            "nullable": true
          },
          "AssetAllocationDescription": {
            "type": "string",
            "description": "Text description of the Asset Allocation of the Account",
            "nullable": true
          },
          "AssetAllocationName": {
            "type": "string",
            "description": "Name of the target asset allocation",
            "nullable": true
          },
          "BaseCurrency": {
            "type": "string",
            "description": "Book Currency of the Account",
            "nullable": true
          },
          "BulletCount": {
            "type": "integer",
            "description": "The number of bullet withdrawls",
            "format": "int32",
            "nullable": true
          },
          "BulletFrequency": {
            "type": "string",
            "description": "The frequencey (i.e. daily, monthly, quarterly) of the bullet payments",
            "nullable": true
          },
          "BulletWithdrawlAmount": {
            "type": "number",
            "description": "The amount of bullet withdrawls",
            "format": "double",
            "nullable": true
          },
          "BusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable",
            "nullable": true
          },
          "CloseReason": {
            "type": "string",
            "description": "Reason the account was clossed",
            "nullable": true
          },
          "Comments": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Free form comment field",
            "nullable": true
          },
          "CustodyCity": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyCountry": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyRegion": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyState": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "DecumulationStartDate": {
            "type": "string",
            "description": "The date at which decumulation starts",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "description": "Text description of the account",
            "nullable": true
          },
          "DiscretionaryTermStartDate": {
            "type": "string",
            "description": "Performance start date for the current advisor or manager",
            "nullable": true
          },
          "ExpectedDecumulationEndDate": {
            "type": "string",
            "description": "Expected decumulation end date",
            "nullable": true
          },
          "ExpectedGrowthRateToTargetMarketValue": {
            "type": "number",
            "description": "Expected growth rate of assets in the account",
            "format": "double",
            "nullable": true
          },
          "ExpectedMonthlyContributionToTargetMarketValue": {
            "type": "number",
            "description": "Amount the owner will contribute monthly to attempt to achieve the target amount",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "GeneralLedgerType": {
            "type": "string",
            "description": "Asset or Liability",
            "nullable": true
          },
          "HasNewAlerts": {
            "type": "boolean",
            "description": "An indicator there is an unread alert associated with the account",
            "nullable": true
          },
          "HasNewDocuments": {
            "type": "boolean",
            "description": "An indicator the account has had new documents loaded to it",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Communify System Id",
            "nullable": true
          },
          "InflationRate": {
            "type": "number",
            "description": "Used in accumulation and decumulation calculations",
            "format": "double",
            "nullable": true
          },
          "InitialBulletWithdrawlDate": {
            "type": "string",
            "description": "The date at which a limited number of bullet withdrawls may begin",
            "nullable": true
          },
          "InstitutionName1": {
            "type": "string",
            "description": "Name of the Financial Instituion that maintains records for the account",
            "nullable": true
          },
          "InstitutionName2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "IsAdvisorDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsClosed": {
            "type": "boolean",
            "description": "Whether the account is closed",
            "nullable": true
          },
          "IsDiscretionary": {
            "type": "boolean",
            "description": "The advisor or manager has discretion over the account",
            "nullable": true
          },
          "IsERISA": {
            "type": "boolean",
            "description": "ERISA Rules apply to this account",
            "nullable": true
          },
          "IsExcludedFromNetWorthReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from net worth reporting for the owner",
            "nullable": true
          },
          "IsExcludedFromRegulatoryReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from regulatory reporting",
            "nullable": true
          },
          "IsHiddenFromAdvisor": {
            "type": "boolean",
            "description": "Hide the account from the advisor's standard data views",
            "nullable": true
          },
          "IsHiddenFromOwner": {
            "type": "boolean",
            "description": "Hide the account from the owner's standard data views",
            "nullable": true
          },
          "IsMarginApproved": {
            "type": "boolean",
            "description": "Whether assets in the account can be leveraged to purchase securities",
            "nullable": true
          },
          "IsMasterAccount": {
            "type": "boolean",
            "description": "The account is a master account that may have sub accounts",
            "nullable": true
          },
          "IsNIGO": {
            "type": "boolean",
            "description": "The account is not in good order",
            "nullable": true
          },
          "IsOptionsApproved": {
            "type": "boolean",
            "description": "Whether the owner of the account can trade options in this account",
            "nullable": true
          },
          "ISOTimeZone": {
            "type": "string",
            "description": "The GMT time for the end of day (end of activity)",
            "nullable": true
          },
          "IsOwnerDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsSubAccount": {
            "type": "boolean",
            "description": "The account represents a portfolio or sleeve within a Master Account (such as a trust)",
            "nullable": true
          },
          "LegalName": {
            "type": "string",
            "description": "Complete legal name for the account",
            "nullable": true
          },
          "LongName": {
            "type": "string",
            "description": "Custom long name for the account for reporting purposes",
            "nullable": true
          },
          "LotReliefMethodDefault": {
            "type": "string",
            "description": "The lot relief method that should be used to sell lots in the event a specific lot id is not provided on a n order (e.g. LIFO, FIFO, HICO)",
            "nullable": true
          },
          "ManagerFriendlyName": {
            "type": "string",
            "description": "A name the Advisor or account manager can set themselves",
            "nullable": true
          },
          "ManagerStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the portfolio manager",
            "nullable": true
          },
          "ModelPortfolioDescription": {
            "type": "string",
            "description": "Text Description of the Model Portfolio being implemented in the account",
            "nullable": true
          },
          "ModelPortfolioName": {
            "type": "string",
            "description": "Name of the model portfolio for the account",
            "nullable": true
          },
          "OnboardingStages": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The current stage of onboarding the account",
            "nullable": true
          },
          "OwnerCountryOfDomicile": {
            "type": "string",
            "description": "The country the owner lives in",
            "nullable": true
          },
          "OwnerFiscalYearStartDate": {
            "type": "string",
            "description": "Used for reporting purposes if the owner",
            "nullable": true
          },
          "OwnerFriendlyName": {
            "type": "string",
            "description": "A name the Owner can set themselves",
            "nullable": true
          },
          "PreferredSecurityIdentifier": {
            "type": "string",
            "description": "A designation of the preferred security master identifier to be used when showing a position from this account",
            "nullable": true
          },
          "ReportingCurrency": {
            "type": "string",
            "description": "Preferred reporting currency of the account",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Short Name test",
            "nullable": true
          },
          "ExternalSystemKeyAccountingExternalSystemKeyAccounting": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A configuragle designation that includes OPEN, CLOSED, HOLD, DO-NOT-TRADE",
            "nullable": true
          },
          "StatusNote": {
            "type": "string",
            "description": "Short not explaining an account status other than Open",
            "nullable": true
          },
          "StrategyDescription": {
            "type": "string",
            "description": "Text description of the investment strategy of the account",
            "nullable": true
          },
          "SubBusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "TargetDescription": {
            "type": "string",
            "description": "Simple description of the Account objective or goal",
            "nullable": true
          },
          "TargetMarketValue": {
            "type": "number",
            "description": "Simple numeric target for the account",
            "format": "double",
            "nullable": true
          },
          "TargetMarketValueDate": {
            "type": "string",
            "description": "Date the Owner would like to achieve the simple market value target",
            "nullable": true
          },
          "TaxTreatmentTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Simplified tax treatment category (Taxable, Tax Deferred or Tax Free)",
            "nullable": true
          },
          "ThirdPartyConsultantNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Name of the 3rd Part Consulatant who reviews the account for the owner",
            "nullable": true
          },
          "UserAccessId": {
            "type": "string",
            "description": "Temporary field for entitlements until entitlements activated",
            "nullable": true
          },
          "IsPrivateAsset": {
            "type": "boolean",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "nullable": true
          },
          "NetWorthMultiplier": {
            "type": "integer",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Indicates the status of the account",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/AccountDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountFinancialSummaryGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "EnrichWithCodeSet": {
            "type": "boolean",
            "nullable": true
          },
          "CodeSetCategory": {
            "type": "string",
            "nullable": true
          },
          "IncludeInvestments": {
            "type": "boolean"
          },
          "IncludePrivateAssets": {
            "type": "boolean"
          },
          "AccountIds": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountFinancialSummaryGetResponse": {
        "type": "object",
        "properties": {
          "TotalNetWorth": {
            "type": "number",
            "format": "double"
          },
          "TotalInvestments": {
            "type": "number",
            "format": "double"
          },
          "TotalPrivateAssets": {
            "type": "number",
            "format": "double"
          },
          "PrivateAssets": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/AccountFinancialSummaryPrivateAssetDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountFinancialSummaryGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountFinancialSummaryGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountFinancialSummaryPrivateAssetDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Value": {
            "type": "number",
            "format": "double"
          },
          "Percentage": {
            "type": "number",
            "format": "double"
          },
          "Color": {
            "type": "string",
            "nullable": true
          },
          "Count": {
            "type": "integer",
            "format": "int32"
          }
        },
        "additionalProperties": false
      },
      "AccountGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountGetResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountCloseDate": {
            "type": "string",
            "description": "Date the account was closed",
            "nullable": true
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AccountOwnerName": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountOwnerNameJoint": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable Account Type with standard options for: Checking, Savings, Line of Credit, Mortgage, Credit Card, Brokerage, ROTH IRA, Traditional IRA, SEP IRA, 401k, Student Loan, 529 Plan, Health Savings",
            "nullable": true
          },
          "AdvisorName": {
            "type": "string",
            "description": "Name of the financial advisor responsible for managing the account",
            "nullable": true
          },
          "AdvisorStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the financial advisor",
            "nullable": true
          },
          "AdvisoryTeamName": {
            "type": "string",
            "description": "Name for the advisory team responsible for managing the account",
            "nullable": true
          },
          "AnnualManagementFeePercent": {
            "type": "number",
            "description": "For simple accounts this is the management fee for the account (useful for simple accounts and revenue projection)",
            "format": "double",
            "nullable": true
          },
          "AssetAllocationDescription": {
            "type": "string",
            "description": "Text description of the Asset Allocation of the Account",
            "nullable": true
          },
          "AssetAllocationName": {
            "type": "string",
            "description": "Name of the target asset allocation",
            "nullable": true
          },
          "BaseCurrency": {
            "type": "string",
            "description": "Book Currency of the Account",
            "nullable": true
          },
          "BulletCount": {
            "type": "integer",
            "description": "The number of bullet withdrawls",
            "format": "int32",
            "nullable": true
          },
          "BulletFrequency": {
            "type": "string",
            "description": "The frequencey (i.e. daily, monthly, quarterly) of the bullet payments",
            "nullable": true
          },
          "BulletWithdrawlAmount": {
            "type": "number",
            "description": "The amount of bullet withdrawls",
            "format": "double",
            "nullable": true
          },
          "BusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable",
            "nullable": true
          },
          "CloseReason": {
            "type": "string",
            "description": "Reason the account was clossed",
            "nullable": true
          },
          "Comments": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Free form comment field",
            "nullable": true
          },
          "CustodyCity": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyCountry": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyRegion": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyState": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "DecumulationStartDate": {
            "type": "string",
            "description": "The date at which decumulation starts",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "description": "Text description of the account",
            "nullable": true
          },
          "DiscretionaryTermStartDate": {
            "type": "string",
            "description": "Performance start date for the current advisor or manager",
            "nullable": true
          },
          "ExpectedDecumulationEndDate": {
            "type": "string",
            "description": "Expected decumulation end date",
            "nullable": true
          },
          "ExpectedGrowthRateToTargetMarketValue": {
            "type": "number",
            "description": "Expected growth rate of assets in the account",
            "format": "double",
            "nullable": true
          },
          "ExpectedMonthlyContributionToTargetMarketValue": {
            "type": "number",
            "description": "Amount the owner will contribute monthly to attempt to achieve the target amount",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "GeneralLedgerType": {
            "type": "string",
            "description": "Asset or Liability",
            "nullable": true
          },
          "HasNewAlerts": {
            "type": "boolean",
            "description": "An indicator there is an unread alert associated with the account",
            "nullable": true
          },
          "HasNewDocuments": {
            "type": "boolean",
            "description": "An indicator the account has had new documents loaded to it",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Communify System Id",
            "nullable": true
          },
          "InflationRate": {
            "type": "number",
            "description": "Used in accumulation and decumulation calculations",
            "format": "double",
            "nullable": true
          },
          "InitialBulletWithdrawlDate": {
            "type": "string",
            "description": "The date at which a limited number of bullet withdrawls may begin",
            "nullable": true
          },
          "InstitutionName1": {
            "type": "string",
            "description": "Name of the Financial Instituion that maintains records for the account",
            "nullable": true
          },
          "InstitutionName2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "IsAdvisorDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsClosed": {
            "type": "boolean",
            "description": "Whether the account is closed",
            "nullable": true
          },
          "IsDiscretionary": {
            "type": "boolean",
            "description": "The advisor or manager has discretion over the account",
            "nullable": true
          },
          "IsERISA": {
            "type": "boolean",
            "description": "ERISA Rules apply to this account",
            "nullable": true
          },
          "IsExcludedFromNetWorthReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from net worth reporting for the owner",
            "nullable": true
          },
          "IsExcludedFromRegulatoryReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from regulatory reporting",
            "nullable": true
          },
          "IsHiddenFromAdvisor": {
            "type": "boolean",
            "description": "Hide the account from the advisor's standard data views",
            "nullable": true
          },
          "IsHiddenFromOwner": {
            "type": "boolean",
            "description": "Hide the account from the owner's standard data views",
            "nullable": true
          },
          "IsMarginApproved": {
            "type": "boolean",
            "description": "Whether assets in the account can be leveraged to purchase securities",
            "nullable": true
          },
          "IsMasterAccount": {
            "type": "boolean",
            "description": "The account is a master account that may have sub accounts",
            "nullable": true
          },
          "IsNIGO": {
            "type": "boolean",
            "description": "The account is not in good order",
            "nullable": true
          },
          "IsOptionsApproved": {
            "type": "boolean",
            "description": "Whether the owner of the account can trade options in this account",
            "nullable": true
          },
          "ISOTimeZone": {
            "type": "string",
            "description": "The GMT time for the end of day (end of activity)",
            "nullable": true
          },
          "IsOwnerDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsSubAccount": {
            "type": "boolean",
            "description": "The account represents a portfolio or sleeve within a Master Account (such as a trust)",
            "nullable": true
          },
          "LegalName": {
            "type": "string",
            "description": "Complete legal name for the account",
            "nullable": true
          },
          "LongName": {
            "type": "string",
            "description": "Custom long name for the account for reporting purposes",
            "nullable": true
          },
          "LotReliefMethodDefault": {
            "type": "string",
            "description": "The lot relief method that should be used to sell lots in the event a specific lot id is not provided on a n order (e.g. LIFO, FIFO, HICO)",
            "nullable": true
          },
          "ManagerFriendlyName": {
            "type": "string",
            "description": "A name the Advisor or account manager can set themselves",
            "nullable": true
          },
          "ManagerStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the portfolio manager",
            "nullable": true
          },
          "ModelPortfolioDescription": {
            "type": "string",
            "description": "Text Description of the Model Portfolio being implemented in the account",
            "nullable": true
          },
          "ModelPortfolioName": {
            "type": "string",
            "description": "Name of the model portfolio for the account",
            "nullable": true
          },
          "OnboardingStages": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The current stage of onboarding the account",
            "nullable": true
          },
          "OwnerCountryOfDomicile": {
            "type": "string",
            "description": "The country the owner lives in",
            "nullable": true
          },
          "OwnerFiscalYearStartDate": {
            "type": "string",
            "description": "Used for reporting purposes if the owner",
            "nullable": true
          },
          "OwnerFriendlyName": {
            "type": "string",
            "description": "A name the Owner can set themselves",
            "nullable": true
          },
          "PreferredSecurityIdentifier": {
            "type": "string",
            "description": "A designation of the preferred security master identifier to be used when showing a position from this account",
            "nullable": true
          },
          "ReportingCurrency": {
            "type": "string",
            "description": "Preferred reporting currency of the account",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Short Name test",
            "nullable": true
          },
          "ExternalSystemKeyAccountingExternalSystemKeyAccounting": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A configuragle designation that includes OPEN, CLOSED, HOLD, DO-NOT-TRADE",
            "nullable": true
          },
          "StatusNote": {
            "type": "string",
            "description": "Short not explaining an account status other than Open",
            "nullable": true
          },
          "StrategyDescription": {
            "type": "string",
            "description": "Text description of the investment strategy of the account",
            "nullable": true
          },
          "SubBusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "TargetDescription": {
            "type": "string",
            "description": "Simple description of the Account objective or goal",
            "nullable": true
          },
          "TargetMarketValue": {
            "type": "number",
            "description": "Simple numeric target for the account",
            "format": "double",
            "nullable": true
          },
          "TargetMarketValueDate": {
            "type": "string",
            "description": "Date the Owner would like to achieve the simple market value target",
            "nullable": true
          },
          "TaxTreatmentTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Simplified tax treatment category (Taxable, Tax Deferred or Tax Free)",
            "nullable": true
          },
          "ThirdPartyConsultantNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Name of the 3rd Part Consulatant who reviews the account for the owner",
            "nullable": true
          },
          "UserAccessId": {
            "type": "string",
            "description": "Temporary field for entitlements until entitlements activated",
            "nullable": true
          },
          "IsPrivateAsset": {
            "type": "boolean",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "nullable": true
          },
          "NetWorthMultiplier": {
            "type": "integer",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Indicates the status of the account",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Communify internal system Account Id.",
            "nullable": true,
            "example": "5175bd1b-2fe5-4704-98d9-1d9420d00883"
          },
          "HasUsers": {
            "type": "boolean",
            "description": "Flag indicating if account has client users that have entitlement to the account.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Communify internal system Account Id.",
            "nullable": true,
            "example": "5175bd1b-2fe5-4704-98d9-1d9420d00883"
          },
          "HasUsers": {
            "type": "boolean",
            "description": "Flag indicating if account has client users that have entitlement to the account.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountMetricCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Communify internal system Account Id.",
            "nullable": true,
            "example": "5175bd1b-2fe5-4704-98d9-1d9420d00883"
          },
          "HasUsers": {
            "type": "boolean",
            "description": "Flag indicating if account has client users that have entitlement to the account.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountMetricDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Communify internal system Account Id.",
            "nullable": true,
            "example": "5175bd1b-2fe5-4704-98d9-1d9420d00883"
          },
          "HasUsers": {
            "type": "boolean",
            "description": "Flag indicating if account has client users that have entitlement to the account.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/AccountMetricDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountMetricDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Communify internal system Account Id.",
            "nullable": true,
            "example": "5175bd1b-2fe5-4704-98d9-1d9420d00883"
          },
          "HasUsers": {
            "type": "boolean",
            "description": "Flag indicating if account has client users that have entitlement to the account.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountMetricGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Communify internal system Account Id.",
            "nullable": true,
            "example": "5175bd1b-2fe5-4704-98d9-1d9420d00883"
          },
          "HasUsers": {
            "type": "boolean",
            "description": "Flag indicating if account has client users that have entitlement to the account.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Communify internal system Account Id.",
            "nullable": true,
            "example": "5175bd1b-2fe5-4704-98d9-1d9420d00883"
          },
          "HasUsers": {
            "type": "boolean",
            "description": "Flag indicating if account has client users that have entitlement to the account.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountMetricUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountMetricUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountQueryWithoutUsersRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountQueryWithoutUsersResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "type": "object",
              "additionalProperties": {
                "$ref": "#/components/schemas/JsonNode"
              }
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountQueryWithoutUsersResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountQueryWithoutUsersResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountSummaryGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountSummaryGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "description": "Account Id",
            "nullable": true
          },
          "Account": {
            "type": "string",
            "description": "Account number",
            "nullable": true
          },
          "TotalValueUpperLeft": {
            "type": "number",
            "description": "Total value",
            "format": "double",
            "nullable": true
          },
          "DaysGainLoss": {
            "type": "number",
            "description": "Days Gain/Loss",
            "format": "double",
            "nullable": true
          },
          "YTDDividendIncome": {
            "type": "number",
            "description": "YTD Dividend Income",
            "format": "double",
            "nullable": true
          },
          "YTDInterestIncome": {
            "type": "number",
            "description": "YTD Interest Income",
            "format": "double",
            "nullable": true
          },
          "Investments": {
            "type": "number",
            "description": "Investments",
            "format": "double",
            "nullable": true
          },
          "CashAndBankSweep": {
            "type": "number",
            "description": "Cash And Bank Sweep",
            "format": "double",
            "nullable": true
          },
          "YTDRealizedGainLoss": {
            "type": "number",
            "description": "YTD Realized Gain/loss",
            "format": "double",
            "nullable": true
          },
          "UnrealizedGainLoss": {
            "type": "number",
            "description": "Unrealized Gain/Loss",
            "format": "double",
            "nullable": true
          },
          "Date": {
            "type": "string",
            "description": "Values date",
            "format": "date-time",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountSummaryGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountSummaryGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountUpdateRequest": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountCloseDate": {
            "type": "string",
            "description": "Date the account was closed",
            "nullable": true
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AccountOwnerName": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountOwnerNameJoint": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable Account Type with standard options for: Checking, Savings, Line of Credit, Mortgage, Credit Card, Brokerage, ROTH IRA, Traditional IRA, SEP IRA, 401k, Student Loan, 529 Plan, Health Savings",
            "nullable": true
          },
          "AdvisorName": {
            "type": "string",
            "description": "Name of the financial advisor responsible for managing the account",
            "nullable": true
          },
          "AdvisorStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the financial advisor",
            "nullable": true
          },
          "AdvisoryTeamName": {
            "type": "string",
            "description": "Name for the advisory team responsible for managing the account",
            "nullable": true
          },
          "AnnualManagementFeePercent": {
            "type": "number",
            "description": "For simple accounts this is the management fee for the account (useful for simple accounts and revenue projection)",
            "format": "double",
            "nullable": true
          },
          "AssetAllocationDescription": {
            "type": "string",
            "description": "Text description of the Asset Allocation of the Account",
            "nullable": true
          },
          "AssetAllocationName": {
            "type": "string",
            "description": "Name of the target asset allocation",
            "nullable": true
          },
          "BaseCurrency": {
            "type": "string",
            "description": "Book Currency of the Account",
            "nullable": true
          },
          "BulletCount": {
            "type": "integer",
            "description": "The number of bullet withdrawls",
            "format": "int32",
            "nullable": true
          },
          "BulletFrequency": {
            "type": "string",
            "description": "The frequencey (i.e. daily, monthly, quarterly) of the bullet payments",
            "nullable": true
          },
          "BulletWithdrawlAmount": {
            "type": "number",
            "description": "The amount of bullet withdrawls",
            "format": "double",
            "nullable": true
          },
          "BusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable",
            "nullable": true
          },
          "CloseReason": {
            "type": "string",
            "description": "Reason the account was clossed",
            "nullable": true
          },
          "Comments": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Free form comment field",
            "nullable": true
          },
          "CustodyCity": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyCountry": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyRegion": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyState": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "DecumulationStartDate": {
            "type": "string",
            "description": "The date at which decumulation starts",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "description": "Text description of the account",
            "nullable": true
          },
          "DiscretionaryTermStartDate": {
            "type": "string",
            "description": "Performance start date for the current advisor or manager",
            "nullable": true
          },
          "ExpectedDecumulationEndDate": {
            "type": "string",
            "description": "Expected decumulation end date",
            "nullable": true
          },
          "ExpectedGrowthRateToTargetMarketValue": {
            "type": "number",
            "description": "Expected growth rate of assets in the account",
            "format": "double",
            "nullable": true
          },
          "ExpectedMonthlyContributionToTargetMarketValue": {
            "type": "number",
            "description": "Amount the owner will contribute monthly to attempt to achieve the target amount",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "GeneralLedgerType": {
            "type": "string",
            "description": "Asset or Liability",
            "nullable": true
          },
          "HasNewAlerts": {
            "type": "boolean",
            "description": "An indicator there is an unread alert associated with the account",
            "nullable": true
          },
          "HasNewDocuments": {
            "type": "boolean",
            "description": "An indicator the account has had new documents loaded to it",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Communify System Id",
            "nullable": true
          },
          "InflationRate": {
            "type": "number",
            "description": "Used in accumulation and decumulation calculations",
            "format": "double",
            "nullable": true
          },
          "InitialBulletWithdrawlDate": {
            "type": "string",
            "description": "The date at which a limited number of bullet withdrawls may begin",
            "nullable": true
          },
          "InstitutionName1": {
            "type": "string",
            "description": "Name of the Financial Instituion that maintains records for the account",
            "nullable": true
          },
          "InstitutionName2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "IsAdvisorDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsClosed": {
            "type": "boolean",
            "description": "Whether the account is closed",
            "nullable": true
          },
          "IsDiscretionary": {
            "type": "boolean",
            "description": "The advisor or manager has discretion over the account",
            "nullable": true
          },
          "IsERISA": {
            "type": "boolean",
            "description": "ERISA Rules apply to this account",
            "nullable": true
          },
          "IsExcludedFromNetWorthReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from net worth reporting for the owner",
            "nullable": true
          },
          "IsExcludedFromRegulatoryReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from regulatory reporting",
            "nullable": true
          },
          "IsHiddenFromAdvisor": {
            "type": "boolean",
            "description": "Hide the account from the advisor's standard data views",
            "nullable": true
          },
          "IsHiddenFromOwner": {
            "type": "boolean",
            "description": "Hide the account from the owner's standard data views",
            "nullable": true
          },
          "IsMarginApproved": {
            "type": "boolean",
            "description": "Whether assets in the account can be leveraged to purchase securities",
            "nullable": true
          },
          "IsMasterAccount": {
            "type": "boolean",
            "description": "The account is a master account that may have sub accounts",
            "nullable": true
          },
          "IsNIGO": {
            "type": "boolean",
            "description": "The account is not in good order",
            "nullable": true
          },
          "IsOptionsApproved": {
            "type": "boolean",
            "description": "Whether the owner of the account can trade options in this account",
            "nullable": true
          },
          "ISOTimeZone": {
            "type": "string",
            "description": "The GMT time for the end of day (end of activity)",
            "nullable": true
          },
          "IsOwnerDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsSubAccount": {
            "type": "boolean",
            "description": "The account represents a portfolio or sleeve within a Master Account (such as a trust)",
            "nullable": true
          },
          "LegalName": {
            "type": "string",
            "description": "Complete legal name for the account",
            "nullable": true
          },
          "LongName": {
            "type": "string",
            "description": "Custom long name for the account for reporting purposes",
            "nullable": true
          },
          "LotReliefMethodDefault": {
            "type": "string",
            "description": "The lot relief method that should be used to sell lots in the event a specific lot id is not provided on a n order (e.g. LIFO, FIFO, HICO)",
            "nullable": true
          },
          "ManagerFriendlyName": {
            "type": "string",
            "description": "A name the Advisor or account manager can set themselves",
            "nullable": true
          },
          "ManagerStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the portfolio manager",
            "nullable": true
          },
          "ModelPortfolioDescription": {
            "type": "string",
            "description": "Text Description of the Model Portfolio being implemented in the account",
            "nullable": true
          },
          "ModelPortfolioName": {
            "type": "string",
            "description": "Name of the model portfolio for the account",
            "nullable": true
          },
          "OnboardingStages": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The current stage of onboarding the account",
            "nullable": true
          },
          "OwnerCountryOfDomicile": {
            "type": "string",
            "description": "The country the owner lives in",
            "nullable": true
          },
          "OwnerFiscalYearStartDate": {
            "type": "string",
            "description": "Used for reporting purposes if the owner",
            "nullable": true
          },
          "OwnerFriendlyName": {
            "type": "string",
            "description": "A name the Owner can set themselves",
            "nullable": true
          },
          "PreferredSecurityIdentifier": {
            "type": "string",
            "description": "A designation of the preferred security master identifier to be used when showing a position from this account",
            "nullable": true
          },
          "ReportingCurrency": {
            "type": "string",
            "description": "Preferred reporting currency of the account",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Short Name test",
            "nullable": true
          },
          "ExternalSystemKeyAccountingExternalSystemKeyAccounting": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A configuragle designation that includes OPEN, CLOSED, HOLD, DO-NOT-TRADE",
            "nullable": true
          },
          "StatusNote": {
            "type": "string",
            "description": "Short not explaining an account status other than Open",
            "nullable": true
          },
          "StrategyDescription": {
            "type": "string",
            "description": "Text description of the investment strategy of the account",
            "nullable": true
          },
          "SubBusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "TargetDescription": {
            "type": "string",
            "description": "Simple description of the Account objective or goal",
            "nullable": true
          },
          "TargetMarketValue": {
            "type": "number",
            "description": "Simple numeric target for the account",
            "format": "double",
            "nullable": true
          },
          "TargetMarketValueDate": {
            "type": "string",
            "description": "Date the Owner would like to achieve the simple market value target",
            "nullable": true
          },
          "TaxTreatmentTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Simplified tax treatment category (Taxable, Tax Deferred or Tax Free)",
            "nullable": true
          },
          "ThirdPartyConsultantNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Name of the 3rd Part Consulatant who reviews the account for the owner",
            "nullable": true
          },
          "UserAccessId": {
            "type": "string",
            "description": "Temporary field for entitlements until entitlements activated",
            "nullable": true
          },
          "IsPrivateAsset": {
            "type": "boolean",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "nullable": true
          },
          "NetWorthMultiplier": {
            "type": "integer",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Indicates the status of the account",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountUpdateResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountCloseDate": {
            "type": "string",
            "description": "Date the account was closed",
            "nullable": true
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AccountOwnerName": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountOwnerNameJoint": {
            "type": "string",
            "description": "The name of the joint owner of the account (if there is a joint owner)",
            "nullable": true
          },
          "AccountTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable Account Type with standard options for: Checking, Savings, Line of Credit, Mortgage, Credit Card, Brokerage, ROTH IRA, Traditional IRA, SEP IRA, 401k, Student Loan, 529 Plan, Health Savings",
            "nullable": true
          },
          "AdvisorName": {
            "type": "string",
            "description": "Name of the financial advisor responsible for managing the account",
            "nullable": true
          },
          "AdvisorStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the financial advisor",
            "nullable": true
          },
          "AdvisoryTeamName": {
            "type": "string",
            "description": "Name for the advisory team responsible for managing the account",
            "nullable": true
          },
          "AnnualManagementFeePercent": {
            "type": "number",
            "description": "For simple accounts this is the management fee for the account (useful for simple accounts and revenue projection)",
            "format": "double",
            "nullable": true
          },
          "AssetAllocationDescription": {
            "type": "string",
            "description": "Text description of the Asset Allocation of the Account",
            "nullable": true
          },
          "AssetAllocationName": {
            "type": "string",
            "description": "Name of the target asset allocation",
            "nullable": true
          },
          "BaseCurrency": {
            "type": "string",
            "description": "Book Currency of the Account",
            "nullable": true
          },
          "BulletCount": {
            "type": "integer",
            "description": "The number of bullet withdrawls",
            "format": "int32",
            "nullable": true
          },
          "BulletFrequency": {
            "type": "string",
            "description": "The frequencey (i.e. daily, monthly, quarterly) of the bullet payments",
            "nullable": true
          },
          "BulletWithdrawlAmount": {
            "type": "number",
            "description": "The amount of bullet withdrawls",
            "format": "double",
            "nullable": true
          },
          "BusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "BusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Configurable",
            "nullable": true
          },
          "CloseReason": {
            "type": "string",
            "description": "Reason the account was clossed",
            "nullable": true
          },
          "Comments": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Free form comment field",
            "nullable": true
          },
          "CustodyCity": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyCountry": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyRegion": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "CustodyState": {
            "type": "string",
            "description": "Client Configurable",
            "nullable": true
          },
          "DecumulationStartDate": {
            "type": "string",
            "description": "The date at which decumulation starts",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "description": "Text description of the account",
            "nullable": true
          },
          "DiscretionaryTermStartDate": {
            "type": "string",
            "description": "Performance start date for the current advisor or manager",
            "nullable": true
          },
          "ExpectedDecumulationEndDate": {
            "type": "string",
            "description": "Expected decumulation end date",
            "nullable": true
          },
          "ExpectedGrowthRateToTargetMarketValue": {
            "type": "number",
            "description": "Expected growth rate of assets in the account",
            "format": "double",
            "nullable": true
          },
          "ExpectedMonthlyContributionToTargetMarketValue": {
            "type": "number",
            "description": "Amount the owner will contribute monthly to attempt to achieve the target amount",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "GeneralLedgerType": {
            "type": "string",
            "description": "Asset or Liability",
            "nullable": true
          },
          "HasNewAlerts": {
            "type": "boolean",
            "description": "An indicator there is an unread alert associated with the account",
            "nullable": true
          },
          "HasNewDocuments": {
            "type": "boolean",
            "description": "An indicator the account has had new documents loaded to it",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Communify System Id",
            "nullable": true
          },
          "InflationRate": {
            "type": "number",
            "description": "Used in accumulation and decumulation calculations",
            "format": "double",
            "nullable": true
          },
          "InitialBulletWithdrawlDate": {
            "type": "string",
            "description": "The date at which a limited number of bullet withdrawls may begin",
            "nullable": true
          },
          "InstitutionName1": {
            "type": "string",
            "description": "Name of the Financial Instituion that maintains records for the account",
            "nullable": true
          },
          "InstitutionName2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "IsAdvisorDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsClosed": {
            "type": "boolean",
            "description": "Whether the account is closed",
            "nullable": true
          },
          "IsDiscretionary": {
            "type": "boolean",
            "description": "The advisor or manager has discretion over the account",
            "nullable": true
          },
          "IsERISA": {
            "type": "boolean",
            "description": "ERISA Rules apply to this account",
            "nullable": true
          },
          "IsExcludedFromNetWorthReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from net worth reporting for the owner",
            "nullable": true
          },
          "IsExcludedFromRegulatoryReporting": {
            "type": "boolean",
            "description": "A boolean flag to exclude the account from regulatory reporting",
            "nullable": true
          },
          "IsHiddenFromAdvisor": {
            "type": "boolean",
            "description": "Hide the account from the advisor's standard data views",
            "nullable": true
          },
          "IsHiddenFromOwner": {
            "type": "boolean",
            "description": "Hide the account from the owner's standard data views",
            "nullable": true
          },
          "IsMarginApproved": {
            "type": "boolean",
            "description": "Whether assets in the account can be leveraged to purchase securities",
            "nullable": true
          },
          "IsMasterAccount": {
            "type": "boolean",
            "description": "The account is a master account that may have sub accounts",
            "nullable": true
          },
          "IsNIGO": {
            "type": "boolean",
            "description": "The account is not in good order",
            "nullable": true
          },
          "IsOptionsApproved": {
            "type": "boolean",
            "description": "Whether the owner of the account can trade options in this account",
            "nullable": true
          },
          "ISOTimeZone": {
            "type": "string",
            "description": "The GMT time for the end of day (end of activity)",
            "nullable": true
          },
          "IsOwnerDefaultAccount": {
            "type": "boolean",
            "description": "An indicator that this account should be used if a default account is needed",
            "nullable": true
          },
          "IsSubAccount": {
            "type": "boolean",
            "description": "The account represents a portfolio or sleeve within a Master Account (such as a trust)",
            "nullable": true
          },
          "LegalName": {
            "type": "string",
            "description": "Complete legal name for the account",
            "nullable": true
          },
          "LongName": {
            "type": "string",
            "description": "Custom long name for the account for reporting purposes",
            "nullable": true
          },
          "LotReliefMethodDefault": {
            "type": "string",
            "description": "The lot relief method that should be used to sell lots in the event a specific lot id is not provided on a n order (e.g. LIFO, FIFO, HICO)",
            "nullable": true
          },
          "ManagerFriendlyName": {
            "type": "string",
            "description": "A name the Advisor or account manager can set themselves",
            "nullable": true
          },
          "ManagerStrategyDescription": {
            "type": "string",
            "description": "Description of the strategy for the account as defined by the portfolio manager",
            "nullable": true
          },
          "ModelPortfolioDescription": {
            "type": "string",
            "description": "Text Description of the Model Portfolio being implemented in the account",
            "nullable": true
          },
          "ModelPortfolioName": {
            "type": "string",
            "description": "Name of the model portfolio for the account",
            "nullable": true
          },
          "OnboardingStages": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The current stage of onboarding the account",
            "nullable": true
          },
          "OwnerCountryOfDomicile": {
            "type": "string",
            "description": "The country the owner lives in",
            "nullable": true
          },
          "OwnerFiscalYearStartDate": {
            "type": "string",
            "description": "Used for reporting purposes if the owner",
            "nullable": true
          },
          "OwnerFriendlyName": {
            "type": "string",
            "description": "A name the Owner can set themselves",
            "nullable": true
          },
          "PreferredSecurityIdentifier": {
            "type": "string",
            "description": "A designation of the preferred security master identifier to be used when showing a position from this account",
            "nullable": true
          },
          "ReportingCurrency": {
            "type": "string",
            "description": "Preferred reporting currency of the account",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Short Name test",
            "nullable": true
          },
          "ExternalSystemKeyAccountingExternalSystemKeyAccounting": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A configuragle designation that includes OPEN, CLOSED, HOLD, DO-NOT-TRADE",
            "nullable": true
          },
          "StatusNote": {
            "type": "string",
            "description": "Short not explaining an account status other than Open",
            "nullable": true
          },
          "StrategyDescription": {
            "type": "string",
            "description": "Text description of the investment strategy of the account",
            "nullable": true
          },
          "SubBusinessUnitLevel1": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel2": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel3": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel4": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "SubBusinessUnitLevel5": {
            "type": "string",
            "description": "Configurable by Client",
            "nullable": true
          },
          "TargetDescription": {
            "type": "string",
            "description": "Simple description of the Account objective or goal",
            "nullable": true
          },
          "TargetMarketValue": {
            "type": "number",
            "description": "Simple numeric target for the account",
            "format": "double",
            "nullable": true
          },
          "TargetMarketValueDate": {
            "type": "string",
            "description": "Date the Owner would like to achieve the simple market value target",
            "nullable": true
          },
          "TaxTreatmentTypes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Simplified tax treatment category (Taxable, Tax Deferred or Tax Free)",
            "nullable": true
          },
          "ThirdPartyConsultantNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Name of the 3rd Part Consulatant who reviews the account for the owner",
            "nullable": true
          },
          "UserAccessId": {
            "type": "string",
            "description": "Temporary field for entitlements until entitlements activated",
            "nullable": true
          },
          "IsPrivateAsset": {
            "type": "boolean",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "nullable": true
          },
          "NetWorthMultiplier": {
            "type": "integer",
            "description": "Indicated whether the value of the account should be obtained from the PrivateAsset entity (instead of from Position)",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Indicates the status of the account",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AccountUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AccountUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ActionDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ActionDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "CreatedBy": {
            "$ref": "#/components/schemas/UserProfileDto"
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeadlineDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "DeadlineTime": {
            "type": "string",
            "format": "time",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "Priority": {
            "type": "string",
            "nullable": true
          },
          "Text": {
            "type": "string",
            "nullable": true
          },
          "Note": {
            "$ref": "#/components/schemas/NoteDto"
          },
          "CurrentAssignment": {
            "$ref": "#/components/schemas/AssignmentDto"
          },
          "Trackers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Files": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/FileMeta"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ActionDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/ActionDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ActionDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "CreatedBy": {
            "$ref": "#/components/schemas/UserProfileDto"
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeadlineDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "DeadlineTime": {
            "type": "string",
            "format": "time",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "Priority": {
            "type": "string",
            "nullable": true
          },
          "Text": {
            "type": "string",
            "nullable": true
          },
          "Note": {
            "$ref": "#/components/schemas/NoteDto"
          },
          "CurrentAssignment": {
            "$ref": "#/components/schemas/AssignmentDto"
          },
          "Trackers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Files": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/FileMeta"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ActionDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/ActionDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ActionDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/ActionDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ActionGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Filter": {
            "$ref": "#/components/schemas/ActionsFilter"
          }
        },
        "additionalProperties": false
      },
      "ActionGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "CreatedBy": {
            "$ref": "#/components/schemas/UserProfileDto"
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeadlineDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "DeadlineTime": {
            "type": "string",
            "format": "time",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "Priority": {
            "type": "string",
            "nullable": true
          },
          "Text": {
            "type": "string",
            "nullable": true
          },
          "Note": {
            "$ref": "#/components/schemas/NoteDto"
          },
          "CurrentAssignment": {
            "$ref": "#/components/schemas/AssignmentDto"
          },
          "Trackers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Files": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/FileMeta"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ActionGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/ActionGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ActionMergeRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "CreatedBy": {
            "$ref": "#/components/schemas/UserProfileDto"
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeadlineDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "DeadlineTime": {
            "type": "string",
            "format": "time",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "Priority": {
            "type": "string",
            "nullable": true
          },
          "Text": {
            "type": "string",
            "nullable": true
          },
          "Note": {
            "$ref": "#/components/schemas/NoteDto"
          },
          "CurrentAssignment": {
            "$ref": "#/components/schemas/AssignmentDto"
          },
          "Trackers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Files": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/FileMeta"
            },
            "nullable": true
          },
          "AutoAssignStatus": {
            "type": "boolean"
          },
          "NewFiles": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/FileMeta"
            },
            "nullable": true
          },
          "AssignTo": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ActionMergeResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "CreatedBy": {
            "$ref": "#/components/schemas/UserProfileDto"
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeadlineDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "DeadlineTime": {
            "type": "string",
            "format": "time",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "Priority": {
            "type": "string",
            "nullable": true
          },
          "Text": {
            "type": "string",
            "nullable": true
          },
          "Note": {
            "$ref": "#/components/schemas/NoteDto"
          },
          "CurrentAssignment": {
            "$ref": "#/components/schemas/AssignmentDto"
          },
          "Trackers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Files": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/FileMeta"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ActionMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/ActionMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ActionQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "$ref": "#/components/schemas/ActionsFilter"
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ActionsFilter": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "Priority": {
            "type": "string",
            "nullable": true
          },
          "Assignee": {
            "type": "string",
            "nullable": true
          },
          "DelegatedByMe": {
            "type": "boolean",
            "nullable": true
          },
          "AssignedByMe": {
            "type": "boolean",
            "nullable": true
          },
          "TrackedBy": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AdminKPIQueryDashboardRequest": {
        "type": "object",
        "additionalProperties": false
      },
      "AdminKPIQueryDashboardResponse": {
        "type": "object",
        "properties": {
          "PendingUserInvitations": {
            "type": "integer",
            "description": "Pending User Invitations",
            "format": "int32"
          },
          "DocumentsUploaded": {
            "type": "integer",
            "description": "Documents Uploaded",
            "format": "int32"
          },
          "UnpublishedDocuments": {
            "type": "integer",
            "description": "Unpublished Documents",
            "format": "int32"
          },
          "PublishedDocuments": {
            "type": "integer",
            "description": "Published Documents",
            "format": "int32"
          }
        },
        "additionalProperties": false
      },
      "AdminKPIQueryDashboardResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AdminKPIQueryDashboardResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AdminKPIQueryNotificationRequest": {
        "type": "object",
        "properties": {
          "StartDate": {
            "type": "string",
            "format": "date-time"
          },
          "EndDate": {
            "type": "string",
            "format": "date-time"
          }
        },
        "additionalProperties": false
      },
      "AdminKPIQueryNotificationResponse": {
        "type": "object",
        "properties": {
          "NotificationsSentToday": {
            "type": "integer",
            "description": "Notifications Sent Today",
            "format": "int32"
          },
          "NotificationsSent": {
            "type": "integer",
            "description": "Notifications Sent",
            "format": "int32"
          },
          "StatementNotifications": {
            "type": "integer",
            "description": "Statement Notifications",
            "format": "int32"
          },
          "FormADVNotifications": {
            "type": "integer",
            "description": "FormADV Notifications",
            "format": "int32"
          },
          "FormCRSNotifications": {
            "type": "integer",
            "description": "Form CRS Notifications",
            "format": "int32"
          }
        },
        "additionalProperties": false
      },
      "AdminKPIQueryNotificationResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AdminKPIQueryNotificationResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ApxPackagesDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountClassifications": {
            "type": "string",
            "nullable": true
          },
          "IsDocumentApproved": {
            "type": "boolean",
            "nullable": true
          },
          "FileUploadDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "AdminApprover": {
            "type": "string",
            "nullable": true
          },
          "ComplianceApprover": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ApxPackagesDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/ApxPackagesDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ApxPackagesDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/ApxPackagesDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ApxPackagesQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AssignmentDto": {
        "type": "object",
        "properties": {
          "ActionId": {
            "type": "string",
            "nullable": true
          },
          "AssignedOn": {
            "type": "string",
            "format": "date-time"
          },
          "Assignee": {
            "$ref": "#/components/schemas/UserProfileDto"
          },
          "AssignedBy": {
            "$ref": "#/components/schemas/UserProfileDto"
          }
        },
        "additionalProperties": false
      },
      "AuthCreateCalcEntitlementsRequest": {
        "type": "object",
        "properties": {
          "UserId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthCreateCalcEntitlementsResponse": {
        "type": "object",
        "additionalProperties": false
      },
      "AuthCreateCalcEntitlementsResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AuthCreateCalcEntitlementsResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthCreateImpersonateRequest": {
        "type": "object",
        "properties": {
          "UserId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthCreateImpersonateResponse": {
        "type": "object",
        "properties": {
          "ImpersonationRequestId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthCreateImpersonateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AuthCreateImpersonateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthUpdateRequestResetPasswordRequest": {
        "type": "object",
        "properties": {
          "UserId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthUpdateRequestResetPasswordResponse": {
        "type": "object",
        "additionalProperties": false
      },
      "AuthUpdateRequestResetPasswordResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AuthUpdateRequestResetPasswordResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthUpdateSetPasswordRequest": {
        "type": "object",
        "properties": {
          "UserId": {
            "type": "string",
            "nullable": true
          },
          "NewPassword": {
            "type": "string",
            "nullable": true
          },
          "IsTemporary": {
            "type": "boolean"
          }
        },
        "additionalProperties": false
      },
      "AuthUpdateSetPasswordResponse": {
        "type": "object",
        "additionalProperties": false
      },
      "AuthUpdateSetPasswordResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AuthUpdateSetPasswordResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "nullable": true
          },
          "Rule": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "nullable": true
          },
          "Rule": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AuthorizedEmailCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "nullable": true
          },
          "Rule": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AuthorizedEmailDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "nullable": true
          },
          "Rule": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/AuthorizedEmailDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AuthorizedEmailDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "nullable": true
          },
          "Rule": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AuthorizedEmailGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "nullable": true
          },
          "Rule": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "nullable": true
          },
          "Rule": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "AuthorizedEmailUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/AuthorizedEmailUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "BasicAuthConfig": {
        "type": "object",
        "properties": {
          "Username": {
            "type": "string",
            "nullable": true
          },
          "Password": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "CodeSetDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Source": {
            "type": "string",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "nullable": true
          },
          "Code": {
            "type": "string",
            "nullable": true
          },
          "Category": {
            "type": "string",
            "nullable": true
          },
          "CodeValueString1": {
            "type": "string",
            "nullable": true
          },
          "CodeValueString2": {
            "type": "string",
            "nullable": true
          },
          "CodeValueString3": {
            "type": "string",
            "nullable": true
          },
          "CodeValueFloat1": {
            "type": "number",
            "format": "float",
            "nullable": true
          },
          "CodeValueFloat2": {
            "type": "number",
            "format": "float",
            "nullable": true
          },
          "CodeValueFloat3": {
            "type": "number",
            "format": "float",
            "nullable": true
          },
          "CodeValueDate1": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "CodeValueDate2": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "CodeValueDate3": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "CodeValueInt1": {
            "type": "integer",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt2": {
            "type": "integer",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt3": {
            "type": "integer",
            "format": "int32",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "CodeSetDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/CodeSetDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "CodeSetDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/CodeSetDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "CodeSetFilter": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "SearchString": {
            "type": "string",
            "nullable": true
          },
          "Source": {
            "type": "string",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "nullable": true
          },
          "Code": {
            "type": "string",
            "nullable": true
          },
          "Category": {
            "type": "string",
            "nullable": true
          },
          "CodeValueString1": {
            "type": "string",
            "nullable": true
          },
          "CodeValueString2": {
            "type": "string",
            "nullable": true
          },
          "CodeValueString3": {
            "type": "string",
            "nullable": true
          },
          "CodeValueFloat1": {
            "type": "number",
            "format": "float",
            "nullable": true
          },
          "CodeValueFloat2": {
            "type": "number",
            "format": "float",
            "nullable": true
          },
          "CodeValueFloat3": {
            "type": "number",
            "format": "float",
            "nullable": true
          },
          "CodeValueDate1": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "CodeValueDate2": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "CodeValueDate3": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "CodeValueInt1": {
            "type": "integer",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt2": {
            "type": "integer",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt3": {
            "type": "integer",
            "format": "int32",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "CodeSetGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Filter": {
            "$ref": "#/components/schemas/CodeSetFilter"
          }
        },
        "additionalProperties": false
      },
      "CodeSetGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Source": {
            "type": "string",
            "nullable": true
          },
          "Description": {
            "type": "string",
            "nullable": true
          },
          "Code": {
            "type": "string",
            "nullable": true
          },
          "Category": {
            "type": "string",
            "nullable": true
          },
          "CodeValueString1": {
            "type": "string",
            "nullable": true
          },
          "CodeValueString2": {
            "type": "string",
            "nullable": true
          },
          "CodeValueString3": {
            "type": "string",
            "nullable": true
          },
          "CodeValueFloat1": {
            "type": "number",
            "format": "float",
            "nullable": true
          },
          "CodeValueFloat2": {
            "type": "number",
            "format": "float",
            "nullable": true
          },
          "CodeValueFloat3": {
            "type": "number",
            "format": "float",
            "nullable": true
          },
          "CodeValueDate1": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "CodeValueDate2": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "CodeValueDate3": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "CodeValueInt1": {
            "type": "integer",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt2": {
            "type": "integer",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt3": {
            "type": "integer",
            "format": "int32",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "CodeSetGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/CodeSetGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "CodeSetQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ComponentGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "ComponentId": {
            "type": "string",
            "nullable": true
          },
          "NewName": {
            "type": "string",
            "nullable": true
          },
          "StringReplaceFrom": {
            "type": "string",
            "nullable": true
          },
          "StringReplaceTo": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ComponentGetResponse": {
        "type": "object",
        "properties": {
          "NewComponentId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ComponentGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/ComponentGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "CreateSessionRequestConfig": {
        "type": "object",
        "properties": {
          "Url": {
            "type": "string",
            "nullable": true
          },
          "Method": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DataSecurity": {
        "type": "object",
        "properties": {
          "DisableObfuscation": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "BatchFileName": {
            "type": "string",
            "description": "The batch file name usied to transmit the batch from the source system.",
            "nullable": true,
            "example": ""
          },
          "ApprovalWorkflowStatus": {
            "type": "string",
            "description": "Current status of the batch approval workflow (e.g., PROCESSING, PUBLISHED, INCOMPLETE, DELETE).",
            "nullable": true,
            "example": ""
          },
          "ReportingPeriodEnd": {
            "type": "string",
            "description": "The reporting period end date for which the document batch is being generated, may be NULL depending on the document type.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "RecordCountLoaded": {
            "type": "integer",
            "description": "The number of records successfully loaded within the batch.",
            "format": "int32",
            "nullable": true
          },
          "RecordCountExpected": {
            "type": "integer",
            "description": "The number of records expected to be loaded in the batch, the expected number of records may be contained as meta data within the batch file.",
            "format": "int32",
            "nullable": true
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "BatchFileName": {
            "type": "string",
            "description": "The batch file name usied to transmit the batch from the source system.",
            "nullable": true,
            "example": ""
          },
          "ApprovalWorkflowStatus": {
            "type": "string",
            "description": "Current status of the batch approval workflow (e.g., PROCESSING, PUBLISHED, INCOMPLETE, DELETE).",
            "nullable": true,
            "example": ""
          },
          "ReportingPeriodEnd": {
            "type": "string",
            "description": "The reporting period end date for which the document batch is being generated, may be NULL depending on the document type.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "RecordCountLoaded": {
            "type": "integer",
            "description": "The number of records successfully loaded within the batch.",
            "format": "int32",
            "nullable": true
          },
          "RecordCountExpected": {
            "type": "integer",
            "description": "The number of records expected to be loaded in the batch, the expected number of records may be contained as meta data within the batch file.",
            "format": "int32",
            "nullable": true
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentBatchCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "BatchFileName": {
            "type": "string",
            "description": "The batch file name usied to transmit the batch from the source system.",
            "nullable": true,
            "example": ""
          },
          "ApprovalWorkflowStatus": {
            "type": "string",
            "description": "Current status of the batch approval workflow (e.g., PROCESSING, PUBLISHED, INCOMPLETE, DELETE).",
            "nullable": true,
            "example": ""
          },
          "ReportingPeriodEnd": {
            "type": "string",
            "description": "The reporting period end date for which the document batch is being generated, may be NULL depending on the document type.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "RecordCountLoaded": {
            "type": "integer",
            "description": "The number of records successfully loaded within the batch.",
            "format": "int32",
            "nullable": true
          },
          "RecordCountExpected": {
            "type": "integer",
            "description": "The number of records expected to be loaded in the batch, the expected number of records may be contained as meta data within the batch file.",
            "format": "int32",
            "nullable": true
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentBatchDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "BatchFileName": {
            "type": "string",
            "description": "The batch file name usied to transmit the batch from the source system.",
            "nullable": true,
            "example": ""
          },
          "ApprovalWorkflowStatus": {
            "type": "string",
            "description": "Current status of the batch approval workflow (e.g., PROCESSING, PUBLISHED, INCOMPLETE, DELETE).",
            "nullable": true,
            "example": ""
          },
          "ReportingPeriodEnd": {
            "type": "string",
            "description": "The reporting period end date for which the document batch is being generated, may be NULL depending on the document type.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "RecordCountLoaded": {
            "type": "integer",
            "description": "The number of records successfully loaded within the batch.",
            "format": "int32",
            "nullable": true
          },
          "RecordCountExpected": {
            "type": "integer",
            "description": "The number of records expected to be loaded in the batch, the expected number of records may be contained as meta data within the batch file.",
            "format": "int32",
            "nullable": true
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/DocumentBatchDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentBatchDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "BatchFileName": {
            "type": "string",
            "description": "The batch file name usied to transmit the batch from the source system.",
            "nullable": true,
            "example": ""
          },
          "ApprovalWorkflowStatus": {
            "type": "string",
            "description": "Current status of the batch approval workflow (e.g., PROCESSING, PUBLISHED, INCOMPLETE, DELETE).",
            "nullable": true,
            "example": ""
          },
          "ReportingPeriodEnd": {
            "type": "string",
            "description": "The reporting period end date for which the document batch is being generated, may be NULL depending on the document type.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "RecordCountLoaded": {
            "type": "integer",
            "description": "The number of records successfully loaded within the batch.",
            "format": "int32",
            "nullable": true
          },
          "RecordCountExpected": {
            "type": "integer",
            "description": "The number of records expected to be loaded in the batch, the expected number of records may be contained as meta data within the batch file.",
            "format": "int32",
            "nullable": true
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentBatchGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "BatchFileName": {
            "type": "string",
            "description": "The batch file name usied to transmit the batch from the source system.",
            "nullable": true,
            "example": ""
          },
          "ApprovalWorkflowStatus": {
            "type": "string",
            "description": "Current status of the batch approval workflow (e.g., PROCESSING, PUBLISHED, INCOMPLETE, DELETE).",
            "nullable": true,
            "example": ""
          },
          "ReportingPeriodEnd": {
            "type": "string",
            "description": "The reporting period end date for which the document batch is being generated, may be NULL depending on the document type.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "RecordCountLoaded": {
            "type": "integer",
            "description": "The number of records successfully loaded within the batch.",
            "format": "int32",
            "nullable": true
          },
          "RecordCountExpected": {
            "type": "integer",
            "description": "The number of records expected to be loaded in the batch, the expected number of records may be contained as meta data within the batch file.",
            "format": "int32",
            "nullable": true
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "BatchFileName": {
            "type": "string",
            "description": "The batch file name usied to transmit the batch from the source system.",
            "nullable": true,
            "example": ""
          },
          "ApprovalWorkflowStatus": {
            "type": "string",
            "description": "Current status of the batch approval workflow (e.g., PROCESSING, PUBLISHED, INCOMPLETE, DELETE).",
            "nullable": true,
            "example": ""
          },
          "ReportingPeriodEnd": {
            "type": "string",
            "description": "The reporting period end date for which the document batch is being generated, may be NULL depending on the document type.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "RecordCountLoaded": {
            "type": "integer",
            "description": "The number of records successfully loaded within the batch.",
            "format": "int32",
            "nullable": true
          },
          "RecordCountExpected": {
            "type": "integer",
            "description": "The number of records expected to be loaded in the batch, the expected number of records may be contained as meta data within the batch file.",
            "format": "int32",
            "nullable": true
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentBatchUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentBatchUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentCreateBulkRequest": {
        "type": "object",
        "properties": {
          "Documents": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/DocumentCreateRequest"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentCreateBulkResponse": {
        "type": "object",
        "properties": {
          "Documents": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/DocumentDtoPlatformResult"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentCreateBulkResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentCreateBulkResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "ExternalDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "Origin from which a document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a document based on its content, purpose, or function within an organizational system or process. This categorization helps in organizing, managing, and retrieving documents efficiently",
            "nullable": true,
            "example": "Client Statements"
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial documents, reports, or data entries to indicate when the information was accurate or when a particular financial status was recorded.",
            "format": "date",
            "nullable": true,
            "example": "2023-12-31"
          },
          "CreationDate": {
            "type": "string",
            "description": "The date when the document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For documents that are time-sensitive, the date when the document is no longer valid or should be reviewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the document was published, if applicable, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the document, often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the document. Creator / Author.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "string",
            "description": "Identifies the iteration of the document, useful for tracking revisions and updates.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "description": "Current state of the document (e.g., draft, reviewed, approved, archived).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the document�s content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "string",
            "description": "The size of the document file in Bytes,important for storage and retrieval considerations.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The topic of the resource.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "An account of the resource.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The entity responsible for making the resource available.",
            "nullable": true,
            "example": ""
          },
          "Contributor": {
            "type": "string",
            "description": "An entity responsible for making contributions to the resource.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The file format, physical medium, or dimensions of the resource.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "A related resource.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The spatial or temporal topic of the resource, the geographic area covered by the resource, or the jurisdiction under which the resource is relevant.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "format": "int32"
          },
          "FileExtension": {
            "type": "string",
            "nullable": true
          },
          "FileName": {
            "type": "string",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "description": "EntityId",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "EntityType": {
            "type": "string",
            "description": "Entity Type e.g. account | transaction | etc",
            "nullable": true,
            "example": "account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "A short, unique name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "A friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "IsPublished": {
            "type": "boolean"
          },
          "IsDeleted": {
            "type": "boolean"
          },
          "DocumentBatchId": {
            "type": "string",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          },
          "File": {
            "$ref": "#/components/schemas/FileMeta"
          }
        },
        "additionalProperties": false
      },
      "DocumentCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "ExternalDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "Origin from which a document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a document based on its content, purpose, or function within an organizational system or process. This categorization helps in organizing, managing, and retrieving documents efficiently",
            "nullable": true,
            "example": "Client Statements"
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial documents, reports, or data entries to indicate when the information was accurate or when a particular financial status was recorded.",
            "format": "date",
            "nullable": true,
            "example": "2023-12-31"
          },
          "CreationDate": {
            "type": "string",
            "description": "The date when the document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For documents that are time-sensitive, the date when the document is no longer valid or should be reviewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the document was published, if applicable, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the document, often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the document. Creator / Author.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "string",
            "description": "Identifies the iteration of the document, useful for tracking revisions and updates.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "description": "Current state of the document (e.g., draft, reviewed, approved, archived).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the document�s content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "string",
            "description": "The size of the document file in Bytes,important for storage and retrieval considerations.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The topic of the resource.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "An account of the resource.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The entity responsible for making the resource available.",
            "nullable": true,
            "example": ""
          },
          "Contributor": {
            "type": "string",
            "description": "An entity responsible for making contributions to the resource.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The file format, physical medium, or dimensions of the resource.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "A related resource.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The spatial or temporal topic of the resource, the geographic area covered by the resource, or the jurisdiction under which the resource is relevant.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "format": "int32"
          },
          "FileExtension": {
            "type": "string",
            "nullable": true
          },
          "FileName": {
            "type": "string",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "description": "EntityId",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "EntityType": {
            "type": "string",
            "description": "Entity Type e.g. account | transaction | etc",
            "nullable": true,
            "example": "account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "A short, unique name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "A friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "IsPublished": {
            "type": "boolean"
          },
          "IsDeleted": {
            "type": "boolean"
          },
          "DocumentBatchId": {
            "type": "string",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentCreateShareRequest": {
        "type": "object",
        "properties": {
          "DocumentIds": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "UserIds": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentCreateShareResponse": {
        "type": "object",
        "additionalProperties": false
      },
      "DocumentCreateShareResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentCreateShareResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentCreateShareWithAllUsersRequest": {
        "type": "object",
        "properties": {
          "DocumentIds": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentCreateShareWithAllUsersResponse": {
        "type": "object",
        "additionalProperties": false
      },
      "DocumentCreateShareWithAllUsersResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentCreateShareWithAllUsersResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "ExternalDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "Origin from which a document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a document based on its content, purpose, or function within an organizational system or process. This categorization helps in organizing, managing, and retrieving documents efficiently",
            "nullable": true,
            "example": "Client Statements"
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial documents, reports, or data entries to indicate when the information was accurate or when a particular financial status was recorded.",
            "format": "date",
            "nullable": true,
            "example": "2023-12-31"
          },
          "CreationDate": {
            "type": "string",
            "description": "The date when the document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For documents that are time-sensitive, the date when the document is no longer valid or should be reviewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the document was published, if applicable, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the document, often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the document. Creator / Author.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "string",
            "description": "Identifies the iteration of the document, useful for tracking revisions and updates.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "description": "Current state of the document (e.g., draft, reviewed, approved, archived).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the document�s content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "string",
            "description": "The size of the document file in Bytes,important for storage and retrieval considerations.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The topic of the resource.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "An account of the resource.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The entity responsible for making the resource available.",
            "nullable": true,
            "example": ""
          },
          "Contributor": {
            "type": "string",
            "description": "An entity responsible for making contributions to the resource.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The file format, physical medium, or dimensions of the resource.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "A related resource.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The spatial or temporal topic of the resource, the geographic area covered by the resource, or the jurisdiction under which the resource is relevant.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "format": "int32"
          },
          "FileExtension": {
            "type": "string",
            "nullable": true
          },
          "FileName": {
            "type": "string",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "description": "EntityId",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "EntityType": {
            "type": "string",
            "description": "Entity Type e.g. account | transaction | etc",
            "nullable": true,
            "example": "account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "A short, unique name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "A friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "IsPublished": {
            "type": "boolean"
          },
          "IsDeleted": {
            "type": "boolean"
          },
          "DocumentBatchId": {
            "type": "string",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftAccountDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The origin from which a Document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a Document based on its content, purpose, or function.",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the Document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial Document. or reports to indicate when the information was accurate.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentCreationDate": {
            "type": "string",
            "description": "The date when the Document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the Document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For time-sensitive Document., the date when the Document is no longer valid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the Document was published, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the Document. often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the Document.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the Document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "integer",
            "description": "Identifies the iteration of the Document, useful for tracking revisions and updates.",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Current state of the Document (e.g., New, Reviewed, Published, Rejected).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the Document content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "integer",
            "description": "The size of the Document file in bytes, important for storage and retrieval considerations.",
            "format": "int32",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the Document. following the IETF language tag standard (e.g., en-US).",
            "nullable": true,
            "example": ""
          },
          "Checksum": {
            "type": "string",
            "description": "Checksum of the asssociated Document and meta data.",
            "nullable": true,
            "example": ""
          },
          "DocumentBatchId": {
            "type": "string",
            "description": "The Idenifier for the Document batch used to loadDocument. may be null if Document was not loaded via a batch process.",
            "nullable": true,
            "example": ""
          },
          "DocumentSourceFileName": {
            "type": "string",
            "description": "The file name of the Document file contained within the batch file as tramsmitted from the source system.",
            "nullable": true,
            "example": ""
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          },
          "IsDeleted": {
            "type": "boolean",
            "description": "Flag to indicate if batch has been marked for deletion.",
            "nullable": true
          },
          "Contributor": {
            "type": "string",
            "description": "The person or entity who contributed the Document.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The scope or extent of content within the Document.",
            "nullable": true,
            "example": ""
          },
          "EntityId": {
            "type": "string",
            "description": "Unique identifier for the related entity.",
            "nullable": true,
            "example": "00dabc1f-db0e-442b-bb7c-f041ee224530"
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity the Document is related to.",
            "nullable": true,
            "example": "Account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "The short name uniquely identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "The friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The subject or title of the Document.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The person or system that published the Document.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "The relationship of this Document to other Document. or entities.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The format of the Document (e.g., PDF, DOCX).",
            "nullable": true,
            "example": ""
          },
          "FileExtension": {
            "type": "string",
            "description": "The extension of the file associated with the Document.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the Document and its content.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "description": "Unique identifier for a large object or file associated with the Document.",
            "format": "int32"
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          },
          "AccountClassifications": {
            "type": "string",
            "nullable": true
          },
          "LastActionBy": {
            "type": "string",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftAccountDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/DocumentDraftAccountDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftAccountDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDraftAccountDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftAccountQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftCreatePublishRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftCreatePublishResponse": {
        "type": "object",
        "properties": {
          "Success": {
            "type": "boolean"
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftCreatePublishResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDraftCreatePublishResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The origin from which a Document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a Document based on its content, purpose, or function.",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the Document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial Document. or reports to indicate when the information was accurate.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentCreationDate": {
            "type": "string",
            "description": "The date when the Document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the Document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For time-sensitive Document., the date when the Document is no longer valid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the Document was published, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the Document. often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the Document.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the Document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "integer",
            "description": "Identifies the iteration of the Document, useful for tracking revisions and updates.",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Current state of the Document (e.g., New, Reviewed, Published, Rejected).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the Document content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "integer",
            "description": "The size of the Document file in bytes, important for storage and retrieval considerations.",
            "format": "int32",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the Document. following the IETF language tag standard (e.g., en-US).",
            "nullable": true,
            "example": ""
          },
          "Checksum": {
            "type": "string",
            "description": "Checksum of the asssociated Document and meta data.",
            "nullable": true,
            "example": ""
          },
          "DocumentBatchId": {
            "type": "string",
            "description": "The Idenifier for the Document batch used to loadDocument. may be null if Document was not loaded via a batch process.",
            "nullable": true,
            "example": ""
          },
          "DocumentSourceFileName": {
            "type": "string",
            "description": "The file name of the Document file contained within the batch file as tramsmitted from the source system.",
            "nullable": true,
            "example": ""
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          },
          "IsDeleted": {
            "type": "boolean",
            "description": "Flag to indicate if batch has been marked for deletion.",
            "nullable": true
          },
          "Contributor": {
            "type": "string",
            "description": "The person or entity who contributed the Document.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The scope or extent of content within the Document.",
            "nullable": true,
            "example": ""
          },
          "EntityId": {
            "type": "string",
            "description": "Unique identifier for the related entity.",
            "nullable": true,
            "example": "00dabc1f-db0e-442b-bb7c-f041ee224530"
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity the Document is related to.",
            "nullable": true,
            "example": "Account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "The short name uniquely identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "The friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The subject or title of the Document.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The person or system that published the Document.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "The relationship of this Document to other Document. or entities.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The format of the Document (e.g., PDF, DOCX).",
            "nullable": true,
            "example": ""
          },
          "FileExtension": {
            "type": "string",
            "description": "The extension of the file associated with the Document.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the Document and its content.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "description": "Unique identifier for a large object or file associated with the Document.",
            "format": "int32"
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          },
          "File": {
            "$ref": "#/components/schemas/FileMeta"
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The origin from which a Document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a Document based on its content, purpose, or function.",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the Document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial Document. or reports to indicate when the information was accurate.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentCreationDate": {
            "type": "string",
            "description": "The date when the Document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the Document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For time-sensitive Document., the date when the Document is no longer valid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the Document was published, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the Document. often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the Document.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the Document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "integer",
            "description": "Identifies the iteration of the Document, useful for tracking revisions and updates.",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Current state of the Document (e.g., New, Reviewed, Published, Rejected).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the Document content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "integer",
            "description": "The size of the Document file in bytes, important for storage and retrieval considerations.",
            "format": "int32",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the Document. following the IETF language tag standard (e.g., en-US).",
            "nullable": true,
            "example": ""
          },
          "Checksum": {
            "type": "string",
            "description": "Checksum of the asssociated Document and meta data.",
            "nullable": true,
            "example": ""
          },
          "DocumentBatchId": {
            "type": "string",
            "description": "The Idenifier for the Document batch used to loadDocument. may be null if Document was not loaded via a batch process.",
            "nullable": true,
            "example": ""
          },
          "DocumentSourceFileName": {
            "type": "string",
            "description": "The file name of the Document file contained within the batch file as tramsmitted from the source system.",
            "nullable": true,
            "example": ""
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          },
          "IsDeleted": {
            "type": "boolean",
            "description": "Flag to indicate if batch has been marked for deletion.",
            "nullable": true
          },
          "Contributor": {
            "type": "string",
            "description": "The person or entity who contributed the Document.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The scope or extent of content within the Document.",
            "nullable": true,
            "example": ""
          },
          "EntityId": {
            "type": "string",
            "description": "Unique identifier for the related entity.",
            "nullable": true,
            "example": "00dabc1f-db0e-442b-bb7c-f041ee224530"
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity the Document is related to.",
            "nullable": true,
            "example": "Account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "The short name uniquely identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "The friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The subject or title of the Document.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The person or system that published the Document.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "The relationship of this Document to other Document. or entities.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The format of the Document (e.g., PDF, DOCX).",
            "nullable": true,
            "example": ""
          },
          "FileExtension": {
            "type": "string",
            "description": "The extension of the file associated with the Document.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the Document and its content.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "description": "Unique identifier for a large object or file associated with the Document.",
            "format": "int32"
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDraftCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftCreateShareWithAllUsersRequest": {
        "type": "object",
        "properties": {
          "DocumentDraftIds": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftCreateShareWithAllUsersResponse": {
        "type": "object",
        "additionalProperties": false
      },
      "DocumentDraftCreateShareWithAllUsersResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDraftCreateShareWithAllUsersResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftDeletePublishRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftDeletePublishResponse": {
        "type": "object",
        "properties": {
          "Success": {
            "type": "boolean"
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftDeletePublishResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDraftDeletePublishResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The origin from which a Document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a Document based on its content, purpose, or function.",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the Document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial Document. or reports to indicate when the information was accurate.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentCreationDate": {
            "type": "string",
            "description": "The date when the Document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the Document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For time-sensitive Document., the date when the Document is no longer valid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the Document was published, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the Document. often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the Document.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the Document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "integer",
            "description": "Identifies the iteration of the Document, useful for tracking revisions and updates.",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Current state of the Document (e.g., New, Reviewed, Published, Rejected).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the Document content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "integer",
            "description": "The size of the Document file in bytes, important for storage and retrieval considerations.",
            "format": "int32",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the Document. following the IETF language tag standard (e.g., en-US).",
            "nullable": true,
            "example": ""
          },
          "Checksum": {
            "type": "string",
            "description": "Checksum of the asssociated Document and meta data.",
            "nullable": true,
            "example": ""
          },
          "DocumentBatchId": {
            "type": "string",
            "description": "The Idenifier for the Document batch used to loadDocument. may be null if Document was not loaded via a batch process.",
            "nullable": true,
            "example": ""
          },
          "DocumentSourceFileName": {
            "type": "string",
            "description": "The file name of the Document file contained within the batch file as tramsmitted from the source system.",
            "nullable": true,
            "example": ""
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          },
          "IsDeleted": {
            "type": "boolean",
            "description": "Flag to indicate if batch has been marked for deletion.",
            "nullable": true
          },
          "Contributor": {
            "type": "string",
            "description": "The person or entity who contributed the Document.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The scope or extent of content within the Document.",
            "nullable": true,
            "example": ""
          },
          "EntityId": {
            "type": "string",
            "description": "Unique identifier for the related entity.",
            "nullable": true,
            "example": "00dabc1f-db0e-442b-bb7c-f041ee224530"
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity the Document is related to.",
            "nullable": true,
            "example": "Account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "The short name uniquely identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "The friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The subject or title of the Document.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The person or system that published the Document.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "The relationship of this Document to other Document. or entities.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The format of the Document (e.g., PDF, DOCX).",
            "nullable": true,
            "example": ""
          },
          "FileExtension": {
            "type": "string",
            "description": "The extension of the file associated with the Document.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the Document and its content.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "description": "Unique identifier for a large object or file associated with the Document.",
            "format": "int32"
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDraftDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The origin from which a Document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a Document based on its content, purpose, or function.",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the Document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial Document. or reports to indicate when the information was accurate.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentCreationDate": {
            "type": "string",
            "description": "The date when the Document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the Document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For time-sensitive Document., the date when the Document is no longer valid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the Document was published, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the Document. often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the Document.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the Document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "integer",
            "description": "Identifies the iteration of the Document, useful for tracking revisions and updates.",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Current state of the Document (e.g., New, Reviewed, Published, Rejected).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the Document content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "integer",
            "description": "The size of the Document file in bytes, important for storage and retrieval considerations.",
            "format": "int32",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the Document. following the IETF language tag standard (e.g., en-US).",
            "nullable": true,
            "example": ""
          },
          "Checksum": {
            "type": "string",
            "description": "Checksum of the asssociated Document and meta data.",
            "nullable": true,
            "example": ""
          },
          "DocumentBatchId": {
            "type": "string",
            "description": "The Idenifier for the Document batch used to loadDocument. may be null if Document was not loaded via a batch process.",
            "nullable": true,
            "example": ""
          },
          "DocumentSourceFileName": {
            "type": "string",
            "description": "The file name of the Document file contained within the batch file as tramsmitted from the source system.",
            "nullable": true,
            "example": ""
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          },
          "IsDeleted": {
            "type": "boolean",
            "description": "Flag to indicate if batch has been marked for deletion.",
            "nullable": true
          },
          "Contributor": {
            "type": "string",
            "description": "The person or entity who contributed the Document.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The scope or extent of content within the Document.",
            "nullable": true,
            "example": ""
          },
          "EntityId": {
            "type": "string",
            "description": "Unique identifier for the related entity.",
            "nullable": true,
            "example": "00dabc1f-db0e-442b-bb7c-f041ee224530"
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity the Document is related to.",
            "nullable": true,
            "example": "Account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "The short name uniquely identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "The friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The subject or title of the Document.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The person or system that published the Document.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "The relationship of this Document to other Document. or entities.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The format of the Document (e.g., PDF, DOCX).",
            "nullable": true,
            "example": ""
          },
          "FileExtension": {
            "type": "string",
            "description": "The extension of the file associated with the Document.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the Document and its content.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "description": "Unique identifier for a large object or file associated with the Document.",
            "format": "int32"
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftDtoPlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDraftDto"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/DocumentDraftDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDraftDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The origin from which a Document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a Document based on its content, purpose, or function.",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the Document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial Document. or reports to indicate when the information was accurate.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentCreationDate": {
            "type": "string",
            "description": "The date when the Document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the Document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For time-sensitive Document., the date when the Document is no longer valid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the Document was published, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the Document. often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the Document.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the Document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "integer",
            "description": "Identifies the iteration of the Document, useful for tracking revisions and updates.",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Current state of the Document (e.g., New, Reviewed, Published, Rejected).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the Document content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "integer",
            "description": "The size of the Document file in bytes, important for storage and retrieval considerations.",
            "format": "int32",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the Document. following the IETF language tag standard (e.g., en-US).",
            "nullable": true,
            "example": ""
          },
          "Checksum": {
            "type": "string",
            "description": "Checksum of the asssociated Document and meta data.",
            "nullable": true,
            "example": ""
          },
          "DocumentBatchId": {
            "type": "string",
            "description": "The Idenifier for the Document batch used to loadDocument. may be null if Document was not loaded via a batch process.",
            "nullable": true,
            "example": ""
          },
          "DocumentSourceFileName": {
            "type": "string",
            "description": "The file name of the Document file contained within the batch file as tramsmitted from the source system.",
            "nullable": true,
            "example": ""
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          },
          "IsDeleted": {
            "type": "boolean",
            "description": "Flag to indicate if batch has been marked for deletion.",
            "nullable": true
          },
          "Contributor": {
            "type": "string",
            "description": "The person or entity who contributed the Document.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The scope or extent of content within the Document.",
            "nullable": true,
            "example": ""
          },
          "EntityId": {
            "type": "string",
            "description": "Unique identifier for the related entity.",
            "nullable": true,
            "example": "00dabc1f-db0e-442b-bb7c-f041ee224530"
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity the Document is related to.",
            "nullable": true,
            "example": "Account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "The short name uniquely identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "The friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The subject or title of the Document.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The person or system that published the Document.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "The relationship of this Document to other Document. or entities.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The format of the Document (e.g., PDF, DOCX).",
            "nullable": true,
            "example": ""
          },
          "FileExtension": {
            "type": "string",
            "description": "The extension of the file associated with the Document.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the Document and its content.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "description": "Unique identifier for a large object or file associated with the Document.",
            "format": "int32"
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDraftGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The origin from which a Document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a Document based on its content, purpose, or function.",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the Document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial Document. or reports to indicate when the information was accurate.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentCreationDate": {
            "type": "string",
            "description": "The date when the Document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the Document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For time-sensitive Document., the date when the Document is no longer valid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the Document was published, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the Document. often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the Document.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the Document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "integer",
            "description": "Identifies the iteration of the Document, useful for tracking revisions and updates.",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Current state of the Document (e.g., New, Reviewed, Published, Rejected).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the Document content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "integer",
            "description": "The size of the Document file in bytes, important for storage and retrieval considerations.",
            "format": "int32",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the Document. following the IETF language tag standard (e.g., en-US).",
            "nullable": true,
            "example": ""
          },
          "Checksum": {
            "type": "string",
            "description": "Checksum of the asssociated Document and meta data.",
            "nullable": true,
            "example": ""
          },
          "DocumentBatchId": {
            "type": "string",
            "description": "The Idenifier for the Document batch used to loadDocument. may be null if Document was not loaded via a batch process.",
            "nullable": true,
            "example": ""
          },
          "DocumentSourceFileName": {
            "type": "string",
            "description": "The file name of the Document file contained within the batch file as tramsmitted from the source system.",
            "nullable": true,
            "example": ""
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          },
          "IsDeleted": {
            "type": "boolean",
            "description": "Flag to indicate if batch has been marked for deletion.",
            "nullable": true
          },
          "Contributor": {
            "type": "string",
            "description": "The person or entity who contributed the Document.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The scope or extent of content within the Document.",
            "nullable": true,
            "example": ""
          },
          "EntityId": {
            "type": "string",
            "description": "Unique identifier for the related entity.",
            "nullable": true,
            "example": "00dabc1f-db0e-442b-bb7c-f041ee224530"
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity the Document is related to.",
            "nullable": true,
            "example": "Account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "The short name uniquely identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "The friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The subject or title of the Document.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The person or system that published the Document.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "The relationship of this Document to other Document. or entities.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The format of the Document (e.g., PDF, DOCX).",
            "nullable": true,
            "example": ""
          },
          "FileExtension": {
            "type": "string",
            "description": "The extension of the file associated with the Document.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the Document and its content.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "description": "Unique identifier for a large object or file associated with the Document.",
            "format": "int32"
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The origin from which a Document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a Document based on its content, purpose, or function.",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the Document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial Document. or reports to indicate when the information was accurate.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentCreationDate": {
            "type": "string",
            "description": "The date when the Document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the Document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For time-sensitive Document., the date when the Document is no longer valid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the Document was published, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the Document. often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the Document.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the Document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "integer",
            "description": "Identifies the iteration of the Document, useful for tracking revisions and updates.",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Current state of the Document (e.g., New, Reviewed, Published, Rejected).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the Document content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "integer",
            "description": "The size of the Document file in bytes, important for storage and retrieval considerations.",
            "format": "int32",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the Document. following the IETF language tag standard (e.g., en-US).",
            "nullable": true,
            "example": ""
          },
          "Checksum": {
            "type": "string",
            "description": "Checksum of the asssociated Document and meta data.",
            "nullable": true,
            "example": ""
          },
          "DocumentBatchId": {
            "type": "string",
            "description": "The Idenifier for the Document batch used to loadDocument. may be null if Document was not loaded via a batch process.",
            "nullable": true,
            "example": ""
          },
          "DocumentSourceFileName": {
            "type": "string",
            "description": "The file name of the Document file contained within the batch file as tramsmitted from the source system.",
            "nullable": true,
            "example": ""
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          },
          "IsDeleted": {
            "type": "boolean",
            "description": "Flag to indicate if batch has been marked for deletion.",
            "nullable": true
          },
          "Contributor": {
            "type": "string",
            "description": "The person or entity who contributed the Document.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The scope or extent of content within the Document.",
            "nullable": true,
            "example": ""
          },
          "EntityId": {
            "type": "string",
            "description": "Unique identifier for the related entity.",
            "nullable": true,
            "example": "00dabc1f-db0e-442b-bb7c-f041ee224530"
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity the Document is related to.",
            "nullable": true,
            "example": "Account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "The short name uniquely identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "The friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The subject or title of the Document.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The person or system that published the Document.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "The relationship of this Document to other Document. or entities.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The format of the Document (e.g., PDF, DOCX).",
            "nullable": true,
            "example": ""
          },
          "FileExtension": {
            "type": "string",
            "description": "The extension of the file associated with the Document.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the Document and its content.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "description": "Unique identifier for a large object or file associated with the Document.",
            "format": "int32"
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDraftUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftUpdateStatusBulkRequest": {
        "type": "object",
        "properties": {
          "Ids": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "PublicationDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftUpdateStatusBulkResponse": {
        "type": "object",
        "properties": {
          "DocumentDrafts": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/DocumentDraftDtoPlatformResult"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftUpdateStatusBulkResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDraftUpdateStatusBulkResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftUpdateStatusRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The origin from which a Document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a Document based on its content, purpose, or function.",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the Document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial Document. or reports to indicate when the information was accurate.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentCreationDate": {
            "type": "string",
            "description": "The date when the Document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the Document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For time-sensitive Document., the date when the Document is no longer valid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the Document was published, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the Document. often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the Document.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the Document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "integer",
            "description": "Identifies the iteration of the Document, useful for tracking revisions and updates.",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Current state of the Document (e.g., New, Reviewed, Published, Rejected).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the Document content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "integer",
            "description": "The size of the Document file in bytes, important for storage and retrieval considerations.",
            "format": "int32",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the Document. following the IETF language tag standard (e.g., en-US).",
            "nullable": true,
            "example": ""
          },
          "Checksum": {
            "type": "string",
            "description": "Checksum of the asssociated Document and meta data.",
            "nullable": true,
            "example": ""
          },
          "DocumentBatchId": {
            "type": "string",
            "description": "The Idenifier for the Document batch used to loadDocument. may be null if Document was not loaded via a batch process.",
            "nullable": true,
            "example": ""
          },
          "DocumentSourceFileName": {
            "type": "string",
            "description": "The file name of the Document file contained within the batch file as tramsmitted from the source system.",
            "nullable": true,
            "example": ""
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          },
          "IsDeleted": {
            "type": "boolean",
            "description": "Flag to indicate if batch has been marked for deletion.",
            "nullable": true
          },
          "Contributor": {
            "type": "string",
            "description": "The person or entity who contributed the Document.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The scope or extent of content within the Document.",
            "nullable": true,
            "example": ""
          },
          "EntityId": {
            "type": "string",
            "description": "Unique identifier for the related entity.",
            "nullable": true,
            "example": "00dabc1f-db0e-442b-bb7c-f041ee224530"
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity the Document is related to.",
            "nullable": true,
            "example": "Account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "The short name uniquely identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "The friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The subject or title of the Document.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The person or system that published the Document.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "The relationship of this Document to other Document. or entities.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The format of the Document (e.g., PDF, DOCX).",
            "nullable": true,
            "example": ""
          },
          "FileExtension": {
            "type": "string",
            "description": "The extension of the file associated with the Document.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the Document and its content.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "description": "Unique identifier for a large object or file associated with the Document.",
            "format": "int32"
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftUpdateStatusResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The origin from which a Document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a Document based on its content, purpose, or function.",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the Document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial Document. or reports to indicate when the information was accurate.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentCreationDate": {
            "type": "string",
            "description": "The date when the Document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the Document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For time-sensitive Document., the date when the Document is no longer valid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the Document was published, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the Document. often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the Document.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the Document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "integer",
            "description": "Identifies the iteration of the Document, useful for tracking revisions and updates.",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Current state of the Document (e.g., New, Reviewed, Published, Rejected).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the Document content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "integer",
            "description": "The size of the Document file in bytes, important for storage and retrieval considerations.",
            "format": "int32",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the Document. following the IETF language tag standard (e.g., en-US).",
            "nullable": true,
            "example": ""
          },
          "Checksum": {
            "type": "string",
            "description": "Checksum of the asssociated Document and meta data.",
            "nullable": true,
            "example": ""
          },
          "DocumentBatchId": {
            "type": "string",
            "description": "The Idenifier for the Document batch used to loadDocument. may be null if Document was not loaded via a batch process.",
            "nullable": true,
            "example": ""
          },
          "DocumentSourceFileName": {
            "type": "string",
            "description": "The file name of the Document file contained within the batch file as tramsmitted from the source system.",
            "nullable": true,
            "example": ""
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          },
          "IsDeleted": {
            "type": "boolean",
            "description": "Flag to indicate if batch has been marked for deletion.",
            "nullable": true
          },
          "Contributor": {
            "type": "string",
            "description": "The person or entity who contributed the Document.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The scope or extent of content within the Document.",
            "nullable": true,
            "example": ""
          },
          "EntityId": {
            "type": "string",
            "description": "Unique identifier for the related entity.",
            "nullable": true,
            "example": "00dabc1f-db0e-442b-bb7c-f041ee224530"
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity the Document is related to.",
            "nullable": true,
            "example": "Account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "The short name uniquely identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "The friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The subject or title of the Document.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The person or system that published the Document.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "The relationship of this Document to other Document. or entities.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The format of the Document (e.g., PDF, DOCX).",
            "nullable": true,
            "example": ""
          },
          "FileExtension": {
            "type": "string",
            "description": "The extension of the file associated with the Document.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the Document and its content.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "description": "Unique identifier for a large object or file associated with the Document.",
            "format": "int32"
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDraftUpdateStatusResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDraftUpdateStatusResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "ExternalDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "Origin from which a document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a document based on its content, purpose, or function within an organizational system or process. This categorization helps in organizing, managing, and retrieving documents efficiently",
            "nullable": true,
            "example": "Client Statements"
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial documents, reports, or data entries to indicate when the information was accurate or when a particular financial status was recorded.",
            "format": "date",
            "nullable": true,
            "example": "2023-12-31"
          },
          "CreationDate": {
            "type": "string",
            "description": "The date when the document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For documents that are time-sensitive, the date when the document is no longer valid or should be reviewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the document was published, if applicable, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the document, often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the document. Creator / Author.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "string",
            "description": "Identifies the iteration of the document, useful for tracking revisions and updates.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "description": "Current state of the document (e.g., draft, reviewed, approved, archived).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the document�s content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "string",
            "description": "The size of the document file in Bytes,important for storage and retrieval considerations.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The topic of the resource.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "An account of the resource.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The entity responsible for making the resource available.",
            "nullable": true,
            "example": ""
          },
          "Contributor": {
            "type": "string",
            "description": "An entity responsible for making contributions to the resource.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The file format, physical medium, or dimensions of the resource.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "A related resource.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The spatial or temporal topic of the resource, the geographic area covered by the resource, or the jurisdiction under which the resource is relevant.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "format": "int32"
          },
          "FileExtension": {
            "type": "string",
            "nullable": true
          },
          "FileName": {
            "type": "string",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "description": "EntityId",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "EntityType": {
            "type": "string",
            "description": "Entity Type e.g. account | transaction | etc",
            "nullable": true,
            "example": "account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "A short, unique name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "A friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "IsPublished": {
            "type": "boolean"
          },
          "IsDeleted": {
            "type": "boolean"
          },
          "DocumentBatchId": {
            "type": "string",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDtoPlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDto"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/DocumentDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "ExternalDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "Origin from which a document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a document based on its content, purpose, or function within an organizational system or process. This categorization helps in organizing, managing, and retrieving documents efficiently",
            "nullable": true,
            "example": "Client Statements"
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial documents, reports, or data entries to indicate when the information was accurate or when a particular financial status was recorded.",
            "format": "date",
            "nullable": true,
            "example": "2023-12-31"
          },
          "CreationDate": {
            "type": "string",
            "description": "The date when the document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For documents that are time-sensitive, the date when the document is no longer valid or should be reviewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the document was published, if applicable, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the document, often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the document. Creator / Author.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "string",
            "description": "Identifies the iteration of the document, useful for tracking revisions and updates.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "description": "Current state of the document (e.g., draft, reviewed, approved, archived).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the document�s content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "string",
            "description": "The size of the document file in Bytes,important for storage and retrieval considerations.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The topic of the resource.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "An account of the resource.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The entity responsible for making the resource available.",
            "nullable": true,
            "example": ""
          },
          "Contributor": {
            "type": "string",
            "description": "An entity responsible for making contributions to the resource.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The file format, physical medium, or dimensions of the resource.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "A related resource.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The spatial or temporal topic of the resource, the geographic area covered by the resource, or the jurisdiction under which the resource is relevant.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "format": "int32"
          },
          "FileExtension": {
            "type": "string",
            "nullable": true
          },
          "FileName": {
            "type": "string",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "description": "EntityId",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "EntityType": {
            "type": "string",
            "description": "Entity Type e.g. account | transaction | etc",
            "nullable": true,
            "example": "account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "A short, unique name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "A friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "IsPublished": {
            "type": "boolean"
          },
          "IsDeleted": {
            "type": "boolean"
          },
          "DocumentBatchId": {
            "type": "string",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentSource": {
        "type": "object",
        "properties": {
          "CreateSession": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/CreateSessionRequestConfig"
            },
            "nullable": true
          },
          "GetData": {
            "$ref": "#/components/schemas/GetDataConfig"
          },
          "GetFile": {
            "$ref": "#/components/schemas/GetFileConfig"
          }
        },
        "additionalProperties": false
      },
      "DocumentUpdateBulkRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "ExternalDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "Origin from which a document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a document based on its content, purpose, or function within an organizational system or process. This categorization helps in organizing, managing, and retrieving documents efficiently",
            "nullable": true,
            "example": "Client Statements"
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial documents, reports, or data entries to indicate when the information was accurate or when a particular financial status was recorded.",
            "format": "date",
            "nullable": true,
            "example": "2023-12-31"
          },
          "CreationDate": {
            "type": "string",
            "description": "The date when the document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For documents that are time-sensitive, the date when the document is no longer valid or should be reviewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the document was published, if applicable, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the document, often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the document. Creator / Author.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "string",
            "description": "Identifies the iteration of the document, useful for tracking revisions and updates.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "description": "Current state of the document (e.g., draft, reviewed, approved, archived).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the document�s content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "string",
            "description": "The size of the document file in Bytes,important for storage and retrieval considerations.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The topic of the resource.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "An account of the resource.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The entity responsible for making the resource available.",
            "nullable": true,
            "example": ""
          },
          "Contributor": {
            "type": "string",
            "description": "An entity responsible for making contributions to the resource.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The file format, physical medium, or dimensions of the resource.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "A related resource.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The spatial or temporal topic of the resource, the geographic area covered by the resource, or the jurisdiction under which the resource is relevant.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "format": "int32"
          },
          "FileExtension": {
            "type": "string",
            "nullable": true
          },
          "FileName": {
            "type": "string",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "description": "EntityId",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "EntityType": {
            "type": "string",
            "description": "Entity Type e.g. account | transaction | etc",
            "nullable": true,
            "example": "account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "A short, unique name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "A friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "IsPublished": {
            "type": "boolean"
          },
          "IsDeleted": {
            "type": "boolean"
          },
          "DocumentBatchId": {
            "type": "string",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          },
          "Documents": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/DocumentUpdateRequest"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentUpdateBulkResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "ExternalDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "Origin from which a document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a document based on its content, purpose, or function within an organizational system or process. This categorization helps in organizing, managing, and retrieving documents efficiently",
            "nullable": true,
            "example": "Client Statements"
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial documents, reports, or data entries to indicate when the information was accurate or when a particular financial status was recorded.",
            "format": "date",
            "nullable": true,
            "example": "2023-12-31"
          },
          "CreationDate": {
            "type": "string",
            "description": "The date when the document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For documents that are time-sensitive, the date when the document is no longer valid or should be reviewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the document was published, if applicable, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the document, often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the document. Creator / Author.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "string",
            "description": "Identifies the iteration of the document, useful for tracking revisions and updates.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "description": "Current state of the document (e.g., draft, reviewed, approved, archived).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the document�s content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "string",
            "description": "The size of the document file in Bytes,important for storage and retrieval considerations.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The topic of the resource.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "An account of the resource.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The entity responsible for making the resource available.",
            "nullable": true,
            "example": ""
          },
          "Contributor": {
            "type": "string",
            "description": "An entity responsible for making contributions to the resource.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The file format, physical medium, or dimensions of the resource.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "A related resource.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The spatial or temporal topic of the resource, the geographic area covered by the resource, or the jurisdiction under which the resource is relevant.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "format": "int32"
          },
          "FileExtension": {
            "type": "string",
            "nullable": true
          },
          "FileName": {
            "type": "string",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "description": "EntityId",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "EntityType": {
            "type": "string",
            "description": "Entity Type e.g. account | transaction | etc",
            "nullable": true,
            "example": "account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "A short, unique name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "A friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "IsPublished": {
            "type": "boolean"
          },
          "IsDeleted": {
            "type": "boolean"
          },
          "DocumentBatchId": {
            "type": "string",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          },
          "Documents": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/DocumentDtoPlatformResult"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentUpdateBulkResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentUpdateBulkResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentUpdateExpireRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "ExternalDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "Origin from which a document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a document based on its content, purpose, or function within an organizational system or process. This categorization helps in organizing, managing, and retrieving documents efficiently",
            "nullable": true,
            "example": "Client Statements"
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial documents, reports, or data entries to indicate when the information was accurate or when a particular financial status was recorded.",
            "format": "date",
            "nullable": true,
            "example": "2023-12-31"
          },
          "CreationDate": {
            "type": "string",
            "description": "The date when the document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For documents that are time-sensitive, the date when the document is no longer valid or should be reviewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the document was published, if applicable, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the document, often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the document. Creator / Author.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "string",
            "description": "Identifies the iteration of the document, useful for tracking revisions and updates.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "description": "Current state of the document (e.g., draft, reviewed, approved, archived).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the document�s content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "string",
            "description": "The size of the document file in Bytes,important for storage and retrieval considerations.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The topic of the resource.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "An account of the resource.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The entity responsible for making the resource available.",
            "nullable": true,
            "example": ""
          },
          "Contributor": {
            "type": "string",
            "description": "An entity responsible for making contributions to the resource.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The file format, physical medium, or dimensions of the resource.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "A related resource.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The spatial or temporal topic of the resource, the geographic area covered by the resource, or the jurisdiction under which the resource is relevant.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "format": "int32"
          },
          "FileExtension": {
            "type": "string",
            "nullable": true
          },
          "FileName": {
            "type": "string",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "description": "EntityId",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "EntityType": {
            "type": "string",
            "description": "Entity Type e.g. account | transaction | etc",
            "nullable": true,
            "example": "account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "A short, unique name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "A friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "IsPublished": {
            "type": "boolean"
          },
          "IsDeleted": {
            "type": "boolean"
          },
          "DocumentBatchId": {
            "type": "string",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentUpdateExpireResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "ExternalDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "Origin from which a document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a document based on its content, purpose, or function within an organizational system or process. This categorization helps in organizing, managing, and retrieving documents efficiently",
            "nullable": true,
            "example": "Client Statements"
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial documents, reports, or data entries to indicate when the information was accurate or when a particular financial status was recorded.",
            "format": "date",
            "nullable": true,
            "example": "2023-12-31"
          },
          "CreationDate": {
            "type": "string",
            "description": "The date when the document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For documents that are time-sensitive, the date when the document is no longer valid or should be reviewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the document was published, if applicable, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the document, often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the document. Creator / Author.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "string",
            "description": "Identifies the iteration of the document, useful for tracking revisions and updates.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "description": "Current state of the document (e.g., draft, reviewed, approved, archived).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the document�s content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "string",
            "description": "The size of the document file in Bytes,important for storage and retrieval considerations.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The topic of the resource.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "An account of the resource.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The entity responsible for making the resource available.",
            "nullable": true,
            "example": ""
          },
          "Contributor": {
            "type": "string",
            "description": "An entity responsible for making contributions to the resource.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The file format, physical medium, or dimensions of the resource.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "A related resource.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The spatial or temporal topic of the resource, the geographic area covered by the resource, or the jurisdiction under which the resource is relevant.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "format": "int32"
          },
          "FileExtension": {
            "type": "string",
            "nullable": true
          },
          "FileName": {
            "type": "string",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "description": "EntityId",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "EntityType": {
            "type": "string",
            "description": "Entity Type e.g. account | transaction | etc",
            "nullable": true,
            "example": "account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "A short, unique name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "A friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "IsPublished": {
            "type": "boolean"
          },
          "IsDeleted": {
            "type": "boolean"
          },
          "DocumentBatchId": {
            "type": "string",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentUpdateExpireResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentUpdateExpireResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "ExternalDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "Origin from which a document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a document based on its content, purpose, or function within an organizational system or process. This categorization helps in organizing, managing, and retrieving documents efficiently",
            "nullable": true,
            "example": "Client Statements"
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial documents, reports, or data entries to indicate when the information was accurate or when a particular financial status was recorded.",
            "format": "date",
            "nullable": true,
            "example": "2023-12-31"
          },
          "CreationDate": {
            "type": "string",
            "description": "The date when the document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For documents that are time-sensitive, the date when the document is no longer valid or should be reviewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the document was published, if applicable, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the document, often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the document. Creator / Author.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "string",
            "description": "Identifies the iteration of the document, useful for tracking revisions and updates.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "description": "Current state of the document (e.g., draft, reviewed, approved, archived).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the document�s content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "string",
            "description": "The size of the document file in Bytes,important for storage and retrieval considerations.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The topic of the resource.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "An account of the resource.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The entity responsible for making the resource available.",
            "nullable": true,
            "example": ""
          },
          "Contributor": {
            "type": "string",
            "description": "An entity responsible for making contributions to the resource.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The file format, physical medium, or dimensions of the resource.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "A related resource.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The spatial or temporal topic of the resource, the geographic area covered by the resource, or the jurisdiction under which the resource is relevant.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "format": "int32"
          },
          "FileExtension": {
            "type": "string",
            "nullable": true
          },
          "FileName": {
            "type": "string",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "description": "EntityId",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "EntityType": {
            "type": "string",
            "description": "Entity Type e.g. account | transaction | etc",
            "nullable": true,
            "example": "account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "A short, unique name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "A friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "IsPublished": {
            "type": "boolean"
          },
          "IsDeleted": {
            "type": "boolean"
          },
          "DocumentBatchId": {
            "type": "string",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          },
          "File": {
            "$ref": "#/components/schemas/FileMeta"
          }
        },
        "additionalProperties": false
      },
      "DocumentUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "ExternalDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "Origin from which a document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a document based on its content, purpose, or function within an organizational system or process. This categorization helps in organizing, managing, and retrieving documents efficiently",
            "nullable": true,
            "example": "Client Statements"
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial documents, reports, or data entries to indicate when the information was accurate or when a particular financial status was recorded.",
            "format": "date",
            "nullable": true,
            "example": "2023-12-31"
          },
          "CreationDate": {
            "type": "string",
            "description": "The date when the document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For documents that are time-sensitive, the date when the document is no longer valid or should be reviewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the document was published, if applicable, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the document, often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the document. Creator / Author.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "string",
            "description": "Identifies the iteration of the document, useful for tracking revisions and updates.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "description": "Current state of the document (e.g., draft, reviewed, approved, archived).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the document�s content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "string",
            "description": "The size of the document file in Bytes,important for storage and retrieval considerations.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The topic of the resource.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "An account of the resource.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The entity responsible for making the resource available.",
            "nullable": true,
            "example": ""
          },
          "Contributor": {
            "type": "string",
            "description": "An entity responsible for making contributions to the resource.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The file format, physical medium, or dimensions of the resource.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "A related resource.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The spatial or temporal topic of the resource, the geographic area covered by the resource, or the jurisdiction under which the resource is relevant.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "format": "int32"
          },
          "FileExtension": {
            "type": "string",
            "nullable": true
          },
          "FileName": {
            "type": "string",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "description": "EntityId",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "EntityType": {
            "type": "string",
            "description": "Entity Type e.g. account | transaction | etc",
            "nullable": true,
            "example": "account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "A short, unique name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "A friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "IsPublished": {
            "type": "boolean"
          },
          "IsDeleted": {
            "type": "boolean"
          },
          "DocumentBatchId": {
            "type": "string",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentWorkflowDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The origin from which a Document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a Document based on its content, purpose, or function.",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the Document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial Document. or reports to indicate when the information was accurate.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentCreationDate": {
            "type": "string",
            "description": "The date when the Document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the Document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For time-sensitive Document., the date when the Document is no longer valid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the Document was published, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the Document. often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the Document.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the Document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "integer",
            "description": "Identifies the iteration of the Document, useful for tracking revisions and updates.",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Current state of the Document (e.g., New, Reviewed, Published, Rejected).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the Document content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "integer",
            "description": "The size of the Document file in bytes, important for storage and retrieval considerations.",
            "format": "int32",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the Document. following the IETF language tag standard (e.g., en-US).",
            "nullable": true,
            "example": ""
          },
          "Checksum": {
            "type": "string",
            "description": "Checksum of the asssociated Document and meta data.",
            "nullable": true,
            "example": ""
          },
          "DocumentBatchId": {
            "type": "string",
            "description": "The Idenifier for the Document batch used to loadDocument. may be null if Document was not loaded via a batch process.",
            "nullable": true,
            "example": ""
          },
          "DocumentSourceFileName": {
            "type": "string",
            "description": "The file name of the Document file contained within the batch file as tramsmitted from the source system.",
            "nullable": true,
            "example": ""
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          },
          "IsDeleted": {
            "type": "boolean",
            "description": "Flag to indicate if batch has been marked for deletion.",
            "nullable": true
          },
          "Contributor": {
            "type": "string",
            "description": "The person or entity who contributed the Document.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The scope or extent of content within the Document.",
            "nullable": true,
            "example": ""
          },
          "EntityId": {
            "type": "string",
            "description": "Unique identifier for the related entity.",
            "nullable": true,
            "example": "00dabc1f-db0e-442b-bb7c-f041ee224530"
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity the Document is related to.",
            "nullable": true,
            "example": "Account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "The short name uniquely identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "The friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The subject or title of the Document.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The person or system that published the Document.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "The relationship of this Document to other Document. or entities.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The format of the Document (e.g., PDF, DOCX).",
            "nullable": true,
            "example": ""
          },
          "FileExtension": {
            "type": "string",
            "description": "The extension of the file associated with the Document.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the Document and its content.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "description": "Unique identifier for a large object or file associated with the Document.",
            "format": "int32"
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          },
          "AccountClassifications": {
            "type": "string",
            "nullable": true
          },
          "LastActionBy": {
            "type": "string",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PossibleEndStatuses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentWorkflowDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/DocumentWorkflowDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentWorkflowDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentWorkflowDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentWorkflowDtoTlc": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The origin from which a Document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a Document based on its content, purpose, or function.",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the Document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial Document. or reports to indicate when the information was accurate.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentCreationDate": {
            "type": "string",
            "description": "The date when the Document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the Document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For time-sensitive Document., the date when the Document is no longer valid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the Document was published, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the Document. often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the Document.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the Document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "integer",
            "description": "Identifies the iteration of the Document, useful for tracking revisions and updates.",
            "format": "int32",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Current state of the Document (e.g., New, Reviewed, Published, Rejected).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the Document content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "integer",
            "description": "The size of the Document file in bytes, important for storage and retrieval considerations.",
            "format": "int32",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the Document. following the IETF language tag standard (e.g., en-US).",
            "nullable": true,
            "example": ""
          },
          "Checksum": {
            "type": "string",
            "description": "Checksum of the asssociated Document and meta data.",
            "nullable": true,
            "example": ""
          },
          "DocumentBatchId": {
            "type": "string",
            "description": "The Idenifier for the Document batch used to loadDocument. may be null if Document was not loaded via a batch process.",
            "nullable": true,
            "example": ""
          },
          "DocumentSourceFileName": {
            "type": "string",
            "description": "The file name of the Document file contained within the batch file as tramsmitted from the source system.",
            "nullable": true,
            "example": ""
          },
          "IsPublished": {
            "type": "boolean",
            "description": "Flag to indication of the batch in its entirety has been published to end users.",
            "nullable": true
          },
          "IsDeleted": {
            "type": "boolean",
            "description": "Flag to indicate if batch has been marked for deletion.",
            "nullable": true
          },
          "Contributor": {
            "type": "string",
            "description": "The person or entity who contributed the Document.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The scope or extent of content within the Document.",
            "nullable": true,
            "example": ""
          },
          "EntityId": {
            "type": "string",
            "description": "Unique identifier for the related entity.",
            "nullable": true,
            "example": "00dabc1f-db0e-442b-bb7c-f041ee224530"
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity the Document is related to.",
            "nullable": true,
            "example": "Account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "The short name uniquely identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "The friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The subject or title of the Document.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The person or system that published the Document.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "The relationship of this Document to other Document. or entities.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The format of the Document (e.g., PDF, DOCX).",
            "nullable": true,
            "example": ""
          },
          "FileExtension": {
            "type": "string",
            "description": "The extension of the file associated with the Document.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the Document and its content.",
            "nullable": true,
            "example": ""
          },
          "LargeObjectId": {
            "type": "integer",
            "description": "Unique identifier for a large object or file associated with the Document.",
            "format": "int32"
          },
          "MimeType": {
            "type": "string",
            "nullable": true
          },
          "AccountClassifications": {
            "type": "string",
            "nullable": true
          },
          "LastActionBy": {
            "type": "string",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PossibleEndStatuses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "PortfolioAdmin": {
            "$ref": "#/components/schemas/UserProfileDto"
          },
          "Action1": {
            "type": "string",
            "nullable": true
          },
          "ActionLabel1": {
            "type": "string",
            "nullable": true
          },
          "ActionIcon1": {
            "type": "string",
            "nullable": true
          },
          "Action2": {
            "type": "string",
            "nullable": true
          },
          "ActionLabel2": {
            "type": "string",
            "nullable": true
          },
          "ActionIcon2": {
            "type": "string",
            "nullable": true
          },
          "Action3": {
            "type": "string",
            "nullable": true
          },
          "ActionLabel3": {
            "type": "string",
            "nullable": true
          },
          "ActionIcon3": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentWorkflowQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "HasActions": {
            "type": "boolean",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentWorkflowQueryTlcRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "HasActions": {
            "type": "boolean",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentWorkflowQueryTlcResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/DocumentWorkflowDtoTlc"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "DocumentWorkflowQueryTlcResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/DocumentWorkflowQueryTlcResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "EventUserEntitlementUpdateManyRequest": {
        "type": "object",
        "properties": {
          "UserEntitlements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserEntitlement"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "EventUserEntitlementUpdateManyResponse": {
        "type": "object",
        "additionalProperties": false
      },
      "EventUserEntitlementUpdateManyResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/EventUserEntitlementUpdateManyResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FeedComponentReview": {
        "type": "object",
        "properties": {
          "Item": {
            "$ref": "#/components/schemas/ReviewItem"
          },
          "Reaction": {
            "type": "string",
            "nullable": true
          },
          "Notes": {
            "type": "integer",
            "format": "int32"
          }
        },
        "additionalProperties": false
      },
      "FeedGetRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Interests": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "InReviewMode": {
            "type": "boolean"
          },
          "OrderBy": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FeedGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Components": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/JsonNode"
            },
            "nullable": true
          },
          "Reviews": {
            "type": "object",
            "additionalProperties": {
              "$ref": "#/components/schemas/FeedComponentReview"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FeedGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/FeedGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FileMeta": {
        "required": [
          "ContentType",
          "Id",
          "Name"
        ],
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Size": {
            "type": "integer",
            "format": "int64",
            "nullable": true
          },
          "LastModified": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ContentType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FilePayload": {
        "required": [
          "Data",
          "Name"
        ],
        "type": "object",
        "properties": {
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Size": {
            "type": "integer",
            "format": "int64",
            "nullable": true
          },
          "LastModified": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Data": {
            "type": "string",
            "format": "byte",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "Filter": {
        "type": "object",
        "properties": {
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Value": {
            "nullable": true
          },
          "Operator": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FinAppTokenCreateRequest": {
        "type": "object",
        "properties": {
          "Issuer": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FinAppTokenCreateResponse": {
        "type": "object",
        "properties": {
          "Token": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FinAppTokenCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/FinAppTokenCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FinAppTokenDeleteRequest": {
        "type": "object",
        "properties": {
          "Token": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FinAppTokenDeleteResponse": {
        "type": "object",
        "properties": {
          "Token": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FinAppTokenDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/FinAppTokenDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FinAppsConfig": {
        "type": "object",
        "properties": {
          "UserTier": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FormGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "ServiceId": {
            "type": "string",
            "nullable": true
          },
          "GenerationType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FormGetResponse": {
        "type": "object",
        "properties": {
          "FormId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FormGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/FormGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "Format": {
        "type": "object",
        "properties": {
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Value": {
            "$ref": "#/components/schemas/JsonNode"
          }
        },
        "additionalProperties": false
      },
      "FormatFilter": {
        "type": "object",
        "properties": {
          "SearchString": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FormatsGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "SiteName": {
            "type": "string",
            "nullable": true
          },
          "FormatName": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FormatsGetResponse": {
        "type": "object",
        "properties": {
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Value": {
            "$ref": "#/components/schemas/JsonNode"
          }
        },
        "additionalProperties": false
      },
      "FormatsGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/FormatsGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FormatsMergeRequest": {
        "type": "object",
        "properties": {
          "SiteName": {
            "type": "string",
            "nullable": true
          },
          "Format": {
            "$ref": "#/components/schemas/Format"
          }
        },
        "additionalProperties": false
      },
      "FormatsMergeResponse": {
        "type": "object",
        "properties": {
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Value": {
            "$ref": "#/components/schemas/JsonNode"
          }
        },
        "additionalProperties": false
      },
      "FormatsMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/FormatsMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "FormatsQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "$ref": "#/components/schemas/FormatFilter"
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "SiteName": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "GetDataConfig": {
        "type": "object",
        "properties": {
          "Url": {
            "type": "string",
            "nullable": true
          },
          "Method": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "GetFileConfig": {
        "type": "object",
        "properties": {
          "Url": {
            "type": "string",
            "nullable": true
          },
          "Method": {
            "type": "string",
            "nullable": true
          },
          "BasicAuth": {
            "$ref": "#/components/schemas/BasicAuthConfig"
          }
        },
        "additionalProperties": false
      },
      "GitConfig": {
        "type": "object",
        "properties": {
          "Username": {
            "type": "string",
            "nullable": true
          },
          "Password": {
            "type": "string",
            "nullable": true
          },
          "RemoteOriginUrl": {
            "type": "string",
            "nullable": true
          },
          "DefaultBranch": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "description": "Unique identifier representing the tenant or organization owning this holding.",
            "nullable": true,
            "example": ""
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Business date that the positons are stated as of the end of day",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshotId": {
            "type": "string",
            "description": "A unique identifier for the asset state entity",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshot": {
            "$ref": "#/components/schemas/HoldingSnapshotDto"
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "OriginalFaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "QuantityUnsettled": {
            "type": "integer",
            "description": "The number of units held within the position that have not yet been settled.",
            "format": "int32",
            "nullable": true
          },
          "QuantityRestricted": {
            "type": "integer",
            "description": "The number of units held within the position that are restricted from being traded.",
            "format": "int32",
            "nullable": true
          },
          "QuantityAvailable": {
            "type": "integer",
            "description": "The number of units held within the position that are available for sale.",
            "format": "int32",
            "nullable": true
          },
          "QuantityTotal": {
            "type": "integer",
            "description": "The total number of units held within the position.",
            "format": "int32",
            "nullable": true
          },
          "TaxLotOpenDate": {
            "type": "string",
            "description": "The opening date if the Tax Lot",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "The reference to the individual tax lot.",
            "nullable": true,
            "example": ""
          },
          "StatementBasis": {
            "type": "string",
            "description": "Indicates where the holdings are on a Trade Date or Settlement Basis.",
            "nullable": true,
            "example": ""
          },
          "Ticker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true,
            "example": "AAPL"
          },
          "Cusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol assigned to a security for trading purposes.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "BondCallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bonds maturity.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "BondCallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "CountryOfIssuanceCode": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true,
            "example": ""
          },
          "CountryOfLegalRegistrationCode": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true,
            "example": ""
          },
          "CountryOfRiskCode": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true,
            "example": ""
          },
          "CountryOfTradingCode": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true,
            "example": ""
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true,
            "example": ""
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true,
            "example": ""
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true,
            "example": ""
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "OptionStrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "SharesOutstanding": {
            "type": "integer",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company�s officers and insiders.",
            "format": "int32",
            "nullable": true
          },
          "PaydownFactor": {
            "type": "number",
            "description": "Paydown factor - the percent of principal received relative to the original principal amount.",
            "format": "double",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a companys annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moodys, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the current market price.",
            "format": "double",
            "nullable": true
          },
          "YieldToCost": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the original cost.",
            "format": "double",
            "nullable": true
          },
          "YieldToWorst": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until the worst of the call date and the maturity date, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "StrategyCode": {
            "type": "string",
            "description": "A unique code used to label and track a specific investment strategy.",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "DefaultCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "DefaultOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DefaultPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "LocalOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "LocalPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "description": "Unique identifier representing the tenant or organization owning this holding.",
            "nullable": true,
            "example": ""
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Business date that the positons are stated as of the end of day",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshotId": {
            "type": "string",
            "description": "A unique identifier for the asset state entity",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshot": {
            "$ref": "#/components/schemas/HoldingSnapshotDto"
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "OriginalFaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "QuantityUnsettled": {
            "type": "integer",
            "description": "The number of units held within the position that have not yet been settled.",
            "format": "int32",
            "nullable": true
          },
          "QuantityRestricted": {
            "type": "integer",
            "description": "The number of units held within the position that are restricted from being traded.",
            "format": "int32",
            "nullable": true
          },
          "QuantityAvailable": {
            "type": "integer",
            "description": "The number of units held within the position that are available for sale.",
            "format": "int32",
            "nullable": true
          },
          "QuantityTotal": {
            "type": "integer",
            "description": "The total number of units held within the position.",
            "format": "int32",
            "nullable": true
          },
          "TaxLotOpenDate": {
            "type": "string",
            "description": "The opening date if the Tax Lot",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "The reference to the individual tax lot.",
            "nullable": true,
            "example": ""
          },
          "StatementBasis": {
            "type": "string",
            "description": "Indicates where the holdings are on a Trade Date or Settlement Basis.",
            "nullable": true,
            "example": ""
          },
          "Ticker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true,
            "example": "AAPL"
          },
          "Cusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol assigned to a security for trading purposes.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "BondCallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bonds maturity.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "BondCallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "CountryOfIssuanceCode": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true,
            "example": ""
          },
          "CountryOfLegalRegistrationCode": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true,
            "example": ""
          },
          "CountryOfRiskCode": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true,
            "example": ""
          },
          "CountryOfTradingCode": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true,
            "example": ""
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true,
            "example": ""
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true,
            "example": ""
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true,
            "example": ""
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "OptionStrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "SharesOutstanding": {
            "type": "integer",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company�s officers and insiders.",
            "format": "int32",
            "nullable": true
          },
          "PaydownFactor": {
            "type": "number",
            "description": "Paydown factor - the percent of principal received relative to the original principal amount.",
            "format": "double",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a companys annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moodys, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the current market price.",
            "format": "double",
            "nullable": true
          },
          "YieldToCost": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the original cost.",
            "format": "double",
            "nullable": true
          },
          "YieldToWorst": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until the worst of the call date and the maturity date, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "StrategyCode": {
            "type": "string",
            "description": "A unique code used to label and track a specific investment strategy.",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "DefaultCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "DefaultOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DefaultPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "LocalOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "LocalPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Code": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "nullable": true
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "OriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "AmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "OriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "FedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "MarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "TotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "ProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "EarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Code": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "nullable": true
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "OriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "AmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "OriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "FedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "MarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "TotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "ProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "EarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingCurrencyCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Code": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "nullable": true
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "OriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "AmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "OriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "FedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "MarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "TotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "ProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "EarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingCurrencyDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Code": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "nullable": true
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "OriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "AmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "OriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "FedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "MarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "TotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "ProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "EarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/HoldingCurrencyDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingCurrencyDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Code": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "nullable": true
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "OriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "AmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "OriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "FedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "MarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "TotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "ProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "EarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingCurrencyGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Code": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "nullable": true
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "OriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "AmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "OriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "FedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "MarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "TotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "ProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "EarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Code": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "nullable": true
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "OriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "AmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "OriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "FedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "MarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "PricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "TotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "UnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "ProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "EarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingCurrencyUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingCurrencyUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "description": "Unique identifier representing the tenant or organization owning this holding.",
            "nullable": true,
            "example": ""
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Business date that the positons are stated as of the end of day",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshotId": {
            "type": "string",
            "description": "A unique identifier for the asset state entity",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshot": {
            "$ref": "#/components/schemas/HoldingSnapshotDto"
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "OriginalFaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "QuantityUnsettled": {
            "type": "integer",
            "description": "The number of units held within the position that have not yet been settled.",
            "format": "int32",
            "nullable": true
          },
          "QuantityRestricted": {
            "type": "integer",
            "description": "The number of units held within the position that are restricted from being traded.",
            "format": "int32",
            "nullable": true
          },
          "QuantityAvailable": {
            "type": "integer",
            "description": "The number of units held within the position that are available for sale.",
            "format": "int32",
            "nullable": true
          },
          "QuantityTotal": {
            "type": "integer",
            "description": "The total number of units held within the position.",
            "format": "int32",
            "nullable": true
          },
          "TaxLotOpenDate": {
            "type": "string",
            "description": "The opening date if the Tax Lot",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "The reference to the individual tax lot.",
            "nullable": true,
            "example": ""
          },
          "StatementBasis": {
            "type": "string",
            "description": "Indicates where the holdings are on a Trade Date or Settlement Basis.",
            "nullable": true,
            "example": ""
          },
          "Ticker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true,
            "example": "AAPL"
          },
          "Cusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol assigned to a security for trading purposes.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "BondCallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bonds maturity.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "BondCallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "CountryOfIssuanceCode": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true,
            "example": ""
          },
          "CountryOfLegalRegistrationCode": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true,
            "example": ""
          },
          "CountryOfRiskCode": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true,
            "example": ""
          },
          "CountryOfTradingCode": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true,
            "example": ""
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true,
            "example": ""
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true,
            "example": ""
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true,
            "example": ""
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "OptionStrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "SharesOutstanding": {
            "type": "integer",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company�s officers and insiders.",
            "format": "int32",
            "nullable": true
          },
          "PaydownFactor": {
            "type": "number",
            "description": "Paydown factor - the percent of principal received relative to the original principal amount.",
            "format": "double",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a companys annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moodys, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the current market price.",
            "format": "double",
            "nullable": true
          },
          "YieldToCost": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the original cost.",
            "format": "double",
            "nullable": true
          },
          "YieldToWorst": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until the worst of the call date and the maturity date, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "StrategyCode": {
            "type": "string",
            "description": "A unique code used to label and track a specific investment strategy.",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "DefaultCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "DefaultOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DefaultPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "LocalOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "LocalPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "description": "Unique identifier representing the tenant or organization owning this holding.",
            "nullable": true,
            "example": ""
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Business date that the positons are stated as of the end of day",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshotId": {
            "type": "string",
            "description": "A unique identifier for the asset state entity",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshot": {
            "$ref": "#/components/schemas/HoldingSnapshotDto"
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "OriginalFaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "QuantityUnsettled": {
            "type": "integer",
            "description": "The number of units held within the position that have not yet been settled.",
            "format": "int32",
            "nullable": true
          },
          "QuantityRestricted": {
            "type": "integer",
            "description": "The number of units held within the position that are restricted from being traded.",
            "format": "int32",
            "nullable": true
          },
          "QuantityAvailable": {
            "type": "integer",
            "description": "The number of units held within the position that are available for sale.",
            "format": "int32",
            "nullable": true
          },
          "QuantityTotal": {
            "type": "integer",
            "description": "The total number of units held within the position.",
            "format": "int32",
            "nullable": true
          },
          "TaxLotOpenDate": {
            "type": "string",
            "description": "The opening date if the Tax Lot",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "The reference to the individual tax lot.",
            "nullable": true,
            "example": ""
          },
          "StatementBasis": {
            "type": "string",
            "description": "Indicates where the holdings are on a Trade Date or Settlement Basis.",
            "nullable": true,
            "example": ""
          },
          "Ticker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true,
            "example": "AAPL"
          },
          "Cusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol assigned to a security for trading purposes.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "BondCallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bonds maturity.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "BondCallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "CountryOfIssuanceCode": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true,
            "example": ""
          },
          "CountryOfLegalRegistrationCode": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true,
            "example": ""
          },
          "CountryOfRiskCode": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true,
            "example": ""
          },
          "CountryOfTradingCode": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true,
            "example": ""
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true,
            "example": ""
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true,
            "example": ""
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true,
            "example": ""
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "OptionStrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "SharesOutstanding": {
            "type": "integer",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company�s officers and insiders.",
            "format": "int32",
            "nullable": true
          },
          "PaydownFactor": {
            "type": "number",
            "description": "Paydown factor - the percent of principal received relative to the original principal amount.",
            "format": "double",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a companys annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moodys, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the current market price.",
            "format": "double",
            "nullable": true
          },
          "YieldToCost": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the original cost.",
            "format": "double",
            "nullable": true
          },
          "YieldToWorst": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until the worst of the call date and the maturity date, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "StrategyCode": {
            "type": "string",
            "description": "A unique code used to label and track a specific investment strategy.",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "DefaultCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "DefaultOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DefaultPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "LocalOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "LocalPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/HoldingDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "description": "Unique identifier representing the tenant or organization owning this holding.",
            "nullable": true,
            "example": ""
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Business date that the positons are stated as of the end of day",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshotId": {
            "type": "string",
            "description": "A unique identifier for the asset state entity",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshot": {
            "$ref": "#/components/schemas/HoldingSnapshotDto"
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "OriginalFaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "QuantityUnsettled": {
            "type": "integer",
            "description": "The number of units held within the position that have not yet been settled.",
            "format": "int32",
            "nullable": true
          },
          "QuantityRestricted": {
            "type": "integer",
            "description": "The number of units held within the position that are restricted from being traded.",
            "format": "int32",
            "nullable": true
          },
          "QuantityAvailable": {
            "type": "integer",
            "description": "The number of units held within the position that are available for sale.",
            "format": "int32",
            "nullable": true
          },
          "QuantityTotal": {
            "type": "integer",
            "description": "The total number of units held within the position.",
            "format": "int32",
            "nullable": true
          },
          "TaxLotOpenDate": {
            "type": "string",
            "description": "The opening date if the Tax Lot",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "The reference to the individual tax lot.",
            "nullable": true,
            "example": ""
          },
          "StatementBasis": {
            "type": "string",
            "description": "Indicates where the holdings are on a Trade Date or Settlement Basis.",
            "nullable": true,
            "example": ""
          },
          "Ticker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true,
            "example": "AAPL"
          },
          "Cusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol assigned to a security for trading purposes.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "BondCallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bonds maturity.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "BondCallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "CountryOfIssuanceCode": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true,
            "example": ""
          },
          "CountryOfLegalRegistrationCode": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true,
            "example": ""
          },
          "CountryOfRiskCode": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true,
            "example": ""
          },
          "CountryOfTradingCode": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true,
            "example": ""
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true,
            "example": ""
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true,
            "example": ""
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true,
            "example": ""
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "OptionStrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "SharesOutstanding": {
            "type": "integer",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company�s officers and insiders.",
            "format": "int32",
            "nullable": true
          },
          "PaydownFactor": {
            "type": "number",
            "description": "Paydown factor - the percent of principal received relative to the original principal amount.",
            "format": "double",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a companys annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moodys, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the current market price.",
            "format": "double",
            "nullable": true
          },
          "YieldToCost": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the original cost.",
            "format": "double",
            "nullable": true
          },
          "YieldToWorst": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until the worst of the call date and the maturity date, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "StrategyCode": {
            "type": "string",
            "description": "A unique code used to label and track a specific investment strategy.",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "DefaultCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "DefaultOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DefaultPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "LocalOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "LocalPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingSnapshotCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingSnapshotDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/HoldingSnapshotDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingSnapshotDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingSnapshotGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingSnapshotHistoryCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingSnapshotHistoryDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/HoldingSnapshotHistoryDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingSnapshotHistoryDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingSnapshotHistoryGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotHistoryUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingSnapshotHistoryUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de5c"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if the record is on a Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day, etc.",
            "nullable": true,
            "example": "EOD"
          },
          "AsOfDate": {
            "type": "string",
            "description": "The business date for which the asset positions are stated as of the end of the day.",
            "format": "date",
            "nullable": true,
            "example": "45565"
          },
          "IsVisible": {
            "type": "boolean",
            "description": "Flag indicating whether the asset state record is visible or not.",
            "nullable": true,
            "example": 1
          },
          "TenantId": {
            "type": "string",
            "description": "System-generated field that identifies the tenant or organization associated with the transaction.",
            "nullable": true,
            "example": "f4d053c2-d4aa-473d-8f18-27b7c5a4de7a"
          }
        },
        "additionalProperties": false
      },
      "HoldingSnapshotUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingSnapshotUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "description": "Holding",
            "nullable": true,
            "example": ""
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "description": "Holding",
            "nullable": true,
            "example": ""
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingTranslationCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "description": "Holding",
            "nullable": true,
            "example": ""
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingTranslationDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "description": "Holding",
            "nullable": true,
            "example": ""
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/HoldingTranslationDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingTranslationDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "description": "Holding",
            "nullable": true,
            "example": ""
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingTranslationGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "description": "Holding",
            "nullable": true,
            "example": ""
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "HoldingId": {
            "type": "string",
            "description": "Holding",
            "nullable": true,
            "example": ""
          },
          "Holding": {
            "$ref": "#/components/schemas/HoldingDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "HoldingTranslationUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingTranslationUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "description": "Unique identifier representing the tenant or organization owning this holding.",
            "nullable": true,
            "example": ""
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Business date that the positons are stated as of the end of day",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshotId": {
            "type": "string",
            "description": "A unique identifier for the asset state entity",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshot": {
            "$ref": "#/components/schemas/HoldingSnapshotDto"
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "OriginalFaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "QuantityUnsettled": {
            "type": "integer",
            "description": "The number of units held within the position that have not yet been settled.",
            "format": "int32",
            "nullable": true
          },
          "QuantityRestricted": {
            "type": "integer",
            "description": "The number of units held within the position that are restricted from being traded.",
            "format": "int32",
            "nullable": true
          },
          "QuantityAvailable": {
            "type": "integer",
            "description": "The number of units held within the position that are available for sale.",
            "format": "int32",
            "nullable": true
          },
          "QuantityTotal": {
            "type": "integer",
            "description": "The total number of units held within the position.",
            "format": "int32",
            "nullable": true
          },
          "TaxLotOpenDate": {
            "type": "string",
            "description": "The opening date if the Tax Lot",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "The reference to the individual tax lot.",
            "nullable": true,
            "example": ""
          },
          "StatementBasis": {
            "type": "string",
            "description": "Indicates where the holdings are on a Trade Date or Settlement Basis.",
            "nullable": true,
            "example": ""
          },
          "Ticker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true,
            "example": "AAPL"
          },
          "Cusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol assigned to a security for trading purposes.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "BondCallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bonds maturity.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "BondCallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "CountryOfIssuanceCode": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true,
            "example": ""
          },
          "CountryOfLegalRegistrationCode": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true,
            "example": ""
          },
          "CountryOfRiskCode": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true,
            "example": ""
          },
          "CountryOfTradingCode": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true,
            "example": ""
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true,
            "example": ""
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true,
            "example": ""
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true,
            "example": ""
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "OptionStrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "SharesOutstanding": {
            "type": "integer",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company�s officers and insiders.",
            "format": "int32",
            "nullable": true
          },
          "PaydownFactor": {
            "type": "number",
            "description": "Paydown factor - the percent of principal received relative to the original principal amount.",
            "format": "double",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a companys annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moodys, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the current market price.",
            "format": "double",
            "nullable": true
          },
          "YieldToCost": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the original cost.",
            "format": "double",
            "nullable": true
          },
          "YieldToWorst": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until the worst of the call date and the maturity date, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "StrategyCode": {
            "type": "string",
            "description": "A unique code used to label and track a specific investment strategy.",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "DefaultCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "DefaultOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DefaultPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "LocalOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "LocalPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "description": "Unique identifier representing the tenant or organization owning this holding.",
            "nullable": true,
            "example": ""
          },
          "ReasonCode": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": ""
          },
          "AsOfDate": {
            "type": "string",
            "description": "Business date that the positons are stated as of the end of day",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshotId": {
            "type": "string",
            "description": "A unique identifier for the asset state entity",
            "nullable": true,
            "example": ""
          },
          "HoldingSnapshot": {
            "$ref": "#/components/schemas/HoldingSnapshotDto"
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "OriginalFaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "QuantityUnsettled": {
            "type": "integer",
            "description": "The number of units held within the position that have not yet been settled.",
            "format": "int32",
            "nullable": true
          },
          "QuantityRestricted": {
            "type": "integer",
            "description": "The number of units held within the position that are restricted from being traded.",
            "format": "int32",
            "nullable": true
          },
          "QuantityAvailable": {
            "type": "integer",
            "description": "The number of units held within the position that are available for sale.",
            "format": "int32",
            "nullable": true
          },
          "QuantityTotal": {
            "type": "integer",
            "description": "The total number of units held within the position.",
            "format": "int32",
            "nullable": true
          },
          "TaxLotOpenDate": {
            "type": "string",
            "description": "The opening date if the Tax Lot",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "The reference to the individual tax lot.",
            "nullable": true,
            "example": ""
          },
          "StatementBasis": {
            "type": "string",
            "description": "Indicates where the holdings are on a Trade Date or Settlement Basis.",
            "nullable": true,
            "example": ""
          },
          "Ticker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true,
            "example": "AAPL"
          },
          "Cusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol assigned to a security for trading purposes.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker symbol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "BondCallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bonds maturity.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "BondCallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true,
            "example": ""
          },
          "CompanyWebsiteUrl": {
            "type": "string",
            "description": "The official internet address (URL) of a companys website, providing access to information about the companys identity, activities, products, and services.",
            "nullable": true,
            "example": ""
          },
          "CountryOfIssuanceCode": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true,
            "example": ""
          },
          "CountryOfLegalRegistrationCode": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true,
            "example": ""
          },
          "CountryOfRiskCode": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true,
            "example": ""
          },
          "CountryOfTradingCode": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true,
            "example": ""
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true,
            "example": ""
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true,
            "example": ""
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true,
            "example": ""
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true,
            "example": ""
          },
          "OptionExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "OptionStrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "OptionStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true,
            "example": ""
          },
          "OptionType": {
            "type": "string",
            "description": "Indicates whether the option is a call or a put.",
            "nullable": true,
            "example": ""
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true,
            "example": ""
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true,
            "example": ""
          },
          "SecFilingUrl": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "A brief description of the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true,
            "example": ""
          },
          "SecurityType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true,
            "example": ""
          },
          "SharesOutstanding": {
            "type": "integer",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company�s officers and insiders.",
            "format": "int32",
            "nullable": true
          },
          "PaydownFactor": {
            "type": "number",
            "description": "Paydown factor - the percent of principal received relative to the original principal amount.",
            "format": "double",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a companys annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moodys, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the current market price.",
            "format": "double",
            "nullable": true
          },
          "YieldToCost": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate. Based on the original cost.",
            "format": "double",
            "nullable": true
          },
          "YieldToWorst": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until the worst of the call date and the maturity date, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "StrategyName": {
            "type": "string",
            "description": "The name given to an investment strategy, describing its methodology or focus (e.g., Growth, Value, Income).",
            "nullable": true,
            "example": ""
          },
          "StrategyCode": {
            "type": "string",
            "description": "A unique code used to label and track a specific investment strategy.",
            "nullable": true,
            "example": ""
          },
          "Classification1": {
            "type": "string",
            "description": "A categorization of the asset positions, typically used for detailed portfolio analysis and reporting.",
            "nullable": true,
            "example": ""
          },
          "DefaultCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "DefaultOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "DefaultPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DefaultPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "DefaultEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalCode": {
            "type": "string",
            "description": "The ISO code representing the base currency of the account.",
            "nullable": true,
            "example": ""
          },
          "LocalOriginalCost": {
            "type": "number",
            "description": "The original cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalAmortizedCostPeriodEnd": {
            "type": "number",
            "description": "The amortized original cost of the holding at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalOriginalUnitCost": {
            "type": "number",
            "description": "The original unit cost of the holding in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalFedAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated using the federal tax cost basis.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, determined by dividing the total acquisition cost by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedInterestPeriodEnd": {
            "type": "number",
            "description": "The accrued interest on the holding in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalMarketValuePeriodEnd": {
            "type": "number",
            "description": "The total market value of the position in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security in the base currency at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndSource": {
            "type": "number",
            "description": "The source used for determining the price of the security at the end of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalPricePeriodEndDate": {
            "type": "string",
            "description": "The business date when the end of period security price was recorded.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "LocalPriorPricePeriodEnd": {
            "type": "number",
            "description": "The price of the security at the end of the prior period in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalUnrealizedGainLoss": {
            "type": "number",
            "description": "The total unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedLongTermGainLoss": {
            "type": "number",
            "description": "The long-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalUnrealizedShortTermGainLoss": {
            "type": "number",
            "description": "The short-term unrealized gain or loss in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalProjectedAnnualIncome": {
            "type": "number",
            "description": "The projected annual income from the security over the next 12 months from interest and dividends.",
            "format": "double",
            "nullable": true
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from the security over a year, in the base currency.",
            "format": "double",
            "nullable": true
          },
          "LocalEarningsPerShare": {
            "type": "number",
            "description": "The earnings per share for equity securities, in the base currency.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "HoldingUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/HoldingUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ITenant": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Identifier": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "ConnectionString": {
            "type": "string",
            "nullable": true
          },
          "Jwt": {
            "$ref": "#/components/schemas/JwtConfig"
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Project": {
            "type": "string",
            "nullable": true
          },
          "Git": {
            "$ref": "#/components/schemas/GitConfig"
          },
          "Keycloak": {
            "$ref": "#/components/schemas/KeycloakConfig"
          },
          "FinApps": {
            "$ref": "#/components/schemas/FinAppsConfig"
          },
          "DocumentSources": {
            "type": "object",
            "additionalProperties": {
              "$ref": "#/components/schemas/DocumentSource"
            },
            "nullable": true
          },
          "DataSecurity": {
            "$ref": "#/components/schemas/DataSecurity"
          },
          "NotificationService": {
            "$ref": "#/components/schemas/NotificationServiceConfig"
          },
          "PortalUrl": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ImpersonationRequestCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Token": {
            "type": "string",
            "nullable": true
          },
          "RefreshToken": {
            "type": "string",
            "nullable": true
          },
          "DueDate": {
            "type": "string",
            "format": "date-time"
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ImpersonationRequestCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Token": {
            "type": "string",
            "nullable": true
          },
          "RefreshToken": {
            "type": "string",
            "nullable": true
          },
          "DueDate": {
            "type": "string",
            "format": "date-time"
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ImpersonationRequestCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/ImpersonationRequestCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ImpersonationRequestGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ImpersonationRequestGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Token": {
            "type": "string",
            "nullable": true
          },
          "RefreshToken": {
            "type": "string",
            "nullable": true
          },
          "DueDate": {
            "type": "string",
            "format": "date-time"
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserId": {
            "type": "string",
            "nullable": true
          },
          "NatsJwt": {
            "type": "string",
            "nullable": true
          },
          "NatsSeed": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ImpersonationRequestGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/ImpersonationRequestGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "IngestErrorDto": {
        "type": "object",
        "properties": {
          "Message": {
            "type": "string",
            "nullable": true
          },
          "Line": {
            "type": "integer",
            "format": "int32"
          }
        },
        "additionalProperties": false
      },
      "IngestFileEntityMergeRequest": {
        "type": "object",
        "properties": {
          "File": {
            "$ref": "#/components/schemas/FilePayload"
          }
        },
        "additionalProperties": false
      },
      "IngestFileEntityMergeResponse": {
        "type": "object",
        "properties": {
          "Count": {
            "type": "integer",
            "format": "int32"
          }
        },
        "additionalProperties": false
      },
      "IngestFileEntityMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/IngestFileEntityMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "IngestMergeAccountRequest": {
        "type": "object",
        "properties": {
          "File": {
            "$ref": "#/components/schemas/FilePayload"
          },
          "IsGroup": {
            "type": "boolean",
            "nullable": true
          },
          "Type": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "IngestMergeAccountResponse": {
        "type": "object",
        "properties": {
          "Count": {
            "type": "integer",
            "format": "int32"
          },
          "Created": {
            "type": "integer",
            "format": "int32"
          },
          "Updated": {
            "type": "integer",
            "format": "int32"
          },
          "Deleted": {
            "type": "integer",
            "format": "int32"
          },
          "ValidationFail": {
            "type": "integer",
            "format": "int32"
          },
          "NoChanges": {
            "type": "integer",
            "format": "int32"
          },
          "Errors": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/IngestErrorDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "IngestMergeAccountResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/IngestMergeAccountResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "IngestMergeManifestRequest": {
        "type": "object",
        "properties": {
          "File": {
            "$ref": "#/components/schemas/FilePayload"
          }
        },
        "additionalProperties": false
      },
      "IngestMergeManifestResponse": {
        "type": "object",
        "properties": {
          "Count": {
            "type": "integer",
            "format": "int32"
          },
          "Created": {
            "type": "integer",
            "format": "int32"
          },
          "ValidationFail": {
            "type": "integer",
            "format": "int32"
          },
          "NoChanges": {
            "type": "integer",
            "format": "int32"
          },
          "Errors": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/IngestErrorDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "IngestMergeManifestResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/IngestMergeManifestResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "IngestMergeUserRequest": {
        "type": "object",
        "properties": {
          "File": {
            "$ref": "#/components/schemas/FilePayload"
          },
          "Type": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "IngestMergeUserResponse": {
        "type": "object",
        "properties": {
          "Count": {
            "type": "integer",
            "format": "int32"
          },
          "Created": {
            "type": "integer",
            "format": "int32"
          },
          "Updated": {
            "type": "integer",
            "format": "int32"
          },
          "Deleted": {
            "type": "integer",
            "format": "int32"
          },
          "ValidationFail": {
            "type": "integer",
            "format": "int32"
          },
          "NoChanges": {
            "type": "integer",
            "format": "int32"
          },
          "Errors": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/IngestErrorDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "IngestMergeUserResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/IngestMergeUserResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "IngestMergeUserToAccountRequest": {
        "type": "object",
        "properties": {
          "File": {
            "$ref": "#/components/schemas/FilePayload"
          }
        },
        "additionalProperties": false
      },
      "IngestMergeUserToAccountResponse": {
        "type": "object",
        "properties": {
          "Count": {
            "type": "integer",
            "format": "int32"
          },
          "ValidationFail": {
            "type": "integer",
            "format": "int32"
          },
          "NoChanges": {
            "type": "integer",
            "format": "int32"
          },
          "RowsProcessed": {
            "type": "integer",
            "format": "int32"
          },
          "Errors": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/IngestErrorDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "IngestMergeUserToAccountResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/IngestMergeUserToAccountResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the auto insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "VehicleDetails": {
            "type": "string",
            "description": "Detailed information about the insured vehicle, including make, model, year, and Vehicle Identification Number (VIN).",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The types of coverage included in the auto insurance policy, such as Liability, Collision, Comprehensive, Uninsured Motorist, and Personal Injury Protection (PIP).",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code for the base currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code for the local currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "HasLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes liability coverage.",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "DriverDetails": {
            "type": "string",
            "description": "Information about the driver(s) covered by the policy, including name, license number, driving history, and relationship to the policyholder.",
            "nullable": true,
            "example": ""
          },
          "AccidentHistory": {
            "type": "string",
            "description": "A record of any accidents involving the insured vehicle or drivers, including dates, damages, and claim amounts.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "HasRoadsideAssistance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes roadside assistance coverage.",
            "nullable": true
          },
          "HasRentalReimbursement": {
            "type": "boolean",
            "description": "Indicates whether the policy includes rental reimbursement coverage.",
            "nullable": true
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the auto insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the auto insurance policy.",
            "nullable": true
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "GarageLocation": {
            "type": "string",
            "description": "The location where the vehicle is primarily garaged, which can affect the premium due to varying risks in different areas (e.g., urban vs. rural).",
            "nullable": true,
            "example": ""
          },
          "AnnualMileage": {
            "type": "integer",
            "description": "The estimated number of miles the vehicle is driven annually, which can affect the premium calculation.",
            "format": "int32",
            "nullable": true
          },
          "HasAntiTheftDiscount": {
            "type": "boolean",
            "description": "Indicates whether the vehicle has anti-theft devices installed, which might qualify the policyholder for a discount on their premium.",
            "nullable": true
          },
          "HasGoodDriverDiscount": {
            "type": "boolean",
            "description": "Indicates whether the policyholder qualifies for a discount based on a good driving record, often with no recent accidents or violations.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the auto insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "VehicleDetails": {
            "type": "string",
            "description": "Detailed information about the insured vehicle, including make, model, year, and Vehicle Identification Number (VIN).",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The types of coverage included in the auto insurance policy, such as Liability, Collision, Comprehensive, Uninsured Motorist, and Personal Injury Protection (PIP).",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code for the base currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code for the local currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "HasLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes liability coverage.",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "DriverDetails": {
            "type": "string",
            "description": "Information about the driver(s) covered by the policy, including name, license number, driving history, and relationship to the policyholder.",
            "nullable": true,
            "example": ""
          },
          "AccidentHistory": {
            "type": "string",
            "description": "A record of any accidents involving the insured vehicle or drivers, including dates, damages, and claim amounts.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "HasRoadsideAssistance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes roadside assistance coverage.",
            "nullable": true
          },
          "HasRentalReimbursement": {
            "type": "boolean",
            "description": "Indicates whether the policy includes rental reimbursement coverage.",
            "nullable": true
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the auto insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the auto insurance policy.",
            "nullable": true
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "GarageLocation": {
            "type": "string",
            "description": "The location where the vehicle is primarily garaged, which can affect the premium due to varying risks in different areas (e.g., urban vs. rural).",
            "nullable": true,
            "example": ""
          },
          "AnnualMileage": {
            "type": "integer",
            "description": "The estimated number of miles the vehicle is driven annually, which can affect the premium calculation.",
            "format": "int32",
            "nullable": true
          },
          "HasAntiTheftDiscount": {
            "type": "boolean",
            "description": "Indicates whether the vehicle has anti-theft devices installed, which might qualify the policyholder for a discount on their premium.",
            "nullable": true
          },
          "HasGoodDriverDiscount": {
            "type": "boolean",
            "description": "Indicates whether the policyholder qualifies for a discount based on a good driving record, often with no recent accidents or violations.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceAutoCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the auto insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "VehicleDetails": {
            "type": "string",
            "description": "Detailed information about the insured vehicle, including make, model, year, and Vehicle Identification Number (VIN).",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The types of coverage included in the auto insurance policy, such as Liability, Collision, Comprehensive, Uninsured Motorist, and Personal Injury Protection (PIP).",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code for the base currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code for the local currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "HasLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes liability coverage.",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "DriverDetails": {
            "type": "string",
            "description": "Information about the driver(s) covered by the policy, including name, license number, driving history, and relationship to the policyholder.",
            "nullable": true,
            "example": ""
          },
          "AccidentHistory": {
            "type": "string",
            "description": "A record of any accidents involving the insured vehicle or drivers, including dates, damages, and claim amounts.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "HasRoadsideAssistance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes roadside assistance coverage.",
            "nullable": true
          },
          "HasRentalReimbursement": {
            "type": "boolean",
            "description": "Indicates whether the policy includes rental reimbursement coverage.",
            "nullable": true
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the auto insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the auto insurance policy.",
            "nullable": true
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "GarageLocation": {
            "type": "string",
            "description": "The location where the vehicle is primarily garaged, which can affect the premium due to varying risks in different areas (e.g., urban vs. rural).",
            "nullable": true,
            "example": ""
          },
          "AnnualMileage": {
            "type": "integer",
            "description": "The estimated number of miles the vehicle is driven annually, which can affect the premium calculation.",
            "format": "int32",
            "nullable": true
          },
          "HasAntiTheftDiscount": {
            "type": "boolean",
            "description": "Indicates whether the vehicle has anti-theft devices installed, which might qualify the policyholder for a discount on their premium.",
            "nullable": true
          },
          "HasGoodDriverDiscount": {
            "type": "boolean",
            "description": "Indicates whether the policyholder qualifies for a discount based on a good driving record, often with no recent accidents or violations.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceAutoDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the auto insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "VehicleDetails": {
            "type": "string",
            "description": "Detailed information about the insured vehicle, including make, model, year, and Vehicle Identification Number (VIN).",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The types of coverage included in the auto insurance policy, such as Liability, Collision, Comprehensive, Uninsured Motorist, and Personal Injury Protection (PIP).",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code for the base currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code for the local currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "HasLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes liability coverage.",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "DriverDetails": {
            "type": "string",
            "description": "Information about the driver(s) covered by the policy, including name, license number, driving history, and relationship to the policyholder.",
            "nullable": true,
            "example": ""
          },
          "AccidentHistory": {
            "type": "string",
            "description": "A record of any accidents involving the insured vehicle or drivers, including dates, damages, and claim amounts.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "HasRoadsideAssistance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes roadside assistance coverage.",
            "nullable": true
          },
          "HasRentalReimbursement": {
            "type": "boolean",
            "description": "Indicates whether the policy includes rental reimbursement coverage.",
            "nullable": true
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the auto insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the auto insurance policy.",
            "nullable": true
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "GarageLocation": {
            "type": "string",
            "description": "The location where the vehicle is primarily garaged, which can affect the premium due to varying risks in different areas (e.g., urban vs. rural).",
            "nullable": true,
            "example": ""
          },
          "AnnualMileage": {
            "type": "integer",
            "description": "The estimated number of miles the vehicle is driven annually, which can affect the premium calculation.",
            "format": "int32",
            "nullable": true
          },
          "HasAntiTheftDiscount": {
            "type": "boolean",
            "description": "Indicates whether the vehicle has anti-theft devices installed, which might qualify the policyholder for a discount on their premium.",
            "nullable": true
          },
          "HasGoodDriverDiscount": {
            "type": "boolean",
            "description": "Indicates whether the policyholder qualifies for a discount based on a good driving record, often with no recent accidents or violations.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/InsuranceAutoDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceAutoDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the auto insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "VehicleDetails": {
            "type": "string",
            "description": "Detailed information about the insured vehicle, including make, model, year, and Vehicle Identification Number (VIN).",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The types of coverage included in the auto insurance policy, such as Liability, Collision, Comprehensive, Uninsured Motorist, and Personal Injury Protection (PIP).",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code for the base currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code for the local currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "HasLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes liability coverage.",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "DriverDetails": {
            "type": "string",
            "description": "Information about the driver(s) covered by the policy, including name, license number, driving history, and relationship to the policyholder.",
            "nullable": true,
            "example": ""
          },
          "AccidentHistory": {
            "type": "string",
            "description": "A record of any accidents involving the insured vehicle or drivers, including dates, damages, and claim amounts.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "HasRoadsideAssistance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes roadside assistance coverage.",
            "nullable": true
          },
          "HasRentalReimbursement": {
            "type": "boolean",
            "description": "Indicates whether the policy includes rental reimbursement coverage.",
            "nullable": true
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the auto insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the auto insurance policy.",
            "nullable": true
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "GarageLocation": {
            "type": "string",
            "description": "The location where the vehicle is primarily garaged, which can affect the premium due to varying risks in different areas (e.g., urban vs. rural).",
            "nullable": true,
            "example": ""
          },
          "AnnualMileage": {
            "type": "integer",
            "description": "The estimated number of miles the vehicle is driven annually, which can affect the premium calculation.",
            "format": "int32",
            "nullable": true
          },
          "HasAntiTheftDiscount": {
            "type": "boolean",
            "description": "Indicates whether the vehicle has anti-theft devices installed, which might qualify the policyholder for a discount on their premium.",
            "nullable": true
          },
          "HasGoodDriverDiscount": {
            "type": "boolean",
            "description": "Indicates whether the policyholder qualifies for a discount based on a good driving record, often with no recent accidents or violations.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceAutoGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the auto insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "VehicleDetails": {
            "type": "string",
            "description": "Detailed information about the insured vehicle, including make, model, year, and Vehicle Identification Number (VIN).",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The types of coverage included in the auto insurance policy, such as Liability, Collision, Comprehensive, Uninsured Motorist, and Personal Injury Protection (PIP).",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code for the base currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code for the local currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "HasLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes liability coverage.",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "DriverDetails": {
            "type": "string",
            "description": "Information about the driver(s) covered by the policy, including name, license number, driving history, and relationship to the policyholder.",
            "nullable": true,
            "example": ""
          },
          "AccidentHistory": {
            "type": "string",
            "description": "A record of any accidents involving the insured vehicle or drivers, including dates, damages, and claim amounts.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "HasRoadsideAssistance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes roadside assistance coverage.",
            "nullable": true
          },
          "HasRentalReimbursement": {
            "type": "boolean",
            "description": "Indicates whether the policy includes rental reimbursement coverage.",
            "nullable": true
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the auto insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the auto insurance policy.",
            "nullable": true
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "GarageLocation": {
            "type": "string",
            "description": "The location where the vehicle is primarily garaged, which can affect the premium due to varying risks in different areas (e.g., urban vs. rural).",
            "nullable": true,
            "example": ""
          },
          "AnnualMileage": {
            "type": "integer",
            "description": "The estimated number of miles the vehicle is driven annually, which can affect the premium calculation.",
            "format": "int32",
            "nullable": true
          },
          "HasAntiTheftDiscount": {
            "type": "boolean",
            "description": "Indicates whether the vehicle has anti-theft devices installed, which might qualify the policyholder for a discount on their premium.",
            "nullable": true
          },
          "HasGoodDriverDiscount": {
            "type": "boolean",
            "description": "Indicates whether the policyholder qualifies for a discount based on a good driving record, often with no recent accidents or violations.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the auto insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "VehicleDetails": {
            "type": "string",
            "description": "Detailed information about the insured vehicle, including make, model, year, and Vehicle Identification Number (VIN).",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The types of coverage included in the auto insurance policy, such as Liability, Collision, Comprehensive, Uninsured Motorist, and Personal Injury Protection (PIP).",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code for the base currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code for the local currency in which coverage amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "HasLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes liability coverage.",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the liability coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCollisionCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for collision coverage.",
            "nullable": true
          },
          "BaseCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCollisionCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the collision coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage.",
            "format": "double",
            "nullable": true
          },
          "HasComprehensiveCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for comprehensive coverage.",
            "nullable": true
          },
          "BaseCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyComprehensiveCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the comprehensive coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage.",
            "format": "double",
            "nullable": true
          },
          "HasUninsuredMotoristCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for uninsured motorist coverage.",
            "nullable": true
          },
          "BaseCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyUninsuredMotoristCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the uninsured motorist coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage.",
            "format": "double",
            "nullable": true
          },
          "HasGlassCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for glass coverage.",
            "nullable": true
          },
          "BaseCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyGlassCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the glass coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverage": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage.",
            "format": "double",
            "nullable": true
          },
          "HasCustomPartsCoverageDeductible": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a deductible for custom parts coverage.",
            "nullable": true
          },
          "BaseCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the base currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCustomPartsCoverageDeductible": {
            "type": "number",
            "description": "The currency code for the local currency of the custom parts coverage deductible.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the general deductible amount.",
            "format": "double",
            "nullable": true
          },
          "DriverDetails": {
            "type": "string",
            "description": "Information about the driver(s) covered by the policy, including name, license number, driving history, and relationship to the policyholder.",
            "nullable": true,
            "example": ""
          },
          "AccidentHistory": {
            "type": "string",
            "description": "A record of any accidents involving the insured vehicle or drivers, including dates, damages, and claim amounts.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the base currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The currency code for the local currency of the premium amount.",
            "format": "double",
            "nullable": true
          },
          "HasRoadsideAssistance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes roadside assistance coverage.",
            "nullable": true
          },
          "HasRentalReimbursement": {
            "type": "boolean",
            "description": "Indicates whether the policy includes rental reimbursement coverage.",
            "nullable": true
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the auto insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the auto insurance policy.",
            "nullable": true
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the auto insurance policy.",
            "nullable": true,
            "example": ""
          },
          "GarageLocation": {
            "type": "string",
            "description": "The location where the vehicle is primarily garaged, which can affect the premium due to varying risks in different areas (e.g., urban vs. rural).",
            "nullable": true,
            "example": ""
          },
          "AnnualMileage": {
            "type": "integer",
            "description": "The estimated number of miles the vehicle is driven annually, which can affect the premium calculation.",
            "format": "int32",
            "nullable": true
          },
          "HasAntiTheftDiscount": {
            "type": "boolean",
            "description": "Indicates whether the vehicle has anti-theft devices installed, which might qualify the policyholder for a discount on their premium.",
            "nullable": true
          },
          "HasGoodDriverDiscount": {
            "type": "boolean",
            "description": "Indicates whether the policyholder qualifies for a discount based on a good driving record, often with no recent accidents or violations.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceAutoUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceAutoUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the home insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "PropertyPropertyAddress": {
            "type": "string",
            "description": "The full address of the property being insured, including street, city, state, and ZIP code.",
            "nullable": true,
            "example": ""
          },
          "PropertyCity": {
            "type": "string",
            "description": "The city where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyCountry": {
            "type": "string",
            "description": "The country where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyZipCode": {
            "type": "string",
            "description": "The ZIP or postal code of the insured property.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the base currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the local currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "PerilsCovered": {
            "type": "string",
            "description": "A list or description of the perils covered by the policy, such as fire, theft, vandalism, or natural disasters.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the home insurance policy.",
            "nullable": true
          },
          "HasFloodInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for flood damage, which is often a separate policy or rider.",
            "nullable": true
          },
          "HasEarthquakeInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for earthquake damage, which may also be a separate policy or rider.",
            "nullable": true
          },
          "Endorsement": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional coverage options or modifications to the standard policy, such as increased coverage for valuable items.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the home insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ConstructionType": {
            "type": "string",
            "description": "The type of construction of the home, such as brick, wood, or concrete.",
            "nullable": true,
            "example": ""
          },
          "YearBuilt": {
            "type": "integer",
            "description": "The year the home was built.",
            "format": "int32",
            "nullable": true
          },
          "RoofMaterial": {
            "type": "string",
            "description": "The material of the roof, such as asphalt shingles, metal, or tile.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the home insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "PropertyPropertyAddress": {
            "type": "string",
            "description": "The full address of the property being insured, including street, city, state, and ZIP code.",
            "nullable": true,
            "example": ""
          },
          "PropertyCity": {
            "type": "string",
            "description": "The city where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyCountry": {
            "type": "string",
            "description": "The country where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyZipCode": {
            "type": "string",
            "description": "The ZIP or postal code of the insured property.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the base currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the local currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "PerilsCovered": {
            "type": "string",
            "description": "A list or description of the perils covered by the policy, such as fire, theft, vandalism, or natural disasters.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the home insurance policy.",
            "nullable": true
          },
          "HasFloodInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for flood damage, which is often a separate policy or rider.",
            "nullable": true
          },
          "HasEarthquakeInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for earthquake damage, which may also be a separate policy or rider.",
            "nullable": true
          },
          "Endorsement": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional coverage options or modifications to the standard policy, such as increased coverage for valuable items.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the home insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ConstructionType": {
            "type": "string",
            "description": "The type of construction of the home, such as brick, wood, or concrete.",
            "nullable": true,
            "example": ""
          },
          "YearBuilt": {
            "type": "integer",
            "description": "The year the home was built.",
            "format": "int32",
            "nullable": true
          },
          "RoofMaterial": {
            "type": "string",
            "description": "The material of the roof, such as asphalt shingles, metal, or tile.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceHomeCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the home insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "PropertyPropertyAddress": {
            "type": "string",
            "description": "The full address of the property being insured, including street, city, state, and ZIP code.",
            "nullable": true,
            "example": ""
          },
          "PropertyCity": {
            "type": "string",
            "description": "The city where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyCountry": {
            "type": "string",
            "description": "The country where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyZipCode": {
            "type": "string",
            "description": "The ZIP or postal code of the insured property.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the base currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the local currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "PerilsCovered": {
            "type": "string",
            "description": "A list or description of the perils covered by the policy, such as fire, theft, vandalism, or natural disasters.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the home insurance policy.",
            "nullable": true
          },
          "HasFloodInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for flood damage, which is often a separate policy or rider.",
            "nullable": true
          },
          "HasEarthquakeInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for earthquake damage, which may also be a separate policy or rider.",
            "nullable": true
          },
          "Endorsement": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional coverage options or modifications to the standard policy, such as increased coverage for valuable items.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the home insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ConstructionType": {
            "type": "string",
            "description": "The type of construction of the home, such as brick, wood, or concrete.",
            "nullable": true,
            "example": ""
          },
          "YearBuilt": {
            "type": "integer",
            "description": "The year the home was built.",
            "format": "int32",
            "nullable": true
          },
          "RoofMaterial": {
            "type": "string",
            "description": "The material of the roof, such as asphalt shingles, metal, or tile.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceHomeDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the home insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "PropertyPropertyAddress": {
            "type": "string",
            "description": "The full address of the property being insured, including street, city, state, and ZIP code.",
            "nullable": true,
            "example": ""
          },
          "PropertyCity": {
            "type": "string",
            "description": "The city where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyCountry": {
            "type": "string",
            "description": "The country where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyZipCode": {
            "type": "string",
            "description": "The ZIP or postal code of the insured property.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the base currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the local currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "PerilsCovered": {
            "type": "string",
            "description": "A list or description of the perils covered by the policy, such as fire, theft, vandalism, or natural disasters.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the home insurance policy.",
            "nullable": true
          },
          "HasFloodInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for flood damage, which is often a separate policy or rider.",
            "nullable": true
          },
          "HasEarthquakeInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for earthquake damage, which may also be a separate policy or rider.",
            "nullable": true
          },
          "Endorsement": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional coverage options or modifications to the standard policy, such as increased coverage for valuable items.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the home insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ConstructionType": {
            "type": "string",
            "description": "The type of construction of the home, such as brick, wood, or concrete.",
            "nullable": true,
            "example": ""
          },
          "YearBuilt": {
            "type": "integer",
            "description": "The year the home was built.",
            "format": "int32",
            "nullable": true
          },
          "RoofMaterial": {
            "type": "string",
            "description": "The material of the roof, such as asphalt shingles, metal, or tile.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/InsuranceHomeDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceHomeDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the home insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "PropertyPropertyAddress": {
            "type": "string",
            "description": "The full address of the property being insured, including street, city, state, and ZIP code.",
            "nullable": true,
            "example": ""
          },
          "PropertyCity": {
            "type": "string",
            "description": "The city where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyCountry": {
            "type": "string",
            "description": "The country where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyZipCode": {
            "type": "string",
            "description": "The ZIP or postal code of the insured property.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the base currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the local currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "PerilsCovered": {
            "type": "string",
            "description": "A list or description of the perils covered by the policy, such as fire, theft, vandalism, or natural disasters.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the home insurance policy.",
            "nullable": true
          },
          "HasFloodInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for flood damage, which is often a separate policy or rider.",
            "nullable": true
          },
          "HasEarthquakeInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for earthquake damage, which may also be a separate policy or rider.",
            "nullable": true
          },
          "Endorsement": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional coverage options or modifications to the standard policy, such as increased coverage for valuable items.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the home insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ConstructionType": {
            "type": "string",
            "description": "The type of construction of the home, such as brick, wood, or concrete.",
            "nullable": true,
            "example": ""
          },
          "YearBuilt": {
            "type": "integer",
            "description": "The year the home was built.",
            "format": "int32",
            "nullable": true
          },
          "RoofMaterial": {
            "type": "string",
            "description": "The material of the roof, such as asphalt shingles, metal, or tile.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceHomeGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the home insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "PropertyPropertyAddress": {
            "type": "string",
            "description": "The full address of the property being insured, including street, city, state, and ZIP code.",
            "nullable": true,
            "example": ""
          },
          "PropertyCity": {
            "type": "string",
            "description": "The city where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyCountry": {
            "type": "string",
            "description": "The country where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyZipCode": {
            "type": "string",
            "description": "The ZIP or postal code of the insured property.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the base currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the local currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "PerilsCovered": {
            "type": "string",
            "description": "A list or description of the perils covered by the policy, such as fire, theft, vandalism, or natural disasters.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the home insurance policy.",
            "nullable": true
          },
          "HasFloodInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for flood damage, which is often a separate policy or rider.",
            "nullable": true
          },
          "HasEarthquakeInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for earthquake damage, which may also be a separate policy or rider.",
            "nullable": true
          },
          "Endorsement": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional coverage options or modifications to the standard policy, such as increased coverage for valuable items.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the home insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ConstructionType": {
            "type": "string",
            "description": "The type of construction of the home, such as brick, wood, or concrete.",
            "nullable": true,
            "example": ""
          },
          "YearBuilt": {
            "type": "integer",
            "description": "The year the home was built.",
            "format": "int32",
            "nullable": true
          },
          "RoofMaterial": {
            "type": "string",
            "description": "The material of the roof, such as asphalt shingles, metal, or tile.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the home insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-641b-4094-9bc4-b4918fd55bbe"
          },
          "PropertyPropertyAddress": {
            "type": "string",
            "description": "The full address of the property being insured, including street, city, state, and ZIP code.",
            "nullable": true,
            "example": ""
          },
          "PropertyCity": {
            "type": "string",
            "description": "The city where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyCountry": {
            "type": "string",
            "description": "The country where the insured property is located.",
            "nullable": true,
            "example": ""
          },
          "PropertyZipCode": {
            "type": "string",
            "description": "The ZIP or postal code of the insured property.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDwellingCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the dwelling coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPersonalPropertyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the personal property coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLiabilityCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the liability coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLossOfUseCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the loss of use coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium amount of the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the base currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeReplacementCost": {
            "type": "number",
            "description": "The amount in the local currency for the estimated cost to rebuild the home from scratch, often used as the basis for the coverage amount.",
            "format": "double",
            "nullable": true
          },
          "PerilsCovered": {
            "type": "string",
            "description": "A list or description of the perils covered by the policy, such as fire, theft, vandalism, or natural disasters.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the home insurance policy.",
            "nullable": true
          },
          "HasFloodInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for flood damage, which is often a separate policy or rider.",
            "nullable": true
          },
          "HasEarthquakeInsurance": {
            "type": "boolean",
            "description": "Indicates whether the policy includes coverage for earthquake damage, which may also be a separate policy or rider.",
            "nullable": true
          },
          "Endorsement": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional coverage options or modifications to the standard policy, such as increased coverage for valuable items.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the home insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the home insurance policy.",
            "nullable": true,
            "example": ""
          },
          "ConstructionType": {
            "type": "string",
            "description": "The type of construction of the home, such as brick, wood, or concrete.",
            "nullable": true,
            "example": ""
          },
          "YearBuilt": {
            "type": "integer",
            "description": "The year the home was built.",
            "format": "int32",
            "nullable": true
          },
          "RoofMaterial": {
            "type": "string",
            "description": "The material of the roof, such as asphalt shingles, metal, or tile.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceHomeUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceHomeUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the life insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "AccountId": {
            "type": "string",
            "description": "A foreign key linking the life insurance policy to an associated investment account, if applicable.",
            "nullable": true,
            "example": ""
          },
          "LifeInsuranceType": {
            "type": "string",
            "description": "The type of life insurance policy, such as term life, whole life, universal life, or variable life.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency for the death benefit provided by the policy, which is paid out to beneficiaries upon the insured persons death.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency for the death benefit provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "Beneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the death benefit from the life insurance policy.",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the base currency for the cash value that accumulates in certain types of life insurance policies, such as whole life or universal life.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the local currency for the cash value that accumulates in the policy.",
            "format": "double",
            "nullable": true
          },
          "PolicyTerm": {
            "type": "string",
            "description": "The length of time (e.g., 10 years, 20 years) for which the life insurance policy provides coverage.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the base currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the local currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "PolicyMaturityDate": {
            "type": "string",
            "description": "The date on which the life insurance policy matures, meaning the cash value is paid out if the insured is still living.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the life insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "AccountId": {
            "type": "string",
            "description": "A foreign key linking the life insurance policy to an associated investment account, if applicable.",
            "nullable": true,
            "example": ""
          },
          "LifeInsuranceType": {
            "type": "string",
            "description": "The type of life insurance policy, such as term life, whole life, universal life, or variable life.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency for the death benefit provided by the policy, which is paid out to beneficiaries upon the insured persons death.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency for the death benefit provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "Beneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the death benefit from the life insurance policy.",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the base currency for the cash value that accumulates in certain types of life insurance policies, such as whole life or universal life.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the local currency for the cash value that accumulates in the policy.",
            "format": "double",
            "nullable": true
          },
          "PolicyTerm": {
            "type": "string",
            "description": "The length of time (e.g., 10 years, 20 years) for which the life insurance policy provides coverage.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the base currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the local currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "PolicyMaturityDate": {
            "type": "string",
            "description": "The date on which the life insurance policy matures, meaning the cash value is paid out if the insured is still living.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceLifeCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the life insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "AccountId": {
            "type": "string",
            "description": "A foreign key linking the life insurance policy to an associated investment account, if applicable.",
            "nullable": true,
            "example": ""
          },
          "LifeInsuranceType": {
            "type": "string",
            "description": "The type of life insurance policy, such as term life, whole life, universal life, or variable life.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency for the death benefit provided by the policy, which is paid out to beneficiaries upon the insured persons death.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency for the death benefit provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "Beneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the death benefit from the life insurance policy.",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the base currency for the cash value that accumulates in certain types of life insurance policies, such as whole life or universal life.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the local currency for the cash value that accumulates in the policy.",
            "format": "double",
            "nullable": true
          },
          "PolicyTerm": {
            "type": "string",
            "description": "The length of time (e.g., 10 years, 20 years) for which the life insurance policy provides coverage.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the base currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the local currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "PolicyMaturityDate": {
            "type": "string",
            "description": "The date on which the life insurance policy matures, meaning the cash value is paid out if the insured is still living.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceLifeDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the life insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "AccountId": {
            "type": "string",
            "description": "A foreign key linking the life insurance policy to an associated investment account, if applicable.",
            "nullable": true,
            "example": ""
          },
          "LifeInsuranceType": {
            "type": "string",
            "description": "The type of life insurance policy, such as term life, whole life, universal life, or variable life.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency for the death benefit provided by the policy, which is paid out to beneficiaries upon the insured persons death.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency for the death benefit provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "Beneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the death benefit from the life insurance policy.",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the base currency for the cash value that accumulates in certain types of life insurance policies, such as whole life or universal life.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the local currency for the cash value that accumulates in the policy.",
            "format": "double",
            "nullable": true
          },
          "PolicyTerm": {
            "type": "string",
            "description": "The length of time (e.g., 10 years, 20 years) for which the life insurance policy provides coverage.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the base currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the local currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "PolicyMaturityDate": {
            "type": "string",
            "description": "The date on which the life insurance policy matures, meaning the cash value is paid out if the insured is still living.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/InsuranceLifeDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceLifeDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the life insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "AccountId": {
            "type": "string",
            "description": "A foreign key linking the life insurance policy to an associated investment account, if applicable.",
            "nullable": true,
            "example": ""
          },
          "LifeInsuranceType": {
            "type": "string",
            "description": "The type of life insurance policy, such as term life, whole life, universal life, or variable life.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency for the death benefit provided by the policy, which is paid out to beneficiaries upon the insured persons death.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency for the death benefit provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "Beneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the death benefit from the life insurance policy.",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the base currency for the cash value that accumulates in certain types of life insurance policies, such as whole life or universal life.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the local currency for the cash value that accumulates in the policy.",
            "format": "double",
            "nullable": true
          },
          "PolicyTerm": {
            "type": "string",
            "description": "The length of time (e.g., 10 years, 20 years) for which the life insurance policy provides coverage.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the base currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the local currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "PolicyMaturityDate": {
            "type": "string",
            "description": "The date on which the life insurance policy matures, meaning the cash value is paid out if the insured is still living.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceLifeGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the life insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "AccountId": {
            "type": "string",
            "description": "A foreign key linking the life insurance policy to an associated investment account, if applicable.",
            "nullable": true,
            "example": ""
          },
          "LifeInsuranceType": {
            "type": "string",
            "description": "The type of life insurance policy, such as term life, whole life, universal life, or variable life.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency for the death benefit provided by the policy, which is paid out to beneficiaries upon the insured persons death.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency for the death benefit provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "Beneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the death benefit from the life insurance policy.",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the base currency for the cash value that accumulates in certain types of life insurance policies, such as whole life or universal life.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the local currency for the cash value that accumulates in the policy.",
            "format": "double",
            "nullable": true
          },
          "PolicyTerm": {
            "type": "string",
            "description": "The length of time (e.g., 10 years, 20 years) for which the life insurance policy provides coverage.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the base currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the local currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "PolicyMaturityDate": {
            "type": "string",
            "description": "The date on which the life insurance policy matures, meaning the cash value is paid out if the insured is still living.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the life insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "AccountId": {
            "type": "string",
            "description": "A foreign key linking the life insurance policy to an associated investment account, if applicable.",
            "nullable": true,
            "example": ""
          },
          "LifeInsuranceType": {
            "type": "string",
            "description": "The type of life insurance policy, such as term life, whole life, universal life, or variable life.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency for the death benefit provided by the policy, which is paid out to beneficiaries upon the insured persons death.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeathBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency for the death benefit provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "Beneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the death benefit from the life insurance policy.",
            "nullable": true
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the base currency for the cash value that accumulates in certain types of life insurance policies, such as whole life or universal life.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCashValue": {
            "type": "number",
            "description": "The amount in the local currency for the cash value that accumulates in the policy.",
            "format": "double",
            "nullable": true
          },
          "PolicyTerm": {
            "type": "string",
            "description": "The length of time (e.g., 10 years, 20 years) for which the life insurance policy provides coverage.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the base currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyLoanAmount": {
            "type": "number",
            "description": "The amount in the local currency for any loans taken out against the policys cash value.",
            "format": "double",
            "nullable": true
          },
          "PolicyMaturityDate": {
            "type": "string",
            "description": "The date on which the life insurance policy matures, meaning the cash value is paid out if the insured is still living.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLifeUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceLifeUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the long-term care insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "BenefitPeriod": {
            "type": "string",
            "description": "The duration of time (e.g., 3 years, 5 years, lifetime) during which benefits will be paid under the policy.",
            "nullable": true,
            "example": ""
          },
          "EliminationPeriod": {
            "type": "string",
            "description": "The waiting period (e.g., 30 days, 90 days) before benefits begin after a qualifying event.",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "string",
            "description": "The types of care covered by the policy, such as nursing home care, in-home care, assisted living, etc.",
            "nullable": true,
            "example": ""
          },
          "HasInflationProtection": {
            "type": "boolean",
            "description": "Indicates whether the policy includes inflation protection, which increases benefit amounts over time to keep up with inflation.",
            "nullable": true
          },
          "HasNonforfeitureBenefit": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a nonforfeiture benefit, which ensures that some benefits remain available even if the policyholder stops paying premiums.",
            "nullable": true
          },
          "HasWaiverOfPremium": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a waiver of premium, allowing the policyholder to stop paying premiums once they begin receiving benefits.",
            "nullable": true
          },
          "Rider": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional options or coverage added to the standard policy, such as a return of premium rider.",
            "nullable": true
          },
          "BaseCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "HasCareCoordination": {
            "type": "boolean",
            "description": "Indicates whether the policy includes care coordination services, which help manage the care and services the policyholder receives.",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the long-term care insurance policy.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the long-term care insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the long-term care insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "BenefitPeriod": {
            "type": "string",
            "description": "The duration of time (e.g., 3 years, 5 years, lifetime) during which benefits will be paid under the policy.",
            "nullable": true,
            "example": ""
          },
          "EliminationPeriod": {
            "type": "string",
            "description": "The waiting period (e.g., 30 days, 90 days) before benefits begin after a qualifying event.",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "string",
            "description": "The types of care covered by the policy, such as nursing home care, in-home care, assisted living, etc.",
            "nullable": true,
            "example": ""
          },
          "HasInflationProtection": {
            "type": "boolean",
            "description": "Indicates whether the policy includes inflation protection, which increases benefit amounts over time to keep up with inflation.",
            "nullable": true
          },
          "HasNonforfeitureBenefit": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a nonforfeiture benefit, which ensures that some benefits remain available even if the policyholder stops paying premiums.",
            "nullable": true
          },
          "HasWaiverOfPremium": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a waiver of premium, allowing the policyholder to stop paying premiums once they begin receiving benefits.",
            "nullable": true
          },
          "Rider": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional options or coverage added to the standard policy, such as a return of premium rider.",
            "nullable": true
          },
          "BaseCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "HasCareCoordination": {
            "type": "boolean",
            "description": "Indicates whether the policy includes care coordination services, which help manage the care and services the policyholder receives.",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the long-term care insurance policy.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the long-term care insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceLongTermCareCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the long-term care insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "BenefitPeriod": {
            "type": "string",
            "description": "The duration of time (e.g., 3 years, 5 years, lifetime) during which benefits will be paid under the policy.",
            "nullable": true,
            "example": ""
          },
          "EliminationPeriod": {
            "type": "string",
            "description": "The waiting period (e.g., 30 days, 90 days) before benefits begin after a qualifying event.",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "string",
            "description": "The types of care covered by the policy, such as nursing home care, in-home care, assisted living, etc.",
            "nullable": true,
            "example": ""
          },
          "HasInflationProtection": {
            "type": "boolean",
            "description": "Indicates whether the policy includes inflation protection, which increases benefit amounts over time to keep up with inflation.",
            "nullable": true
          },
          "HasNonforfeitureBenefit": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a nonforfeiture benefit, which ensures that some benefits remain available even if the policyholder stops paying premiums.",
            "nullable": true
          },
          "HasWaiverOfPremium": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a waiver of premium, allowing the policyholder to stop paying premiums once they begin receiving benefits.",
            "nullable": true
          },
          "Rider": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional options or coverage added to the standard policy, such as a return of premium rider.",
            "nullable": true
          },
          "BaseCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "HasCareCoordination": {
            "type": "boolean",
            "description": "Indicates whether the policy includes care coordination services, which help manage the care and services the policyholder receives.",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the long-term care insurance policy.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the long-term care insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceLongTermCareDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the long-term care insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "BenefitPeriod": {
            "type": "string",
            "description": "The duration of time (e.g., 3 years, 5 years, lifetime) during which benefits will be paid under the policy.",
            "nullable": true,
            "example": ""
          },
          "EliminationPeriod": {
            "type": "string",
            "description": "The waiting period (e.g., 30 days, 90 days) before benefits begin after a qualifying event.",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "string",
            "description": "The types of care covered by the policy, such as nursing home care, in-home care, assisted living, etc.",
            "nullable": true,
            "example": ""
          },
          "HasInflationProtection": {
            "type": "boolean",
            "description": "Indicates whether the policy includes inflation protection, which increases benefit amounts over time to keep up with inflation.",
            "nullable": true
          },
          "HasNonforfeitureBenefit": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a nonforfeiture benefit, which ensures that some benefits remain available even if the policyholder stops paying premiums.",
            "nullable": true
          },
          "HasWaiverOfPremium": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a waiver of premium, allowing the policyholder to stop paying premiums once they begin receiving benefits.",
            "nullable": true
          },
          "Rider": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional options or coverage added to the standard policy, such as a return of premium rider.",
            "nullable": true
          },
          "BaseCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "HasCareCoordination": {
            "type": "boolean",
            "description": "Indicates whether the policy includes care coordination services, which help manage the care and services the policyholder receives.",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the long-term care insurance policy.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the long-term care insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/InsuranceLongTermCareDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceLongTermCareDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the long-term care insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "BenefitPeriod": {
            "type": "string",
            "description": "The duration of time (e.g., 3 years, 5 years, lifetime) during which benefits will be paid under the policy.",
            "nullable": true,
            "example": ""
          },
          "EliminationPeriod": {
            "type": "string",
            "description": "The waiting period (e.g., 30 days, 90 days) before benefits begin after a qualifying event.",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "string",
            "description": "The types of care covered by the policy, such as nursing home care, in-home care, assisted living, etc.",
            "nullable": true,
            "example": ""
          },
          "HasInflationProtection": {
            "type": "boolean",
            "description": "Indicates whether the policy includes inflation protection, which increases benefit amounts over time to keep up with inflation.",
            "nullable": true
          },
          "HasNonforfeitureBenefit": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a nonforfeiture benefit, which ensures that some benefits remain available even if the policyholder stops paying premiums.",
            "nullable": true
          },
          "HasWaiverOfPremium": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a waiver of premium, allowing the policyholder to stop paying premiums once they begin receiving benefits.",
            "nullable": true
          },
          "Rider": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional options or coverage added to the standard policy, such as a return of premium rider.",
            "nullable": true
          },
          "BaseCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "HasCareCoordination": {
            "type": "boolean",
            "description": "Indicates whether the policy includes care coordination services, which help manage the care and services the policyholder receives.",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the long-term care insurance policy.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the long-term care insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceLongTermCareGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the long-term care insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "BenefitPeriod": {
            "type": "string",
            "description": "The duration of time (e.g., 3 years, 5 years, lifetime) during which benefits will be paid under the policy.",
            "nullable": true,
            "example": ""
          },
          "EliminationPeriod": {
            "type": "string",
            "description": "The waiting period (e.g., 30 days, 90 days) before benefits begin after a qualifying event.",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "string",
            "description": "The types of care covered by the policy, such as nursing home care, in-home care, assisted living, etc.",
            "nullable": true,
            "example": ""
          },
          "HasInflationProtection": {
            "type": "boolean",
            "description": "Indicates whether the policy includes inflation protection, which increases benefit amounts over time to keep up with inflation.",
            "nullable": true
          },
          "HasNonforfeitureBenefit": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a nonforfeiture benefit, which ensures that some benefits remain available even if the policyholder stops paying premiums.",
            "nullable": true
          },
          "HasWaiverOfPremium": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a waiver of premium, allowing the policyholder to stop paying premiums once they begin receiving benefits.",
            "nullable": true
          },
          "Rider": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional options or coverage added to the standard policy, such as a return of premium rider.",
            "nullable": true
          },
          "BaseCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "HasCareCoordination": {
            "type": "boolean",
            "description": "Indicates whether the policy includes care coordination services, which help manage the care and services the policyholder receives.",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the long-term care insurance policy.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the long-term care insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the long-term care insurance policy with its general policy details.",
            "nullable": true,
            "example": "b5763bc8-321b-4094-9bc4-b4918fd55bbe"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the total coverage provided by the policy.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the base currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDailyBenefitAmount": {
            "type": "number",
            "description": "The amount in the local currency of the daily benefit amount, which is the amount paid out per day of care.",
            "format": "double",
            "nullable": true
          },
          "BenefitPeriod": {
            "type": "string",
            "description": "The duration of time (e.g., 3 years, 5 years, lifetime) during which benefits will be paid under the policy.",
            "nullable": true,
            "example": ""
          },
          "EliminationPeriod": {
            "type": "string",
            "description": "The waiting period (e.g., 30 days, 90 days) before benefits begin after a qualifying event.",
            "nullable": true,
            "example": ""
          },
          "CoverageType": {
            "type": "string",
            "description": "The types of care covered by the policy, such as nursing home care, in-home care, assisted living, etc.",
            "nullable": true,
            "example": ""
          },
          "HasInflationProtection": {
            "type": "boolean",
            "description": "Indicates whether the policy includes inflation protection, which increases benefit amounts over time to keep up with inflation.",
            "nullable": true
          },
          "HasNonforfeitureBenefit": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a nonforfeiture benefit, which ensures that some benefits remain available even if the policyholder stops paying premiums.",
            "nullable": true
          },
          "HasWaiverOfPremium": {
            "type": "boolean",
            "description": "Indicates whether the policy includes a waiver of premium, allowing the policyholder to stop paying premiums once they begin receiving benefits.",
            "nullable": true
          },
          "Rider": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Additional options or coverage added to the standard policy, such as a return of premium rider.",
            "nullable": true
          },
          "BaseCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMaximumLifetimeBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the maximum lifetime benefit, which is the total amount the policy will pay out over its lifetime.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the base currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyHomeModificationBenefit": {
            "type": "number",
            "description": "The amount in the local currency of the home modification benefit, which covers costs for modifying the home to accommodate the policyholder�s care needs.",
            "format": "double",
            "nullable": true
          },
          "HasCareCoordination": {
            "type": "boolean",
            "description": "Indicates whether the policy includes care coordination services, which help manage the care and services the policyholder receives.",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the long-term care insurance policy.",
            "nullable": true
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the long-term care insurance policy, including dates, amounts, and the status of each claim.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceLongTermCareUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceLongTermCareUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PolicyType": {
            "type": "string",
            "description": "Specifies the type of insurance policy (e.g., Life, Auto, Home, Umbrella). This helps categorize the policy for management and reporting purposes.",
            "nullable": true,
            "example": ""
          },
          "PolicyRenewalDate": {
            "type": "string",
            "description": "The date on which the insurance policy is set to renew. This field is important for tracking when the policy needs to be renewed to maintain coverage.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": ""
          },
          "PolicyStatus": {
            "type": "string",
            "description": "Indicates the current status of the policy, such as Active, Lapsed, Cancelled, or Expired. This helps in managing the lifecycle of the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyNumber": {
            "type": "string",
            "description": "A unique policy number assigned by the insurance company. This number is used to identify the policy in communications, claims processing, and other operations.",
            "nullable": true,
            "example": ""
          },
          "PolicyStartDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage begins. This field is crucial for determining the period during which the policy is active.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEndDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage ends. This marks the end of the policy�s coverage period unless it is renewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEffectiveDate": {
            "type": "string",
            "description": "The date when the insurance coverage officially takes effect. This might differ from the PolicyStartDate if there is a waiting period or specific conditions before coverage begins.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "InsuranceCompany": {
            "type": "string",
            "description": "The name or unique identifier of the insurance company that issued the policy. This field is used to track which insurer is responsible for underwriting and managing the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the insurance policy. This field identifies who the policyholder is, which is essential for customer service and legal purposes.",
            "nullable": true,
            "example": ""
          },
          "PaymentFrequency": {
            "type": "string",
            "description": "The frequency at which premium payments are due, such as Monthly, Quarterly, Semi-Annual, or Annual. This helps in managing the billing and payment cycles.",
            "nullable": true,
            "example": ""
          },
          "PolicyBeneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the policys benefits in the event of a claim. This is especially relevant for life insurance policies.",
            "nullable": true
          },
          "UnderwritingStatus": {
            "type": "string",
            "description": "Indicates the current status of the underwriting process, such as Pending, Approved, Rejected, or Under Review. This field tracks where the policy is in the approval process.",
            "nullable": true,
            "example": ""
          },
          "AgentName": {
            "type": "string",
            "description": "The name of the insurance agent or broker who facilitated the sale of the policy. This field helps in identifying the sales agent responsible for the policy.",
            "nullable": true,
            "example": ""
          },
          "AgentContactInfo": {
            "type": "string",
            "description": "Contact details of the insurance agent or broker associated with the policy, including phone numbers, email addresses, and office locations. This is important for communication and customer service.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the policy, including dates, amounts, and the status of each claim. This field helps in understanding the policys usage and any impact on future premiums.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the policy. This could include special instructions, conditions, or any other relevant information that does not fit into other fields.",
            "nullable": true,
            "example": ""
          },
          "RenewalOption": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Details on the policy�s renewal terms, including whether it renews automatically, any changes in premiums upon renewal, and conditions for renewal. This helps in managing the renewal process.",
            "nullable": true
          },
          "GracePeriod": {
            "type": "string",
            "description": "The amount of time after the premium due date during which the policyholder can still make a payment without the policy lapsing. This field helps in managing late payments and preventing policy cancellations.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions or circumstances that are not covered by the policy. This is important for setting expectations and for claims processing.",
            "nullable": true
          },
          "PolicyVersion": {
            "type": "string",
            "description": "Tracks the version of the policy document, which is useful if the terms and conditions of the policy are updated over time. This ensures that the correct version is referenced for claims and renewals.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PolicyType": {
            "type": "string",
            "description": "Specifies the type of insurance policy (e.g., Life, Auto, Home, Umbrella). This helps categorize the policy for management and reporting purposes.",
            "nullable": true,
            "example": ""
          },
          "PolicyRenewalDate": {
            "type": "string",
            "description": "The date on which the insurance policy is set to renew. This field is important for tracking when the policy needs to be renewed to maintain coverage.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": ""
          },
          "PolicyStatus": {
            "type": "string",
            "description": "Indicates the current status of the policy, such as Active, Lapsed, Cancelled, or Expired. This helps in managing the lifecycle of the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyNumber": {
            "type": "string",
            "description": "A unique policy number assigned by the insurance company. This number is used to identify the policy in communications, claims processing, and other operations.",
            "nullable": true,
            "example": ""
          },
          "PolicyStartDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage begins. This field is crucial for determining the period during which the policy is active.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEndDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage ends. This marks the end of the policy�s coverage period unless it is renewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEffectiveDate": {
            "type": "string",
            "description": "The date when the insurance coverage officially takes effect. This might differ from the PolicyStartDate if there is a waiting period or specific conditions before coverage begins.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "InsuranceCompany": {
            "type": "string",
            "description": "The name or unique identifier of the insurance company that issued the policy. This field is used to track which insurer is responsible for underwriting and managing the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the insurance policy. This field identifies who the policyholder is, which is essential for customer service and legal purposes.",
            "nullable": true,
            "example": ""
          },
          "PaymentFrequency": {
            "type": "string",
            "description": "The frequency at which premium payments are due, such as Monthly, Quarterly, Semi-Annual, or Annual. This helps in managing the billing and payment cycles.",
            "nullable": true,
            "example": ""
          },
          "PolicyBeneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the policys benefits in the event of a claim. This is especially relevant for life insurance policies.",
            "nullable": true
          },
          "UnderwritingStatus": {
            "type": "string",
            "description": "Indicates the current status of the underwriting process, such as Pending, Approved, Rejected, or Under Review. This field tracks where the policy is in the approval process.",
            "nullable": true,
            "example": ""
          },
          "AgentName": {
            "type": "string",
            "description": "The name of the insurance agent or broker who facilitated the sale of the policy. This field helps in identifying the sales agent responsible for the policy.",
            "nullable": true,
            "example": ""
          },
          "AgentContactInfo": {
            "type": "string",
            "description": "Contact details of the insurance agent or broker associated with the policy, including phone numbers, email addresses, and office locations. This is important for communication and customer service.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the policy, including dates, amounts, and the status of each claim. This field helps in understanding the policys usage and any impact on future premiums.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the policy. This could include special instructions, conditions, or any other relevant information that does not fit into other fields.",
            "nullable": true,
            "example": ""
          },
          "RenewalOption": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Details on the policy�s renewal terms, including whether it renews automatically, any changes in premiums upon renewal, and conditions for renewal. This helps in managing the renewal process.",
            "nullable": true
          },
          "GracePeriod": {
            "type": "string",
            "description": "The amount of time after the premium due date during which the policyholder can still make a payment without the policy lapsing. This field helps in managing late payments and preventing policy cancellations.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions or circumstances that are not covered by the policy. This is important for setting expectations and for claims processing.",
            "nullable": true
          },
          "PolicyVersion": {
            "type": "string",
            "description": "Tracks the version of the policy document, which is useful if the terms and conditions of the policy are updated over time. This ensures that the correct version is referenced for claims and renewals.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsurancePolicyCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PolicyType": {
            "type": "string",
            "description": "Specifies the type of insurance policy (e.g., Life, Auto, Home, Umbrella). This helps categorize the policy for management and reporting purposes.",
            "nullable": true,
            "example": ""
          },
          "PolicyRenewalDate": {
            "type": "string",
            "description": "The date on which the insurance policy is set to renew. This field is important for tracking when the policy needs to be renewed to maintain coverage.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": ""
          },
          "PolicyStatus": {
            "type": "string",
            "description": "Indicates the current status of the policy, such as Active, Lapsed, Cancelled, or Expired. This helps in managing the lifecycle of the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyNumber": {
            "type": "string",
            "description": "A unique policy number assigned by the insurance company. This number is used to identify the policy in communications, claims processing, and other operations.",
            "nullable": true,
            "example": ""
          },
          "PolicyStartDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage begins. This field is crucial for determining the period during which the policy is active.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEndDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage ends. This marks the end of the policy�s coverage period unless it is renewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEffectiveDate": {
            "type": "string",
            "description": "The date when the insurance coverage officially takes effect. This might differ from the PolicyStartDate if there is a waiting period or specific conditions before coverage begins.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "InsuranceCompany": {
            "type": "string",
            "description": "The name or unique identifier of the insurance company that issued the policy. This field is used to track which insurer is responsible for underwriting and managing the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the insurance policy. This field identifies who the policyholder is, which is essential for customer service and legal purposes.",
            "nullable": true,
            "example": ""
          },
          "PaymentFrequency": {
            "type": "string",
            "description": "The frequency at which premium payments are due, such as Monthly, Quarterly, Semi-Annual, or Annual. This helps in managing the billing and payment cycles.",
            "nullable": true,
            "example": ""
          },
          "PolicyBeneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the policys benefits in the event of a claim. This is especially relevant for life insurance policies.",
            "nullable": true
          },
          "UnderwritingStatus": {
            "type": "string",
            "description": "Indicates the current status of the underwriting process, such as Pending, Approved, Rejected, or Under Review. This field tracks where the policy is in the approval process.",
            "nullable": true,
            "example": ""
          },
          "AgentName": {
            "type": "string",
            "description": "The name of the insurance agent or broker who facilitated the sale of the policy. This field helps in identifying the sales agent responsible for the policy.",
            "nullable": true,
            "example": ""
          },
          "AgentContactInfo": {
            "type": "string",
            "description": "Contact details of the insurance agent or broker associated with the policy, including phone numbers, email addresses, and office locations. This is important for communication and customer service.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the policy, including dates, amounts, and the status of each claim. This field helps in understanding the policys usage and any impact on future premiums.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the policy. This could include special instructions, conditions, or any other relevant information that does not fit into other fields.",
            "nullable": true,
            "example": ""
          },
          "RenewalOption": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Details on the policy�s renewal terms, including whether it renews automatically, any changes in premiums upon renewal, and conditions for renewal. This helps in managing the renewal process.",
            "nullable": true
          },
          "GracePeriod": {
            "type": "string",
            "description": "The amount of time after the premium due date during which the policyholder can still make a payment without the policy lapsing. This field helps in managing late payments and preventing policy cancellations.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions or circumstances that are not covered by the policy. This is important for setting expectations and for claims processing.",
            "nullable": true
          },
          "PolicyVersion": {
            "type": "string",
            "description": "Tracks the version of the policy document, which is useful if the terms and conditions of the policy are updated over time. This ensures that the correct version is referenced for claims and renewals.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsurancePolicyDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PolicyType": {
            "type": "string",
            "description": "Specifies the type of insurance policy (e.g., Life, Auto, Home, Umbrella). This helps categorize the policy for management and reporting purposes.",
            "nullable": true,
            "example": ""
          },
          "PolicyRenewalDate": {
            "type": "string",
            "description": "The date on which the insurance policy is set to renew. This field is important for tracking when the policy needs to be renewed to maintain coverage.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": ""
          },
          "PolicyStatus": {
            "type": "string",
            "description": "Indicates the current status of the policy, such as Active, Lapsed, Cancelled, or Expired. This helps in managing the lifecycle of the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyNumber": {
            "type": "string",
            "description": "A unique policy number assigned by the insurance company. This number is used to identify the policy in communications, claims processing, and other operations.",
            "nullable": true,
            "example": ""
          },
          "PolicyStartDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage begins. This field is crucial for determining the period during which the policy is active.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEndDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage ends. This marks the end of the policy�s coverage period unless it is renewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEffectiveDate": {
            "type": "string",
            "description": "The date when the insurance coverage officially takes effect. This might differ from the PolicyStartDate if there is a waiting period or specific conditions before coverage begins.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "InsuranceCompany": {
            "type": "string",
            "description": "The name or unique identifier of the insurance company that issued the policy. This field is used to track which insurer is responsible for underwriting and managing the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the insurance policy. This field identifies who the policyholder is, which is essential for customer service and legal purposes.",
            "nullable": true,
            "example": ""
          },
          "PaymentFrequency": {
            "type": "string",
            "description": "The frequency at which premium payments are due, such as Monthly, Quarterly, Semi-Annual, or Annual. This helps in managing the billing and payment cycles.",
            "nullable": true,
            "example": ""
          },
          "PolicyBeneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the policys benefits in the event of a claim. This is especially relevant for life insurance policies.",
            "nullable": true
          },
          "UnderwritingStatus": {
            "type": "string",
            "description": "Indicates the current status of the underwriting process, such as Pending, Approved, Rejected, or Under Review. This field tracks where the policy is in the approval process.",
            "nullable": true,
            "example": ""
          },
          "AgentName": {
            "type": "string",
            "description": "The name of the insurance agent or broker who facilitated the sale of the policy. This field helps in identifying the sales agent responsible for the policy.",
            "nullable": true,
            "example": ""
          },
          "AgentContactInfo": {
            "type": "string",
            "description": "Contact details of the insurance agent or broker associated with the policy, including phone numbers, email addresses, and office locations. This is important for communication and customer service.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the policy, including dates, amounts, and the status of each claim. This field helps in understanding the policys usage and any impact on future premiums.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the policy. This could include special instructions, conditions, or any other relevant information that does not fit into other fields.",
            "nullable": true,
            "example": ""
          },
          "RenewalOption": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Details on the policy�s renewal terms, including whether it renews automatically, any changes in premiums upon renewal, and conditions for renewal. This helps in managing the renewal process.",
            "nullable": true
          },
          "GracePeriod": {
            "type": "string",
            "description": "The amount of time after the premium due date during which the policyholder can still make a payment without the policy lapsing. This field helps in managing late payments and preventing policy cancellations.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions or circumstances that are not covered by the policy. This is important for setting expectations and for claims processing.",
            "nullable": true
          },
          "PolicyVersion": {
            "type": "string",
            "description": "Tracks the version of the policy document, which is useful if the terms and conditions of the policy are updated over time. This ensures that the correct version is referenced for claims and renewals.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/InsurancePolicyDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsurancePolicyDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PolicyType": {
            "type": "string",
            "description": "Specifies the type of insurance policy (e.g., Life, Auto, Home, Umbrella). This helps categorize the policy for management and reporting purposes.",
            "nullable": true,
            "example": ""
          },
          "PolicyRenewalDate": {
            "type": "string",
            "description": "The date on which the insurance policy is set to renew. This field is important for tracking when the policy needs to be renewed to maintain coverage.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": ""
          },
          "PolicyStatus": {
            "type": "string",
            "description": "Indicates the current status of the policy, such as Active, Lapsed, Cancelled, or Expired. This helps in managing the lifecycle of the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyNumber": {
            "type": "string",
            "description": "A unique policy number assigned by the insurance company. This number is used to identify the policy in communications, claims processing, and other operations.",
            "nullable": true,
            "example": ""
          },
          "PolicyStartDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage begins. This field is crucial for determining the period during which the policy is active.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEndDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage ends. This marks the end of the policy�s coverage period unless it is renewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEffectiveDate": {
            "type": "string",
            "description": "The date when the insurance coverage officially takes effect. This might differ from the PolicyStartDate if there is a waiting period or specific conditions before coverage begins.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "InsuranceCompany": {
            "type": "string",
            "description": "The name or unique identifier of the insurance company that issued the policy. This field is used to track which insurer is responsible for underwriting and managing the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the insurance policy. This field identifies who the policyholder is, which is essential for customer service and legal purposes.",
            "nullable": true,
            "example": ""
          },
          "PaymentFrequency": {
            "type": "string",
            "description": "The frequency at which premium payments are due, such as Monthly, Quarterly, Semi-Annual, or Annual. This helps in managing the billing and payment cycles.",
            "nullable": true,
            "example": ""
          },
          "PolicyBeneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the policys benefits in the event of a claim. This is especially relevant for life insurance policies.",
            "nullable": true
          },
          "UnderwritingStatus": {
            "type": "string",
            "description": "Indicates the current status of the underwriting process, such as Pending, Approved, Rejected, or Under Review. This field tracks where the policy is in the approval process.",
            "nullable": true,
            "example": ""
          },
          "AgentName": {
            "type": "string",
            "description": "The name of the insurance agent or broker who facilitated the sale of the policy. This field helps in identifying the sales agent responsible for the policy.",
            "nullable": true,
            "example": ""
          },
          "AgentContactInfo": {
            "type": "string",
            "description": "Contact details of the insurance agent or broker associated with the policy, including phone numbers, email addresses, and office locations. This is important for communication and customer service.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the policy, including dates, amounts, and the status of each claim. This field helps in understanding the policys usage and any impact on future premiums.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the policy. This could include special instructions, conditions, or any other relevant information that does not fit into other fields.",
            "nullable": true,
            "example": ""
          },
          "RenewalOption": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Details on the policy�s renewal terms, including whether it renews automatically, any changes in premiums upon renewal, and conditions for renewal. This helps in managing the renewal process.",
            "nullable": true
          },
          "GracePeriod": {
            "type": "string",
            "description": "The amount of time after the premium due date during which the policyholder can still make a payment without the policy lapsing. This field helps in managing late payments and preventing policy cancellations.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions or circumstances that are not covered by the policy. This is important for setting expectations and for claims processing.",
            "nullable": true
          },
          "PolicyVersion": {
            "type": "string",
            "description": "Tracks the version of the policy document, which is useful if the terms and conditions of the policy are updated over time. This ensures that the correct version is referenced for claims and renewals.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsurancePolicyGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PolicyType": {
            "type": "string",
            "description": "Specifies the type of insurance policy (e.g., Life, Auto, Home, Umbrella). This helps categorize the policy for management and reporting purposes.",
            "nullable": true,
            "example": ""
          },
          "PolicyRenewalDate": {
            "type": "string",
            "description": "The date on which the insurance policy is set to renew. This field is important for tracking when the policy needs to be renewed to maintain coverage.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": ""
          },
          "PolicyStatus": {
            "type": "string",
            "description": "Indicates the current status of the policy, such as Active, Lapsed, Cancelled, or Expired. This helps in managing the lifecycle of the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyNumber": {
            "type": "string",
            "description": "A unique policy number assigned by the insurance company. This number is used to identify the policy in communications, claims processing, and other operations.",
            "nullable": true,
            "example": ""
          },
          "PolicyStartDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage begins. This field is crucial for determining the period during which the policy is active.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEndDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage ends. This marks the end of the policy�s coverage period unless it is renewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEffectiveDate": {
            "type": "string",
            "description": "The date when the insurance coverage officially takes effect. This might differ from the PolicyStartDate if there is a waiting period or specific conditions before coverage begins.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "InsuranceCompany": {
            "type": "string",
            "description": "The name or unique identifier of the insurance company that issued the policy. This field is used to track which insurer is responsible for underwriting and managing the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the insurance policy. This field identifies who the policyholder is, which is essential for customer service and legal purposes.",
            "nullable": true,
            "example": ""
          },
          "PaymentFrequency": {
            "type": "string",
            "description": "The frequency at which premium payments are due, such as Monthly, Quarterly, Semi-Annual, or Annual. This helps in managing the billing and payment cycles.",
            "nullable": true,
            "example": ""
          },
          "PolicyBeneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the policys benefits in the event of a claim. This is especially relevant for life insurance policies.",
            "nullable": true
          },
          "UnderwritingStatus": {
            "type": "string",
            "description": "Indicates the current status of the underwriting process, such as Pending, Approved, Rejected, or Under Review. This field tracks where the policy is in the approval process.",
            "nullable": true,
            "example": ""
          },
          "AgentName": {
            "type": "string",
            "description": "The name of the insurance agent or broker who facilitated the sale of the policy. This field helps in identifying the sales agent responsible for the policy.",
            "nullable": true,
            "example": ""
          },
          "AgentContactInfo": {
            "type": "string",
            "description": "Contact details of the insurance agent or broker associated with the policy, including phone numbers, email addresses, and office locations. This is important for communication and customer service.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the policy, including dates, amounts, and the status of each claim. This field helps in understanding the policys usage and any impact on future premiums.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the policy. This could include special instructions, conditions, or any other relevant information that does not fit into other fields.",
            "nullable": true,
            "example": ""
          },
          "RenewalOption": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Details on the policy�s renewal terms, including whether it renews automatically, any changes in premiums upon renewal, and conditions for renewal. This helps in managing the renewal process.",
            "nullable": true
          },
          "GracePeriod": {
            "type": "string",
            "description": "The amount of time after the premium due date during which the policyholder can still make a payment without the policy lapsing. This field helps in managing late payments and preventing policy cancellations.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions or circumstances that are not covered by the policy. This is important for setting expectations and for claims processing.",
            "nullable": true
          },
          "PolicyVersion": {
            "type": "string",
            "description": "Tracks the version of the policy document, which is useful if the terms and conditions of the policy are updated over time. This ensures that the correct version is referenced for claims and renewals.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PolicyType": {
            "type": "string",
            "description": "Specifies the type of insurance policy (e.g., Life, Auto, Home, Umbrella). This helps categorize the policy for management and reporting purposes.",
            "nullable": true,
            "example": ""
          },
          "PolicyRenewalDate": {
            "type": "string",
            "description": "The date on which the insurance policy is set to renew. This field is important for tracking when the policy needs to be renewed to maintain coverage.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Identifier of the associated Account.",
            "nullable": true,
            "example": ""
          },
          "PolicyStatus": {
            "type": "string",
            "description": "Indicates the current status of the policy, such as Active, Lapsed, Cancelled, or Expired. This helps in managing the lifecycle of the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyNumber": {
            "type": "string",
            "description": "A unique policy number assigned by the insurance company. This number is used to identify the policy in communications, claims processing, and other operations.",
            "nullable": true,
            "example": ""
          },
          "PolicyStartDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage begins. This field is crucial for determining the period during which the policy is active.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEndDate": {
            "type": "string",
            "description": "The date on which the insurance policy coverage ends. This marks the end of the policy�s coverage period unless it is renewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PolicyEffectiveDate": {
            "type": "string",
            "description": "The date when the insurance coverage officially takes effect. This might differ from the PolicyStartDate if there is a waiting period or specific conditions before coverage begins.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "InsuranceCompany": {
            "type": "string",
            "description": "The name or unique identifier of the insurance company that issued the policy. This field is used to track which insurer is responsible for underwriting and managing the policy.",
            "nullable": true,
            "example": ""
          },
          "PolicyholderName": {
            "type": "string",
            "description": "The name of the individual or entity that owns the insurance policy. This field identifies who the policyholder is, which is essential for customer service and legal purposes.",
            "nullable": true,
            "example": ""
          },
          "PaymentFrequency": {
            "type": "string",
            "description": "The frequency at which premium payments are due, such as Monthly, Quarterly, Semi-Annual, or Annual. This helps in managing the billing and payment cycles.",
            "nullable": true,
            "example": ""
          },
          "PolicyBeneficiary": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of individuals or entities designated to receive the policys benefits in the event of a claim. This is especially relevant for life insurance policies.",
            "nullable": true
          },
          "UnderwritingStatus": {
            "type": "string",
            "description": "Indicates the current status of the underwriting process, such as Pending, Approved, Rejected, or Under Review. This field tracks where the policy is in the approval process.",
            "nullable": true,
            "example": ""
          },
          "AgentName": {
            "type": "string",
            "description": "The name of the insurance agent or broker who facilitated the sale of the policy. This field helps in identifying the sales agent responsible for the policy.",
            "nullable": true,
            "example": ""
          },
          "AgentContactInfo": {
            "type": "string",
            "description": "Contact details of the insurance agent or broker associated with the policy, including phone numbers, email addresses, and office locations. This is important for communication and customer service.",
            "nullable": true,
            "example": ""
          },
          "ClaimsHistory": {
            "type": "string",
            "description": "A record of any claims made against the policy, including dates, amounts, and the status of each claim. This field helps in understanding the policys usage and any impact on future premiums.",
            "nullable": true,
            "example": ""
          },
          "PolicyNotes": {
            "type": "string",
            "description": "A field for any additional notes or comments related to the policy. This could include special instructions, conditions, or any other relevant information that does not fit into other fields.",
            "nullable": true,
            "example": ""
          },
          "RenewalOption": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Details on the policy�s renewal terms, including whether it renews automatically, any changes in premiums upon renewal, and conditions for renewal. This helps in managing the renewal process.",
            "nullable": true
          },
          "GracePeriod": {
            "type": "string",
            "description": "The amount of time after the premium due date during which the policyholder can still make a payment without the policy lapsing. This field helps in managing late payments and preventing policy cancellations.",
            "nullable": true,
            "example": ""
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions or circumstances that are not covered by the policy. This is important for setting expectations and for claims processing.",
            "nullable": true
          },
          "PolicyVersion": {
            "type": "string",
            "description": "Tracks the version of the policy document, which is useful if the terms and conditions of the policy are updated over time. This ensures that the correct version is referenced for claims and renewals.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsurancePolicyUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsurancePolicyUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the umbrella insurance policy with its general policy details.",
            "nullable": true,
            "example": "24e67hsf-b154-4731-v43w-92d346ythfa"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the coverage limit, which is the maximum amount the policy will pay out.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasExcessLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes excess liability coverage, which provides additional coverage beyond the limits of underlying policies.",
            "nullable": true
          },
          "CoverageTerritory": {
            "type": "string",
            "description": "The geographical area where the umbrella insurance coverage applies, such as worldwide or specific regions.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingPolicy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of underlying policies that the umbrella insurance supplements, such as auto or home insurance policies.",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible that the policyholder must pay before the umbrella coverage kicks in.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible.",
            "format": "double",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the umbrella insurance policy.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the umbrella insurance policy with its general policy details.",
            "nullable": true,
            "example": "24e67hsf-b154-4731-v43w-92d346ythfa"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the coverage limit, which is the maximum amount the policy will pay out.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasExcessLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes excess liability coverage, which provides additional coverage beyond the limits of underlying policies.",
            "nullable": true
          },
          "CoverageTerritory": {
            "type": "string",
            "description": "The geographical area where the umbrella insurance coverage applies, such as worldwide or specific regions.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingPolicy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of underlying policies that the umbrella insurance supplements, such as auto or home insurance policies.",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible that the policyholder must pay before the umbrella coverage kicks in.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible.",
            "format": "double",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the umbrella insurance policy.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceUmbrellaCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the umbrella insurance policy with its general policy details.",
            "nullable": true,
            "example": "24e67hsf-b154-4731-v43w-92d346ythfa"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the coverage limit, which is the maximum amount the policy will pay out.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasExcessLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes excess liability coverage, which provides additional coverage beyond the limits of underlying policies.",
            "nullable": true
          },
          "CoverageTerritory": {
            "type": "string",
            "description": "The geographical area where the umbrella insurance coverage applies, such as worldwide or specific regions.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingPolicy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of underlying policies that the umbrella insurance supplements, such as auto or home insurance policies.",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible that the policyholder must pay before the umbrella coverage kicks in.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible.",
            "format": "double",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the umbrella insurance policy.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceUmbrellaDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the umbrella insurance policy with its general policy details.",
            "nullable": true,
            "example": "24e67hsf-b154-4731-v43w-92d346ythfa"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the coverage limit, which is the maximum amount the policy will pay out.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasExcessLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes excess liability coverage, which provides additional coverage beyond the limits of underlying policies.",
            "nullable": true
          },
          "CoverageTerritory": {
            "type": "string",
            "description": "The geographical area where the umbrella insurance coverage applies, such as worldwide or specific regions.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingPolicy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of underlying policies that the umbrella insurance supplements, such as auto or home insurance policies.",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible that the policyholder must pay before the umbrella coverage kicks in.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible.",
            "format": "double",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the umbrella insurance policy.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/InsuranceUmbrellaDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceUmbrellaDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the umbrella insurance policy with its general policy details.",
            "nullable": true,
            "example": "24e67hsf-b154-4731-v43w-92d346ythfa"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the coverage limit, which is the maximum amount the policy will pay out.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasExcessLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes excess liability coverage, which provides additional coverage beyond the limits of underlying policies.",
            "nullable": true
          },
          "CoverageTerritory": {
            "type": "string",
            "description": "The geographical area where the umbrella insurance coverage applies, such as worldwide or specific regions.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingPolicy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of underlying policies that the umbrella insurance supplements, such as auto or home insurance policies.",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible that the policyholder must pay before the umbrella coverage kicks in.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible.",
            "format": "double",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the umbrella insurance policy.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceUmbrellaGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the umbrella insurance policy with its general policy details.",
            "nullable": true,
            "example": "24e67hsf-b154-4731-v43w-92d346ythfa"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the coverage limit, which is the maximum amount the policy will pay out.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasExcessLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes excess liability coverage, which provides additional coverage beyond the limits of underlying policies.",
            "nullable": true
          },
          "CoverageTerritory": {
            "type": "string",
            "description": "The geographical area where the umbrella insurance coverage applies, such as worldwide or specific regions.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingPolicy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of underlying policies that the umbrella insurance supplements, such as auto or home insurance policies.",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible that the policyholder must pay before the umbrella coverage kicks in.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible.",
            "format": "double",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the umbrella insurance policy.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "InsurancePolicyId": {
            "type": "string",
            "description": "A foreign key linking to the general InsurancePolicy entity. This associates the umbrella insurance policy with its general policy details.",
            "nullable": true,
            "example": "24e67hsf-b154-4731-v43w-92d346ythfa"
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the base currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency code (e.g., USD, EUR) for the local currency in which the financial amounts are denominated.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the base currency for the coverage limit, which is the maximum amount the policy will pay out.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCoverage": {
            "type": "number",
            "description": "The amount in the local currency for the coverage limit.",
            "format": "double",
            "nullable": true
          },
          "HasExcessLiabilityCoverage": {
            "type": "boolean",
            "description": "Indicates whether the policy includes excess liability coverage, which provides additional coverage beyond the limits of underlying policies.",
            "nullable": true
          },
          "CoverageTerritory": {
            "type": "string",
            "description": "The geographical area where the umbrella insurance coverage applies, such as worldwide or specific regions.",
            "nullable": true,
            "example": ""
          },
          "BaseCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the base currency for the premium paid by the policyholder, either monthly, quarterly, or annually.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPremiumAmount": {
            "type": "number",
            "description": "The amount in the local currency for the premium paid by the policyholder.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingPolicy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of underlying policies that the umbrella insurance supplements, such as auto or home insurance policies.",
            "nullable": true
          },
          "BaseCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the base currency for the deductible that the policyholder must pay before the umbrella coverage kicks in.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDeductibleAmount": {
            "type": "number",
            "description": "The amount in the local currency for the deductible.",
            "format": "double",
            "nullable": true
          },
          "CoverageExclusion": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "Specific conditions, risks, or circumstances that are not covered by the umbrella insurance policy.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was created.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "CreatedBy": {
            "type": "string",
            "description": "The user or system that created the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedBy": {
            "type": "string",
            "description": "The user or system that last updated the insurance.",
            "nullable": true,
            "example": ""
          },
          "UpdatedOn": {
            "type": "string",
            "description": "The date and time when the insurance was last updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedOn": {
            "type": "string",
            "description": "The date and time when the insurance was updated.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeletedBy": {
            "type": "string",
            "description": "The user or system that updated the insurance.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "InsuranceUmbrellaUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/InsuranceUmbrellaUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "JsonNode": {
        "type": "object",
        "properties": {
          "Options": {
            "$ref": "#/components/schemas/JsonNodeOptions"
          },
          "Parent": {
            "$ref": "#/components/schemas/JsonNode"
          },
          "Root": {
            "$ref": "#/components/schemas/JsonNode"
          }
        },
        "additionalProperties": false
      },
      "JsonNodeOptions": {
        "type": "object",
        "properties": {
          "PropertyNameCaseInsensitive": {
            "type": "boolean"
          }
        },
        "additionalProperties": false
      },
      "JsonNodePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/JsonNode"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "JsonObjectQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "type": "object",
              "additionalProperties": {
                "$ref": "#/components/schemas/JsonNode"
              }
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "JsonObjectQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/JsonObjectQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "JwtConfig": {
        "required": [
          "Audience",
          "Expiration",
          "Id",
          "PrivateKey",
          "PubKey"
        ],
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Audience": {
            "type": "string",
            "nullable": true
          },
          "PubKey": {
            "type": "string",
            "nullable": true
          },
          "PrivateKey": {
            "type": "string",
            "nullable": true
          },
          "Expiration": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "KeycloakConfig": {
        "type": "object",
        "properties": {
          "ClientId": {
            "type": "string",
            "nullable": true
          },
          "ClientSecret": {
            "type": "string",
            "nullable": true
          },
          "Realm": {
            "type": "string",
            "nullable": true
          },
          "Url": {
            "type": "string",
            "nullable": true
          },
          "Audience": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "LatestUserNotificationsByClassificationDto": {
        "required": [
          "Email"
        ],
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "NotificationClassification": {
            "type": "string",
            "nullable": true
          },
          "SentOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PortalAcceptanceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "LatestUserNotificationsByClassificationDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/LatestUserNotificationsByClassificationDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "LatestUserNotificationsByClassificationDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/LatestUserNotificationsByClassificationDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "LatestUserNotificationsByClassificationQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "LoginUserRequest": {
        "type": "object",
        "properties": {
          "Email": {
            "type": "string",
            "nullable": true
          },
          "Password": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "LoginUserResponse": {
        "type": "object",
        "properties": {
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Tenant": {
            "type": "string",
            "nullable": true
          },
          "Token": {
            "type": "string",
            "nullable": true
          },
          "NatsJwt": {
            "type": "string",
            "nullable": true
          },
          "NatsSeed": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MenuUpdateAssignmentRequest": {
        "type": "object",
        "properties": {
          "MenuId": {
            "type": "string",
            "nullable": true
          },
          "UserIds": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MenuUpdateAssignmentResponse": {
        "type": "object",
        "additionalProperties": false
      },
      "MenuUpdateAssignmentResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/MenuUpdateAssignmentResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationCreateResetPasswordKeycloakBulkRequest": {
        "type": "object",
        "properties": {
          "Requests": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/MigrationCreateResetPasswordKeycloakRequest"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationCreateResetPasswordKeycloakBulkResponse": {
        "type": "object",
        "properties": {
          "Results": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/MigrationCreateResetPasswordKeycloakResponsePlatformResult"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationCreateResetPasswordKeycloakBulkResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/MigrationCreateResetPasswordKeycloakBulkResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationCreateResetPasswordKeycloakRequest": {
        "type": "object",
        "properties": {
          "Role": {
            "type": "string",
            "nullable": true
          },
          "Emails": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Password": {
            "type": "string",
            "nullable": true
          },
          "IsTemporary": {
            "type": "boolean"
          }
        },
        "additionalProperties": false
      },
      "MigrationCreateResetPasswordKeycloakResponse": {
        "type": "object",
        "properties": {
          "migrationResults": {
            "type": "object",
            "additionalProperties": {
              "type": "boolean"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationCreateResetPasswordKeycloakResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/MigrationCreateResetPasswordKeycloakResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationCreateUserActionKeycloakRequest": {
        "type": "object",
        "properties": {
          "Emails": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationCreateUserActionKeycloakResponse": {
        "type": "object",
        "properties": {
          "migrationResults": {
            "type": "object",
            "additionalProperties": {
              "type": "boolean"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationCreateUserActionKeycloakResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/MigrationCreateUserActionKeycloakResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationCreateUserKeycloakRequest": {
        "type": "object",
        "properties": {
          "emails": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationCreateUserKeycloakResponse": {
        "type": "object",
        "properties": {
          "migrationResults": {
            "type": "object",
            "additionalProperties": {
              "type": "boolean"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationCreateUserKeycloakResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/MigrationCreateUserKeycloakResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationUpdateUserKeycloakRequest": {
        "type": "object",
        "properties": {
          "emails": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationUpdateUserKeycloakResponse": {
        "type": "object",
        "properties": {
          "migrationResults": {
            "type": "object",
            "additionalProperties": {
              "type": "boolean"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MigrationUpdateUserKeycloakResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/MigrationUpdateUserKeycloakResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AccountId": {
            "type": "integer",
            "format": "int64",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountNumber": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountShortName": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "nullable": true
          },
          "CountryOfAsset": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceNumber1": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber2": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber3": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceDate1": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate2": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate3": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "BaseCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyCode": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyCode": {
            "type": "string",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedTradingGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDNetProfitLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorId": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "string",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AccountId": {
            "type": "integer",
            "format": "int64",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountNumber": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountShortName": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "nullable": true
          },
          "CountryOfAsset": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceNumber1": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber2": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber3": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceDate1": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate2": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate3": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "BaseCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyCode": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyCode": {
            "type": "string",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedTradingGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDNetProfitLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorId": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "string",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/MutableAssetCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AccountId": {
            "type": "integer",
            "format": "int64",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountNumber": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountShortName": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "nullable": true
          },
          "CountryOfAsset": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceNumber1": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber2": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber3": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceDate1": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate2": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate3": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "BaseCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyCode": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyCode": {
            "type": "string",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedTradingGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDNetProfitLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorId": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "string",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/MutableAssetDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AccountId": {
            "type": "integer",
            "format": "int64",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountNumber": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountShortName": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "nullable": true
          },
          "CountryOfAsset": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceNumber1": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber2": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber3": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceDate1": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate2": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate3": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "BaseCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyCode": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyCode": {
            "type": "string",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedTradingGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDNetProfitLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorId": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "string",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/MutableAssetDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/MutableAssetDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AccountId": {
            "type": "integer",
            "format": "int64",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountNumber": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountShortName": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "nullable": true
          },
          "CountryOfAsset": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceNumber1": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber2": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber3": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceDate1": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate2": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate3": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "BaseCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyCode": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyCode": {
            "type": "string",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedTradingGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDNetProfitLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorId": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "string",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/MutableAssetGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "integer",
            "format": "int64",
            "nullable": true
          },
          "UserId": {
            "type": "string",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean"
          }
        },
        "additionalProperties": false
      },
      "MutableAssetUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AccountId": {
            "type": "integer",
            "format": "int64",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountNumber": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountShortName": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "nullable": true
          },
          "CountryOfAsset": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceNumber1": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber2": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber3": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceDate1": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate2": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate3": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "BaseCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyCode": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyCode": {
            "type": "string",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedTradingGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDNetProfitLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorId": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "string",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AccountId": {
            "type": "integer",
            "format": "int64",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountNumber": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountName": {
            "type": "string",
            "nullable": true
          },
          "FinancialAccountShortName": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "nullable": true
          },
          "CountryOfAsset": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableClassification3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText1": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText2": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceText3": {
            "type": "string",
            "nullable": true
          },
          "ConfigurableReferenceNumber1": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber2": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceNumber3": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ConfigurableReferenceDate1": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate2": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ConfigurableReferenceDate3": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "BaseCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyITDChangePrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyCode": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyCode": {
            "type": "string",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "nullable": true
          },
          "BaseCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "LocalCcyEndMarketValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndAccruedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedDividendIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedInterestExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherIncome": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedOtherExpense": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDRealizedTradingGainLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "BaseCcyITDNetProfitLoss": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorId": {
            "type": "string",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "string",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "string",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "MutableAssetUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/MutableAssetUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Configuration": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Configuration": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NewsConfigurationCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Configuration": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NewsConfigurationDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Configuration": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/NewsConfigurationDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NewsConfigurationDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationFilter": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Configuration": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Filter": {
            "$ref": "#/components/schemas/NewsConfigurationFilter"
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Configuration": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NewsConfigurationGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationMergeRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Configuration": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationMergeResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Configuration": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NewsConfigurationMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "$ref": "#/components/schemas/NewsConfigurationFilter"
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Configuration": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Configuration": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NewsConfigurationUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NewsConfigurationUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NoteDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "NoteType": {
            "$ref": "#/components/schemas/NoteType"
          },
          "Text": {
            "type": "string",
            "nullable": true
          },
          "File": {
            "type": "string",
            "nullable": true
          },
          "Bucket": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NoteType": {
        "enum": [
          0,
          1,
          2
        ],
        "type": "integer",
        "format": "int32"
      },
      "NotificationCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "The name of the notification rule",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "Description for the notification rule",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipientId": {
            "type": "string",
            "description": "Identifier for the recipient details for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipient": {
            "$ref": "#/components/schemas/NotificationRecipientDto"
          },
          "NotificationTriggerId": {
            "type": "string",
            "description": "Identifier for the trigger for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationTrigger": {
            "$ref": "#/components/schemas/NotificationTriggerDto"
          },
          "NotificationScheduleId": {
            "type": "string",
            "description": "Identifier for the selected schedule e.g. 'Weekly, Mon-Fri @ 6am EST'",
            "nullable": true,
            "example": ""
          },
          "NotificationSchedule": {
            "$ref": "#/components/schemas/NotificationScheduleDto"
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal identifier for the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "DeliveryMechanism": {
            "type": "string",
            "description": "Delivery mechanism - Email, SMS text.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the rule is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "The name of the notification rule",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "Description for the notification rule",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipientId": {
            "type": "string",
            "description": "Identifier for the recipient details for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipient": {
            "$ref": "#/components/schemas/NotificationRecipientDto"
          },
          "NotificationTriggerId": {
            "type": "string",
            "description": "Identifier for the trigger for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationTrigger": {
            "$ref": "#/components/schemas/NotificationTriggerDto"
          },
          "NotificationScheduleId": {
            "type": "string",
            "description": "Identifier for the selected schedule e.g. 'Weekly, Mon-Fri @ 6am EST'",
            "nullable": true,
            "example": ""
          },
          "NotificationSchedule": {
            "$ref": "#/components/schemas/NotificationScheduleDto"
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal identifier for the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "DeliveryMechanism": {
            "type": "string",
            "description": "Delivery mechanism - Email, SMS text.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the rule is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "The name of the notification rule",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "Description for the notification rule",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipientId": {
            "type": "string",
            "description": "Identifier for the recipient details for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipient": {
            "$ref": "#/components/schemas/NotificationRecipientDto"
          },
          "NotificationTriggerId": {
            "type": "string",
            "description": "Identifier for the trigger for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationTrigger": {
            "$ref": "#/components/schemas/NotificationTriggerDto"
          },
          "NotificationScheduleId": {
            "type": "string",
            "description": "Identifier for the selected schedule e.g. 'Weekly, Mon-Fri @ 6am EST'",
            "nullable": true,
            "example": ""
          },
          "NotificationSchedule": {
            "$ref": "#/components/schemas/NotificationScheduleDto"
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal identifier for the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "DeliveryMechanism": {
            "type": "string",
            "description": "Delivery mechanism - Email, SMS text.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the rule is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "The name of the notification rule",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "Description for the notification rule",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipientId": {
            "type": "string",
            "description": "Identifier for the recipient details for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipient": {
            "$ref": "#/components/schemas/NotificationRecipientDto"
          },
          "NotificationTriggerId": {
            "type": "string",
            "description": "Identifier for the trigger for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationTrigger": {
            "$ref": "#/components/schemas/NotificationTriggerDto"
          },
          "NotificationScheduleId": {
            "type": "string",
            "description": "Identifier for the selected schedule e.g. 'Weekly, Mon-Fri @ 6am EST'",
            "nullable": true,
            "example": ""
          },
          "NotificationSchedule": {
            "$ref": "#/components/schemas/NotificationScheduleDto"
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal identifier for the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "DeliveryMechanism": {
            "type": "string",
            "description": "Delivery mechanism - Email, SMS text.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the rule is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/NotificationDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunMessageId": {
            "type": "string",
            "description": "Identifier for associated parent notification run message record.",
            "nullable": true,
            "example": ""
          },
          "NotificationRunMessage": {
            "$ref": "#/components/schemas/NotificationRunMessageDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language that the notification message was sent in.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "string",
            "description": "The entity type for the recipient - Client, User, Employeee etc.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The identfier of the recipient of the associated entity.",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient of the notification.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityType": {
            "type": "string",
            "description": "Notification entity type - example Document, Holding, Transaction.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityId": {
            "type": "string",
            "description": "Unique identifier for the notification entity record. e.g. Id for a specific Document record.",
            "nullable": true,
            "example": ""
          },
          "NotificationObjectName": {
            "type": "string",
            "description": "Name of the object for which the notification is being sent, e.g. document name - 'Client Statement Q4 2024', transaction details - 'Contribution - $50,000'.",
            "nullable": true,
            "example": ""
          },
          "NotificationClassification": {
            "type": "string",
            "description": "The classification of document for which the notification was sent e.g. Statement, Tax Document etc.",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Unique identifier for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "AccountShortName": {
            "type": "string",
            "description": "Account shortname for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Notification destination type e.g. EmailAddress, PhoneNumber, Mail Address.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "The Email address, phone number or mailing address for the notification.",
            "nullable": true,
            "example": ""
          },
          "SentOn": {
            "type": "string",
            "description": "The date and timestamp of that the notification was sent.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "SentBy": {
            "type": "string",
            "description": "User Id that initiated the notifcation event, may be system user or an end user.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunMessageId": {
            "type": "string",
            "description": "Identifier for associated parent notification run message record.",
            "nullable": true,
            "example": ""
          },
          "NotificationRunMessage": {
            "$ref": "#/components/schemas/NotificationRunMessageDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language that the notification message was sent in.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "string",
            "description": "The entity type for the recipient - Client, User, Employeee etc.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The identfier of the recipient of the associated entity.",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient of the notification.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityType": {
            "type": "string",
            "description": "Notification entity type - example Document, Holding, Transaction.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityId": {
            "type": "string",
            "description": "Unique identifier for the notification entity record. e.g. Id for a specific Document record.",
            "nullable": true,
            "example": ""
          },
          "NotificationObjectName": {
            "type": "string",
            "description": "Name of the object for which the notification is being sent, e.g. document name - 'Client Statement Q4 2024', transaction details - 'Contribution - $50,000'.",
            "nullable": true,
            "example": ""
          },
          "NotificationClassification": {
            "type": "string",
            "description": "The classification of document for which the notification was sent e.g. Statement, Tax Document etc.",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Unique identifier for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "AccountShortName": {
            "type": "string",
            "description": "Account shortname for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Notification destination type e.g. EmailAddress, PhoneNumber, Mail Address.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "The Email address, phone number or mailing address for the notification.",
            "nullable": true,
            "example": ""
          },
          "SentOn": {
            "type": "string",
            "description": "The date and timestamp of that the notification was sent.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "SentBy": {
            "type": "string",
            "description": "User Id that initiated the notifcation event, may be system user or an end user.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationEventCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunMessageId": {
            "type": "string",
            "description": "Identifier for associated parent notification run message record.",
            "nullable": true,
            "example": ""
          },
          "NotificationRunMessage": {
            "$ref": "#/components/schemas/NotificationRunMessageDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language that the notification message was sent in.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "string",
            "description": "The entity type for the recipient - Client, User, Employeee etc.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The identfier of the recipient of the associated entity.",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient of the notification.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityType": {
            "type": "string",
            "description": "Notification entity type - example Document, Holding, Transaction.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityId": {
            "type": "string",
            "description": "Unique identifier for the notification entity record. e.g. Id for a specific Document record.",
            "nullable": true,
            "example": ""
          },
          "NotificationObjectName": {
            "type": "string",
            "description": "Name of the object for which the notification is being sent, e.g. document name - 'Client Statement Q4 2024', transaction details - 'Contribution - $50,000'.",
            "nullable": true,
            "example": ""
          },
          "NotificationClassification": {
            "type": "string",
            "description": "The classification of document for which the notification was sent e.g. Statement, Tax Document etc.",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Unique identifier for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "AccountShortName": {
            "type": "string",
            "description": "Account shortname for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Notification destination type e.g. EmailAddress, PhoneNumber, Mail Address.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "The Email address, phone number or mailing address for the notification.",
            "nullable": true,
            "example": ""
          },
          "SentOn": {
            "type": "string",
            "description": "The date and timestamp of that the notification was sent.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "SentBy": {
            "type": "string",
            "description": "User Id that initiated the notifcation event, may be system user or an end user.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationEventDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunMessageId": {
            "type": "string",
            "description": "Identifier for associated parent notification run message record.",
            "nullable": true,
            "example": ""
          },
          "NotificationRunMessage": {
            "$ref": "#/components/schemas/NotificationRunMessageDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language that the notification message was sent in.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "string",
            "description": "The entity type for the recipient - Client, User, Employeee etc.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The identfier of the recipient of the associated entity.",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient of the notification.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityType": {
            "type": "string",
            "description": "Notification entity type - example Document, Holding, Transaction.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityId": {
            "type": "string",
            "description": "Unique identifier for the notification entity record. e.g. Id for a specific Document record.",
            "nullable": true,
            "example": ""
          },
          "NotificationObjectName": {
            "type": "string",
            "description": "Name of the object for which the notification is being sent, e.g. document name - 'Client Statement Q4 2024', transaction details - 'Contribution - $50,000'.",
            "nullable": true,
            "example": ""
          },
          "NotificationClassification": {
            "type": "string",
            "description": "The classification of document for which the notification was sent e.g. Statement, Tax Document etc.",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Unique identifier for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "AccountShortName": {
            "type": "string",
            "description": "Account shortname for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Notification destination type e.g. EmailAddress, PhoneNumber, Mail Address.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "The Email address, phone number or mailing address for the notification.",
            "nullable": true,
            "example": ""
          },
          "SentOn": {
            "type": "string",
            "description": "The date and timestamp of that the notification was sent.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "SentBy": {
            "type": "string",
            "description": "User Id that initiated the notifcation event, may be system user or an end user.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/NotificationEventDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationEventDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunMessageId": {
            "type": "string",
            "description": "Identifier for associated parent notification run message record.",
            "nullable": true,
            "example": ""
          },
          "NotificationRunMessage": {
            "$ref": "#/components/schemas/NotificationRunMessageDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language that the notification message was sent in.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "string",
            "description": "The entity type for the recipient - Client, User, Employeee etc.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The identfier of the recipient of the associated entity.",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient of the notification.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityType": {
            "type": "string",
            "description": "Notification entity type - example Document, Holding, Transaction.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityId": {
            "type": "string",
            "description": "Unique identifier for the notification entity record. e.g. Id for a specific Document record.",
            "nullable": true,
            "example": ""
          },
          "NotificationObjectName": {
            "type": "string",
            "description": "Name of the object for which the notification is being sent, e.g. document name - 'Client Statement Q4 2024', transaction details - 'Contribution - $50,000'.",
            "nullable": true,
            "example": ""
          },
          "NotificationClassification": {
            "type": "string",
            "description": "The classification of document for which the notification was sent e.g. Statement, Tax Document etc.",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Unique identifier for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "AccountShortName": {
            "type": "string",
            "description": "Account shortname for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Notification destination type e.g. EmailAddress, PhoneNumber, Mail Address.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "The Email address, phone number or mailing address for the notification.",
            "nullable": true,
            "example": ""
          },
          "SentOn": {
            "type": "string",
            "description": "The date and timestamp of that the notification was sent.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "SentBy": {
            "type": "string",
            "description": "User Id that initiated the notifcation event, may be system user or an end user.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationEventGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunMessageId": {
            "type": "string",
            "description": "Identifier for associated parent notification run message record.",
            "nullable": true,
            "example": ""
          },
          "NotificationRunMessage": {
            "$ref": "#/components/schemas/NotificationRunMessageDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language that the notification message was sent in.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "string",
            "description": "The entity type for the recipient - Client, User, Employeee etc.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The identfier of the recipient of the associated entity.",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient of the notification.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityType": {
            "type": "string",
            "description": "Notification entity type - example Document, Holding, Transaction.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityId": {
            "type": "string",
            "description": "Unique identifier for the notification entity record. e.g. Id for a specific Document record.",
            "nullable": true,
            "example": ""
          },
          "NotificationObjectName": {
            "type": "string",
            "description": "Name of the object for which the notification is being sent, e.g. document name - 'Client Statement Q4 2024', transaction details - 'Contribution - $50,000'.",
            "nullable": true,
            "example": ""
          },
          "NotificationClassification": {
            "type": "string",
            "description": "The classification of document for which the notification was sent e.g. Statement, Tax Document etc.",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Unique identifier for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "AccountShortName": {
            "type": "string",
            "description": "Account shortname for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Notification destination type e.g. EmailAddress, PhoneNumber, Mail Address.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "The Email address, phone number or mailing address for the notification.",
            "nullable": true,
            "example": ""
          },
          "SentOn": {
            "type": "string",
            "description": "The date and timestamp of that the notification was sent.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "SentBy": {
            "type": "string",
            "description": "User Id that initiated the notifcation event, may be system user or an end user.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunMessageId": {
            "type": "string",
            "description": "Identifier for associated parent notification run message record.",
            "nullable": true,
            "example": ""
          },
          "NotificationRunMessage": {
            "$ref": "#/components/schemas/NotificationRunMessageDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language that the notification message was sent in.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "string",
            "description": "The entity type for the recipient - Client, User, Employeee etc.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The identfier of the recipient of the associated entity.",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient of the notification.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityType": {
            "type": "string",
            "description": "Notification entity type - example Document, Holding, Transaction.",
            "nullable": true,
            "example": ""
          },
          "NotificationEntityId": {
            "type": "string",
            "description": "Unique identifier for the notification entity record. e.g. Id for a specific Document record.",
            "nullable": true,
            "example": ""
          },
          "NotificationObjectName": {
            "type": "string",
            "description": "Name of the object for which the notification is being sent, e.g. document name - 'Client Statement Q4 2024', transaction details - 'Contribution - $50,000'.",
            "nullable": true,
            "example": ""
          },
          "NotificationClassification": {
            "type": "string",
            "description": "The classification of document for which the notification was sent e.g. Statement, Tax Document etc.",
            "nullable": true,
            "example": ""
          },
          "AccountId": {
            "type": "string",
            "description": "Unique identifier for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "AccountShortName": {
            "type": "string",
            "description": "Account shortname for the account associated with the notification event.",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Notification destination type e.g. EmailAddress, PhoneNumber, Mail Address.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "The Email address, phone number or mailing address for the notification.",
            "nullable": true,
            "example": ""
          },
          "SentOn": {
            "type": "string",
            "description": "The date and timestamp of that the notification was sent.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "SentBy": {
            "type": "string",
            "description": "User Id that initiated the notifcation event, may be system user or an end user.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationEventUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationEventUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationFlatDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Trigger": {
            "type": "string",
            "nullable": true
          },
          "Frequency": {
            "type": "string",
            "nullable": true
          },
          "Template": {
            "type": "string",
            "nullable": true
          },
          "Delivery": {
            "type": "string",
            "nullable": true
          },
          "TargetList": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationFlatDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/NotificationFlatDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationFlatDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationFlatDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationFlatQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "The name of the notification rule",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "Description for the notification rule",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipientId": {
            "type": "string",
            "description": "Identifier for the recipient details for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipient": {
            "$ref": "#/components/schemas/NotificationRecipientDto"
          },
          "NotificationTriggerId": {
            "type": "string",
            "description": "Identifier for the trigger for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationTrigger": {
            "$ref": "#/components/schemas/NotificationTriggerDto"
          },
          "NotificationScheduleId": {
            "type": "string",
            "description": "Identifier for the selected schedule e.g. 'Weekly, Mon-Fri @ 6am EST'",
            "nullable": true,
            "example": ""
          },
          "NotificationSchedule": {
            "$ref": "#/components/schemas/NotificationScheduleDto"
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal identifier for the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "DeliveryMechanism": {
            "type": "string",
            "description": "Delivery mechanism - Email, SMS text.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the rule is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of the recipient list e.g. 'All active client portal users'",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "boolean",
            "description": "Receipient entity type e.g. 'User', 'Cleint'",
            "nullable": true
          },
          "IsStaticRecipientList": {
            "type": "string",
            "description": "Is the receipient list a static list based on stored list of identifiers or a dynamic list based on filter criteria",
            "nullable": true,
            "example": ""
          },
          "RecipientFilterCriteria": {
            "description": "Filter criteria for the receipient list",
            "nullable": true
          },
          "RecipientIdStaticList": {
            "type": "array",
            "items": {
              "type": "string",
              "format": "uuid"
            },
            "description": "List of receipient Identifiers",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the list is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of the recipient list e.g. 'All active client portal users'",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "boolean",
            "description": "Receipient entity type e.g. 'User', 'Cleint'",
            "nullable": true
          },
          "IsStaticRecipientList": {
            "type": "string",
            "description": "Is the receipient list a static list based on stored list of identifiers or a dynamic list based on filter criteria",
            "nullable": true,
            "example": ""
          },
          "RecipientFilterCriteria": {
            "description": "Filter criteria for the receipient list",
            "nullable": true
          },
          "RecipientIdStaticList": {
            "type": "array",
            "items": {
              "type": "string",
              "format": "uuid"
            },
            "description": "List of receipient Identifiers",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the list is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRecipientCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of the recipient list e.g. 'All active client portal users'",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "boolean",
            "description": "Receipient entity type e.g. 'User', 'Cleint'",
            "nullable": true
          },
          "IsStaticRecipientList": {
            "type": "string",
            "description": "Is the receipient list a static list based on stored list of identifiers or a dynamic list based on filter criteria",
            "nullable": true,
            "example": ""
          },
          "RecipientFilterCriteria": {
            "description": "Filter criteria for the receipient list",
            "nullable": true
          },
          "RecipientIdStaticList": {
            "type": "array",
            "items": {
              "type": "string",
              "format": "uuid"
            },
            "description": "List of receipient Identifiers",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the list is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRecipientDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of the recipient list e.g. 'All active client portal users'",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "boolean",
            "description": "Receipient entity type e.g. 'User', 'Cleint'",
            "nullable": true
          },
          "IsStaticRecipientList": {
            "type": "string",
            "description": "Is the receipient list a static list based on stored list of identifiers or a dynamic list based on filter criteria",
            "nullable": true,
            "example": ""
          },
          "RecipientFilterCriteria": {
            "description": "Filter criteria for the receipient list",
            "nullable": true
          },
          "RecipientIdStaticList": {
            "type": "array",
            "items": {
              "type": "string",
              "format": "uuid"
            },
            "description": "List of receipient Identifiers",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the list is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/NotificationRecipientDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRecipientDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of the recipient list e.g. 'All active client portal users'",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "boolean",
            "description": "Receipient entity type e.g. 'User', 'Cleint'",
            "nullable": true
          },
          "IsStaticRecipientList": {
            "type": "string",
            "description": "Is the receipient list a static list based on stored list of identifiers or a dynamic list based on filter criteria",
            "nullable": true,
            "example": ""
          },
          "RecipientFilterCriteria": {
            "description": "Filter criteria for the receipient list",
            "nullable": true
          },
          "RecipientIdStaticList": {
            "type": "array",
            "items": {
              "type": "string",
              "format": "uuid"
            },
            "description": "List of receipient Identifiers",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the list is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRecipientGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of the recipient list e.g. 'All active client portal users'",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "boolean",
            "description": "Receipient entity type e.g. 'User', 'Cleint'",
            "nullable": true
          },
          "IsStaticRecipientList": {
            "type": "string",
            "description": "Is the receipient list a static list based on stored list of identifiers or a dynamic list based on filter criteria",
            "nullable": true,
            "example": ""
          },
          "RecipientFilterCriteria": {
            "description": "Filter criteria for the receipient list",
            "nullable": true
          },
          "RecipientIdStaticList": {
            "type": "array",
            "items": {
              "type": "string",
              "format": "uuid"
            },
            "description": "List of receipient Identifiers",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the list is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of the recipient list e.g. 'All active client portal users'",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityType": {
            "type": "boolean",
            "description": "Receipient entity type e.g. 'User', 'Cleint'",
            "nullable": true
          },
          "IsStaticRecipientList": {
            "type": "string",
            "description": "Is the receipient list a static list based on stored list of identifiers or a dynamic list based on filter criteria",
            "nullable": true,
            "example": ""
          },
          "RecipientFilterCriteria": {
            "description": "Filter criteria for the receipient list",
            "nullable": true
          },
          "RecipientIdStaticList": {
            "type": "array",
            "items": {
              "type": "string",
              "format": "uuid"
            },
            "description": "List of receipient Identifiers",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the list is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRecipientUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRecipientUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationId": {
            "type": "string",
            "description": "The system identifier for the notification.",
            "nullable": true,
            "example": ""
          },
          "Notification": {
            "$ref": "#/components/schemas/NotificationDto"
          },
          "NotificationRunTime": {
            "type": "string",
            "description": "Date and time that the notification ran.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "NumberRunMessageGenerated": {
            "type": "integer",
            "description": "Number of messages generated by the notification run",
            "format": "int32",
            "nullable": true
          },
          "NumberEventGenerated": {
            "type": "integer",
            "description": "Number of underlying events that the notification messages were generated for.",
            "format": "int32",
            "nullable": true
          },
          "IsComplete": {
            "type": "boolean",
            "description": "Flag to indicate if Notification run has been completed successfully",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Status of the notification run.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationRunCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationId": {
            "type": "string",
            "description": "The system identifier for the notification.",
            "nullable": true,
            "example": ""
          },
          "Notification": {
            "$ref": "#/components/schemas/NotificationDto"
          },
          "NotificationRunTime": {
            "type": "string",
            "description": "Date and time that the notification ran.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "NumberRunMessageGenerated": {
            "type": "integer",
            "description": "Number of messages generated by the notification run",
            "format": "int32",
            "nullable": true
          },
          "NumberEventGenerated": {
            "type": "integer",
            "description": "Number of underlying events that the notification messages were generated for.",
            "format": "int32",
            "nullable": true
          },
          "IsComplete": {
            "type": "boolean",
            "description": "Flag to indicate if Notification run has been completed successfully",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Status of the notification run.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationRunCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRunCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationId": {
            "type": "string",
            "description": "The system identifier for the notification.",
            "nullable": true,
            "example": ""
          },
          "Notification": {
            "$ref": "#/components/schemas/NotificationDto"
          },
          "NotificationRunTime": {
            "type": "string",
            "description": "Date and time that the notification ran.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "NumberRunMessageGenerated": {
            "type": "integer",
            "description": "Number of messages generated by the notification run",
            "format": "int32",
            "nullable": true
          },
          "NumberEventGenerated": {
            "type": "integer",
            "description": "Number of underlying events that the notification messages were generated for.",
            "format": "int32",
            "nullable": true
          },
          "IsComplete": {
            "type": "boolean",
            "description": "Flag to indicate if Notification run has been completed successfully",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Status of the notification run.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationRunDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRunDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationId": {
            "type": "string",
            "description": "The system identifier for the notification.",
            "nullable": true,
            "example": ""
          },
          "Notification": {
            "$ref": "#/components/schemas/NotificationDto"
          },
          "NotificationRunTime": {
            "type": "string",
            "description": "Date and time that the notification ran.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "NumberRunMessageGenerated": {
            "type": "integer",
            "description": "Number of messages generated by the notification run",
            "format": "int32",
            "nullable": true
          },
          "NumberEventGenerated": {
            "type": "integer",
            "description": "Number of underlying events that the notification messages were generated for.",
            "format": "int32",
            "nullable": true
          },
          "IsComplete": {
            "type": "boolean",
            "description": "Flag to indicate if Notification run has been completed successfully",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Status of the notification run.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationRunDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/NotificationRunDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRunDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationId": {
            "type": "string",
            "description": "The system identifier for the notification.",
            "nullable": true,
            "example": ""
          },
          "Notification": {
            "$ref": "#/components/schemas/NotificationDto"
          },
          "NotificationRunTime": {
            "type": "string",
            "description": "Date and time that the notification ran.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "NumberRunMessageGenerated": {
            "type": "integer",
            "description": "Number of messages generated by the notification run",
            "format": "int32",
            "nullable": true
          },
          "NumberEventGenerated": {
            "type": "integer",
            "description": "Number of underlying events that the notification messages were generated for.",
            "format": "int32",
            "nullable": true
          },
          "IsComplete": {
            "type": "boolean",
            "description": "Flag to indicate if Notification run has been completed successfully",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Status of the notification run.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationRunGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRunGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunId": {
            "type": "string",
            "description": "Internal system Identifier of the notification run.",
            "nullable": true,
            "example": ""
          },
          "NotificationRun": {
            "$ref": "#/components/schemas/NotificationRunDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The recipient identfier (on specified Receipient Entity Type)",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Destination type - Email, SMS Text etc.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "Address for the destination e.g. Email address",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal system Identifier of the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Subject": {
            "type": "string",
            "description": "Subject line on the message.",
            "nullable": true,
            "example": ""
          },
          "Message": {
            "type": "string",
            "description": "Body of the message.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "ErrorMessage": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunId": {
            "type": "string",
            "description": "Internal system Identifier of the notification run.",
            "nullable": true,
            "example": ""
          },
          "NotificationRun": {
            "$ref": "#/components/schemas/NotificationRunDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The recipient identfier (on specified Receipient Entity Type)",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Destination type - Email, SMS Text etc.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "Address for the destination e.g. Email address",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal system Identifier of the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Subject": {
            "type": "string",
            "description": "Subject line on the message.",
            "nullable": true,
            "example": ""
          },
          "Message": {
            "type": "string",
            "description": "Body of the message.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "ErrorMessage": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRunMessageCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunId": {
            "type": "string",
            "description": "Internal system Identifier of the notification run.",
            "nullable": true,
            "example": ""
          },
          "NotificationRun": {
            "$ref": "#/components/schemas/NotificationRunDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The recipient identfier (on specified Receipient Entity Type)",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Destination type - Email, SMS Text etc.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "Address for the destination e.g. Email address",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal system Identifier of the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Subject": {
            "type": "string",
            "description": "Subject line on the message.",
            "nullable": true,
            "example": ""
          },
          "Message": {
            "type": "string",
            "description": "Body of the message.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "ErrorMessage": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRunMessageDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunId": {
            "type": "string",
            "description": "Internal system Identifier of the notification run.",
            "nullable": true,
            "example": ""
          },
          "NotificationRun": {
            "$ref": "#/components/schemas/NotificationRunDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The recipient identfier (on specified Receipient Entity Type)",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Destination type - Email, SMS Text etc.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "Address for the destination e.g. Email address",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal system Identifier of the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Subject": {
            "type": "string",
            "description": "Subject line on the message.",
            "nullable": true,
            "example": ""
          },
          "Message": {
            "type": "string",
            "description": "Body of the message.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "ErrorMessage": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/NotificationRunMessageDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRunMessageDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunId": {
            "type": "string",
            "description": "Internal system Identifier of the notification run.",
            "nullable": true,
            "example": ""
          },
          "NotificationRun": {
            "$ref": "#/components/schemas/NotificationRunDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The recipient identfier (on specified Receipient Entity Type)",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Destination type - Email, SMS Text etc.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "Address for the destination e.g. Email address",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal system Identifier of the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Subject": {
            "type": "string",
            "description": "Subject line on the message.",
            "nullable": true,
            "example": ""
          },
          "Message": {
            "type": "string",
            "description": "Body of the message.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "ErrorMessage": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRunMessageGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunId": {
            "type": "string",
            "description": "Internal system Identifier of the notification run.",
            "nullable": true,
            "example": ""
          },
          "NotificationRun": {
            "$ref": "#/components/schemas/NotificationRunDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The recipient identfier (on specified Receipient Entity Type)",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Destination type - Email, SMS Text etc.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "Address for the destination e.g. Email address",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal system Identifier of the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Subject": {
            "type": "string",
            "description": "Subject line on the message.",
            "nullable": true,
            "example": ""
          },
          "Message": {
            "type": "string",
            "description": "Body of the message.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "ErrorMessage": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationRunId": {
            "type": "string",
            "description": "Internal system Identifier of the notification run.",
            "nullable": true,
            "example": ""
          },
          "NotificationRun": {
            "$ref": "#/components/schemas/NotificationRunDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "RecipientEntityId": {
            "type": "string",
            "description": "The recipient identfier (on specified Receipient Entity Type)",
            "nullable": true,
            "example": ""
          },
          "RecipientName": {
            "type": "string",
            "description": "The name of the recipient",
            "nullable": true,
            "example": ""
          },
          "DestinationType": {
            "type": "string",
            "description": "Destination type - Email, SMS Text etc.",
            "nullable": true,
            "example": ""
          },
          "DestinationAddress": {
            "type": "string",
            "description": "Address for the destination e.g. Email address",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal system Identifier of the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Subject": {
            "type": "string",
            "description": "Subject line on the message.",
            "nullable": true,
            "example": ""
          },
          "Message": {
            "type": "string",
            "description": "Body of the message.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "nullable": true
          },
          "ErrorMessage": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunMessageUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRunMessageUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationRunUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationId": {
            "type": "string",
            "description": "The system identifier for the notification.",
            "nullable": true,
            "example": ""
          },
          "Notification": {
            "$ref": "#/components/schemas/NotificationDto"
          },
          "NotificationRunTime": {
            "type": "string",
            "description": "Date and time that the notification ran.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "NumberRunMessageGenerated": {
            "type": "integer",
            "description": "Number of messages generated by the notification run",
            "format": "int32",
            "nullable": true
          },
          "NumberEventGenerated": {
            "type": "integer",
            "description": "Number of underlying events that the notification messages were generated for.",
            "format": "int32",
            "nullable": true
          },
          "IsComplete": {
            "type": "boolean",
            "description": "Flag to indicate if Notification run has been completed successfully",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Status of the notification run.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationRunUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "NotificationId": {
            "type": "string",
            "description": "The system identifier for the notification.",
            "nullable": true,
            "example": ""
          },
          "Notification": {
            "$ref": "#/components/schemas/NotificationDto"
          },
          "NotificationRunTime": {
            "type": "string",
            "description": "Date and time that the notification ran.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "NumberRunMessageGenerated": {
            "type": "integer",
            "description": "Number of messages generated by the notification run",
            "format": "int32",
            "nullable": true
          },
          "NumberEventGenerated": {
            "type": "integer",
            "description": "Number of underlying events that the notification messages were generated for.",
            "format": "int32",
            "nullable": true
          },
          "IsComplete": {
            "type": "boolean",
            "description": "Flag to indicate if Notification run has been completed successfully",
            "nullable": true
          },
          "Status": {
            "type": "string",
            "description": "Status of the notification run.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationRunUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationRunUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of schedule e.g. 'Weekly, Monday - Friday @ 6am EST'",
            "nullable": true,
            "example": "Daily - Weekdays"
          },
          "IsImmediate": {
            "type": "boolean",
            "description": "Notification event to run immediately",
            "nullable": true
          },
          "IsOnUserLogin": {
            "type": "boolean",
            "description": "Notification event to run when user logs on",
            "nullable": true
          },
          "IsCalendarSchedule": {
            "type": "boolean",
            "description": "Notification event to run based on a calendar schedule defined based on days and time of day",
            "nullable": true
          },
          "DayOfWeek": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "List of the days of the week on which the notification is to run",
            "nullable": true
          },
          "TimeOfDay": {
            "type": "string",
            "description": "List of the time of day that the notification is to run.",
            "format": "time",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the schedule is active or disabled.",
            "nullable": true
          },
          "TimeZone": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of schedule e.g. 'Weekly, Monday - Friday @ 6am EST'",
            "nullable": true,
            "example": "Daily - Weekdays"
          },
          "IsImmediate": {
            "type": "boolean",
            "description": "Notification event to run immediately",
            "nullable": true
          },
          "IsOnUserLogin": {
            "type": "boolean",
            "description": "Notification event to run when user logs on",
            "nullable": true
          },
          "IsCalendarSchedule": {
            "type": "boolean",
            "description": "Notification event to run based on a calendar schedule defined based on days and time of day",
            "nullable": true
          },
          "DayOfWeek": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "List of the days of the week on which the notification is to run",
            "nullable": true
          },
          "TimeOfDay": {
            "type": "string",
            "description": "List of the time of day that the notification is to run.",
            "format": "time",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the schedule is active or disabled.",
            "nullable": true
          },
          "TimeZone": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationScheduleCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of schedule e.g. 'Weekly, Monday - Friday @ 6am EST'",
            "nullable": true,
            "example": "Daily - Weekdays"
          },
          "IsImmediate": {
            "type": "boolean",
            "description": "Notification event to run immediately",
            "nullable": true
          },
          "IsOnUserLogin": {
            "type": "boolean",
            "description": "Notification event to run when user logs on",
            "nullable": true
          },
          "IsCalendarSchedule": {
            "type": "boolean",
            "description": "Notification event to run based on a calendar schedule defined based on days and time of day",
            "nullable": true
          },
          "DayOfWeek": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "List of the days of the week on which the notification is to run",
            "nullable": true
          },
          "TimeOfDay": {
            "type": "string",
            "description": "List of the time of day that the notification is to run.",
            "format": "time",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the schedule is active or disabled.",
            "nullable": true
          },
          "TimeZone": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationScheduleDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of schedule e.g. 'Weekly, Monday - Friday @ 6am EST'",
            "nullable": true,
            "example": "Daily - Weekdays"
          },
          "IsImmediate": {
            "type": "boolean",
            "description": "Notification event to run immediately",
            "nullable": true
          },
          "IsOnUserLogin": {
            "type": "boolean",
            "description": "Notification event to run when user logs on",
            "nullable": true
          },
          "IsCalendarSchedule": {
            "type": "boolean",
            "description": "Notification event to run based on a calendar schedule defined based on days and time of day",
            "nullable": true
          },
          "DayOfWeek": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "List of the days of the week on which the notification is to run",
            "nullable": true
          },
          "TimeOfDay": {
            "type": "string",
            "description": "List of the time of day that the notification is to run.",
            "format": "time",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the schedule is active or disabled.",
            "nullable": true
          },
          "TimeZone": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/NotificationScheduleDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationScheduleDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of schedule e.g. 'Weekly, Monday - Friday @ 6am EST'",
            "nullable": true,
            "example": "Daily - Weekdays"
          },
          "IsImmediate": {
            "type": "boolean",
            "description": "Notification event to run immediately",
            "nullable": true
          },
          "IsOnUserLogin": {
            "type": "boolean",
            "description": "Notification event to run when user logs on",
            "nullable": true
          },
          "IsCalendarSchedule": {
            "type": "boolean",
            "description": "Notification event to run based on a calendar schedule defined based on days and time of day",
            "nullable": true
          },
          "DayOfWeek": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "List of the days of the week on which the notification is to run",
            "nullable": true
          },
          "TimeOfDay": {
            "type": "string",
            "description": "List of the time of day that the notification is to run.",
            "format": "time",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the schedule is active or disabled.",
            "nullable": true
          },
          "TimeZone": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationScheduleGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of schedule e.g. 'Weekly, Monday - Friday @ 6am EST'",
            "nullable": true,
            "example": "Daily - Weekdays"
          },
          "IsImmediate": {
            "type": "boolean",
            "description": "Notification event to run immediately",
            "nullable": true
          },
          "IsOnUserLogin": {
            "type": "boolean",
            "description": "Notification event to run when user logs on",
            "nullable": true
          },
          "IsCalendarSchedule": {
            "type": "boolean",
            "description": "Notification event to run based on a calendar schedule defined based on days and time of day",
            "nullable": true
          },
          "DayOfWeek": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "List of the days of the week on which the notification is to run",
            "nullable": true
          },
          "TimeOfDay": {
            "type": "string",
            "description": "List of the time of day that the notification is to run.",
            "format": "time",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the schedule is active or disabled.",
            "nullable": true
          },
          "TimeZone": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Name of schedule e.g. 'Weekly, Monday - Friday @ 6am EST'",
            "nullable": true,
            "example": "Daily - Weekdays"
          },
          "IsImmediate": {
            "type": "boolean",
            "description": "Notification event to run immediately",
            "nullable": true
          },
          "IsOnUserLogin": {
            "type": "boolean",
            "description": "Notification event to run when user logs on",
            "nullable": true
          },
          "IsCalendarSchedule": {
            "type": "boolean",
            "description": "Notification event to run based on a calendar schedule defined based on days and time of day",
            "nullable": true
          },
          "DayOfWeek": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "List of the days of the week on which the notification is to run",
            "nullable": true
          },
          "TimeOfDay": {
            "type": "string",
            "description": "List of the time of day that the notification is to run.",
            "format": "time",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the schedule is active or disabled.",
            "nullable": true
          },
          "TimeZone": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationScheduleUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationScheduleUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationServiceConfig": {
        "type": "object",
        "properties": {
          "Smtp": {
            "$ref": "#/components/schemas/SmtpSettings"
          }
        },
        "additionalProperties": false
      },
      "NotificationTaskCreateScheduleRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "PreviousFireTimeUtc": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FireTimeUtc": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTaskCreateScheduleResponse": {
        "type": "object",
        "additionalProperties": false
      },
      "NotificationTaskCreateScheduleResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTaskCreateScheduleResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTaskCreateSenderRequest": {
        "type": "object",
        "properties": {
          "NotificationRunId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTaskCreateSenderResponse": {
        "type": "object",
        "additionalProperties": false
      },
      "NotificationTaskCreateSenderResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTaskCreateSenderResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTaskCreateWorkerRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "PreviousFireTimeUtc": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "FireTimeUtc": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTaskCreateWorkerResponse": {
        "type": "object",
        "additionalProperties": false
      },
      "NotificationTaskCreateWorkerResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTaskCreateWorkerResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          },
          "MessageType": {
            "type": "string",
            "description": "Message type for which the template is to be used for - Email, Text, Portal notifications.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the template is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          },
          "MessageType": {
            "type": "string",
            "description": "Message type for which the template is to be used for - Email, Text, Portal notifications.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the template is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTemplateCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          },
          "MessageType": {
            "type": "string",
            "description": "Message type for which the template is to be used for - Email, Text, Portal notifications.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the template is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTemplateDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          },
          "MessageType": {
            "type": "string",
            "description": "Message type for which the template is to be used for - Email, Text, Portal notifications.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the template is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/NotificationTemplateDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTemplateDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          },
          "MessageType": {
            "type": "string",
            "description": "Message type for which the template is to be used for - Email, Text, Portal notifications.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the template is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTemplateGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "NotificationTemplate",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "NotificationTemplate",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTemplateTranslationCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "NotificationTemplate",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTemplateTranslationDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "NotificationTemplate",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/NotificationTemplateTranslationDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTemplateTranslationDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "NotificationTemplate",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTemplateTranslationGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "NotificationTemplate",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "NotificationTemplate",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateTranslationUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTemplateTranslationUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          },
          "MessageType": {
            "type": "string",
            "description": "Message type for which the template is to be used for - Email, Text, Portal notifications.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the template is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - en-US, en stands for English, and US indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "Name": {
            "type": "string",
            "description": "Template name.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the template to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "Email Subject",
            "nullable": true,
            "example": ""
          },
          "MessageBody": {
            "type": "string",
            "description": "Template for the body of the message, with details of message parameters to be inserted into the template to generate each individual message",
            "nullable": true,
            "example": ""
          },
          "MessageType": {
            "type": "string",
            "description": "Message type for which the template is to be used for - Email, Text, Portal notifications.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the template is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTemplateUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTemplateUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Trigger name, e.g. New Quarterly Statement documents are available.",
            "nullable": true,
            "example": "Documents Published"
          },
          "Description": {
            "type": "string",
            "description": "Description of the notification trigger and the business purpose is serves.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "nullable": true
          },
          "Options": {
            "description": "User entered options for the trigger e.g. Document types include - 'Invoice' etc.",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the trigger is active or disabled.",
            "nullable": true
          },
          "Identifier": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Trigger name, e.g. New Quarterly Statement documents are available.",
            "nullable": true,
            "example": "Documents Published"
          },
          "Description": {
            "type": "string",
            "description": "Description of the notification trigger and the business purpose is serves.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "nullable": true
          },
          "Options": {
            "description": "User entered options for the trigger e.g. Document types include - 'Invoice' etc.",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the trigger is active or disabled.",
            "nullable": true
          },
          "Identifier": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTriggerCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Trigger name, e.g. New Quarterly Statement documents are available.",
            "nullable": true,
            "example": "Documents Published"
          },
          "Description": {
            "type": "string",
            "description": "Description of the notification trigger and the business purpose is serves.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "nullable": true
          },
          "Options": {
            "description": "User entered options for the trigger e.g. Document types include - 'Invoice' etc.",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the trigger is active or disabled.",
            "nullable": true
          },
          "Identifier": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTriggerDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Trigger name, e.g. New Quarterly Statement documents are available.",
            "nullable": true,
            "example": "Documents Published"
          },
          "Description": {
            "type": "string",
            "description": "Description of the notification trigger and the business purpose is serves.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "nullable": true
          },
          "Options": {
            "description": "User entered options for the trigger e.g. Document types include - 'Invoice' etc.",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the trigger is active or disabled.",
            "nullable": true
          },
          "Identifier": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/NotificationTriggerDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTriggerDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Trigger name, e.g. New Quarterly Statement documents are available.",
            "nullable": true,
            "example": "Documents Published"
          },
          "Description": {
            "type": "string",
            "description": "Description of the notification trigger and the business purpose is serves.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "nullable": true
          },
          "Options": {
            "description": "User entered options for the trigger e.g. Document types include - 'Invoice' etc.",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the trigger is active or disabled.",
            "nullable": true
          },
          "Identifier": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTriggerGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Trigger name, e.g. New Quarterly Statement documents are available.",
            "nullable": true,
            "example": "Documents Published"
          },
          "Description": {
            "type": "string",
            "description": "Description of the notification trigger and the business purpose is serves.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "nullable": true
          },
          "Options": {
            "description": "User entered options for the trigger e.g. Document types include - 'Invoice' etc.",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the trigger is active or disabled.",
            "nullable": true
          },
          "Identifier": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "Trigger name, e.g. New Quarterly Statement documents are available.",
            "nullable": true,
            "example": "Documents Published"
          },
          "Description": {
            "type": "string",
            "description": "Description of the notification trigger and the business purpose is serves.",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "nullable": true
          },
          "Options": {
            "description": "User entered options for the trigger e.g. Document types include - 'Invoice' etc.",
            "nullable": true
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the trigger is active or disabled.",
            "nullable": true
          },
          "Identifier": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationTriggerUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationTriggerUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "The name of the notification rule",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "Description for the notification rule",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipientId": {
            "type": "string",
            "description": "Identifier for the recipient details for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipient": {
            "$ref": "#/components/schemas/NotificationRecipientDto"
          },
          "NotificationTriggerId": {
            "type": "string",
            "description": "Identifier for the trigger for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationTrigger": {
            "$ref": "#/components/schemas/NotificationTriggerDto"
          },
          "NotificationScheduleId": {
            "type": "string",
            "description": "Identifier for the selected schedule e.g. 'Weekly, Mon-Fri @ 6am EST'",
            "nullable": true,
            "example": ""
          },
          "NotificationSchedule": {
            "$ref": "#/components/schemas/NotificationScheduleDto"
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal identifier for the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "DeliveryMechanism": {
            "type": "string",
            "description": "Delivery mechanism - Email, SMS text.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the rule is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "The name of the notification rule",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "Description for the notification rule",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipientId": {
            "type": "string",
            "description": "Identifier for the recipient details for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationRecipient": {
            "$ref": "#/components/schemas/NotificationRecipientDto"
          },
          "NotificationTriggerId": {
            "type": "string",
            "description": "Identifier for the trigger for the notification",
            "nullable": true,
            "example": ""
          },
          "NotificationTrigger": {
            "$ref": "#/components/schemas/NotificationTriggerDto"
          },
          "NotificationScheduleId": {
            "type": "string",
            "description": "Identifier for the selected schedule e.g. 'Weekly, Mon-Fri @ 6am EST'",
            "nullable": true,
            "example": ""
          },
          "NotificationSchedule": {
            "$ref": "#/components/schemas/NotificationScheduleDto"
          },
          "NotificationTemplateId": {
            "type": "string",
            "description": "Internal identifier for the notification template.",
            "nullable": true,
            "example": ""
          },
          "NotificationTemplate": {
            "$ref": "#/components/schemas/NotificationTemplateDto"
          },
          "DeliveryMechanism": {
            "type": "string",
            "description": "Delivery mechanism - Email, SMS text.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "Flag to indicate if the rule is active or disabled.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "NotificationUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/NotificationUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "OpenApiQueryRequest": {
        "type": "object",
        "additionalProperties": false
      },
      "OrphanedDocumentDto": {
        "type": "object",
        "properties": {
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": ""
          },
          "ExternalDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "Origin from which a document originates or is obtained.",
            "nullable": true,
            "example": ""
          },
          "ModificationDate": {
            "type": "string",
            "description": "The date when the document was last modified.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "Category": {
            "type": "string",
            "description": "Classification of a document based on its content, purpose, or function within an organizational system or process. This categorization helps in organizing, managing, and retrieving documents efficiently",
            "nullable": true,
            "example": "Client Statements"
          },
          "AsOfDate": {
            "type": "string",
            "description": "Specific date mentioned on financial documents, reports, or data entries to indicate when the information was accurate or when a particular financial status was recorded.",
            "format": "date",
            "nullable": true,
            "example": "2023-12-31"
          },
          "CreationDate": {
            "type": "string",
            "description": "The date when the document was originally created.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ExpirationDate": {
            "type": "string",
            "description": "For documents that are time-sensitive, the date when the document is no longer valid or should be reviewed.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PublicationDate": {
            "type": "string",
            "description": "When the document was published, if applicable, which can be different from the creation or modification dates.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "DocumentType": {
            "type": "string",
            "description": "The category or type of the document (e.g., invoice, memo, report, contract).",
            "nullable": true,
            "example": ""
          },
          "Title": {
            "type": "string",
            "description": "The name of the document, often used as the primary identifier.",
            "nullable": true,
            "example": ""
          },
          "Author": {
            "type": "string",
            "description": "The person or entity who created the document. Creator / Author.",
            "nullable": true,
            "example": ""
          },
          "Keywords": {
            "type": "string",
            "description": "Descriptive terms associated with the document to aid in search and categorization.",
            "nullable": true,
            "example": ""
          },
          "VersionNumber": {
            "type": "string",
            "description": "Identifies the iteration of the document, useful for tracking revisions and updates.",
            "nullable": true,
            "example": ""
          },
          "Status": {
            "type": "string",
            "description": "Current state of the document (e.g., draft, reviewed, approved, archived).",
            "nullable": true,
            "example": ""
          },
          "Summary": {
            "type": "string",
            "description": "A brief description of the document�s content.",
            "nullable": true,
            "example": ""
          },
          "FileSize": {
            "type": "string",
            "description": "The size of the document file in Bytes,important for storage and retrieval considerations.",
            "nullable": true,
            "example": ""
          },
          "Subject": {
            "type": "string",
            "description": "The topic of the resource.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "An account of the resource.",
            "nullable": true,
            "example": ""
          },
          "Publisher": {
            "type": "string",
            "description": "The entity responsible for making the resource available.",
            "nullable": true,
            "example": ""
          },
          "Contributor": {
            "type": "string",
            "description": "An entity responsible for making contributions to the resource.",
            "nullable": true,
            "example": ""
          },
          "Format": {
            "type": "string",
            "description": "The file format, physical medium, or dimensions of the resource.",
            "nullable": true,
            "example": ""
          },
          "Relation": {
            "type": "string",
            "description": "A related resource.",
            "nullable": true,
            "example": ""
          },
          "Coverage": {
            "type": "string",
            "description": "The spatial or temporal topic of the resource, the geographic area covered by the resource, or the jurisdiction under which the resource is relevant.",
            "nullable": true,
            "example": ""
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "LargeObjectId": {
            "type": "integer",
            "format": "int32"
          },
          "FileExtension": {
            "type": "string",
            "nullable": true
          },
          "FileName": {
            "type": "string",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "description": "EntityId",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "EntityType": {
            "type": "string",
            "description": "Entity Type e.g. account | transaction | etc",
            "nullable": true,
            "example": "account"
          },
          "EntityShortName": {
            "type": "string",
            "description": "A short, unique name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "EntityFriendlyName": {
            "type": "string",
            "description": "A friendly name identifying a record in the reference entity.",
            "nullable": true,
            "example": "Private Account"
          },
          "IsPublished": {
            "type": "boolean"
          },
          "IsDeleted": {
            "type": "boolean"
          },
          "DocumentBatchId": {
            "type": "string",
            "nullable": true
          },
          "SourceSystemDocumentIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each Document for tracking and reference.",
            "nullable": true,
            "example": ""
          },
          "ApprovalHistory": {
            "type": "array",
            "items": {},
            "description": "Details of the approval publish workflow with each status and user update.",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "OrphanedDocumentDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/OrphanedDocumentDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "OrphanedDocumentDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/OrphanedDocumentDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "OrphanedDocumentQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PageGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PageGetResponse": {
        "type": "object",
        "properties": {
          "PageId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PageGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PageGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PartyMergeRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "LegalName": {
            "type": "string",
            "nullable": true
          },
          "PartyType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PartyMergeResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "LegalName": {
            "type": "string",
            "nullable": true
          },
          "PartyType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PartyMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PartyMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PeriodBeginDate": {
            "type": "string",
            "description": "Specific start date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PeriodEndDate": {
            "type": "string",
            "description": "Specific end date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-31"
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is End of Day, End of Month, Final, Preliminary, Gross of Fees, Net of Fees",
            "nullable": true,
            "example": "EOD"
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency used for tracking the value of investments within the account and for which performance is being measured.",
            "nullable": true,
            "example": "USD"
          },
          "AccountId": {
            "type": "string",
            "description": "Communify System Account Id",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "SegmentIdentifier": {
            "type": "string",
            "description": "Segment Identifier",
            "nullable": true,
            "example": ""
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterID": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkSymbol": {
            "type": "string",
            "description": "Concise alphanumeric code or abbreviation used to identify and track a financial benchmark index",
            "nullable": true,
            "example": ""
          },
          "MarketValueStartBeginningOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of - Beginning of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueEndOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  End of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartWTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  WTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartMTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  MTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartQTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  QTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartYTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  YTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartITD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  ITD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1d": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1d",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1w": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1w",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart6m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  6m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart9m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  9m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart12m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  12m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart5yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  5yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart10yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  10yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsEndOfPeriod": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. End of Period",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsWTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. WTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsMTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. MTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsQTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. QTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsYTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. YTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsITD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. ITD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1d": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1d",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1w": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1w",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows6m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 6m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows9m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 9m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows12m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 12m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows5yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 5yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows10yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 10yr",
            "format": "double",
            "nullable": true
          },
          "FeesPeriod": {
            "type": "number",
            "description": "All fees associated with managing the account. These may include management fees, performance fees, transaction fees, and any other costs charged by investment managers or brokers for the operation and maintenance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "IncomePeriod": {
            "type": "number",
            "description": "Total income from the accounts investments. This typically includes interest payments from bonds, dividends from stocks.",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossEndOfPeriod": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. End of Period",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossWTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. WTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossMTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. MTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossQTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. QTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossYTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. YTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossITD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. ITD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1d": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1d",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1w": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1w",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss6m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 6m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss9m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 9m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss12m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 12m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss5yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 5yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss10yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1d": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1w": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount11m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount12m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1d": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1w": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark11m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark12m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectWTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectMTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectQTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectYTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectITD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1d": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1w": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect6m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect9m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect12m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect5yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect10yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectWTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectMTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectQTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectYTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectITD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1d": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1w": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect6m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect9m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect12m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect5yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect10yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionWTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionMTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionQTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionYTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionITD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1d": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1w": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution6m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution9m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution12m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution5yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution10yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "InformationRatioPeriod": {
            "type": "number",
            "description": "Measures a portfolio managers ability to generate excess returns relative to a benchmark, but also considers the consistency of the performance.",
            "format": "double",
            "nullable": true
          },
          "SharpeRatioPeriod": {
            "type": "number",
            "description": "Used to understand the return of an investment compared to its risk. The ratio is the average return earned in excess of the risk-free rate per unit of volatility or total risk. Higher Sharpe ratios indicate better risk-adjusted performance.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PeriodBeginDate": {
            "type": "string",
            "description": "Specific start date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PeriodEndDate": {
            "type": "string",
            "description": "Specific end date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-31"
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is End of Day, End of Month, Final, Preliminary, Gross of Fees, Net of Fees",
            "nullable": true,
            "example": "EOD"
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency used for tracking the value of investments within the account and for which performance is being measured.",
            "nullable": true,
            "example": "USD"
          },
          "AccountId": {
            "type": "string",
            "description": "Communify System Account Id",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "SegmentIdentifier": {
            "type": "string",
            "description": "Segment Identifier",
            "nullable": true,
            "example": ""
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterID": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkSymbol": {
            "type": "string",
            "description": "Concise alphanumeric code or abbreviation used to identify and track a financial benchmark index",
            "nullable": true,
            "example": ""
          },
          "MarketValueStartBeginningOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of - Beginning of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueEndOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  End of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartWTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  WTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartMTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  MTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartQTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  QTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartYTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  YTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartITD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  ITD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1d": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1d",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1w": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1w",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart6m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  6m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart9m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  9m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart12m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  12m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart5yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  5yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart10yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  10yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsEndOfPeriod": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. End of Period",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsWTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. WTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsMTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. MTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsQTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. QTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsYTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. YTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsITD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. ITD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1d": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1d",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1w": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1w",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows6m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 6m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows9m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 9m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows12m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 12m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows5yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 5yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows10yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 10yr",
            "format": "double",
            "nullable": true
          },
          "FeesPeriod": {
            "type": "number",
            "description": "All fees associated with managing the account. These may include management fees, performance fees, transaction fees, and any other costs charged by investment managers or brokers for the operation and maintenance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "IncomePeriod": {
            "type": "number",
            "description": "Total income from the accounts investments. This typically includes interest payments from bonds, dividends from stocks.",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossEndOfPeriod": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. End of Period",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossWTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. WTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossMTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. MTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossQTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. QTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossYTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. YTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossITD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. ITD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1d": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1d",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1w": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1w",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss6m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 6m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss9m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 9m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss12m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 12m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss5yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 5yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss10yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1d": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1w": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount11m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount12m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1d": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1w": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark11m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark12m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectWTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectMTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectQTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectYTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectITD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1d": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1w": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect6m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect9m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect12m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect5yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect10yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectWTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectMTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectQTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectYTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectITD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1d": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1w": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect6m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect9m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect12m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect5yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect10yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionWTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionMTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionQTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionYTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionITD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1d": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1w": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution6m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution9m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution12m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution5yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution10yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "InformationRatioPeriod": {
            "type": "number",
            "description": "Measures a portfolio managers ability to generate excess returns relative to a benchmark, but also considers the consistency of the performance.",
            "format": "double",
            "nullable": true
          },
          "SharpeRatioPeriod": {
            "type": "number",
            "description": "Used to understand the return of an investment compared to its risk. The ratio is the average return earned in excess of the risk-free rate per unit of volatility or total risk. Higher Sharpe ratios indicate better risk-adjusted performance.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PeriodBeginDate": {
            "type": "string",
            "description": "Specific start date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PeriodEndDate": {
            "type": "string",
            "description": "Specific end date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-31"
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is End of Day, End of Month, Final, Preliminary, Gross of Fees, Net of Fees",
            "nullable": true,
            "example": "EOD"
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency used for tracking the value of investments within the account and for which performance is being measured.",
            "nullable": true,
            "example": "USD"
          },
          "AccountId": {
            "type": "string",
            "description": "Communify System Account Id",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "SegmentIdentifier": {
            "type": "string",
            "description": "Segment Identifier",
            "nullable": true,
            "example": ""
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterID": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkSymbol": {
            "type": "string",
            "description": "Concise alphanumeric code or abbreviation used to identify and track a financial benchmark index",
            "nullable": true,
            "example": ""
          },
          "MarketValueStartBeginningOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of - Beginning of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueEndOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  End of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartWTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  WTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartMTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  MTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartQTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  QTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartYTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  YTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartITD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  ITD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1d": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1d",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1w": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1w",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart6m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  6m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart9m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  9m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart12m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  12m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart5yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  5yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart10yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  10yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsEndOfPeriod": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. End of Period",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsWTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. WTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsMTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. MTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsQTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. QTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsYTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. YTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsITD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. ITD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1d": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1d",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1w": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1w",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows6m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 6m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows9m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 9m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows12m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 12m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows5yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 5yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows10yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 10yr",
            "format": "double",
            "nullable": true
          },
          "FeesPeriod": {
            "type": "number",
            "description": "All fees associated with managing the account. These may include management fees, performance fees, transaction fees, and any other costs charged by investment managers or brokers for the operation and maintenance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "IncomePeriod": {
            "type": "number",
            "description": "Total income from the accounts investments. This typically includes interest payments from bonds, dividends from stocks.",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossEndOfPeriod": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. End of Period",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossWTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. WTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossMTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. MTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossQTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. QTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossYTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. YTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossITD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. ITD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1d": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1d",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1w": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1w",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss6m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 6m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss9m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 9m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss12m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 12m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss5yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 5yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss10yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1d": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1w": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount11m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount12m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1d": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1w": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark11m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark12m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectWTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectMTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectQTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectYTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectITD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1d": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1w": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect6m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect9m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect12m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect5yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect10yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectWTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectMTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectQTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectYTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectITD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1d": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1w": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect6m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect9m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect12m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect5yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect10yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionWTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionMTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionQTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionYTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionITD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1d": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1w": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution6m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution9m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution12m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution5yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution10yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "InformationRatioPeriod": {
            "type": "number",
            "description": "Measures a portfolio managers ability to generate excess returns relative to a benchmark, but also considers the consistency of the performance.",
            "format": "double",
            "nullable": true
          },
          "SharpeRatioPeriod": {
            "type": "number",
            "description": "Used to understand the return of an investment compared to its risk. The ratio is the average return earned in excess of the risk-free rate per unit of volatility or total risk. Higher Sharpe ratios indicate better risk-adjusted performance.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PeriodBeginDate": {
            "type": "string",
            "description": "Specific start date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PeriodEndDate": {
            "type": "string",
            "description": "Specific end date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-31"
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is End of Day, End of Month, Final, Preliminary, Gross of Fees, Net of Fees",
            "nullable": true,
            "example": "EOD"
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency used for tracking the value of investments within the account and for which performance is being measured.",
            "nullable": true,
            "example": "USD"
          },
          "AccountId": {
            "type": "string",
            "description": "Communify System Account Id",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "SegmentIdentifier": {
            "type": "string",
            "description": "Segment Identifier",
            "nullable": true,
            "example": ""
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterID": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkSymbol": {
            "type": "string",
            "description": "Concise alphanumeric code or abbreviation used to identify and track a financial benchmark index",
            "nullable": true,
            "example": ""
          },
          "MarketValueStartBeginningOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of - Beginning of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueEndOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  End of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartWTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  WTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartMTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  MTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartQTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  QTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartYTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  YTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartITD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  ITD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1d": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1d",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1w": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1w",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart6m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  6m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart9m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  9m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart12m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  12m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart5yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  5yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart10yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  10yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsEndOfPeriod": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. End of Period",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsWTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. WTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsMTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. MTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsQTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. QTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsYTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. YTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsITD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. ITD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1d": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1d",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1w": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1w",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows6m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 6m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows9m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 9m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows12m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 12m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows5yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 5yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows10yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 10yr",
            "format": "double",
            "nullable": true
          },
          "FeesPeriod": {
            "type": "number",
            "description": "All fees associated with managing the account. These may include management fees, performance fees, transaction fees, and any other costs charged by investment managers or brokers for the operation and maintenance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "IncomePeriod": {
            "type": "number",
            "description": "Total income from the accounts investments. This typically includes interest payments from bonds, dividends from stocks.",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossEndOfPeriod": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. End of Period",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossWTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. WTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossMTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. MTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossQTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. QTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossYTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. YTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossITD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. ITD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1d": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1d",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1w": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1w",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss6m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 6m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss9m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 9m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss12m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 12m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss5yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 5yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss10yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1d": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1w": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount11m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount12m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1d": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1w": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark11m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark12m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectWTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectMTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectQTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectYTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectITD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1d": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1w": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect6m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect9m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect12m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect5yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect10yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectWTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectMTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectQTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectYTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectITD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1d": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1w": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect6m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect9m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect12m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect5yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect10yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionWTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionMTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionQTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionYTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionITD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1d": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1w": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution6m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution9m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution12m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution5yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution10yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "InformationRatioPeriod": {
            "type": "number",
            "description": "Measures a portfolio managers ability to generate excess returns relative to a benchmark, but also considers the consistency of the performance.",
            "format": "double",
            "nullable": true
          },
          "SharpeRatioPeriod": {
            "type": "number",
            "description": "Used to understand the return of an investment compared to its risk. The ratio is the average return earned in excess of the risk-free rate per unit of volatility or total risk. Higher Sharpe ratios indicate better risk-adjusted performance.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PeriodPerformanceSegmentDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PeriodBeginDate": {
            "type": "string",
            "description": "Specific start date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PeriodEndDate": {
            "type": "string",
            "description": "Specific end date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-31"
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is End of Day, End of Month, Final, Preliminary, Gross of Fees, Net of Fees",
            "nullable": true,
            "example": "EOD"
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency used for tracking the value of investments within the account and for which performance is being measured.",
            "nullable": true,
            "example": "USD"
          },
          "AccountId": {
            "type": "string",
            "description": "Communify System Account Id",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "SegmentIdentifier": {
            "type": "string",
            "description": "Segment Identifier",
            "nullable": true,
            "example": ""
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterID": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkSymbol": {
            "type": "string",
            "description": "Concise alphanumeric code or abbreviation used to identify and track a financial benchmark index",
            "nullable": true,
            "example": ""
          },
          "MarketValueStartBeginningOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of - Beginning of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueEndOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  End of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartWTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  WTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartMTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  MTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartQTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  QTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartYTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  YTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartITD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  ITD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1d": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1d",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1w": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1w",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart6m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  6m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart9m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  9m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart12m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  12m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart5yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  5yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart10yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  10yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsEndOfPeriod": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. End of Period",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsWTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. WTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsMTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. MTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsQTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. QTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsYTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. YTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsITD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. ITD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1d": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1d",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1w": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1w",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows6m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 6m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows9m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 9m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows12m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 12m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows5yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 5yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows10yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 10yr",
            "format": "double",
            "nullable": true
          },
          "FeesPeriod": {
            "type": "number",
            "description": "All fees associated with managing the account. These may include management fees, performance fees, transaction fees, and any other costs charged by investment managers or brokers for the operation and maintenance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "IncomePeriod": {
            "type": "number",
            "description": "Total income from the accounts investments. This typically includes interest payments from bonds, dividends from stocks.",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossEndOfPeriod": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. End of Period",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossWTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. WTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossMTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. MTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossQTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. QTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossYTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. YTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossITD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. ITD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1d": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1d",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1w": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1w",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss6m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 6m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss9m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 9m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss12m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 12m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss5yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 5yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss10yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1d": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1w": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount11m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount12m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1d": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1w": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark11m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark12m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectWTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectMTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectQTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectYTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectITD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1d": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1w": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect6m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect9m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect12m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect5yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect10yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectWTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectMTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectQTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectYTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectITD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1d": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1w": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect6m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect9m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect12m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect5yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect10yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionWTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionMTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionQTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionYTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionITD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1d": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1w": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution6m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution9m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution12m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution5yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution10yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "InformationRatioPeriod": {
            "type": "number",
            "description": "Measures a portfolio managers ability to generate excess returns relative to a benchmark, but also considers the consistency of the performance.",
            "format": "double",
            "nullable": true
          },
          "SharpeRatioPeriod": {
            "type": "number",
            "description": "Used to understand the return of an investment compared to its risk. The ratio is the average return earned in excess of the risk-free rate per unit of volatility or total risk. Higher Sharpe ratios indicate better risk-adjusted performance.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegmentId": {
            "type": "string",
            "description": "PeriodPerformanceSegment",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegment": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentDto"
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegmentId": {
            "type": "string",
            "description": "PeriodPerformanceSegment",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegment": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentDto"
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegmentId": {
            "type": "string",
            "description": "PeriodPerformanceSegment",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegment": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentDto"
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegmentId": {
            "type": "string",
            "description": "PeriodPerformanceSegment",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegment": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentDto"
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegmentId": {
            "type": "string",
            "description": "PeriodPerformanceSegment",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegment": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentDto"
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegmentId": {
            "type": "string",
            "description": "PeriodPerformanceSegment",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegment": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentDto"
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegmentId": {
            "type": "string",
            "description": "PeriodPerformanceSegment",
            "nullable": true,
            "example": ""
          },
          "PeriodPerformanceSegment": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentDto"
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentTranslationUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentTranslationUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PeriodBeginDate": {
            "type": "string",
            "description": "Specific start date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PeriodEndDate": {
            "type": "string",
            "description": "Specific end date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-31"
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is End of Day, End of Month, Final, Preliminary, Gross of Fees, Net of Fees",
            "nullable": true,
            "example": "EOD"
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency used for tracking the value of investments within the account and for which performance is being measured.",
            "nullable": true,
            "example": "USD"
          },
          "AccountId": {
            "type": "string",
            "description": "Communify System Account Id",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "SegmentIdentifier": {
            "type": "string",
            "description": "Segment Identifier",
            "nullable": true,
            "example": ""
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterID": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkSymbol": {
            "type": "string",
            "description": "Concise alphanumeric code or abbreviation used to identify and track a financial benchmark index",
            "nullable": true,
            "example": ""
          },
          "MarketValueStartBeginningOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of - Beginning of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueEndOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  End of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartWTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  WTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartMTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  MTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartQTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  QTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartYTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  YTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartITD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  ITD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1d": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1d",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1w": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1w",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart6m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  6m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart9m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  9m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart12m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  12m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart5yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  5yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart10yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  10yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsEndOfPeriod": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. End of Period",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsWTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. WTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsMTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. MTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsQTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. QTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsYTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. YTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsITD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. ITD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1d": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1d",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1w": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1w",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows6m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 6m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows9m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 9m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows12m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 12m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows5yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 5yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows10yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 10yr",
            "format": "double",
            "nullable": true
          },
          "FeesPeriod": {
            "type": "number",
            "description": "All fees associated with managing the account. These may include management fees, performance fees, transaction fees, and any other costs charged by investment managers or brokers for the operation and maintenance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "IncomePeriod": {
            "type": "number",
            "description": "Total income from the accounts investments. This typically includes interest payments from bonds, dividends from stocks.",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossEndOfPeriod": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. End of Period",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossWTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. WTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossMTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. MTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossQTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. QTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossYTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. YTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossITD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. ITD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1d": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1d",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1w": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1w",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss6m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 6m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss9m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 9m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss12m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 12m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss5yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 5yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss10yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1d": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1w": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount11m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount12m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1d": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1w": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark11m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark12m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectWTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectMTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectQTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectYTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectITD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1d": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1w": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect6m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect9m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect12m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect5yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect10yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectWTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectMTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectQTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectYTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectITD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1d": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1w": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect6m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect9m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect12m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect5yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect10yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionWTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionMTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionQTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionYTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionITD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1d": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1w": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution6m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution9m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution12m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution5yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution10yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "InformationRatioPeriod": {
            "type": "number",
            "description": "Measures a portfolio managers ability to generate excess returns relative to a benchmark, but also considers the consistency of the performance.",
            "format": "double",
            "nullable": true
          },
          "SharpeRatioPeriod": {
            "type": "number",
            "description": "Used to understand the return of an investment compared to its risk. The ratio is the average return earned in excess of the risk-free rate per unit of volatility or total risk. Higher Sharpe ratios indicate better risk-adjusted performance.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "PeriodBeginDate": {
            "type": "string",
            "description": "Specific start date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "PeriodEndDate": {
            "type": "string",
            "description": "Specific end date of the time period during for which measures are being stated.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-31"
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is End of Day, End of Month, Final, Preliminary, Gross of Fees, Net of Fees",
            "nullable": true,
            "example": "EOD"
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency used for tracking the value of investments within the account and for which performance is being measured.",
            "nullable": true,
            "example": "USD"
          },
          "AccountId": {
            "type": "string",
            "description": "Communify System Account Id",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "SegmentIdentifier": {
            "type": "string",
            "description": "Segment Identifier",
            "nullable": true,
            "example": ""
          },
          "SegmentName": {
            "type": "string",
            "description": "SegmentName",
            "nullable": true,
            "example": ""
          },
          "SegmentType": {
            "type": "string",
            "description": "SegmentType",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuirtyDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterID": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkName": {
            "type": "string",
            "description": "Designated title given to a specific benchmark that is used for comparison purposes. Benchmarks are standard indices or measures against which the performance of stocks, bonds, mutual funds, or investment portfolios can be gauged. The name of the benchmark typically reflects the scope, geographic focus, or the specific market segment it represents.",
            "nullable": true,
            "example": ""
          },
          "BenchmarkSymbol": {
            "type": "string",
            "description": "Concise alphanumeric code or abbreviation used to identify and track a financial benchmark index",
            "nullable": true,
            "example": ""
          },
          "MarketValueStartBeginningOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of - Beginning of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueEndOfPeriod": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  End of Period",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartWTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  WTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartMTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  MTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartQTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  QTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartYTD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  YTD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStartITD": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  ITD",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1d": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1d",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1w": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1w",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart6m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  6m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart9m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  9m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart12m": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  12m",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart1yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  1yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart3yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  3yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart5yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  5yr",
            "format": "double",
            "nullable": true
          },
          "MarketValueStart10yr": {
            "type": "number",
            "description": "Market value of all the cash and securities held within the account. At start of -  10yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsEndOfPeriod": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. End of Period",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsWTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. WTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsMTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. MTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsQTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. QTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsYTD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. YTD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlowsITD": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. ITD",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1d": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1d",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1w": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1w",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows6m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 6m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows9m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 9m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows12m": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 12m",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows1yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 1yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows3yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 3yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows5yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 5yr",
            "format": "double",
            "nullable": true
          },
          "NetCashFlows10yr": {
            "type": "number",
            "description": "Total net amount of cash generated or used within the account, taking into account all cash inflows from dividends, interest, and capital gains, as well as cash outflows for expenses, withdrawals, and capital losses. 10yr",
            "format": "double",
            "nullable": true
          },
          "FeesPeriod": {
            "type": "number",
            "description": "All fees associated with managing the account. These may include management fees, performance fees, transaction fees, and any other costs charged by investment managers or brokers for the operation and maintenance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "IncomePeriod": {
            "type": "number",
            "description": "Total income from the accounts investments. This typically includes interest payments from bonds, dividends from stocks.",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossEndOfPeriod": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. End of Period",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossWTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. WTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossMTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. MTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossQTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. QTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossYTD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. YTD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLossITD": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. ITD",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1d": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1d",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1w": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1w",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss6m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 6m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss9m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 9m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss12m": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 12m",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss1yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 1yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss3yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 3yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss5yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 5yr",
            "format": "double",
            "nullable": true
          },
          "ProfitandLoss10yr": {
            "type": "number",
            "description": "Profit / Loss from the the accounts investments over the given period. 10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1d": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1w": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount11m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount12m": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccountPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionAccount30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an investment account has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkWTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period - WTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkMTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkQTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkYTD": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDAnnualized": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Annualized)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkITDCummulative": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  ITD (Cummulative)",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1d": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1w": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark11m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  11m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark12m": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmarkPriorYr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  Prior Year",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark1yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark2yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  2yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark3yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark4yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  4yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark5yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark6yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  6yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark7yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  7yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark8yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  8yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark9yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  9yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark10yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark15yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  15yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark20yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  20yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark25yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  25yr",
            "format": "double",
            "nullable": true
          },
          "PerformanceContributionBenchmark30yr": {
            "type": "number",
            "description": "Measurement of how much the value of an benchmark has increased or decreased over a given period, expressed as a percentage. Period -  30yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectWTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectMTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectQTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectYTD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffectITD": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1d": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1w": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect6m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect9m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect12m": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect1yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect3yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect5yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "AllocationEffect10yr": {
            "type": "number",
            "description": "This measures the impact on the portfolios performance due to differences in asset allocation compared to the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectWTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectMTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectQTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectYTD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffectITD": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1d": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1w": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect6m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect9m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect12m": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect1yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect3yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect5yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "SelectionEffect10yr": {
            "type": "number",
            "description": "This measures the return attributable to the managers ability to pick securities within a given asset class or sector that outperform those in the benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionWTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  WTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionMTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  MTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionQTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  QTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionYTD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  YTD",
            "format": "double",
            "nullable": true
          },
          "TotalAttributionITD": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  ITD",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1d": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1d",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1w": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1w",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution6m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  6m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution9m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  9m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution12m": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  12m",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution1yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  1yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution3yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  3yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution5yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  5yr",
            "format": "double",
            "nullable": true
          },
          "TotalAttribution10yr": {
            "type": "number",
            "description": "This is the sum of the allocation, selection, and interaction effects, explaining the total difference in performance between the portfolio and its benchmark. Period -  10yr",
            "format": "double",
            "nullable": true
          },
          "InformationRatioPeriod": {
            "type": "number",
            "description": "Measures a portfolio managers ability to generate excess returns relative to a benchmark, but also considers the consistency of the performance.",
            "format": "double",
            "nullable": true
          },
          "SharpeRatioPeriod": {
            "type": "number",
            "description": "Used to understand the return of an investment compared to its risk. The ratio is the average return earned in excess of the risk-free rate per unit of volatility or total risk. Higher Sharpe ratios indicate better risk-adjusted performance.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PeriodPerformanceSegmentUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PeriodPerformanceSegmentUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Include": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Exclude": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Include": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Exclude": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PermissionApiCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Include": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Exclude": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PermissionApiDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Include": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Exclude": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PermissionApiDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PermissionApiDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Include": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Exclude": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PermissionApiGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Include": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Exclude": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Include": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Exclude": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionApiUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PermissionApiUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionDeleteCacheRequest": {
        "type": "object",
        "additionalProperties": false
      },
      "PermissionDeleteCacheResponse": {
        "type": "object",
        "additionalProperties": false
      },
      "PermissionDeleteCacheResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PermissionDeleteCacheResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionGetEvaluateRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Menu": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionGetEvaluateResponse": {
        "type": "object",
        "properties": {
          "Services": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "MenuServices": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "ComputedServices": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionGetEvaluateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PermissionGetEvaluateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionGetResponse": {
        "type": "object",
        "properties": {
          "Services": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PermissionGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Services": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Services": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PermissionMenuCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Services": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PermissionMenuDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Services": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PermissionMenuDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PermissionMenuDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Services": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PermissionMenuGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Services": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "type": "string",
            "nullable": true
          },
          "ReadOnly": {
            "type": "boolean"
          },
          "Services": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PermissionMenuUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PermissionMenuUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PlatformReason": {
        "type": "object",
        "properties": {
          "code": {
            "type": "string",
            "nullable": true
          },
          "message": {
            "type": "string",
            "nullable": true
          },
          "stackTrace": {
            "type": "string",
            "nullable": true
          },
          "property": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PortalDisclaimerAcceptanceDto": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          },
          "PortalAcceptance": {
            "type": "boolean",
            "nullable": true
          },
          "PortalAcceptanceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PortalDisclaimerAcceptanceDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PortalDisclaimerAcceptanceDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PortalDisclaimerAcceptanceDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PortalDisclaimerAcceptanceDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PortalDisclaimerAcceptanceQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionCreateRequest": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the day to the end of the day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the day to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayCostBasis": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the income accrued, measured in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current fiscal quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current fiscal quarter to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current fiscal quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current fiscal year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal year to the current day, accounting for the sum of realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current fiscal year, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current fiscal year to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income, measured in the base currency of the account, as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of the unrealized foreign exchange accrued income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income measured in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange accrued income gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current month to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current month to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income at the start of the current month, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, calculated from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current month to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPriorDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current quarter to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account at the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current quarter to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss, in the base currency of the account, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current week to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current week to the current day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account, measured from the start of the current week to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's start value of unrealized foreign exchange accrued income, measured in the base currency of the account from the start of the current week.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current year to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the foreign exchange rate for accrued income, measured from the start of the current year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current year to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundTNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "HasStalePrice": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Unique Serial Database ID for the Position Table",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the local currency of the security, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current fiscal quarter to the current day, expressed in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the inception of the account to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income, measured in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security from the inception of the account to the current day as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current month to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current month, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current week to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current week, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "The total number of units of a security that are currently held in the account.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "ExcludeCash": {
            "type": "string",
            "description": "If any value is present in this field, the returned values will not include cash positions (where SymTicker is USDOLLAR)",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionCreateResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the day to the end of the day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the day to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayCostBasis": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the income accrued, measured in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current fiscal quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current fiscal quarter to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current fiscal quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current fiscal year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal year to the current day, accounting for the sum of realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current fiscal year, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current fiscal year to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income, measured in the base currency of the account, as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of the unrealized foreign exchange accrued income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income measured in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange accrued income gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current month to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current month to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income at the start of the current month, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, calculated from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current month to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPriorDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current quarter to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account at the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current quarter to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss, in the base currency of the account, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current week to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current week to the current day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account, measured from the start of the current week to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's start value of unrealized foreign exchange accrued income, measured in the base currency of the account from the start of the current week.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current year to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the foreign exchange rate for accrued income, measured from the start of the current year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current year to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundTNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "HasStalePrice": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Unique Serial Database ID for the Position Table",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the local currency of the security, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current fiscal quarter to the current day, expressed in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the inception of the account to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income, measured in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security from the inception of the account to the current day as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current month to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current month, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current week to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current week, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "The total number of units of a security that are currently held in the account.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "ExcludeCash": {
            "type": "string",
            "description": "If any value is present in this field, the returned values will not include cash positions (where SymTicker is USDOLLAR)",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PositionCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionDeleteResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the day to the end of the day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the day to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayCostBasis": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the income accrued, measured in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current fiscal quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current fiscal quarter to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current fiscal quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current fiscal year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal year to the current day, accounting for the sum of realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current fiscal year, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current fiscal year to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income, measured in the base currency of the account, as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of the unrealized foreign exchange accrued income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income measured in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange accrued income gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current month to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current month to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income at the start of the current month, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, calculated from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current month to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPriorDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current quarter to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account at the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current quarter to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss, in the base currency of the account, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current week to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current week to the current day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account, measured from the start of the current week to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's start value of unrealized foreign exchange accrued income, measured in the base currency of the account from the start of the current week.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current year to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the foreign exchange rate for accrued income, measured from the start of the current year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current year to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundTNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "HasStalePrice": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Unique Serial Database ID for the Position Table",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the local currency of the security, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current fiscal quarter to the current day, expressed in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the inception of the account to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income, measured in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security from the inception of the account to the current day as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current month to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current month, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current week to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current week, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "The total number of units of a security that are currently held in the account.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "ExcludeCash": {
            "type": "string",
            "description": "If any value is present in this field, the returned values will not include cash positions (where SymTicker is USDOLLAR)",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PositionDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionDto": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the day to the end of the day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the day to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayCostBasis": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the income accrued, measured in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current fiscal quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current fiscal quarter to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current fiscal quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current fiscal year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal year to the current day, accounting for the sum of realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current fiscal year, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current fiscal year to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income, measured in the base currency of the account, as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of the unrealized foreign exchange accrued income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income measured in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange accrued income gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current month to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current month to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income at the start of the current month, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, calculated from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current month to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPriorDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current quarter to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account at the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current quarter to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss, in the base currency of the account, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current week to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current week to the current day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account, measured from the start of the current week to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's start value of unrealized foreign exchange accrued income, measured in the base currency of the account from the start of the current week.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current year to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the foreign exchange rate for accrued income, measured from the start of the current year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current year to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundTNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "HasStalePrice": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Unique Serial Database ID for the Position Table",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the local currency of the security, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current fiscal quarter to the current day, expressed in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the inception of the account to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income, measured in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security from the inception of the account to the current day as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current month to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current month, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current week to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current week, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "The total number of units of a security that are currently held in the account.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "ExcludeCash": {
            "type": "string",
            "description": "If any value is present in this field, the returned values will not include cash positions (where SymTicker is USDOLLAR)",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PositionDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PositionDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionGetResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the day to the end of the day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the day to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayCostBasis": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the income accrued, measured in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current fiscal quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current fiscal quarter to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current fiscal quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current fiscal year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal year to the current day, accounting for the sum of realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current fiscal year, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current fiscal year to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income, measured in the base currency of the account, as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of the unrealized foreign exchange accrued income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income measured in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange accrued income gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current month to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current month to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income at the start of the current month, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, calculated from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current month to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPriorDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current quarter to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account at the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current quarter to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss, in the base currency of the account, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current week to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current week to the current day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account, measured from the start of the current week to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's start value of unrealized foreign exchange accrued income, measured in the base currency of the account from the start of the current week.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current year to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the foreign exchange rate for accrued income, measured from the start of the current year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current year to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundTNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "HasStalePrice": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Unique Serial Database ID for the Position Table",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the local currency of the security, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current fiscal quarter to the current day, expressed in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the inception of the account to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income, measured in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security from the inception of the account to the current day as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current month to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current month, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current week to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current week, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "The total number of units of a security that are currently held in the account.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "ExcludeCash": {
            "type": "string",
            "description": "If any value is present in this field, the returned values will not include cash positions (where SymTicker is USDOLLAR)",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PositionGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionGroupDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "description": "Row identification Id",
            "nullable": true
          },
          "Label": {
            "type": "string",
            "description": "Label",
            "nullable": true
          },
          "Value": {
            "type": "number",
            "description": "Value",
            "format": "double",
            "nullable": true
          },
          "ValuePercent": {
            "type": "number",
            "description": "Value Percent",
            "format": "double",
            "nullable": true
          },
          "Color": {
            "type": "string",
            "description": "Color",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionQueryGroupedRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "SumBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "EnrichWithCodeSet": {
            "type": "boolean",
            "nullable": true
          },
          "CodeSetCategory": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionQueryGroupedResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PositionGroupDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionQueryGroupedResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PositionQueryGroupedResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionQueryODataRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "ODataQuery": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionQueryODataResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "type": "object",
              "additionalProperties": {
                "$ref": "#/components/schemas/JsonNode"
              }
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionQueryODataResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PositionQueryODataResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionUpdateRequest": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the day to the end of the day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the day to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayCostBasis": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the income accrued, measured in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current fiscal quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current fiscal quarter to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current fiscal quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current fiscal year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal year to the current day, accounting for the sum of realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current fiscal year, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current fiscal year to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income, measured in the base currency of the account, as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of the unrealized foreign exchange accrued income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income measured in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange accrued income gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current month to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current month to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income at the start of the current month, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, calculated from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current month to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPriorDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current quarter to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account at the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current quarter to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss, in the base currency of the account, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current week to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current week to the current day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account, measured from the start of the current week to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's start value of unrealized foreign exchange accrued income, measured in the base currency of the account from the start of the current week.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current year to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the foreign exchange rate for accrued income, measured from the start of the current year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current year to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundTNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "HasStalePrice": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Unique Serial Database ID for the Position Table",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the local currency of the security, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current fiscal quarter to the current day, expressed in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the inception of the account to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income, measured in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security from the inception of the account to the current day as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current month to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current month, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current week to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current week, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "The total number of units of a security that are currently held in the account.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "ExcludeCash": {
            "type": "string",
            "description": "If any value is present in this field, the returned values will not include cash positions (where SymTicker is USDOLLAR)",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionUpdateResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the day to the end of the day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured in the base currency of the account from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the day to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayCostBasis": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account for the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the income accrued, measured in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current fiscal quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current fiscal quarter to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current fiscal quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account as of the start of the current fiscal quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current fiscal year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income from the start of the current fiscal year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current fiscal year to the current day, accounting for the sum of realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current fiscal year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current fiscal year, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current fiscal year to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income, measured in the base currency of the account, as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account as of the start of the current fiscal year.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current fiscal year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the inception of the account to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the inception of the account to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of the unrealized foreign exchange accrued income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the inception of the account to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income measured in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current month to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange accrued income gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current month to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current month to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account, measured from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange accrued income at the start of the current month, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account, calculated from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current month to the current day, as measured at the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyPriorDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current quarter to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in the unrealized gain or loss attributable to changes in the FX rate for accrued income, measured from the start of the current quarter to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss, measured in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account at the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current quarter to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income at the start of the current quarter, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized foreign exchange price gain or loss in the base currency of the account, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss, in the base currency of the account, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current week to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income from the start of the current week to the current day in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the FX rate for changes in the price of the position, measured in the base currency of the account, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account, measured from the start of the current week to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current week, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's start value of unrealized foreign exchange accrued income, measured in the base currency of the account from the start of the current week.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured from the start of the current year to the current day, expressed in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeTotalGainLossPercent": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXAccruedIncomeGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized gain or loss attributable to changes in the foreign exchange rate for accrued income, measured from the start of the current year to the current day, in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the start of the current year to the current day, including all realized and unrealized gains and losses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expenses in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the base currency of the account, measured from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account from the start of the current year to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundTNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "HasStalePrice": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "Unique Serial Database ID for the Position Table",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the day to the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security for the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyDayStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security as of the start of the day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the local currency of the security, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current fiscal quarter to the current day, expressed in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current fiscal quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyFYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the inception of the account to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income, measured in the local currency of the security from the inception of the account to the current day, as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security from the inception of the account to the current day as of the start of the period.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current month to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current month to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the start of the current month, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyMTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current month.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyOriginalUnitCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income in the local currency of the security from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current quarter to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, measured in the local currency of the security, as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyQTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security, measured from the start of the current quarter.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income, measured from the start of the current week to the current day, in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income at the start of the current week, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyWTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the local currency of the security from the start of the current week to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedDividendIncome": {
            "type": "number",
            "description": "The position's change in accrued dividend income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedInterestIncome": {
            "type": "number",
            "description": "The position's change in accrued interest income from the start of the current year to the current day, measured in the local currency of the security.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeAccruedOtherIncome": {
            "type": "number",
            "description": "The position's change in accrued other income, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDChangeUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's change in unrealized price gain or loss, measured in the local currency of the security, from the start of the current year to the current day.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyYTDStartUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's value of unrealized price gain or loss in the local currency of the security as of the start of the current year.",
            "format": "double",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "The total number of units of a security that are currently held in the account.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "ExcludeCash": {
            "type": "string",
            "description": "If any value is present in this field, the returned values will not include cash positions (where SymTicker is USDOLLAR)",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PositionUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PositionUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PostLoginUserResponse": {
        "type": "object",
        "properties": {
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Tenant": {
            "type": "string",
            "nullable": true
          },
          "NatsJwt": {
            "type": "string",
            "nullable": true
          },
          "NatsSeed": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PriceHistoryDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Ticker": {
            "type": "string",
            "nullable": true
          },
          "LastUpdated": {
            "type": "string",
            "format": "date-time"
          },
          "AsOfDate": {
            "type": "string",
            "format": "date-time"
          },
          "EnterpriseValueDaily": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "EnterpriseValueOverEBIT": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "EnterpriseValueOverEBITDA": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "MarketCapitalization": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PriceToBookValue": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PriceEarningsRatio": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PriceSalesRatio": {
            "type": "number",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PriceHistoryDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PriceHistoryDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PriceHistoryDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PriceHistoryDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PriceHistoryPeriodDto": {
        "type": "object",
        "properties": {
          "Ticker": {
            "type": "string",
            "nullable": true
          },
          "Date": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "Period": {
            "type": "string",
            "nullable": true
          },
          "PriceChange": {
            "type": "number",
            "format": "double"
          },
          "PriceChangePercent": {
            "type": "number",
            "format": "double"
          },
          "Open": {
            "type": "number",
            "format": "double"
          },
          "High": {
            "type": "number",
            "format": "double"
          },
          "Low": {
            "type": "number",
            "format": "double"
          },
          "Close": {
            "type": "number",
            "format": "double"
          },
          "Volume": {
            "type": "number",
            "format": "double"
          },
          "CloseAdj": {
            "type": "number",
            "format": "double"
          },
          "CloseUnadj": {
            "type": "number",
            "format": "double"
          },
          "LastUpdated": {
            "type": "string",
            "format": "date",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PriceHistoryQueryPeriodsRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "nullable": true
          },
          "Periods": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "InceptionDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PriceHistoryQueryPeriodsResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PriceHistoryPeriodDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PriceHistoryQueryPeriodsResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PriceHistoryQueryPeriodsResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PriceHistoryQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "Tickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Date": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PricingQuotesGetRequest": {
        "type": "object",
        "properties": {
          "Symbols": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PricingQuotesGetResponse": {
        "type": "object",
        "properties": {
          "Quotes": {
            "$ref": "#/components/schemas/JsonNode"
          }
        },
        "additionalProperties": false
      },
      "PricingQuotesGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PricingQuotesGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetCreateRequest": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "description": "Primarily for the Watch List, this is the personal position in a security (date acquired)",
            "format": "date",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "description": "Primarily for the Watch List, this is the personal position in a security (Total cost of the current position - not cost per share)",
            "format": "double",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "CountryOfAssetLocation": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "DiscountRate": {
            "type": "string",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Insurance System",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Onboarding System",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Performance System",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Planning System",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Portfolio Management System",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Risk System",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each Private Asset",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangePrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceDates": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceNumbers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Custom short name for the account for reporting purposes",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymOtherSymbols": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "Quantity of a position (also used in WatchList personal GL calculation)",
            "format": "double",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AssetLocation": {
            "type": "string",
            "description": "Asset Location",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Record Context",
            "nullable": true
          },
          "Color": {
            "type": "string",
            "nullable": true
          },
          "DayZeroGL": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "CurrentPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "TotalCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossAsOfPeriodStart": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PriceHistoryDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "AutoCreateAccount": {
            "type": "boolean",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetCreateResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "description": "Primarily for the Watch List, this is the personal position in a security (date acquired)",
            "format": "date",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "description": "Primarily for the Watch List, this is the personal position in a security (Total cost of the current position - not cost per share)",
            "format": "double",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "CountryOfAssetLocation": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "DiscountRate": {
            "type": "string",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Insurance System",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Onboarding System",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Performance System",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Planning System",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Portfolio Management System",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Risk System",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each Private Asset",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangePrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceDates": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceNumbers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Custom short name for the account for reporting purposes",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymOtherSymbols": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "Quantity of a position (also used in WatchList personal GL calculation)",
            "format": "double",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AssetLocation": {
            "type": "string",
            "description": "Asset Location",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Record Context",
            "nullable": true
          },
          "Color": {
            "type": "string",
            "nullable": true
          },
          "DayZeroGL": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "CurrentPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "TotalCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossAsOfPeriodStart": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PriceHistoryDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PrivateAssetCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetDeleteResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "description": "Primarily for the Watch List, this is the personal position in a security (date acquired)",
            "format": "date",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "description": "Primarily for the Watch List, this is the personal position in a security (Total cost of the current position - not cost per share)",
            "format": "double",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "CountryOfAssetLocation": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "DiscountRate": {
            "type": "string",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Insurance System",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Onboarding System",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Performance System",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Planning System",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Portfolio Management System",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Risk System",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each Private Asset",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangePrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceDates": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceNumbers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Custom short name for the account for reporting purposes",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymOtherSymbols": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "Quantity of a position (also used in WatchList personal GL calculation)",
            "format": "double",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AssetLocation": {
            "type": "string",
            "description": "Asset Location",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Record Context",
            "nullable": true
          },
          "Color": {
            "type": "string",
            "nullable": true
          },
          "DayZeroGL": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "CurrentPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "TotalCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossAsOfPeriodStart": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PriceHistoryDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PrivateAssetDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetDto": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "description": "Primarily for the Watch List, this is the personal position in a security (date acquired)",
            "format": "date",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "description": "Primarily for the Watch List, this is the personal position in a security (Total cost of the current position - not cost per share)",
            "format": "double",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "CountryOfAssetLocation": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "DiscountRate": {
            "type": "string",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Insurance System",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Onboarding System",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Performance System",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Planning System",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Portfolio Management System",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Risk System",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each Private Asset",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangePrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceDates": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceNumbers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Custom short name for the account for reporting purposes",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymOtherSymbols": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "Quantity of a position (also used in WatchList personal GL calculation)",
            "format": "double",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AssetLocation": {
            "type": "string",
            "description": "Asset Location",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Record Context",
            "nullable": true
          },
          "Color": {
            "type": "string",
            "nullable": true
          },
          "DayZeroGL": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "CurrentPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "TotalCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossAsOfPeriodStart": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PriceHistoryDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PrivateAssetDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PrivateAssetDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetGetResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "description": "Primarily for the Watch List, this is the personal position in a security (date acquired)",
            "format": "date",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "description": "Primarily for the Watch List, this is the personal position in a security (Total cost of the current position - not cost per share)",
            "format": "double",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "CountryOfAssetLocation": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "DiscountRate": {
            "type": "string",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Insurance System",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Onboarding System",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Performance System",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Planning System",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Portfolio Management System",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Risk System",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each Private Asset",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangePrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceDates": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceNumbers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Custom short name for the account for reporting purposes",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymOtherSymbols": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "Quantity of a position (also used in WatchList personal GL calculation)",
            "format": "double",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AssetLocation": {
            "type": "string",
            "description": "Asset Location",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Record Context",
            "nullable": true
          },
          "Color": {
            "type": "string",
            "nullable": true
          },
          "DayZeroGL": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "CurrentPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "TotalCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossAsOfPeriodStart": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PriceHistoryDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PrivateAssetGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetMergeRequest": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "description": "Primarily for the Watch List, this is the personal position in a security (date acquired)",
            "format": "date",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "description": "Primarily for the Watch List, this is the personal position in a security (Total cost of the current position - not cost per share)",
            "format": "double",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "CountryOfAssetLocation": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "DiscountRate": {
            "type": "string",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Insurance System",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Onboarding System",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Performance System",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Planning System",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Portfolio Management System",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Risk System",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each Private Asset",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangePrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceDates": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceNumbers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Custom short name for the account for reporting purposes",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymOtherSymbols": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "Quantity of a position (also used in WatchList personal GL calculation)",
            "format": "double",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AssetLocation": {
            "type": "string",
            "description": "Asset Location",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Record Context",
            "nullable": true
          },
          "Color": {
            "type": "string",
            "nullable": true
          },
          "DayZeroGL": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "CurrentPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "TotalCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossAsOfPeriodStart": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PriceHistoryDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "AutoCreateAccount": {
            "type": "boolean",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetMergeResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "description": "Primarily for the Watch List, this is the personal position in a security (date acquired)",
            "format": "date",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "description": "Primarily for the Watch List, this is the personal position in a security (Total cost of the current position - not cost per share)",
            "format": "double",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "CountryOfAssetLocation": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "DiscountRate": {
            "type": "string",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Insurance System",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Onboarding System",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Performance System",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Planning System",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Portfolio Management System",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Risk System",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each Private Asset",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangePrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceDates": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceNumbers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Custom short name for the account for reporting purposes",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymOtherSymbols": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "Quantity of a position (also used in WatchList personal GL calculation)",
            "format": "double",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AssetLocation": {
            "type": "string",
            "description": "Asset Location",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Record Context",
            "nullable": true
          },
          "Color": {
            "type": "string",
            "nullable": true
          },
          "DayZeroGL": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "CurrentPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "TotalCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossAsOfPeriodStart": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PriceHistoryDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PrivateAssetMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetQueryComputePersonalGainLossRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "AccountIds": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "UserId": {
            "type": "string",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetQueryComputePersonalGainLossResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PrivateAssetDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetQueryComputePersonalGainLossResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PrivateAssetQueryComputePersonalGainLossResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "EnrichWithCodeSet": {
            "type": "boolean",
            "nullable": true
          },
          "CodeSetCategory": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetUpdateRequest": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "description": "Primarily for the Watch List, this is the personal position in a security (date acquired)",
            "format": "date",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "description": "Primarily for the Watch List, this is the personal position in a security (Total cost of the current position - not cost per share)",
            "format": "double",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "CountryOfAssetLocation": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "DiscountRate": {
            "type": "string",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Insurance System",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Onboarding System",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Performance System",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Planning System",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Portfolio Management System",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Risk System",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each Private Asset",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangePrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceDates": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceNumbers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Custom short name for the account for reporting purposes",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymOtherSymbols": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "Quantity of a position (also used in WatchList personal GL calculation)",
            "format": "double",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AssetLocation": {
            "type": "string",
            "description": "Asset Location",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Record Context",
            "nullable": true
          },
          "Color": {
            "type": "string",
            "nullable": true
          },
          "DayZeroGL": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "CurrentPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "TotalCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossAsOfPeriodStart": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PriceHistoryDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "AutoCreateAccount": {
            "type": "boolean",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetUpdateResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "AccountNumber": {
            "type": "string",
            "description": "The unique business key of the financial account for the client",
            "nullable": true
          },
          "AcquisitionDate": {
            "type": "string",
            "description": "Primarily for the Watch List, this is the personal position in a security (date acquired)",
            "format": "date",
            "nullable": true
          },
          "AcquisitionCost": {
            "type": "number",
            "description": "Primarily for the Watch List, this is the personal position in a security (Total cost of the current position - not cost per share)",
            "format": "double",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "AssetDescriptionBrief": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetDescriptionDetailed": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetFriendlyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AssetLegalName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoMake": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoModel": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "AutoYear": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyCode": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedDividendIncome": {
            "type": "number",
            "description": "The position's accrued dividend income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedInterestIncome": {
            "type": "number",
            "description": "The position's accrued interest income, valued in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndAccruedOtherIncome": {
            "type": "number",
            "description": "The position's accrued other income at the end of the day, calculated in the base currency of the account.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXAccruedIncome": {
            "type": "number",
            "description": "The position's unrealized foreign exchange accrued income in the base currency of the account as of the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedFXPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized foreign exchange price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyDayEndUnrealizedPriceGainLoss": {
            "type": "number",
            "description": "The position's unrealized price gain or loss in the base currency of the account at the end of the day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyEndMarketValue": {
            "type": "number",
            "description": "The position's total value in the base currency of the account, measured as of the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDNetProfitLoss": {
            "type": "number",
            "description": "The position's net profit or loss in the base currency of the account from the inception of the account to the current day, which is the sum of realized and unrealized gains and losses including interest, dividends, trading activities, and other income or expenses.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedDividendIncome": {
            "type": "number",
            "description": "The position's realized dividend income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestExpense": {
            "type": "number",
            "description": "The position's realized interest expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedInterestIncome": {
            "type": "number",
            "description": "The position's realized interest income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherExpense": {
            "type": "number",
            "description": "The position's realized other expense in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedOtherIncome": {
            "type": "number",
            "description": "The position's realized other income in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyITDRealizedTradingGainLoss": {
            "type": "number",
            "description": "The position's realized trading gain or loss in the base currency of the account from the inception of the account to the current day.",
            "format": "double",
            "nullable": true
          },
          "BaseCurrencyOriginalCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "CountryOfAssetLocation": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "DiscountRate": {
            "type": "string",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "nullable": true
          },
          "ExpectedFutureLiquidationDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ExpectedFutureValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "External Foreign Key used for Id resolution with an outside system",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Compliance System",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions CRM System",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Custody System",
            "nullable": true
          },
          "ExternalSystemKeyInsurance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Insurance System",
            "nullable": true
          },
          "ExternalSystemKeyOnboarding": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Onboarding System",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Performance System",
            "nullable": true
          },
          "ExternalSystemKeyPlanning": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Planning System",
            "nullable": true
          },
          "ExternalSystemKeyPortfolioManagement": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Portfolio Management System",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Regulatory System",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Risk System",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "The financial institutions unique id for the account in the institutions Trading System",
            "nullable": true
          },
          "FinancialInstitutionName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FutureIncomeEndDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomePeriodicity": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "FutureIncomeStartDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "HasAlert": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each Private Asset",
            "nullable": true
          },
          "InterestRatePaid": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "InterestRatePayable": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "IsExcludedFromNetWorth": {
            "type": "boolean",
            "description": "",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "LocalCurrencyEndMarketValue": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyITDChangePrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyPrice": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "PerPeriodFutureIncome": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PerUnitAcquisitionCost": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "PriceDate": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PrincipalBalance": {
            "type": "number",
            "description": "",
            "format": "double",
            "nullable": true
          },
          "RecordState": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceDates": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "ReferenceNumbers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedPartyAddress": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyEmail": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyName": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedPartyPhone": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ShortName": {
            "type": "string",
            "description": "Custom short name for the account for reporting purposes",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymOtherSymbols": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "TaxTreatmentMethod": {
            "type": "string",
            "description": "",
            "nullable": true
          },
          "UnitsHeld": {
            "type": "number",
            "description": "Quantity of a position (also used in WatchList personal GL calculation)",
            "format": "double",
            "nullable": true
          },
          "ComputePersonalGainLoss": {
            "type": "boolean",
            "nullable": true
          },
          "HistoricalPricePeriod": {
            "type": "string",
            "nullable": true
          },
          "HistoricalPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "HistoricalPriceChangePct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossMoney": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossPct": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "AssetLocation": {
            "type": "string",
            "description": "Asset Location",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Record Context",
            "nullable": true
          },
          "Color": {
            "type": "string",
            "nullable": true
          },
          "DayZeroGL": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "CurrentPrice": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "TotalCost": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PersonalGainLossAsOfPeriodStart": {
            "type": "number",
            "format": "double",
            "nullable": true
          },
          "PriceHistoryDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "PrivateAssetUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/PrivateAssetUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "QualifiedStyle": {
        "type": "object",
        "properties": {
          "Key": {
            "type": "string",
            "nullable": true
          },
          "FullClassName": {
            "type": "string",
            "nullable": true
          },
          "Qualifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Value": {
            "$ref": "#/components/schemas/JsonNode"
          }
        },
        "additionalProperties": false
      },
      "RefreshTokenRequest": {
        "type": "object",
        "properties": {
          "RefreshToken": {
            "type": "string",
            "nullable": true
          },
          "UserId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "RefreshTokenResponse": {
        "type": "object",
        "properties": {
          "RefreshToken": {
            "type": "string",
            "nullable": true
          },
          "AccessToken": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Tenant": {
            "type": "string",
            "nullable": true
          },
          "NatsJwt": {
            "type": "string",
            "nullable": true
          },
          "NatsSeed": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "RegisterUserRequest": {
        "type": "object",
        "properties": {
          "UserId": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Password": {
            "type": "string",
            "nullable": true
          },
          "Tenant": {
            "$ref": "#/components/schemas/ITenant"
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean"
          },
          "UseOtpSms": {
            "type": "boolean"
          },
          "Menu": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ReviewItem": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "__typename": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "SerialNumber": {
            "type": "string",
            "nullable": true
          },
          "Screenshot": {
            "type": "string",
            "nullable": true
          },
          "Tags": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ScoreCreateRequest": {
        "required": [
          "Player"
        ],
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Player": {
            "type": "string",
            "nullable": true
          },
          "Current": {
            "type": "integer",
            "format": "int32"
          },
          "Best": {
            "type": "integer",
            "format": "int32"
          }
        },
        "additionalProperties": false
      },
      "ScoreCreateResponse": {
        "required": [
          "Player"
        ],
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Player": {
            "type": "string",
            "nullable": true
          },
          "Current": {
            "type": "integer",
            "format": "int32"
          },
          "Best": {
            "type": "integer",
            "format": "int32"
          }
        },
        "additionalProperties": false
      },
      "ScoreCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/ScoreCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ScoreDto": {
        "required": [
          "Player"
        ],
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Player": {
            "type": "string",
            "nullable": true
          },
          "Current": {
            "type": "integer",
            "format": "int32"
          },
          "Best": {
            "type": "integer",
            "format": "int32"
          }
        },
        "additionalProperties": false
      },
      "ScoreDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/ScoreDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ScoreDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/ScoreDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ScoreGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "MinScore": {
            "type": "integer",
            "format": "int32",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ScoreGetResponse": {
        "required": [
          "Player"
        ],
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Player": {
            "type": "string",
            "nullable": true
          },
          "Current": {
            "type": "integer",
            "format": "int32"
          },
          "Best": {
            "type": "integer",
            "format": "int32"
          }
        },
        "additionalProperties": false
      },
      "ScoreGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/ScoreGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "ScoreQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "MinScore": {
            "type": "integer",
            "format": "int32",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BondInsurer": {
            "type": "string",
            "description": "A company that insures the principal and/or interest payments of bond issuers, providing financial protection and enhancing the creditworthiness of the bond.",
            "nullable": true
          },
          "BondPremium": {
            "type": "number",
            "description": "The amount by which the market price of a bond exceeds its face value.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CEFundFiscalYearEnd": {
            "type": "string",
            "description": "The value of the fund's assets and liabilities at the close of its fiscal year.",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "Convexity": {
            "type": "number",
            "description": "A measure of the curvature in the relationship between bond prices and bond yields, indicating how the duration of a bond changes as interest rates change.",
            "format": "double",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "A unique identifier used to specify the type of currency in which financial transactions are recorded or reported, such as transactions within a financial account.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "Delta": {
            "type": "number",
            "description": "The ratio that compares the change in the price of the underlying asset to the corresponding change in the price of the option.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "description": "The URL link to a document that provides a summary of key information about a financial product, including its performance, investment strategy, and associated risks.",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "integer",
            "description": "The unique identifier of the company that serves as the investment advisor for a fund.",
            "format": "int32",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundCountryOfSales": {
            "type": "string",
            "description": "The country or countries where a fund is actively marketed and sold to investors.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundNAVMonthEndDate": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundValuationFrequency": {
            "type": "string",
            "description": "The regular interval at which the Net Asset Value (NAV) of a fund is calculated and reported, typically used to monitor and manage the performance of the investment vehicle.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "Gamma": {
            "type": "number",
            "description": "The rate of change in delta for a one-point move in the price of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "ImpliedVolatility": {
            "type": "number",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "format": "double",
            "nullable": true
          },
          "InceptionDate": {
            "type": "string",
            "description": "The date on which a financial account or investment was initially established or created.",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsAccumulatedFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether a financial account is structured as an accumulated fund, where contributions and earnings are pooled together over time to increase the value of the fund.",
            "nullable": true
          },
          "IsCallable": {
            "type": "boolean",
            "description": "A bond that can be redeemed by the issuer prior to its maturity.",
            "nullable": true
          },
          "IsCorporate": {
            "type": "boolean",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFloatingRate": {
            "type": "boolean",
            "description": "A bond with a variable interest rate that is adjusted periodically based on a predetermined formula or index.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IsPutable": {
            "type": "boolean",
            "description": "A bond that gives the holder the right to force the issuer to repurchase the security at specified dates before maturity.",
            "nullable": true
          },
          "IsSovereign": {
            "type": "boolean",
            "description": "A bond issued by a national government.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "IsUCITSCompliant": {
            "type": "string",
            "description": "Indicates whether a fund adheres to the standards and regulations set by the Undertakings for Collective Investment in Transferable Securities (UCITS), a regulatory framework of the European Union designed to allow mutual funds to operate freely throughout the EU on the basis of a single authorization from one member state.",
            "nullable": true
          },
          "IsZeroCoupon": {
            "type": "boolean",
            "description": "A bond that is issued at a discount to its face value and does not pay periodic interest payments.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency used within the local market or region where a security is traded, often used to report financial metrics relevant to that specific area.",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MaturityExpiryDate": {
            "type": "string",
            "description": "The date on which a financial instrument, such as a bond or an option, reaches its contractual end and the principal is due to be repaid or the contract ceases to be valid.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "OptionPremium": {
            "type": "number",
            "description": "The price that the buyer of the option pays to the seller for the rights granted by the option.",
            "format": "double",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "description": "A unique symbol assigned to represent specific metadata or attributes within a financial system, typically used to identify and categorize data fields systematically.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for permanent trading purposes on the Nasdaq exchange.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "Theta": {
            "type": "number",
            "description": "The rate of decline in the value of an option due to the passage of time.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "Vega": {
            "type": "number",
            "description": "The measure of an option's sensitivity to changes in the volatility of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "YieldSpread": {
            "type": "number",
            "description": "The difference between the yield on a bond and the yield on a benchmark bond or index, often reflecting the bond’s credit risk.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "CIK": {
            "type": "string",
            "description": "SEC Unique ID for filings",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BondInsurer": {
            "type": "string",
            "description": "A company that insures the principal and/or interest payments of bond issuers, providing financial protection and enhancing the creditworthiness of the bond.",
            "nullable": true
          },
          "BondPremium": {
            "type": "number",
            "description": "The amount by which the market price of a bond exceeds its face value.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CEFundFiscalYearEnd": {
            "type": "string",
            "description": "The value of the fund's assets and liabilities at the close of its fiscal year.",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "Convexity": {
            "type": "number",
            "description": "A measure of the curvature in the relationship between bond prices and bond yields, indicating how the duration of a bond changes as interest rates change.",
            "format": "double",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "A unique identifier used to specify the type of currency in which financial transactions are recorded or reported, such as transactions within a financial account.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "Delta": {
            "type": "number",
            "description": "The ratio that compares the change in the price of the underlying asset to the corresponding change in the price of the option.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "description": "The URL link to a document that provides a summary of key information about a financial product, including its performance, investment strategy, and associated risks.",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "integer",
            "description": "The unique identifier of the company that serves as the investment advisor for a fund.",
            "format": "int32",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundCountryOfSales": {
            "type": "string",
            "description": "The country or countries where a fund is actively marketed and sold to investors.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundNAVMonthEndDate": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundValuationFrequency": {
            "type": "string",
            "description": "The regular interval at which the Net Asset Value (NAV) of a fund is calculated and reported, typically used to monitor and manage the performance of the investment vehicle.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "Gamma": {
            "type": "number",
            "description": "The rate of change in delta for a one-point move in the price of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "ImpliedVolatility": {
            "type": "number",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "format": "double",
            "nullable": true
          },
          "InceptionDate": {
            "type": "string",
            "description": "The date on which a financial account or investment was initially established or created.",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsAccumulatedFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether a financial account is structured as an accumulated fund, where contributions and earnings are pooled together over time to increase the value of the fund.",
            "nullable": true
          },
          "IsCallable": {
            "type": "boolean",
            "description": "A bond that can be redeemed by the issuer prior to its maturity.",
            "nullable": true
          },
          "IsCorporate": {
            "type": "boolean",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFloatingRate": {
            "type": "boolean",
            "description": "A bond with a variable interest rate that is adjusted periodically based on a predetermined formula or index.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IsPutable": {
            "type": "boolean",
            "description": "A bond that gives the holder the right to force the issuer to repurchase the security at specified dates before maturity.",
            "nullable": true
          },
          "IsSovereign": {
            "type": "boolean",
            "description": "A bond issued by a national government.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "IsUCITSCompliant": {
            "type": "string",
            "description": "Indicates whether a fund adheres to the standards and regulations set by the Undertakings for Collective Investment in Transferable Securities (UCITS), a regulatory framework of the European Union designed to allow mutual funds to operate freely throughout the EU on the basis of a single authorization from one member state.",
            "nullable": true
          },
          "IsZeroCoupon": {
            "type": "boolean",
            "description": "A bond that is issued at a discount to its face value and does not pay periodic interest payments.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency used within the local market or region where a security is traded, often used to report financial metrics relevant to that specific area.",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MaturityExpiryDate": {
            "type": "string",
            "description": "The date on which a financial instrument, such as a bond or an option, reaches its contractual end and the principal is due to be repaid or the contract ceases to be valid.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "OptionPremium": {
            "type": "number",
            "description": "The price that the buyer of the option pays to the seller for the rights granted by the option.",
            "format": "double",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "description": "A unique symbol assigned to represent specific metadata or attributes within a financial system, typically used to identify and categorize data fields systematically.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for permanent trading purposes on the Nasdaq exchange.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "Theta": {
            "type": "number",
            "description": "The rate of decline in the value of an option due to the passage of time.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "Vega": {
            "type": "number",
            "description": "The measure of an option's sensitivity to changes in the volatility of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "YieldSpread": {
            "type": "number",
            "description": "The difference between the yield on a bond and the yield on a benchmark bond or index, often reflecting the bond’s credit risk.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "CIK": {
            "type": "string",
            "description": "SEC Unique ID for filings",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/SecurityMasterCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BondInsurer": {
            "type": "string",
            "description": "A company that insures the principal and/or interest payments of bond issuers, providing financial protection and enhancing the creditworthiness of the bond.",
            "nullable": true
          },
          "BondPremium": {
            "type": "number",
            "description": "The amount by which the market price of a bond exceeds its face value.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CEFundFiscalYearEnd": {
            "type": "string",
            "description": "The value of the fund's assets and liabilities at the close of its fiscal year.",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "Convexity": {
            "type": "number",
            "description": "A measure of the curvature in the relationship between bond prices and bond yields, indicating how the duration of a bond changes as interest rates change.",
            "format": "double",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "A unique identifier used to specify the type of currency in which financial transactions are recorded or reported, such as transactions within a financial account.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "Delta": {
            "type": "number",
            "description": "The ratio that compares the change in the price of the underlying asset to the corresponding change in the price of the option.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "description": "The URL link to a document that provides a summary of key information about a financial product, including its performance, investment strategy, and associated risks.",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "integer",
            "description": "The unique identifier of the company that serves as the investment advisor for a fund.",
            "format": "int32",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundCountryOfSales": {
            "type": "string",
            "description": "The country or countries where a fund is actively marketed and sold to investors.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundNAVMonthEndDate": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundValuationFrequency": {
            "type": "string",
            "description": "The regular interval at which the Net Asset Value (NAV) of a fund is calculated and reported, typically used to monitor and manage the performance of the investment vehicle.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "Gamma": {
            "type": "number",
            "description": "The rate of change in delta for a one-point move in the price of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "ImpliedVolatility": {
            "type": "number",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "format": "double",
            "nullable": true
          },
          "InceptionDate": {
            "type": "string",
            "description": "The date on which a financial account or investment was initially established or created.",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsAccumulatedFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether a financial account is structured as an accumulated fund, where contributions and earnings are pooled together over time to increase the value of the fund.",
            "nullable": true
          },
          "IsCallable": {
            "type": "boolean",
            "description": "A bond that can be redeemed by the issuer prior to its maturity.",
            "nullable": true
          },
          "IsCorporate": {
            "type": "boolean",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFloatingRate": {
            "type": "boolean",
            "description": "A bond with a variable interest rate that is adjusted periodically based on a predetermined formula or index.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IsPutable": {
            "type": "boolean",
            "description": "A bond that gives the holder the right to force the issuer to repurchase the security at specified dates before maturity.",
            "nullable": true
          },
          "IsSovereign": {
            "type": "boolean",
            "description": "A bond issued by a national government.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "IsUCITSCompliant": {
            "type": "string",
            "description": "Indicates whether a fund adheres to the standards and regulations set by the Undertakings for Collective Investment in Transferable Securities (UCITS), a regulatory framework of the European Union designed to allow mutual funds to operate freely throughout the EU on the basis of a single authorization from one member state.",
            "nullable": true
          },
          "IsZeroCoupon": {
            "type": "boolean",
            "description": "A bond that is issued at a discount to its face value and does not pay periodic interest payments.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency used within the local market or region where a security is traded, often used to report financial metrics relevant to that specific area.",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MaturityExpiryDate": {
            "type": "string",
            "description": "The date on which a financial instrument, such as a bond or an option, reaches its contractual end and the principal is due to be repaid or the contract ceases to be valid.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "OptionPremium": {
            "type": "number",
            "description": "The price that the buyer of the option pays to the seller for the rights granted by the option.",
            "format": "double",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "description": "A unique symbol assigned to represent specific metadata or attributes within a financial system, typically used to identify and categorize data fields systematically.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for permanent trading purposes on the Nasdaq exchange.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "Theta": {
            "type": "number",
            "description": "The rate of decline in the value of an option due to the passage of time.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "Vega": {
            "type": "number",
            "description": "The measure of an option's sensitivity to changes in the volatility of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "YieldSpread": {
            "type": "number",
            "description": "The difference between the yield on a bond and the yield on a benchmark bond or index, often reflecting the bond’s credit risk.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "CIK": {
            "type": "string",
            "description": "SEC Unique ID for filings",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/SecurityMasterDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BondInsurer": {
            "type": "string",
            "description": "A company that insures the principal and/or interest payments of bond issuers, providing financial protection and enhancing the creditworthiness of the bond.",
            "nullable": true
          },
          "BondPremium": {
            "type": "number",
            "description": "The amount by which the market price of a bond exceeds its face value.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CEFundFiscalYearEnd": {
            "type": "string",
            "description": "The value of the fund's assets and liabilities at the close of its fiscal year.",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "Convexity": {
            "type": "number",
            "description": "A measure of the curvature in the relationship between bond prices and bond yields, indicating how the duration of a bond changes as interest rates change.",
            "format": "double",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "A unique identifier used to specify the type of currency in which financial transactions are recorded or reported, such as transactions within a financial account.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "Delta": {
            "type": "number",
            "description": "The ratio that compares the change in the price of the underlying asset to the corresponding change in the price of the option.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "description": "The URL link to a document that provides a summary of key information about a financial product, including its performance, investment strategy, and associated risks.",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "integer",
            "description": "The unique identifier of the company that serves as the investment advisor for a fund.",
            "format": "int32",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundCountryOfSales": {
            "type": "string",
            "description": "The country or countries where a fund is actively marketed and sold to investors.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundNAVMonthEndDate": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundValuationFrequency": {
            "type": "string",
            "description": "The regular interval at which the Net Asset Value (NAV) of a fund is calculated and reported, typically used to monitor and manage the performance of the investment vehicle.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "Gamma": {
            "type": "number",
            "description": "The rate of change in delta for a one-point move in the price of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "ImpliedVolatility": {
            "type": "number",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "format": "double",
            "nullable": true
          },
          "InceptionDate": {
            "type": "string",
            "description": "The date on which a financial account or investment was initially established or created.",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsAccumulatedFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether a financial account is structured as an accumulated fund, where contributions and earnings are pooled together over time to increase the value of the fund.",
            "nullable": true
          },
          "IsCallable": {
            "type": "boolean",
            "description": "A bond that can be redeemed by the issuer prior to its maturity.",
            "nullable": true
          },
          "IsCorporate": {
            "type": "boolean",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFloatingRate": {
            "type": "boolean",
            "description": "A bond with a variable interest rate that is adjusted periodically based on a predetermined formula or index.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IsPutable": {
            "type": "boolean",
            "description": "A bond that gives the holder the right to force the issuer to repurchase the security at specified dates before maturity.",
            "nullable": true
          },
          "IsSovereign": {
            "type": "boolean",
            "description": "A bond issued by a national government.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "IsUCITSCompliant": {
            "type": "string",
            "description": "Indicates whether a fund adheres to the standards and regulations set by the Undertakings for Collective Investment in Transferable Securities (UCITS), a regulatory framework of the European Union designed to allow mutual funds to operate freely throughout the EU on the basis of a single authorization from one member state.",
            "nullable": true
          },
          "IsZeroCoupon": {
            "type": "boolean",
            "description": "A bond that is issued at a discount to its face value and does not pay periodic interest payments.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency used within the local market or region where a security is traded, often used to report financial metrics relevant to that specific area.",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MaturityExpiryDate": {
            "type": "string",
            "description": "The date on which a financial instrument, such as a bond or an option, reaches its contractual end and the principal is due to be repaid or the contract ceases to be valid.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "OptionPremium": {
            "type": "number",
            "description": "The price that the buyer of the option pays to the seller for the rights granted by the option.",
            "format": "double",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "description": "A unique symbol assigned to represent specific metadata or attributes within a financial system, typically used to identify and categorize data fields systematically.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for permanent trading purposes on the Nasdaq exchange.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "Theta": {
            "type": "number",
            "description": "The rate of decline in the value of an option due to the passage of time.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "Vega": {
            "type": "number",
            "description": "The measure of an option's sensitivity to changes in the volatility of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "YieldSpread": {
            "type": "number",
            "description": "The difference between the yield on a bond and the yield on a benchmark bond or index, often reflecting the bond’s credit risk.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "CIK": {
            "type": "string",
            "description": "SEC Unique ID for filings",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/SecurityMasterDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/SecurityMasterDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "SearchString": {
            "type": "string",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BondInsurer": {
            "type": "string",
            "description": "A company that insures the principal and/or interest payments of bond issuers, providing financial protection and enhancing the creditworthiness of the bond.",
            "nullable": true
          },
          "BondPremium": {
            "type": "number",
            "description": "The amount by which the market price of a bond exceeds its face value.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CEFundFiscalYearEnd": {
            "type": "string",
            "description": "The value of the fund's assets and liabilities at the close of its fiscal year.",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "Convexity": {
            "type": "number",
            "description": "A measure of the curvature in the relationship between bond prices and bond yields, indicating how the duration of a bond changes as interest rates change.",
            "format": "double",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "A unique identifier used to specify the type of currency in which financial transactions are recorded or reported, such as transactions within a financial account.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "Delta": {
            "type": "number",
            "description": "The ratio that compares the change in the price of the underlying asset to the corresponding change in the price of the option.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "description": "The URL link to a document that provides a summary of key information about a financial product, including its performance, investment strategy, and associated risks.",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "integer",
            "description": "The unique identifier of the company that serves as the investment advisor for a fund.",
            "format": "int32",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundCountryOfSales": {
            "type": "string",
            "description": "The country or countries where a fund is actively marketed and sold to investors.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundNAVMonthEndDate": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundValuationFrequency": {
            "type": "string",
            "description": "The regular interval at which the Net Asset Value (NAV) of a fund is calculated and reported, typically used to monitor and manage the performance of the investment vehicle.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "Gamma": {
            "type": "number",
            "description": "The rate of change in delta for a one-point move in the price of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "ImpliedVolatility": {
            "type": "number",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "format": "double",
            "nullable": true
          },
          "InceptionDate": {
            "type": "string",
            "description": "The date on which a financial account or investment was initially established or created.",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsAccumulatedFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether a financial account is structured as an accumulated fund, where contributions and earnings are pooled together over time to increase the value of the fund.",
            "nullable": true
          },
          "IsCallable": {
            "type": "boolean",
            "description": "A bond that can be redeemed by the issuer prior to its maturity.",
            "nullable": true
          },
          "IsCorporate": {
            "type": "boolean",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFloatingRate": {
            "type": "boolean",
            "description": "A bond with a variable interest rate that is adjusted periodically based on a predetermined formula or index.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IsPutable": {
            "type": "boolean",
            "description": "A bond that gives the holder the right to force the issuer to repurchase the security at specified dates before maturity.",
            "nullable": true
          },
          "IsSovereign": {
            "type": "boolean",
            "description": "A bond issued by a national government.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "IsUCITSCompliant": {
            "type": "string",
            "description": "Indicates whether a fund adheres to the standards and regulations set by the Undertakings for Collective Investment in Transferable Securities (UCITS), a regulatory framework of the European Union designed to allow mutual funds to operate freely throughout the EU on the basis of a single authorization from one member state.",
            "nullable": true
          },
          "IsZeroCoupon": {
            "type": "boolean",
            "description": "A bond that is issued at a discount to its face value and does not pay periodic interest payments.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency used within the local market or region where a security is traded, often used to report financial metrics relevant to that specific area.",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MaturityExpiryDate": {
            "type": "string",
            "description": "The date on which a financial instrument, such as a bond or an option, reaches its contractual end and the principal is due to be repaid or the contract ceases to be valid.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "OptionPremium": {
            "type": "number",
            "description": "The price that the buyer of the option pays to the seller for the rights granted by the option.",
            "format": "double",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "description": "A unique symbol assigned to represent specific metadata or attributes within a financial system, typically used to identify and categorize data fields systematically.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for permanent trading purposes on the Nasdaq exchange.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "Theta": {
            "type": "number",
            "description": "The rate of decline in the value of an option due to the passage of time.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "Vega": {
            "type": "number",
            "description": "The measure of an option's sensitivity to changes in the volatility of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "YieldSpread": {
            "type": "number",
            "description": "The difference between the yield on a bond and the yield on a benchmark bond or index, often reflecting the bond’s credit risk.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "CIK": {
            "type": "string",
            "description": "SEC Unique ID for filings",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/SecurityMasterGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterQuerySearchAsYouTypeRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "SearchString": {
            "type": "string",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterQuerySearchAsYouTypeResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/SecurityMasterDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterQuerySearchAsYouTypeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/SecurityMasterQuerySearchAsYouTypeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BondInsurer": {
            "type": "string",
            "description": "A company that insures the principal and/or interest payments of bond issuers, providing financial protection and enhancing the creditworthiness of the bond.",
            "nullable": true
          },
          "BondPremium": {
            "type": "number",
            "description": "The amount by which the market price of a bond exceeds its face value.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CEFundFiscalYearEnd": {
            "type": "string",
            "description": "The value of the fund's assets and liabilities at the close of its fiscal year.",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "Convexity": {
            "type": "number",
            "description": "A measure of the curvature in the relationship between bond prices and bond yields, indicating how the duration of a bond changes as interest rates change.",
            "format": "double",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "A unique identifier used to specify the type of currency in which financial transactions are recorded or reported, such as transactions within a financial account.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "Delta": {
            "type": "number",
            "description": "The ratio that compares the change in the price of the underlying asset to the corresponding change in the price of the option.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "description": "The URL link to a document that provides a summary of key information about a financial product, including its performance, investment strategy, and associated risks.",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "integer",
            "description": "The unique identifier of the company that serves as the investment advisor for a fund.",
            "format": "int32",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundCountryOfSales": {
            "type": "string",
            "description": "The country or countries where a fund is actively marketed and sold to investors.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundNAVMonthEndDate": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundValuationFrequency": {
            "type": "string",
            "description": "The regular interval at which the Net Asset Value (NAV) of a fund is calculated and reported, typically used to monitor and manage the performance of the investment vehicle.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "Gamma": {
            "type": "number",
            "description": "The rate of change in delta for a one-point move in the price of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "ImpliedVolatility": {
            "type": "number",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "format": "double",
            "nullable": true
          },
          "InceptionDate": {
            "type": "string",
            "description": "The date on which a financial account or investment was initially established or created.",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsAccumulatedFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether a financial account is structured as an accumulated fund, where contributions and earnings are pooled together over time to increase the value of the fund.",
            "nullable": true
          },
          "IsCallable": {
            "type": "boolean",
            "description": "A bond that can be redeemed by the issuer prior to its maturity.",
            "nullable": true
          },
          "IsCorporate": {
            "type": "boolean",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFloatingRate": {
            "type": "boolean",
            "description": "A bond with a variable interest rate that is adjusted periodically based on a predetermined formula or index.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IsPutable": {
            "type": "boolean",
            "description": "A bond that gives the holder the right to force the issuer to repurchase the security at specified dates before maturity.",
            "nullable": true
          },
          "IsSovereign": {
            "type": "boolean",
            "description": "A bond issued by a national government.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "IsUCITSCompliant": {
            "type": "string",
            "description": "Indicates whether a fund adheres to the standards and regulations set by the Undertakings for Collective Investment in Transferable Securities (UCITS), a regulatory framework of the European Union designed to allow mutual funds to operate freely throughout the EU on the basis of a single authorization from one member state.",
            "nullable": true
          },
          "IsZeroCoupon": {
            "type": "boolean",
            "description": "A bond that is issued at a discount to its face value and does not pay periodic interest payments.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency used within the local market or region where a security is traded, often used to report financial metrics relevant to that specific area.",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MaturityExpiryDate": {
            "type": "string",
            "description": "The date on which a financial instrument, such as a bond or an option, reaches its contractual end and the principal is due to be repaid or the contract ceases to be valid.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "OptionPremium": {
            "type": "number",
            "description": "The price that the buyer of the option pays to the seller for the rights granted by the option.",
            "format": "double",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "description": "A unique symbol assigned to represent specific metadata or attributes within a financial system, typically used to identify and categorize data fields systematically.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for permanent trading purposes on the Nasdaq exchange.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "Theta": {
            "type": "number",
            "description": "The rate of decline in the value of an option due to the passage of time.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "Vega": {
            "type": "number",
            "description": "The measure of an option's sensitivity to changes in the volatility of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "YieldSpread": {
            "type": "number",
            "description": "The difference between the yield on a bond and the yield on a benchmark bond or index, often reflecting the bond’s credit risk.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "CIK": {
            "type": "string",
            "description": "SEC Unique ID for filings",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "The primary category of investments in which assets are grouped based on their similar financial characteristics, market behaviors, and applicable regulations in an investment portfolio management context.",
            "nullable": true
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "A categorization within a broader asset class that provides a more specific classification of investments based on shared characteristics and behaviors in the financial market.",
            "nullable": true
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "A specific categorization within a more granular investment classification system that provides detailed insights into the characteristics and performance of investment securities, typically used in the context of detailed portfolio analysis and reporting.",
            "nullable": true
          },
          "BondInsurer": {
            "type": "string",
            "description": "A company that insures the principal and/or interest payments of bond issuers, providing financial protection and enhancing the creditworthiness of the bond.",
            "nullable": true
          },
          "BondPremium": {
            "type": "number",
            "description": "The amount by which the market price of a bond exceeds its face value.",
            "format": "double",
            "nullable": true
          },
          "BondPriceCurrency": {
            "type": "string",
            "description": "The currency in which the price of a bond is denoted, either in the base currency of the account or the local currency of the security.",
            "nullable": true
          },
          "BrandSymbolURL": {
            "type": "string",
            "description": "A URL that links to a resource or page representing or containing the symbol or logo of a brand, often used for identification and marketing purposes in digital and print media.",
            "nullable": true
          },
          "CallableDate": {
            "type": "string",
            "description": "The specific date on which a callable bond can be redeemed by the issuer before maturity.",
            "nullable": true
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a bond issuer has the right to repay the principal before the bond's maturity.",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a bond can be repaid by the issuer before its maturity date.",
            "format": "double",
            "nullable": true
          },
          "CEFundFiscalYearEnd": {
            "type": "string",
            "description": "The value of the fund's assets and liabilities at the close of its fiscal year.",
            "nullable": true
          },
          "CompanyDescription": {
            "type": "string",
            "description": "A detailed narrative or summary that outlines the essential characteristics, primary business activities, and other pertinent information about a company.",
            "nullable": true
          },
          "CompanyWebsiteURL": {
            "type": "string",
            "description": "The official internet address (URL) of a company's website, providing access to information about the company's identity, activities, products, and services.",
            "nullable": true
          },
          "Convexity": {
            "type": "number",
            "description": "A measure of the curvature in the relationship between bond prices and bond yields, indicating how the duration of a bond changes as interest rates change.",
            "format": "double",
            "nullable": true
          },
          "CountryOfIssuance": {
            "type": "string",
            "description": "The country in which a security, such as a bond or stock, is legally registered and issued.",
            "nullable": true
          },
          "CountryOfLegalRegistration": {
            "type": "string",
            "description": "The country in which a company or entity is legally registered to operate.",
            "nullable": true
          },
          "CountryOfRisk": {
            "type": "string",
            "description": "The country associated with the highest level of financial risk exposure for a particular investment or security, often determined by the location of the issuer or the primary market in which the security operates.",
            "nullable": true
          },
          "CouponRate": {
            "type": "number",
            "description": "The interest rate paid by a bond as a percent of its face value, distributed to bondholders at regular intervals until the bond matures.",
            "format": "double",
            "nullable": true
          },
          "CreditRating": {
            "type": "string",
            "description": "An assessment of the creditworthiness of a borrower in general terms or with respect to a particular debt or financial obligation.",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "A unique identifier used to specify the type of currency in which financial transactions are recorded or reported, such as transactions within a financial account.",
            "nullable": true
          },
          "CurrentYield": {
            "type": "number",
            "description": "The annual income (interest or dividends) divided by the current price of the security.",
            "format": "double",
            "nullable": true
          },
          "Delta": {
            "type": "number",
            "description": "The ratio that compares the change in the price of the underlying asset to the corresponding change in the price of the option.",
            "format": "double",
            "nullable": true
          },
          "DiscountRate": {
            "type": "number",
            "description": "The interest rate used to discount future cash flows of the bond to their present value.",
            "format": "double",
            "nullable": true
          },
          "DividendPaymentFrequency": {
            "type": "string",
            "description": "The regular interval at which dividends are distributed by a company to its shareholders, typically expressed in terms of monthly, quarterly, semi-annually, or annually.",
            "nullable": true
          },
          "DividendYield": {
            "type": "number",
            "description": "The ratio of a company's annual dividend payments to its share price, expressed as a percentage. This measure indicates how much a company pays out in dividends each year relative to its stock price.",
            "format": "double",
            "nullable": true
          },
          "Duration": {
            "type": "number",
            "description": "A measure of the sensitivity of the price of a bond to a change in interest rates, expressed in years.",
            "format": "double",
            "nullable": true
          },
          "ExchangeCode": {
            "type": "string",
            "description": "A unique identifier assigned to a marketplace where securities, commodities, derivatives, and other financial instruments are traded, ensuring clear and consistent reference in financial transactions and reporting.",
            "nullable": true
          },
          "ExercisePrice": {
            "type": "number",
            "description": "Another term for the strike price, the price at which the underlying asset can be purchased or sold when exercising an option.",
            "format": "double",
            "nullable": true
          },
          "ExerciseStyle": {
            "type": "string",
            "description": "Indicates whether the option is American or European style, determining when it can be exercised.",
            "nullable": true
          },
          "ExpirationDate": {
            "type": "string",
            "description": "The date on which the option expires and can no longer be exercised.",
            "nullable": true
          },
          "ExternalSystemKeyAccounting": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCompliance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCRM": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyCustody": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyEODPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyIntradayPrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyNewIssue": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyPerformance": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRealTimePrice": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRegulatory": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyRisk": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeySecurityGoldCopy": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "ExternalSystemKeyTrading": {
            "type": "string",
            "description": "Unique Id to external data source or system to facility resolution of Id lookups",
            "nullable": true
          },
          "FaceValue": {
            "type": "number",
            "description": "The nominal value or dollar value of a security stated by the issuer; it is the amount paid to the holder at maturity.",
            "format": "double",
            "nullable": true
          },
          "FactSheetURL": {
            "type": "string",
            "description": "The URL link to a document that provides a summary of key information about a financial product, including its performance, investment strategy, and associated risks.",
            "nullable": true
          },
          "FitchRating": {
            "type": "string",
            "description": "A credit rating assigned by Fitch, a global leader in financial information services, which evaluates the creditworthiness of a borrower using a standardized ratings scale.",
            "nullable": true
          },
          "FundAdministratorName": {
            "type": "string",
            "description": "The name of the third-party entity, often a bank or trust company, responsible for providing administrative services to a fund, including tasks related to accounting, compliance, and reporting.",
            "nullable": true
          },
          "FundAdvisorCompanyId": {
            "type": "integer",
            "description": "The unique identifier of the company that serves as the investment advisor for a fund.",
            "format": "int32",
            "nullable": true
          },
          "FundAdvisorCompanyName": {
            "type": "string",
            "description": "The name of the company that manages the fund as the investment advisor.",
            "nullable": true
          },
          "FundCountryOfSales": {
            "type": "string",
            "description": "The country or countries where a fund is actively marketed and sold to investors.",
            "nullable": true
          },
          "FundLegalName": {
            "type": "string",
            "description": "The official name under which a fund is registered and legally recognized in business and by regulatory bodies.",
            "nullable": true
          },
          "FundName": {
            "type": "string",
            "description": "The name assigned to a specific investment fund, which is used for identification and administrative purposes.",
            "nullable": true
          },
          "FundNAVMonthEnd": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundNAVMonthEndDate": {
            "type": "string",
            "description": "The Net Asset Value (NAV) of a fund as calculated at the end of the current month.",
            "nullable": true
          },
          "FundValuationFrequency": {
            "type": "string",
            "description": "The regular interval at which the Net Asset Value (NAV) of a fund is calculated and reported, typically used to monitor and manage the performance of the investment vehicle.",
            "nullable": true
          },
          "FundVintageYear": {
            "type": "string",
            "description": "The year in which the first influx of investment capital is delivered to a fund, marking the start of its financial activities and investment timeline.",
            "nullable": true
          },
          "Gamma": {
            "type": "number",
            "description": "The rate of change in delta for a one-point move in the price of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "GICSIndustry": {
            "type": "string",
            "description": "The classification of a company according to the Global Industry Classification Standard, which categorizes every public company by economic sector and industry group to aid in investment research and management.",
            "nullable": true
          },
          "GICSIndustryGroup": {
            "type": "string",
            "description": "A classification under the Global Industry Classification Standard (GICS) that categorizes public companies based on their primary business activities within a specific industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true
          },
          "GICSSubIndustry": {
            "type": "string",
            "description": "A specific classification within the Global Industry Classification Standard that categorizes a company based on its primary business activities, providing a more detailed level of analysis beyond broader industry and sector classifications. This helps in investment research and management by offering precise categorization.",
            "nullable": true
          },
          "ImpliedVolatility": {
            "type": "number",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "format": "double",
            "nullable": true
          },
          "InceptionDate": {
            "type": "string",
            "description": "The date on which a financial account or investment was initially established or created.",
            "nullable": true
          },
          "InterestPaymentFrequency": {
            "type": "string",
            "description": "The frequency at which interest payments are made to the bondholder, typically annually or semi-annually.",
            "nullable": true
          },
          "InvestmentType": {
            "type": "string",
            "description": "The classification of a financial asset or security based on its nature and characteristics, such as stocks, bonds, funds, or other investment vehicles, indicating the type of returns it may generate and the risks involved.",
            "nullable": true
          },
          "IsAccumulatedFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether a financial account is structured as an accumulated fund, where contributions and earnings are pooled together over time to increase the value of the fund.",
            "nullable": true
          },
          "IsCallable": {
            "type": "boolean",
            "description": "A bond that can be redeemed by the issuer prior to its maturity.",
            "nullable": true
          },
          "IsCorporate": {
            "type": "boolean",
            "nullable": true
          },
          "IsDelisted": {
            "type": "boolean",
            "description": "Indicates whether a security is no longer traded on a public exchange due to various reasons such as mergers, acquisitions, or financial insolvency.",
            "nullable": true
          },
          "IsFloatingRate": {
            "type": "boolean",
            "description": "A bond with a variable interest rate that is adjusted periodically based on a predetermined formula or index.",
            "nullable": true
          },
          "IsFundOfFunds": {
            "type": "boolean",
            "description": "An investment vehicle that pools money from multiple investors to invest in a portfolio of other funds, rather than investing directly in stocks, bonds, or other securities. This allows investors to achieve broad diversification and access to a variety of fund managers and strategies.",
            "nullable": true
          },
          "IsHedgeFund": {
            "type": "boolean",
            "description": "A boolean field indicating whether the financial account is associated with a hedge fund, which is a type of investment vehicle that pools capital from multiple investors to invest in securities and other financial instruments, employing diverse and complex strategies to earn active returns for their investors.",
            "nullable": true
          },
          "IsHighYield": {
            "type": "boolean",
            "description": "A bond that has a lower credit rating than investment-grade bonds and offers a higher yield to compensate for the increased risk.",
            "nullable": true
          },
          "IsInflationLinked": {
            "type": "boolean",
            "description": "A bond where the principal and/or interest payments are adjusted for inflation.",
            "nullable": true
          },
          "IsInvestmentGrade": {
            "type": "boolean",
            "description": "A bond that has a relatively low risk of default and is rated Baa3/BBB- or higher by credit rating agencies.",
            "nullable": true
          },
          "IsMunicipal": {
            "type": "boolean",
            "description": "A bond issued by a local government or territory, or one of their agencies.",
            "nullable": true
          },
          "IsPutable": {
            "type": "boolean",
            "description": "A bond that gives the holder the right to force the issuer to repurchase the security at specified dates before maturity.",
            "nullable": true
          },
          "IsSovereign": {
            "type": "boolean",
            "description": "A bond issued by a national government.",
            "nullable": true
          },
          "IssuerCategory": {
            "type": "string",
            "description": "The classification of an entity, such as a company or governmental body, that issues securities, based on its primary business activities or economic sector. This categorization aids in investment research and management by grouping issuers into sectors and industries according to the Global Industry Classification Standard (GICS) or similar systems.",
            "nullable": true
          },
          "IssuerName": {
            "type": "string",
            "description": "The legal or recognized name of the entity that has issued a security, such as a stock or bond.",
            "nullable": true
          },
          "IsUCITSCompliant": {
            "type": "string",
            "description": "Indicates whether a fund adheres to the standards and regulations set by the Undertakings for Collective Investment in Transferable Securities (UCITS), a regulatory framework of the European Union designed to allow mutual funds to operate freely throughout the EU on the basis of a single authorization from one member state.",
            "nullable": true
          },
          "IsZeroCoupon": {
            "type": "boolean",
            "description": "A bond that is issued at a discount to its face value and does not pay periodic interest payments.",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency used within the local market or region where a security is traded, often used to report financial metrics relevant to that specific area.",
            "nullable": true
          },
          "Location": {
            "type": "string",
            "description": "The specific place or position where something exists or is situated, often used in reference to the physical or digital site of an entity, attribute, or operation within a system or organization.",
            "nullable": true
          },
          "MarketPrice": {
            "type": "number",
            "description": "The current price at which the bond is trading in the market.",
            "format": "double",
            "nullable": true
          },
          "MaturityDate": {
            "type": "string",
            "description": "The date on which the bond will mature, and the bond issuer will pay the bondholder the face value of the bond.",
            "nullable": true
          },
          "MaturityExpiryDate": {
            "type": "string",
            "description": "The date on which a financial instrument, such as a bond or an option, reaches its contractual end and the principal is due to be repaid or the contract ceases to be valid.",
            "nullable": true
          },
          "MoodysRating": {
            "type": "string",
            "description": "The creditworthiness rating assigned to a borrower, security, or financial instrument by Moody's, using a standardized ratings scale that evaluates the risk of default.",
            "nullable": true
          },
          "NasdaqEarliestFinancialQuarter": {
            "type": "string",
            "description": "The earliest fiscal quarter for which financial data is available on the Nasdaq exchange for a specific security or company.",
            "nullable": true
          },
          "NasdaqEarliestPriceDate": {
            "type": "string",
            "description": "The earliest recorded date on which the price of a security was listed on the Nasdaq exchange.",
            "nullable": true
          },
          "NasdaqLatestFinancialQuarter": {
            "type": "string",
            "description": "The most recent financial quarter data for companies listed on the Nasdaq stock exchange.",
            "nullable": true
          },
          "NasdaqMostRecentPriceDate": {
            "type": "string",
            "description": "The date on which the most recent price of a security traded on the Nasdaq exchange was recorded.",
            "nullable": true
          },
          "OptionPremium": {
            "type": "number",
            "description": "The price that the buyer of the option pays to the seller for the rights granted by the option.",
            "format": "double",
            "nullable": true
          },
          "OptionSeries": {
            "type": "number",
            "description": "All options of the same class with the same expiration date and strike price.",
            "format": "double",
            "nullable": true
          },
          "OptionTradingUnit": {
            "type": "number",
            "description": "The number of options contracts that can be bought or sold in a standard transaction or lot in the trading of options securities.",
            "format": "double",
            "nullable": true
          },
          "OptionType": {
            "type": "number",
            "description": "Indicates whether the option is a call or a put.",
            "format": "double",
            "nullable": true
          },
          "OtherAssetClasses": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A classification used to categorize investments that do not fall under traditional asset classes like stocks, bonds, or cash. It includes commodities, real estate, and other non-standard investments.",
            "nullable": true
          },
          "OtherClassifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "An additional or alternative classification or identifier used to categorize or specify attributes related to financial accounts, which may include but is not limited to system identifiers, account features, or stage of processing.",
            "nullable": true
          },
          "OtherCountryOfRisk": {
            "type": "string",
            "description": "Refers to a country other than the primary country associated with a financial security or investment, which presents additional risk factors that could affect the investment's outcome. This includes economic, political, or social risks distinct from those of the primary country.",
            "nullable": true
          },
          "OtherCountryOfTrading": {
            "type": "string",
            "description": "The country, other than the primary country associated with the trading account or entity, where trading activities or transactions are executed for securities.",
            "nullable": true
          },
          "OtherIndustryLevel1": {
            "type": "string",
            "description": "The primary category within the economy under which a company is classified according to its main business activities, as defined by a standardized industry classification system such as the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherIndustryLevel2": {
            "type": "string",
            "description": "A secondary classification within a specific industry, used to provide more detailed categorization of a company's primary business activities under the broader industry and sector definitions.",
            "nullable": true
          },
          "OtherIndustryLevel3": {
            "type": "string",
            "description": "A specific categorization within the industry classification system that provides a granular classification of a company's primary business activities, typically used for more detailed investment research and management. This level is more detailed than broader sector or industry categories and helps in identifying niche areas within a larger industry context.",
            "nullable": true
          },
          "OtherRegionOfRisk": {
            "type": "string",
            "description": "Refers to the potential financial loss inherent in investments or business actions that are associated with a specific region or market outside the primary investment or operational area.",
            "nullable": true
          },
          "OtherRiskScores": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A proprietary or specific risk score assigned to evaluate additional risks not covered by standard risk assessments within a financial entity. This score helps in identifying, quantifying, and managing potential financial risks that are unique or exceptional to the entity's operations or financial standing.",
            "nullable": true
          },
          "OtherSectorLevel1": {
            "type": "string",
            "description": "A classification term used to identify the primary business activity of a company within a specific category that is broader than industries but more specific than sectors, according to the Global Industry Classification Standard (GICS).",
            "nullable": true
          },
          "OtherSectorLevel2": {
            "type": "string",
            "description": "A classification term used to further specify categories within a specific sector of the economy, providing a more detailed breakdown of the primary business activities of companies beyond the initial sector classification. This allows for more granified investment research and management.",
            "nullable": true
          },
          "OtherSectorLevel3": {
            "type": "string",
            "description": "A specific classification within an industry used to categorize entities based on their specialized business activities, which is more detailed than general sector or industry categories. This level helps in further refining the classification for enhanced investment research and management.",
            "nullable": true
          },
          "OtherSecurityDescriptions": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A descriptive label or identifier for a security that does not fall into standard categorizations, providing additional or alternative information about the security's nature, type, or characteristics.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel1": {
            "type": "string",
            "description": "A primary classification within an investment strategy that categorizes the approach or type of securities utilized, typically at the most general tier of strategic differentiation. This level helps in broadly defining the investment focus or methodology before further refinement or specification in subsequent strategy levels.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel2": {
            "type": "string",
            "description": "A secondary classification within a broader investment or security strategy that provides additional details or specifications beyond the primary strategy level, used for enhanced categorization and analysis in investment management.",
            "nullable": true
          },
          "OtherSecurityStrategyLevel3": {
            "type": "string",
            "description": "A categorization used within a financial institution to classify and manage securities based on specific strategies or criteria that are more granular than broader levels, focusing on specialized investment approaches or thematic elements within the security's management or structure.",
            "nullable": true
          },
          "OtherURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A field in a database used to store an alternative or additional URL link that provides supplementary information or access related to the primary entity or record in the system.",
            "nullable": true
          },
          "PriceFactor": {
            "type": "number",
            "description": "A numerical value used to adjust the price of a security or investment, reflecting changes due to market conditions, corporate actions, or other relevant factors.",
            "format": "double",
            "nullable": true
          },
          "PricingVendorPrimary": {
            "type": "string",
            "description": "The main entity responsible for providing current market values of securities and financial instruments, used primarily for the accurate pricing of assets within financial accounts.",
            "nullable": true
          },
          "PricingVendorSecondary": {
            "type": "string",
            "description": "A secondary source or service provider used for determining the market value of securities, often utilized as an alternative or in validation of the primary pricing source.",
            "nullable": true
          },
          "PricingVendorTertiary": {
            "type": "string",
            "description": "The third-priority source or provider used for obtaining market pricing data on securities, typically used when primary and secondary sources are unavailable or to validate consistency across data sources.",
            "nullable": true
          },
          "PrimaryExchange": {
            "type": "string",
            "description": "The main stock exchange where a security is listed and primarily traded.",
            "nullable": true
          },
          "RegionOfRisk": {
            "type": "string",
            "description": "The specific geographical area associated with the potential financial loss inherent in an investment or business action within that region.",
            "nullable": true
          },
          "RelatedTickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A list of unique symbols (tickers) associated with securities that are linked to or have a relationship with the primary security or entity being referenced. These symbols are used for trading and identifying securities on exchanges.",
            "nullable": true
          },
          "ReturnCurrency": {
            "type": "string",
            "description": "The currency type in which the returns on investments are calculated and reported, either in the base currency of the account (BaseCcy) or the local currency of the security (LocalCcy).",
            "nullable": true
          },
          "Rho": {
            "type": "number",
            "description": "The rate at which the price of an option changes relative to a change in the interest rate.",
            "format": "double",
            "nullable": true
          },
          "SECFilingURLs": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The URL that provides direct access to the official filings and documentation of a security or company as submitted to the Securities and Exchange Commission (SEC).",
            "nullable": true
          },
          "SecurityFriendlyName": {
            "type": "string",
            "description": "A custom or informal name assigned to a security for ease of identification and use within investment reporting and management systems.",
            "nullable": true
          },
          "SecurityLegalName": {
            "type": "string",
            "description": "The complete legal name of the security as designated by Security Master.",
            "nullable": true
          },
          "SecurityShortName": {
            "type": "string",
            "description": "A brief and distinctive name or designation used to identify a specific security for trading and reporting purposes in financial markets.",
            "nullable": true
          },
          "ShareClassId": {
            "type": "integer",
            "description": "A unique identifier assigned to a specific class of shares within a fund, distinguishing it from other classes in terms of fees, voting rights, or dividend options associated with the shares.",
            "format": "int32",
            "nullable": true
          },
          "ShareClassName": {
            "type": "string",
            "description": "The designated name of a class of shares within a fund, distinguishing it from other classes in the same fund based on criteria such as fee structures, voting rights, or dividend options.",
            "nullable": true
          },
          "SharesOutstanding": {
            "type": "number",
            "description": "The total number of shares of a company that are currently owned by all its shareholders, including share blocks held by institutional investors and restricted shares owned by the company’s officers and insiders.",
            "format": "double",
            "nullable": true
          },
          "SICCode": {
            "type": "string",
            "description": "A numerical code that classifies an organization by its primary business activity as defined by the Standard Industrial Classification system, which aids in the analysis and comparison of economic data across companies and industries.",
            "nullable": true
          },
          "SICIndustryTitle": {
            "type": "string",
            "description": "The title or name of the industry as classified under the Standard Industrial Classification (SIC) system, which categorizes industries primarily based on their business activities.",
            "nullable": true
          },
          "SICOffice": {
            "type": "string",
            "description": "The specific location or branch of the Standard Industrial Classification (SIC) office responsible for categorizing and managing data related to the economic activities of companies within a designated area or region.",
            "nullable": true
          },
          "SPRating": {
            "type": "string",
            "nullable": true
          },
          "StatusOfListing": {
            "type": "string",
            "description": "The current official state of a security's ability to be traded on an exchange, indicating whether it is actively tradable, suspended, delisted, or under any other specified trading status as defined by regulatory or exchange guidelines.",
            "nullable": true
          },
          "StrikePrice": {
            "type": "number",
            "description": "The amount of the underlying asset that one option contract represents.",
            "format": "double",
            "nullable": true
          },
          "SymCountryOfTrading": {
            "type": "string",
            "description": "The unique symbol representing the specific country in which a security is traded on a financial exchange.",
            "nullable": true
          },
          "SymCusip": {
            "type": "string",
            "description": "A unique alphanumeric identifier assigned to securities, including stocks and bonds, to facilitate clear identification and trading.",
            "nullable": true
          },
          "SymGlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true
          },
          "SymIsin": {
            "type": "string",
            "description": "A unique alphanumeric identifier that combines the stock symbol (Sym) and the International Securities Identification Number (ISIN) to distinctly identify a security in financial databases and systems.",
            "nullable": true
          },
          "SymMEX": {
            "type": "string",
            "description": "A unique symbol assigned to represent specific metadata or attributes within a financial system, typically used to identify and categorize data fields systematically.",
            "nullable": true
          },
          "SymMorningstarFundId": {
            "type": "string",
            "description": "A unique identifier assigned by Morningstar to a specific fund, used for tracking and analysis purposes in investment research and management.",
            "nullable": true
          },
          "SymNasdaqPermanentTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for permanent trading purposes on the Nasdaq exchange.",
            "nullable": true
          },
          "SymSedol": {
            "type": "string",
            "description": "A unique identifier used in the United Kingdom and Ireland for securities trading, represented by a code that assists in the clear identification and tracking of securities on the Stock Exchange Daily Official List.",
            "nullable": true
          },
          "SymTicker": {
            "type": "string",
            "description": "The unique series of letters assigned to a security for trading purposes on an exchange.",
            "nullable": true
          },
          "Theta": {
            "type": "number",
            "description": "The rate of decline in the value of an option due to the passage of time.",
            "format": "double",
            "nullable": true
          },
          "UnderlyingAsset": {
            "type": "number",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "format": "double",
            "nullable": true
          },
          "Vega": {
            "type": "number",
            "description": "The measure of an option's sensitivity to changes in the volatility of the underlying asset.",
            "format": "double",
            "nullable": true
          },
          "YieldSpread": {
            "type": "number",
            "description": "The difference between the yield on a bond and the yield on a benchmark bond or index, often reflecting the bond’s credit risk.",
            "format": "double",
            "nullable": true
          },
          "YieldToMaturity": {
            "type": "number",
            "description": "The total return anticipated on a bond if the bond is held until it matures, expressed as an annual percentage rate.",
            "format": "double",
            "nullable": true
          },
          "CIK": {
            "type": "string",
            "description": "SEC Unique ID for filings",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SecurityMasterUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/SecurityMasterUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SiteGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Url": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SiteGetResponse": {
        "type": "object",
        "properties": {
          "Site": {
            "$ref": "#/components/schemas/JsonNode"
          }
        },
        "additionalProperties": false
      },
      "SiteGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/SiteGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "SmtpSettings": {
        "type": "object",
        "properties": {
          "Host": {
            "type": "string",
            "nullable": true
          },
          "Port": {
            "type": "integer",
            "format": "int32"
          },
          "FromEmail": {
            "type": "string",
            "nullable": true
          },
          "User": {
            "type": "string",
            "nullable": true
          },
          "Pass": {
            "type": "string",
            "nullable": true
          },
          "EnableSsl": {
            "type": "boolean"
          }
        },
        "additionalProperties": false
      },
      "String<>f__AnonymousType0": {
        "type": "object",
        "properties": {
          "Message": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "StringString<>f__AnonymousType1": {
        "type": "object",
        "properties": {
          "Message": {
            "type": "string",
            "nullable": true
          },
          "Tenant": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "StyleFilter": {
        "type": "object",
        "properties": {
          "Typename": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "StylesGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "ThemeName": {
            "type": "string",
            "nullable": true
          },
          "StyleName": {
            "type": "string",
            "nullable": true
          },
          "IgnoreIfNotFound": {
            "type": "boolean",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "StylesGetResponse": {
        "type": "object",
        "properties": {
          "Key": {
            "type": "string",
            "nullable": true
          },
          "FullClassName": {
            "type": "string",
            "nullable": true
          },
          "Qualifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Value": {
            "$ref": "#/components/schemas/JsonNode"
          }
        },
        "additionalProperties": false
      },
      "StylesGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/StylesGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "StylesMergeRequest": {
        "type": "object",
        "properties": {
          "Key": {
            "type": "string",
            "nullable": true
          },
          "FullClassName": {
            "type": "string",
            "nullable": true
          },
          "Qualifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Value": {
            "$ref": "#/components/schemas/JsonNode"
          },
          "ThemeName": {
            "type": "string",
            "nullable": true
          },
          "Style": {
            "$ref": "#/components/schemas/QualifiedStyle"
          }
        },
        "additionalProperties": false
      },
      "StylesMergeResponse": {
        "type": "object",
        "properties": {
          "Key": {
            "type": "string",
            "nullable": true
          },
          "FullClassName": {
            "type": "string",
            "nullable": true
          },
          "Qualifiers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Value": {
            "$ref": "#/components/schemas/JsonNode"
          }
        },
        "additionalProperties": false
      },
      "StylesMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/StylesMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "StylesQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "$ref": "#/components/schemas/StyleFilter"
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "ThemeName": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The source from which the code set originates.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "Code": {
            "type": "string",
            "description": "The specific code value within the set.",
            "nullable": true,
            "example": ""
          },
          "CodeType": {
            "type": "string",
            "description": "The type of code being used.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueInt1": {
            "type": "integer",
            "description": "An integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt2": {
            "type": "integer",
            "description": "A second integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt3": {
            "type": "integer",
            "description": "A third integer value related to the code.",
            "format": "int32",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The source from which the code set originates.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "Code": {
            "type": "string",
            "description": "The specific code value within the set.",
            "nullable": true,
            "example": ""
          },
          "CodeType": {
            "type": "string",
            "description": "The type of code being used.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueInt1": {
            "type": "integer",
            "description": "An integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt2": {
            "type": "integer",
            "description": "A second integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt3": {
            "type": "integer",
            "description": "A third integer value related to the code.",
            "format": "int32",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TenantCodeSetCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The source from which the code set originates.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "Code": {
            "type": "string",
            "description": "The specific code value within the set.",
            "nullable": true,
            "example": ""
          },
          "CodeType": {
            "type": "string",
            "description": "The type of code being used.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueInt1": {
            "type": "integer",
            "description": "An integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt2": {
            "type": "integer",
            "description": "A second integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt3": {
            "type": "integer",
            "description": "A third integer value related to the code.",
            "format": "int32",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TenantCodeSetDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The source from which the code set originates.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "Code": {
            "type": "string",
            "description": "The specific code value within the set.",
            "nullable": true,
            "example": ""
          },
          "CodeType": {
            "type": "string",
            "description": "The type of code being used.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueInt1": {
            "type": "integer",
            "description": "An integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt2": {
            "type": "integer",
            "description": "A second integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt3": {
            "type": "integer",
            "description": "A third integer value related to the code.",
            "format": "int32",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/TenantCodeSetDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TenantCodeSetDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The source from which the code set originates.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "Code": {
            "type": "string",
            "description": "The specific code value within the set.",
            "nullable": true,
            "example": ""
          },
          "CodeType": {
            "type": "string",
            "description": "The type of code being used.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueInt1": {
            "type": "integer",
            "description": "An integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt2": {
            "type": "integer",
            "description": "A second integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt3": {
            "type": "integer",
            "description": "A third integer value related to the code.",
            "format": "int32",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TenantCodeSetGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSetId": {
            "type": "string",
            "description": "TenantCodeSet",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSet": {
            "$ref": "#/components/schemas/TenantCodeSetDto"
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSetId": {
            "type": "string",
            "description": "TenantCodeSet",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSet": {
            "$ref": "#/components/schemas/TenantCodeSetDto"
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TenantCodeSetTranslationCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSetId": {
            "type": "string",
            "description": "TenantCodeSet",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSet": {
            "$ref": "#/components/schemas/TenantCodeSetDto"
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TenantCodeSetTranslationDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSetId": {
            "type": "string",
            "description": "TenantCodeSet",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSet": {
            "$ref": "#/components/schemas/TenantCodeSetDto"
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/TenantCodeSetTranslationDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TenantCodeSetTranslationDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSetId": {
            "type": "string",
            "description": "TenantCodeSet",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSet": {
            "$ref": "#/components/schemas/TenantCodeSetDto"
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TenantCodeSetTranslationGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSetId": {
            "type": "string",
            "description": "TenantCodeSet",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSet": {
            "$ref": "#/components/schemas/TenantCodeSetDto"
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSetId": {
            "type": "string",
            "description": "TenantCodeSet",
            "nullable": true,
            "example": ""
          },
          "TenantCodeSet": {
            "$ref": "#/components/schemas/TenantCodeSetDto"
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetTranslationUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TenantCodeSetTranslationUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The source from which the code set originates.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "Code": {
            "type": "string",
            "description": "The specific code value within the set.",
            "nullable": true,
            "example": ""
          },
          "CodeType": {
            "type": "string",
            "description": "The type of code being used.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueInt1": {
            "type": "integer",
            "description": "An integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt2": {
            "type": "integer",
            "description": "A second integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt3": {
            "type": "integer",
            "description": "A third integer value related to the code.",
            "format": "int32",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code representing the language of the code set.",
            "nullable": true,
            "example": ""
          },
          "Source": {
            "type": "string",
            "description": "The source from which the code set originates.",
            "nullable": true,
            "example": ""
          },
          "Description": {
            "type": "string",
            "description": "A brief description of the code set.",
            "nullable": true,
            "example": ""
          },
          "Code": {
            "type": "string",
            "description": "The specific code value within the set.",
            "nullable": true,
            "example": ""
          },
          "CodeType": {
            "type": "string",
            "description": "The type of code being used.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString1": {
            "type": "string",
            "description": "An additional string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString2": {
            "type": "string",
            "description": "A second string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueString3": {
            "type": "string",
            "description": "A third string value related to the code.",
            "nullable": true,
            "example": ""
          },
          "CodeValueInt1": {
            "type": "integer",
            "description": "An integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt2": {
            "type": "integer",
            "description": "A second integer value related to the code.",
            "format": "int32",
            "nullable": true
          },
          "CodeValueInt3": {
            "type": "integer",
            "description": "A third integer value related to the code.",
            "format": "int32",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantCodeSetUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TenantCodeSetUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantMergeRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Identifier": {
            "type": "string",
            "nullable": true
          },
          "Type": {
            "type": "string",
            "nullable": true
          },
          "Status": {
            "$ref": "#/components/schemas/TenantStatus"
          }
        },
        "additionalProperties": false
      },
      "TenantMergeResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Identifier": {
            "type": "string",
            "nullable": true
          },
          "Type": {
            "type": "string",
            "nullable": true
          },
          "Status": {
            "$ref": "#/components/schemas/TenantStatus"
          }
        },
        "additionalProperties": false
      },
      "TenantMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TenantMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TenantStatus": {
        "enum": [
          "Active",
          "Inactive",
          "Pending",
          "Deleted"
        ],
        "type": "string",
        "description": "Status of a tenant. ACTIVE means the tenant is currently active and can be used in\r\ntransactions. INACTIVE means the tenant is no longer active and cannot be used in transactions. \r\nPENDING means the tenant has been created but is not yet active. \r\nDELETED means the tenant has been deleted and should not be used in transactions."
      },
      "TransactionCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": "EOD"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "Ticker": {
            "type": "string",
            "description": "Ticker refers to a unique series of letters assigned to securities for trading purposes on stock exchanges.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker or preferred security bol of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker bol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterId": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityMasterId": {
            "type": "string",
            "description": "A unique identifier for an underlying security across multiple databases or systems.",
            "nullable": true,
            "example": ""
          },
          "SourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each transaction within a particular tracking system.",
            "nullable": true,
            "example": ""
          },
          "OriginalSourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "The initial unique identifier given to a transaction before any transfers or changes.",
            "nullable": true,
            "example": ""
          },
          "ExternalTransactionKey": {
            "type": "string",
            "description": "A unique identifier used to track and manage transactions involving foreign currencies or occurring in foreign jurisdictions.",
            "nullable": true,
            "example": ""
          },
          "OriginalExternalTransactionKey": {
            "type": "string",
            "description": "The primary key used in the original database or system to identify the transaction.",
            "nullable": true,
            "example": ""
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a callable bond can be redeemed early by the issuer.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CountryofRisk": {
            "type": "string",
            "description": "The ISO country code associated with the highest level of risk in an investment, typically where the issuer is based.",
            "nullable": true,
            "example": ""
          },
          "CountryofIssuance": {
            "type": "string",
            "description": "The ISO country code in which the security was issued.",
            "nullable": true,
            "example": ""
          },
          "Coupon": {
            "type": "number",
            "description": "The interest rate paid by a bond, expressed as a percentage of the bonds face value.",
            "format": "double",
            "nullable": true
          },
          "ExDividendDate": {
            "type": "string",
            "description": "The date on which a stock trades without its next dividend, determining which shareholders are entitled to receive the dividend.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The credit rating assigned by Moodys Investors Service, reflecting the creditworthiness of the debt issuer.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which a financial instruments principal amount is due to be paid back.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementCurrencyCode": {
            "type": "string",
            "description": "The ISO currency code in which the financial transaction is being settled.",
            "nullable": true,
            "example": ""
          },
          "Quantity": {
            "type": "string",
            "description": "The number of units of the security traded or held.",
            "nullable": true,
            "example": ""
          },
          "TradeDate": {
            "type": "string",
            "description": "The date on which a trade is executed.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-30"
          },
          "PostedDate": {
            "type": "string",
            "description": "The date on which a transaction is recorded in accounting or financial systems.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementDate": {
            "type": "string",
            "description": "The agreed upon date by which the buyer must pay for the securities, and the seller must deliver them.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ActualSettlementDate": {
            "type": "string",
            "description": "The actual date on which the securities were exchanged and payment was made.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "ExpectedSettlementDate": {
            "type": "string",
            "description": "The date on which settlement of a transaction is expected to occur.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TraderName": {
            "type": "string",
            "description": "The name of the individual or entity that executed the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "A reference to a group of securities bought at the same time for tax purposes.",
            "nullable": true,
            "example": ""
          },
          "OriginalTaxLotDate": {
            "type": "string",
            "description": "The date on which the securities within a specific tax lot were originally purchased.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotCloseMethod": {
            "type": "string",
            "description": "The method used to determine which tax lot is closed in a sale transaction, such as FIFO (First-In-First-Out) or LIFO (Last-In-First-Out).",
            "nullable": true,
            "example": ""
          },
          "BrokerCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier or code assigned to a specific broker, used for the first brokerage firm involved in a transaction.",
            "nullable": true
          },
          "BrokerNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name of the first brokerage firm involved in a transaction, corresponding to Broker Code 1.",
            "nullable": true
          },
          "CustodianName": {
            "type": "string",
            "description": "The name of the custodian, which is the financial institution responsible for holding and safeguarding the securities of an investment account.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "OriginalFace": {
            "type": "string",
            "description": "The original or nominal amount of a bond or other fixed-income security at the time of issuance.",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodStart": {
            "type": "string",
            "description": "The start date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodEnd": {
            "type": "string",
            "description": "The end date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncFxRate": {
            "type": "number",
            "description": "The foreign exchange rate used to calculate the value of accrued income in a different currency.",
            "format": "double",
            "nullable": true
          },
          "NraCountryCode": {
            "type": "string",
            "description": "The ISO code that identifies the country of a non-resident alien (NRA) for tax and regulatory purposes.",
            "nullable": true,
            "example": ""
          },
          "NraWithholdingRate": {
            "type": "number",
            "description": "The percentage rate at which tax is withheld on income paid to non-resident aliens, as prescribed by law or treaty.",
            "format": "double",
            "nullable": true
          },
          "SpotFwdFxRate": {
            "type": "number",
            "description": "A rate agreed upon for purchasing or selling foreign currency with delivery and settlement at a future date.",
            "format": "double",
            "nullable": true
          },
          "StatementBasis": {
            "type": "string",
            "description": "The accounting or valuation basis on which a financial statement is prepared, such as GAAP (Generally Accepted Accounting Principles) or IFRS (International Financial Reporting Standards).",
            "nullable": true,
            "example": ""
          },
          "StrategyCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier used to label and track a specific investment strategy within a portfolio management system.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxCode": {
            "type": "string",
            "description": "A code that identifies the specific type of tax applicable to a financial transaction, which may vary based on the nature of the transaction or the jurisdiction in which it occurs.",
            "nullable": true,
            "example": ""
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "DefaultCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "DefaultCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "DefaultBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DefaultDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "DefaultGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "DefaultGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "DefaultLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "DefaultLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "DefaultOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "DefaultShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "DefaultPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "LocalCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "LocalBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "LocalDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "LocalFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "LocalGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "LocalGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LocalLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LocalLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "LocalManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "LocalNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "LocalNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "LocalNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "LocalOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "LocalShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "LocalTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "LocalPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": "EOD"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "Ticker": {
            "type": "string",
            "description": "Ticker refers to a unique series of letters assigned to securities for trading purposes on stock exchanges.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker or preferred security bol of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker bol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterId": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityMasterId": {
            "type": "string",
            "description": "A unique identifier for an underlying security across multiple databases or systems.",
            "nullable": true,
            "example": ""
          },
          "SourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each transaction within a particular tracking system.",
            "nullable": true,
            "example": ""
          },
          "OriginalSourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "The initial unique identifier given to a transaction before any transfers or changes.",
            "nullable": true,
            "example": ""
          },
          "ExternalTransactionKey": {
            "type": "string",
            "description": "A unique identifier used to track and manage transactions involving foreign currencies or occurring in foreign jurisdictions.",
            "nullable": true,
            "example": ""
          },
          "OriginalExternalTransactionKey": {
            "type": "string",
            "description": "The primary key used in the original database or system to identify the transaction.",
            "nullable": true,
            "example": ""
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a callable bond can be redeemed early by the issuer.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CountryofRisk": {
            "type": "string",
            "description": "The ISO country code associated with the highest level of risk in an investment, typically where the issuer is based.",
            "nullable": true,
            "example": ""
          },
          "CountryofIssuance": {
            "type": "string",
            "description": "The ISO country code in which the security was issued.",
            "nullable": true,
            "example": ""
          },
          "Coupon": {
            "type": "number",
            "description": "The interest rate paid by a bond, expressed as a percentage of the bonds face value.",
            "format": "double",
            "nullable": true
          },
          "ExDividendDate": {
            "type": "string",
            "description": "The date on which a stock trades without its next dividend, determining which shareholders are entitled to receive the dividend.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The credit rating assigned by Moodys Investors Service, reflecting the creditworthiness of the debt issuer.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which a financial instruments principal amount is due to be paid back.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementCurrencyCode": {
            "type": "string",
            "description": "The ISO currency code in which the financial transaction is being settled.",
            "nullable": true,
            "example": ""
          },
          "Quantity": {
            "type": "string",
            "description": "The number of units of the security traded or held.",
            "nullable": true,
            "example": ""
          },
          "TradeDate": {
            "type": "string",
            "description": "The date on which a trade is executed.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-30"
          },
          "PostedDate": {
            "type": "string",
            "description": "The date on which a transaction is recorded in accounting or financial systems.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementDate": {
            "type": "string",
            "description": "The agreed upon date by which the buyer must pay for the securities, and the seller must deliver them.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ActualSettlementDate": {
            "type": "string",
            "description": "The actual date on which the securities were exchanged and payment was made.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "ExpectedSettlementDate": {
            "type": "string",
            "description": "The date on which settlement of a transaction is expected to occur.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TraderName": {
            "type": "string",
            "description": "The name of the individual or entity that executed the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "A reference to a group of securities bought at the same time for tax purposes.",
            "nullable": true,
            "example": ""
          },
          "OriginalTaxLotDate": {
            "type": "string",
            "description": "The date on which the securities within a specific tax lot were originally purchased.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotCloseMethod": {
            "type": "string",
            "description": "The method used to determine which tax lot is closed in a sale transaction, such as FIFO (First-In-First-Out) or LIFO (Last-In-First-Out).",
            "nullable": true,
            "example": ""
          },
          "BrokerCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier or code assigned to a specific broker, used for the first brokerage firm involved in a transaction.",
            "nullable": true
          },
          "BrokerNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name of the first brokerage firm involved in a transaction, corresponding to Broker Code 1.",
            "nullable": true
          },
          "CustodianName": {
            "type": "string",
            "description": "The name of the custodian, which is the financial institution responsible for holding and safeguarding the securities of an investment account.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "OriginalFace": {
            "type": "string",
            "description": "The original or nominal amount of a bond or other fixed-income security at the time of issuance.",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodStart": {
            "type": "string",
            "description": "The start date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodEnd": {
            "type": "string",
            "description": "The end date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncFxRate": {
            "type": "number",
            "description": "The foreign exchange rate used to calculate the value of accrued income in a different currency.",
            "format": "double",
            "nullable": true
          },
          "NraCountryCode": {
            "type": "string",
            "description": "The ISO code that identifies the country of a non-resident alien (NRA) for tax and regulatory purposes.",
            "nullable": true,
            "example": ""
          },
          "NraWithholdingRate": {
            "type": "number",
            "description": "The percentage rate at which tax is withheld on income paid to non-resident aliens, as prescribed by law or treaty.",
            "format": "double",
            "nullable": true
          },
          "SpotFwdFxRate": {
            "type": "number",
            "description": "A rate agreed upon for purchasing or selling foreign currency with delivery and settlement at a future date.",
            "format": "double",
            "nullable": true
          },
          "StatementBasis": {
            "type": "string",
            "description": "The accounting or valuation basis on which a financial statement is prepared, such as GAAP (Generally Accepted Accounting Principles) or IFRS (International Financial Reporting Standards).",
            "nullable": true,
            "example": ""
          },
          "StrategyCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier used to label and track a specific investment strategy within a portfolio management system.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxCode": {
            "type": "string",
            "description": "A code that identifies the specific type of tax applicable to a financial transaction, which may vary based on the nature of the transaction or the jurisdiction in which it occurs.",
            "nullable": true,
            "example": ""
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "DefaultCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "DefaultCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "DefaultBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DefaultDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "DefaultGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "DefaultGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "DefaultLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "DefaultLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "DefaultOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "DefaultShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "DefaultPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "LocalCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "LocalBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "LocalDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "LocalFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "LocalGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "LocalGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LocalLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LocalLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "LocalManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "LocalNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "LocalNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "LocalNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "LocalOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "LocalShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "LocalTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "LocalPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "AccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "Amount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "BrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "CarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "FedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "FedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "ForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "GainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "GrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "ManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "NetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "NetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "NetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "OtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "RealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "ReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "RegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "RegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "SecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "Section988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "SettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "ShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "TaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "TotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "Price": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "PurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "RealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "RealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "AccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "Amount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "BrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "CarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "FedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "FedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "ForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "GainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "GrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "ManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "NetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "NetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "NetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "OtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "RealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "ReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "RegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "RegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "SecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "Section988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "SettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "ShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "TaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "TotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "Price": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "PurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "RealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "RealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionCurrencyCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "AccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "Amount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "BrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "CarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "FedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "FedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "ForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "GainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "GrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "ManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "NetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "NetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "NetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "OtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "RealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "ReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "RegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "RegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "SecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "Section988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "SettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "ShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "TaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "TotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "Price": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "PurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "RealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "RealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionCurrencyDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "AccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "Amount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "BrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "CarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "FedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "FedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "ForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "GainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "GrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "ManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "NetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "NetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "NetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "OtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "RealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "ReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "RegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "RegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "SecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "Section988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "SettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "ShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "TaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "TotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "Price": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "PurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "RealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "RealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/TransactionCurrencyDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionCurrencyDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "AccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "Amount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "BrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "CarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "FedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "FedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "ForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "GainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "GrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "ManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "NetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "NetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "NetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "OtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "RealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "ReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "RegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "RegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "SecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "Section988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "SettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "ShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "TaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "TotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "Price": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "PurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "RealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "RealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionCurrencyGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "AccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "Amount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "BrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "CarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "FedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "FedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "ForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "GainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "GrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "ManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "NetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "NetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "NetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "OtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "RealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "ReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "RegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "RegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "SecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "Section988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "SettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "ShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "TaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "TotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "Price": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "PurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "RealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "RealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "CurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "AnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "CallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "AverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "AccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "AccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "Amount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "BrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "CarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "FedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "FedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "ForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "GainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "GrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "ManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "NetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "NetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "NetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "NraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "NraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "OtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "RealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "ReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "RegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "RegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "SecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "Section988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "SettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "ShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "TaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "TotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "TotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "Price": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "PurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "RealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "RealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionCurrencyUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionCurrencyUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": "EOD"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "Ticker": {
            "type": "string",
            "description": "Ticker refers to a unique series of letters assigned to securities for trading purposes on stock exchanges.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker or preferred security bol of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker bol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterId": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityMasterId": {
            "type": "string",
            "description": "A unique identifier for an underlying security across multiple databases or systems.",
            "nullable": true,
            "example": ""
          },
          "SourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each transaction within a particular tracking system.",
            "nullable": true,
            "example": ""
          },
          "OriginalSourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "The initial unique identifier given to a transaction before any transfers or changes.",
            "nullable": true,
            "example": ""
          },
          "ExternalTransactionKey": {
            "type": "string",
            "description": "A unique identifier used to track and manage transactions involving foreign currencies or occurring in foreign jurisdictions.",
            "nullable": true,
            "example": ""
          },
          "OriginalExternalTransactionKey": {
            "type": "string",
            "description": "The primary key used in the original database or system to identify the transaction.",
            "nullable": true,
            "example": ""
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a callable bond can be redeemed early by the issuer.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CountryofRisk": {
            "type": "string",
            "description": "The ISO country code associated with the highest level of risk in an investment, typically where the issuer is based.",
            "nullable": true,
            "example": ""
          },
          "CountryofIssuance": {
            "type": "string",
            "description": "The ISO country code in which the security was issued.",
            "nullable": true,
            "example": ""
          },
          "Coupon": {
            "type": "number",
            "description": "The interest rate paid by a bond, expressed as a percentage of the bonds face value.",
            "format": "double",
            "nullable": true
          },
          "ExDividendDate": {
            "type": "string",
            "description": "The date on which a stock trades without its next dividend, determining which shareholders are entitled to receive the dividend.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The credit rating assigned by Moodys Investors Service, reflecting the creditworthiness of the debt issuer.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which a financial instruments principal amount is due to be paid back.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementCurrencyCode": {
            "type": "string",
            "description": "The ISO currency code in which the financial transaction is being settled.",
            "nullable": true,
            "example": ""
          },
          "Quantity": {
            "type": "string",
            "description": "The number of units of the security traded or held.",
            "nullable": true,
            "example": ""
          },
          "TradeDate": {
            "type": "string",
            "description": "The date on which a trade is executed.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-30"
          },
          "PostedDate": {
            "type": "string",
            "description": "The date on which a transaction is recorded in accounting or financial systems.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementDate": {
            "type": "string",
            "description": "The agreed upon date by which the buyer must pay for the securities, and the seller must deliver them.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ActualSettlementDate": {
            "type": "string",
            "description": "The actual date on which the securities were exchanged and payment was made.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "ExpectedSettlementDate": {
            "type": "string",
            "description": "The date on which settlement of a transaction is expected to occur.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TraderName": {
            "type": "string",
            "description": "The name of the individual or entity that executed the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "A reference to a group of securities bought at the same time for tax purposes.",
            "nullable": true,
            "example": ""
          },
          "OriginalTaxLotDate": {
            "type": "string",
            "description": "The date on which the securities within a specific tax lot were originally purchased.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotCloseMethod": {
            "type": "string",
            "description": "The method used to determine which tax lot is closed in a sale transaction, such as FIFO (First-In-First-Out) or LIFO (Last-In-First-Out).",
            "nullable": true,
            "example": ""
          },
          "BrokerCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier or code assigned to a specific broker, used for the first brokerage firm involved in a transaction.",
            "nullable": true
          },
          "BrokerNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name of the first brokerage firm involved in a transaction, corresponding to Broker Code 1.",
            "nullable": true
          },
          "CustodianName": {
            "type": "string",
            "description": "The name of the custodian, which is the financial institution responsible for holding and safeguarding the securities of an investment account.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "OriginalFace": {
            "type": "string",
            "description": "The original or nominal amount of a bond or other fixed-income security at the time of issuance.",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodStart": {
            "type": "string",
            "description": "The start date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodEnd": {
            "type": "string",
            "description": "The end date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncFxRate": {
            "type": "number",
            "description": "The foreign exchange rate used to calculate the value of accrued income in a different currency.",
            "format": "double",
            "nullable": true
          },
          "NraCountryCode": {
            "type": "string",
            "description": "The ISO code that identifies the country of a non-resident alien (NRA) for tax and regulatory purposes.",
            "nullable": true,
            "example": ""
          },
          "NraWithholdingRate": {
            "type": "number",
            "description": "The percentage rate at which tax is withheld on income paid to non-resident aliens, as prescribed by law or treaty.",
            "format": "double",
            "nullable": true
          },
          "SpotFwdFxRate": {
            "type": "number",
            "description": "A rate agreed upon for purchasing or selling foreign currency with delivery and settlement at a future date.",
            "format": "double",
            "nullable": true
          },
          "StatementBasis": {
            "type": "string",
            "description": "The accounting or valuation basis on which a financial statement is prepared, such as GAAP (Generally Accepted Accounting Principles) or IFRS (International Financial Reporting Standards).",
            "nullable": true,
            "example": ""
          },
          "StrategyCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier used to label and track a specific investment strategy within a portfolio management system.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxCode": {
            "type": "string",
            "description": "A code that identifies the specific type of tax applicable to a financial transaction, which may vary based on the nature of the transaction or the jurisdiction in which it occurs.",
            "nullable": true,
            "example": ""
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "DefaultCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "DefaultCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "DefaultBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DefaultDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "DefaultGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "DefaultGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "DefaultLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "DefaultLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "DefaultOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "DefaultShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "DefaultPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "LocalCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "LocalBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "LocalDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "LocalFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "LocalGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "LocalGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LocalLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LocalLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "LocalManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "LocalNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "LocalNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "LocalNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "LocalOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "LocalShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "LocalTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "LocalPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": "EOD"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "Ticker": {
            "type": "string",
            "description": "Ticker refers to a unique series of letters assigned to securities for trading purposes on stock exchanges.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker or preferred security bol of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker bol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterId": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityMasterId": {
            "type": "string",
            "description": "A unique identifier for an underlying security across multiple databases or systems.",
            "nullable": true,
            "example": ""
          },
          "SourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each transaction within a particular tracking system.",
            "nullable": true,
            "example": ""
          },
          "OriginalSourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "The initial unique identifier given to a transaction before any transfers or changes.",
            "nullable": true,
            "example": ""
          },
          "ExternalTransactionKey": {
            "type": "string",
            "description": "A unique identifier used to track and manage transactions involving foreign currencies or occurring in foreign jurisdictions.",
            "nullable": true,
            "example": ""
          },
          "OriginalExternalTransactionKey": {
            "type": "string",
            "description": "The primary key used in the original database or system to identify the transaction.",
            "nullable": true,
            "example": ""
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a callable bond can be redeemed early by the issuer.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CountryofRisk": {
            "type": "string",
            "description": "The ISO country code associated with the highest level of risk in an investment, typically where the issuer is based.",
            "nullable": true,
            "example": ""
          },
          "CountryofIssuance": {
            "type": "string",
            "description": "The ISO country code in which the security was issued.",
            "nullable": true,
            "example": ""
          },
          "Coupon": {
            "type": "number",
            "description": "The interest rate paid by a bond, expressed as a percentage of the bonds face value.",
            "format": "double",
            "nullable": true
          },
          "ExDividendDate": {
            "type": "string",
            "description": "The date on which a stock trades without its next dividend, determining which shareholders are entitled to receive the dividend.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The credit rating assigned by Moodys Investors Service, reflecting the creditworthiness of the debt issuer.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which a financial instruments principal amount is due to be paid back.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementCurrencyCode": {
            "type": "string",
            "description": "The ISO currency code in which the financial transaction is being settled.",
            "nullable": true,
            "example": ""
          },
          "Quantity": {
            "type": "string",
            "description": "The number of units of the security traded or held.",
            "nullable": true,
            "example": ""
          },
          "TradeDate": {
            "type": "string",
            "description": "The date on which a trade is executed.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-30"
          },
          "PostedDate": {
            "type": "string",
            "description": "The date on which a transaction is recorded in accounting or financial systems.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementDate": {
            "type": "string",
            "description": "The agreed upon date by which the buyer must pay for the securities, and the seller must deliver them.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ActualSettlementDate": {
            "type": "string",
            "description": "The actual date on which the securities were exchanged and payment was made.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "ExpectedSettlementDate": {
            "type": "string",
            "description": "The date on which settlement of a transaction is expected to occur.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TraderName": {
            "type": "string",
            "description": "The name of the individual or entity that executed the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "A reference to a group of securities bought at the same time for tax purposes.",
            "nullable": true,
            "example": ""
          },
          "OriginalTaxLotDate": {
            "type": "string",
            "description": "The date on which the securities within a specific tax lot were originally purchased.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotCloseMethod": {
            "type": "string",
            "description": "The method used to determine which tax lot is closed in a sale transaction, such as FIFO (First-In-First-Out) or LIFO (Last-In-First-Out).",
            "nullable": true,
            "example": ""
          },
          "BrokerCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier or code assigned to a specific broker, used for the first brokerage firm involved in a transaction.",
            "nullable": true
          },
          "BrokerNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name of the first brokerage firm involved in a transaction, corresponding to Broker Code 1.",
            "nullable": true
          },
          "CustodianName": {
            "type": "string",
            "description": "The name of the custodian, which is the financial institution responsible for holding and safeguarding the securities of an investment account.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "OriginalFace": {
            "type": "string",
            "description": "The original or nominal amount of a bond or other fixed-income security at the time of issuance.",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodStart": {
            "type": "string",
            "description": "The start date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodEnd": {
            "type": "string",
            "description": "The end date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncFxRate": {
            "type": "number",
            "description": "The foreign exchange rate used to calculate the value of accrued income in a different currency.",
            "format": "double",
            "nullable": true
          },
          "NraCountryCode": {
            "type": "string",
            "description": "The ISO code that identifies the country of a non-resident alien (NRA) for tax and regulatory purposes.",
            "nullable": true,
            "example": ""
          },
          "NraWithholdingRate": {
            "type": "number",
            "description": "The percentage rate at which tax is withheld on income paid to non-resident aliens, as prescribed by law or treaty.",
            "format": "double",
            "nullable": true
          },
          "SpotFwdFxRate": {
            "type": "number",
            "description": "A rate agreed upon for purchasing or selling foreign currency with delivery and settlement at a future date.",
            "format": "double",
            "nullable": true
          },
          "StatementBasis": {
            "type": "string",
            "description": "The accounting or valuation basis on which a financial statement is prepared, such as GAAP (Generally Accepted Accounting Principles) or IFRS (International Financial Reporting Standards).",
            "nullable": true,
            "example": ""
          },
          "StrategyCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier used to label and track a specific investment strategy within a portfolio management system.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxCode": {
            "type": "string",
            "description": "A code that identifies the specific type of tax applicable to a financial transaction, which may vary based on the nature of the transaction or the jurisdiction in which it occurs.",
            "nullable": true,
            "example": ""
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "DefaultCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "DefaultCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "DefaultBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DefaultDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "DefaultGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "DefaultGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "DefaultLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "DefaultLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "DefaultOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "DefaultShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "DefaultPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "LocalCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "LocalBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "LocalDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "LocalFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "LocalGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "LocalGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LocalLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LocalLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "LocalManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "LocalNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "LocalNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "LocalNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "LocalOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "LocalShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "LocalTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "LocalPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/TransactionDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": "EOD"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "Ticker": {
            "type": "string",
            "description": "Ticker refers to a unique series of letters assigned to securities for trading purposes on stock exchanges.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker or preferred security bol of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker bol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterId": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityMasterId": {
            "type": "string",
            "description": "A unique identifier for an underlying security across multiple databases or systems.",
            "nullable": true,
            "example": ""
          },
          "SourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each transaction within a particular tracking system.",
            "nullable": true,
            "example": ""
          },
          "OriginalSourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "The initial unique identifier given to a transaction before any transfers or changes.",
            "nullable": true,
            "example": ""
          },
          "ExternalTransactionKey": {
            "type": "string",
            "description": "A unique identifier used to track and manage transactions involving foreign currencies or occurring in foreign jurisdictions.",
            "nullable": true,
            "example": ""
          },
          "OriginalExternalTransactionKey": {
            "type": "string",
            "description": "The primary key used in the original database or system to identify the transaction.",
            "nullable": true,
            "example": ""
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a callable bond can be redeemed early by the issuer.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CountryofRisk": {
            "type": "string",
            "description": "The ISO country code associated with the highest level of risk in an investment, typically where the issuer is based.",
            "nullable": true,
            "example": ""
          },
          "CountryofIssuance": {
            "type": "string",
            "description": "The ISO country code in which the security was issued.",
            "nullable": true,
            "example": ""
          },
          "Coupon": {
            "type": "number",
            "description": "The interest rate paid by a bond, expressed as a percentage of the bonds face value.",
            "format": "double",
            "nullable": true
          },
          "ExDividendDate": {
            "type": "string",
            "description": "The date on which a stock trades without its next dividend, determining which shareholders are entitled to receive the dividend.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The credit rating assigned by Moodys Investors Service, reflecting the creditworthiness of the debt issuer.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which a financial instruments principal amount is due to be paid back.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementCurrencyCode": {
            "type": "string",
            "description": "The ISO currency code in which the financial transaction is being settled.",
            "nullable": true,
            "example": ""
          },
          "Quantity": {
            "type": "string",
            "description": "The number of units of the security traded or held.",
            "nullable": true,
            "example": ""
          },
          "TradeDate": {
            "type": "string",
            "description": "The date on which a trade is executed.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-30"
          },
          "PostedDate": {
            "type": "string",
            "description": "The date on which a transaction is recorded in accounting or financial systems.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementDate": {
            "type": "string",
            "description": "The agreed upon date by which the buyer must pay for the securities, and the seller must deliver them.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ActualSettlementDate": {
            "type": "string",
            "description": "The actual date on which the securities were exchanged and payment was made.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "ExpectedSettlementDate": {
            "type": "string",
            "description": "The date on which settlement of a transaction is expected to occur.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TraderName": {
            "type": "string",
            "description": "The name of the individual or entity that executed the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "A reference to a group of securities bought at the same time for tax purposes.",
            "nullable": true,
            "example": ""
          },
          "OriginalTaxLotDate": {
            "type": "string",
            "description": "The date on which the securities within a specific tax lot were originally purchased.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotCloseMethod": {
            "type": "string",
            "description": "The method used to determine which tax lot is closed in a sale transaction, such as FIFO (First-In-First-Out) or LIFO (Last-In-First-Out).",
            "nullable": true,
            "example": ""
          },
          "BrokerCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier or code assigned to a specific broker, used for the first brokerage firm involved in a transaction.",
            "nullable": true
          },
          "BrokerNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name of the first brokerage firm involved in a transaction, corresponding to Broker Code 1.",
            "nullable": true
          },
          "CustodianName": {
            "type": "string",
            "description": "The name of the custodian, which is the financial institution responsible for holding and safeguarding the securities of an investment account.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "OriginalFace": {
            "type": "string",
            "description": "The original or nominal amount of a bond or other fixed-income security at the time of issuance.",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodStart": {
            "type": "string",
            "description": "The start date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodEnd": {
            "type": "string",
            "description": "The end date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncFxRate": {
            "type": "number",
            "description": "The foreign exchange rate used to calculate the value of accrued income in a different currency.",
            "format": "double",
            "nullable": true
          },
          "NraCountryCode": {
            "type": "string",
            "description": "The ISO code that identifies the country of a non-resident alien (NRA) for tax and regulatory purposes.",
            "nullable": true,
            "example": ""
          },
          "NraWithholdingRate": {
            "type": "number",
            "description": "The percentage rate at which tax is withheld on income paid to non-resident aliens, as prescribed by law or treaty.",
            "format": "double",
            "nullable": true
          },
          "SpotFwdFxRate": {
            "type": "number",
            "description": "A rate agreed upon for purchasing or selling foreign currency with delivery and settlement at a future date.",
            "format": "double",
            "nullable": true
          },
          "StatementBasis": {
            "type": "string",
            "description": "The accounting or valuation basis on which a financial statement is prepared, such as GAAP (Generally Accepted Accounting Principles) or IFRS (International Financial Reporting Standards).",
            "nullable": true,
            "example": ""
          },
          "StrategyCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier used to label and track a specific investment strategy within a portfolio management system.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxCode": {
            "type": "string",
            "description": "A code that identifies the specific type of tax applicable to a financial transaction, which may vary based on the nature of the transaction or the jurisdiction in which it occurs.",
            "nullable": true,
            "example": ""
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "DefaultCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "DefaultCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "DefaultBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DefaultDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "DefaultGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "DefaultGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "DefaultLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "DefaultLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "DefaultOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "DefaultShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "DefaultPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "LocalCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "LocalBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "LocalDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "LocalFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "LocalGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "LocalGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LocalLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LocalLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "LocalManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "LocalNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "LocalNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "LocalNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "LocalOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "LocalShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "LocalTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "LocalPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionTranslationCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionTranslationDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/TransactionTranslationDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionTranslationDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionTranslationGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "TransactionId": {
            "type": "string",
            "description": "Transaction",
            "nullable": true,
            "example": ""
          },
          "Transaction": {
            "$ref": "#/components/schemas/TransactionDto"
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TransactionTranslationUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionTranslationUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": "EOD"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "Ticker": {
            "type": "string",
            "description": "Ticker refers to a unique series of letters assigned to securities for trading purposes on stock exchanges.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker or preferred security bol of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker bol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterId": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityMasterId": {
            "type": "string",
            "description": "A unique identifier for an underlying security across multiple databases or systems.",
            "nullable": true,
            "example": ""
          },
          "SourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each transaction within a particular tracking system.",
            "nullable": true,
            "example": ""
          },
          "OriginalSourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "The initial unique identifier given to a transaction before any transfers or changes.",
            "nullable": true,
            "example": ""
          },
          "ExternalTransactionKey": {
            "type": "string",
            "description": "A unique identifier used to track and manage transactions involving foreign currencies or occurring in foreign jurisdictions.",
            "nullable": true,
            "example": ""
          },
          "OriginalExternalTransactionKey": {
            "type": "string",
            "description": "The primary key used in the original database or system to identify the transaction.",
            "nullable": true,
            "example": ""
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a callable bond can be redeemed early by the issuer.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CountryofRisk": {
            "type": "string",
            "description": "The ISO country code associated with the highest level of risk in an investment, typically where the issuer is based.",
            "nullable": true,
            "example": ""
          },
          "CountryofIssuance": {
            "type": "string",
            "description": "The ISO country code in which the security was issued.",
            "nullable": true,
            "example": ""
          },
          "Coupon": {
            "type": "number",
            "description": "The interest rate paid by a bond, expressed as a percentage of the bonds face value.",
            "format": "double",
            "nullable": true
          },
          "ExDividendDate": {
            "type": "string",
            "description": "The date on which a stock trades without its next dividend, determining which shareholders are entitled to receive the dividend.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The credit rating assigned by Moodys Investors Service, reflecting the creditworthiness of the debt issuer.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which a financial instruments principal amount is due to be paid back.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementCurrencyCode": {
            "type": "string",
            "description": "The ISO currency code in which the financial transaction is being settled.",
            "nullable": true,
            "example": ""
          },
          "Quantity": {
            "type": "string",
            "description": "The number of units of the security traded or held.",
            "nullable": true,
            "example": ""
          },
          "TradeDate": {
            "type": "string",
            "description": "The date on which a trade is executed.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-30"
          },
          "PostedDate": {
            "type": "string",
            "description": "The date on which a transaction is recorded in accounting or financial systems.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementDate": {
            "type": "string",
            "description": "The agreed upon date by which the buyer must pay for the securities, and the seller must deliver them.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ActualSettlementDate": {
            "type": "string",
            "description": "The actual date on which the securities were exchanged and payment was made.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "ExpectedSettlementDate": {
            "type": "string",
            "description": "The date on which settlement of a transaction is expected to occur.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TraderName": {
            "type": "string",
            "description": "The name of the individual or entity that executed the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "A reference to a group of securities bought at the same time for tax purposes.",
            "nullable": true,
            "example": ""
          },
          "OriginalTaxLotDate": {
            "type": "string",
            "description": "The date on which the securities within a specific tax lot were originally purchased.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotCloseMethod": {
            "type": "string",
            "description": "The method used to determine which tax lot is closed in a sale transaction, such as FIFO (First-In-First-Out) or LIFO (Last-In-First-Out).",
            "nullable": true,
            "example": ""
          },
          "BrokerCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier or code assigned to a specific broker, used for the first brokerage firm involved in a transaction.",
            "nullable": true
          },
          "BrokerNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name of the first brokerage firm involved in a transaction, corresponding to Broker Code 1.",
            "nullable": true
          },
          "CustodianName": {
            "type": "string",
            "description": "The name of the custodian, which is the financial institution responsible for holding and safeguarding the securities of an investment account.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "OriginalFace": {
            "type": "string",
            "description": "The original or nominal amount of a bond or other fixed-income security at the time of issuance.",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodStart": {
            "type": "string",
            "description": "The start date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodEnd": {
            "type": "string",
            "description": "The end date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncFxRate": {
            "type": "number",
            "description": "The foreign exchange rate used to calculate the value of accrued income in a different currency.",
            "format": "double",
            "nullable": true
          },
          "NraCountryCode": {
            "type": "string",
            "description": "The ISO code that identifies the country of a non-resident alien (NRA) for tax and regulatory purposes.",
            "nullable": true,
            "example": ""
          },
          "NraWithholdingRate": {
            "type": "number",
            "description": "The percentage rate at which tax is withheld on income paid to non-resident aliens, as prescribed by law or treaty.",
            "format": "double",
            "nullable": true
          },
          "SpotFwdFxRate": {
            "type": "number",
            "description": "A rate agreed upon for purchasing or selling foreign currency with delivery and settlement at a future date.",
            "format": "double",
            "nullable": true
          },
          "StatementBasis": {
            "type": "string",
            "description": "The accounting or valuation basis on which a financial statement is prepared, such as GAAP (Generally Accepted Accounting Principles) or IFRS (International Financial Reporting Standards).",
            "nullable": true,
            "example": ""
          },
          "StrategyCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier used to label and track a specific investment strategy within a portfolio management system.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxCode": {
            "type": "string",
            "description": "A code that identifies the specific type of tax applicable to a financial transaction, which may vary based on the nature of the transaction or the jurisdiction in which it occurs.",
            "nullable": true,
            "example": ""
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "DefaultCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "DefaultCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "DefaultBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DefaultDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "DefaultGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "DefaultGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "DefaultLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "DefaultLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "DefaultOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "DefaultShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "DefaultPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "LocalCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "LocalBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "LocalDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "LocalFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "LocalGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "LocalGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LocalLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LocalLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "LocalManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "LocalNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "LocalNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "LocalNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "LocalOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "LocalShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "LocalTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "LocalPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "RecordContext": {
            "type": "string",
            "description": "Code to identify if record is Trade Date Basis End of Day, Settlement Date End of Day, Custodian End of Day etc.",
            "nullable": true,
            "example": "EOD"
          },
          "LanguageCode": {
            "type": "string",
            "description": "Code that represents the language code and the country code. It follows the IETF language tag standard, which combines an ISO 639-1 two-letter language code with an ISO 3166-1 alpha-2 country code. Example - \"en-US\", \"en\" stands for English, and \"US\" indicates the United States.",
            "nullable": true,
            "example": "en-US"
          },
          "AccountId": {
            "type": "string",
            "description": "Account",
            "nullable": true,
            "example": "1417a366-9cf4-4a83-92d7-a23e26a8fa77"
          },
          "Account": {
            "$ref": "#/components/schemas/AccountDto"
          },
          "Ticker": {
            "type": "string",
            "description": "Ticker refers to a unique series of letters assigned to securities for trading purposes on stock exchanges.",
            "nullable": true,
            "example": ""
          },
          "Isin": {
            "type": "string",
            "description": "A unique 12-character alphanumeric code that serves as a standardized international identifier for securities such as stocks, bonds, and derivatives, facilitating cross-border trading.",
            "nullable": true,
            "example": ""
          },
          "Cusip": {
            "type": "string",
            "description": "A 9-character alphanumeric code used primarily in the United States and Canada to uniquely identify securities, including stocks of all registered U.S. and Canadian companies, and U.S. government and municipal bonds.",
            "nullable": true,
            "example": ""
          },
          "Sedol": {
            "type": "string",
            "description": "A 7-character alphanumeric code used to identify securities in the United Kingdom and Ireland, issued by the London Stock Exchange to facilitate clearer and more efficient trading.",
            "nullable": true,
            "example": ""
          },
          "SecuritySymbol": {
            "type": "string",
            "description": "The ticker or preferred security bol of the security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecuritySymbol": {
            "type": "string",
            "description": "The ticker bol of the primary security that underpins a derivative or other financial instrument.",
            "nullable": true,
            "example": ""
          },
          "SecurityMasterId": {
            "type": "string",
            "description": "Unique identifier used within a security master database to manage and index all relevant data about a security.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityMasterId": {
            "type": "string",
            "description": "A unique identifier for an underlying security across multiple databases or systems.",
            "nullable": true,
            "example": ""
          },
          "SourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "A unique identifier assigned to each transaction within a particular tracking system.",
            "nullable": true,
            "example": ""
          },
          "OriginalSourceSystemTransactionIdentifier": {
            "type": "string",
            "description": "The initial unique identifier given to a transaction before any transfers or changes.",
            "nullable": true,
            "example": ""
          },
          "ExternalTransactionKey": {
            "type": "string",
            "description": "A unique identifier used to track and manage transactions involving foreign currencies or occurring in foreign jurisdictions.",
            "nullable": true,
            "example": ""
          },
          "OriginalExternalTransactionKey": {
            "type": "string",
            "description": "The primary key used in the original database or system to identify the transaction.",
            "nullable": true,
            "example": ""
          },
          "SecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of a financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel1": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel2": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "AssetClassLevel3": {
            "type": "string",
            "description": "Hierarchical categorizations of assets based on their characteristics and risk profile, such as equities, fixed income, derivatives, etc.",
            "nullable": true,
            "example": ""
          },
          "SecuritySector": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustryGroup": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecurityIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "SecuritySubIndustry": {
            "type": "string",
            "description": "A classification system that categorizes public companies by economic sector and industry group, aiding in investment research and management.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingSecurityDescription": {
            "type": "string",
            "description": "This is a detailed description of the underlying financial security that includes essential information such as the issuer, type of security (e.g., bond, stock, derivative), maturity date, interest rate (if applicable), and other relevant characteristics that define the security.",
            "nullable": true,
            "example": ""
          },
          "CallDate": {
            "type": "string",
            "description": "The date on which a callable bond can be redeemed early by the issuer.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "CountryofRisk": {
            "type": "string",
            "description": "The ISO country code associated with the highest level of risk in an investment, typically where the issuer is based.",
            "nullable": true,
            "example": ""
          },
          "CountryofIssuance": {
            "type": "string",
            "description": "The ISO country code in which the security was issued.",
            "nullable": true,
            "example": ""
          },
          "Coupon": {
            "type": "number",
            "description": "The interest rate paid by a bond, expressed as a percentage of the bonds face value.",
            "format": "double",
            "nullable": true
          },
          "ExDividendDate": {
            "type": "string",
            "description": "The date on which a stock trades without its next dividend, determining which shareholders are entitled to receive the dividend.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "MoodysRating": {
            "type": "string",
            "description": "The credit rating assigned by Moodys Investors Service, reflecting the creditworthiness of the debt issuer.",
            "nullable": true,
            "example": ""
          },
          "SpRating": {
            "type": "string",
            "nullable": true
          },
          "InvestmentMaturityDate": {
            "type": "string",
            "description": "The date on which a financial instruments principal amount is due to be paid back.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementCurrencyCode": {
            "type": "string",
            "description": "The ISO currency code in which the financial transaction is being settled.",
            "nullable": true,
            "example": ""
          },
          "Quantity": {
            "type": "string",
            "description": "The number of units of the security traded or held.",
            "nullable": true,
            "example": ""
          },
          "TradeDate": {
            "type": "string",
            "description": "The date on which a trade is executed.",
            "format": "date",
            "nullable": true,
            "example": "2024-07-30"
          },
          "PostedDate": {
            "type": "string",
            "description": "The date on which a transaction is recorded in accounting or financial systems.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "SettlementDate": {
            "type": "string",
            "description": "The agreed upon date by which the buyer must pay for the securities, and the seller must deliver them.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "ActualSettlementDate": {
            "type": "string",
            "description": "The actual date on which the securities were exchanged and payment was made.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionType": {
            "type": "string",
            "description": "The kind of transaction executed, such as buy, sell, transfer, etc.",
            "nullable": true,
            "example": ""
          },
          "ExpectedSettlementDate": {
            "type": "string",
            "description": "The date on which settlement of a transaction is expected to occur.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TransactionTypeDescription": {
            "type": "string",
            "description": "A detailed description of the nature of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TraderName": {
            "type": "string",
            "description": "The name of the individual or entity that executed the transaction.",
            "nullable": true,
            "example": ""
          },
          "TransactionComments": {
            "type": "string",
            "description": "Additional notes or comments regarding the specifics of the transaction.",
            "nullable": true,
            "example": ""
          },
          "TaxLotReference": {
            "type": "string",
            "description": "A reference to a group of securities bought at the same time for tax purposes.",
            "nullable": true,
            "example": ""
          },
          "OriginalTaxLotDate": {
            "type": "string",
            "description": "The date on which the securities within a specific tax lot were originally purchased.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "TaxLotCloseMethod": {
            "type": "string",
            "description": "The method used to determine which tax lot is closed in a sale transaction, such as FIFO (First-In-First-Out) or LIFO (Last-In-First-Out).",
            "nullable": true,
            "example": ""
          },
          "BrokerCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier or code assigned to a specific broker, used for the first brokerage firm involved in a transaction.",
            "nullable": true
          },
          "BrokerNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name of the first brokerage firm involved in a transaction, corresponding to Broker Code 1.",
            "nullable": true
          },
          "CustodianName": {
            "type": "string",
            "description": "The name of the custodian, which is the financial institution responsible for holding and safeguarding the securities of an investment account.",
            "nullable": true,
            "example": ""
          },
          "Classifications": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A categorization used to group transctions - example \"Net Contributions\", \"Fees\" etc.",
            "nullable": true
          },
          "OriginalFace": {
            "type": "string",
            "description": "The original or nominal amount of a bond or other fixed-income security at the time of issuance.",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodStart": {
            "type": "string",
            "description": "The start date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncPeriodEnd": {
            "type": "string",
            "description": "The end date of the period over which interest has accumulated but not yet been paid.",
            "format": "date",
            "nullable": true,
            "example": ""
          },
          "AccruedIncFxRate": {
            "type": "number",
            "description": "The foreign exchange rate used to calculate the value of accrued income in a different currency.",
            "format": "double",
            "nullable": true
          },
          "NraCountryCode": {
            "type": "string",
            "description": "The ISO code that identifies the country of a non-resident alien (NRA) for tax and regulatory purposes.",
            "nullable": true,
            "example": ""
          },
          "NraWithholdingRate": {
            "type": "number",
            "description": "The percentage rate at which tax is withheld on income paid to non-resident aliens, as prescribed by law or treaty.",
            "format": "double",
            "nullable": true
          },
          "SpotFwdFxRate": {
            "type": "number",
            "description": "A rate agreed upon for purchasing or selling foreign currency with delivery and settlement at a future date.",
            "format": "double",
            "nullable": true
          },
          "StatementBasis": {
            "type": "string",
            "description": "The accounting or valuation basis on which a financial statement is prepared, such as GAAP (Generally Accepted Accounting Principles) or IFRS (International Financial Reporting Standards).",
            "nullable": true,
            "example": ""
          },
          "StrategyCodes": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "A unique identifier used to label and track a specific investment strategy within a portfolio management system.",
            "nullable": true
          },
          "StrategyNames": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "description": "The name given to a particular investment strategy that describes its methodology or the type of investments it focuses on, such as \"Growth\", \"Value\", or \"Income\".",
            "nullable": true
          },
          "TransactionTaxCode": {
            "type": "string",
            "description": "A code that identifies the specific type of tax applicable to a financial transaction, which may vary based on the nature of the transaction or the jurisdiction in which it occurs.",
            "nullable": true,
            "example": ""
          },
          "TransactionTaxDescription": {
            "type": "string",
            "description": "A detailed description of the tax applicable to a transaction, explaining the circumstances under which the tax is levied, the rate, and any relevant legal or regulatory specifics.",
            "nullable": true,
            "example": ""
          },
          "GlobalUniqueSecurityId": {
            "type": "string",
            "description": "A systematic unique identifier assigned to each security, ensuring distinct identification across global financial systems.",
            "nullable": true,
            "example": ""
          },
          "UnderlyingGlobalUniqueSecurityId": {
            "type": "string",
            "description": "The financial instrument (such as a stock, bond, or commodity) on which an option contract is based.",
            "nullable": true,
            "example": ""
          },
          "DefaultCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "DefaultAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "DefaultCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "DefaultAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "DefaultAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "DefaultBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "DefaultDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "DefaultForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "DefaultGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "DefaultGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "DefaultLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "DefaultLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "DefaultNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "DefaultOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "DefaultSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "DefaultSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "DefaultShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "DefaultTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "DefaultTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "DefaultPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "DefaultRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          },
          "LocalCurrencyCode": {
            "type": "string",
            "description": "The currency in which the transactions and balances of the transaction are being reported.",
            "nullable": true,
            "example": "USD"
          },
          "LocalAnnualIncome": {
            "type": "number",
            "description": "The total income generated from an investment over a year.",
            "format": "double",
            "nullable": true
          },
          "LocalCallPrice": {
            "type": "number",
            "description": "The price at which a callable bond can be redeemed before its maturity.",
            "format": "double",
            "nullable": true
          },
          "LocalAverageUnitCost": {
            "type": "number",
            "description": "The average cost per unit of an asset, calculated by dividing the total cost of acquisition by the number of units acquired.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedExpense": {
            "type": "number",
            "description": "Expenses that have been incurred but not yet paid or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAccruedIncome": {
            "type": "number",
            "description": "Income that has been earned but not yet received or recorded in the financial statements during an accounting period.",
            "format": "double",
            "nullable": true
          },
          "LocalAmount": {
            "type": "number",
            "description": "The total monetary value of a transaction recorded in the investment account books.",
            "format": "double",
            "nullable": true
          },
          "LocalBrokerCommission": {
            "type": "number",
            "description": "The fee charged by a broker for executing transactions or providing services related to the purchase or sale of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalCarryingValue": {
            "type": "number",
            "description": "The value of an asset in a company’s books after accounting for depreciation, amortization, and impairment costs.",
            "format": "double",
            "nullable": true
          },
          "LocalDisposedValue": {
            "type": "number",
            "description": "The value recorded in the books at the time an asset is sold or disposed of, reflecting the amount realized from the transaction.",
            "format": "double",
            "nullable": true
          },
          "LocalFedCostPostDispAdj": {
            "type": "number",
            "description": "The federal cost of an asset adjusted post-disposal for tax reporting purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalFedTaxCost": {
            "type": "number",
            "description": "The cost of an asset as recognized for federal tax purposes.",
            "format": "double",
            "nullable": true
          },
          "LocalForeignTax": {
            "type": "number",
            "description": "Taxes paid to foreign governments on income earned from foreign investments.",
            "format": "double",
            "nullable": true
          },
          "LocalGainLossProceeds": {
            "type": "number",
            "description": "The financial result from the sale of an asset, calculated as the difference between the sale proceeds and the assets book value.",
            "format": "double",
            "nullable": true
          },
          "LocalGrossCash": {
            "type": "number",
            "description": "Total cash amount before any deductions or adjustments.",
            "format": "double",
            "nullable": true
          },
          "LocalLedgerCash": {
            "type": "number",
            "description": "Cash balance recorded in the financial ledger.",
            "format": "double",
            "nullable": true
          },
          "LocalLongTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a long period (typically over one year).",
            "format": "double",
            "nullable": true
          },
          "LocalManagementPerformanceFee": {
            "type": "number",
            "description": "Fee charged by managers based on the performance of the investment portfolio.",
            "format": "double",
            "nullable": true
          },
          "LocalNetAmountBroker": {
            "type": "number",
            "description": "The net amount received or paid through a broker after accounting for fees and commissions.",
            "format": "double",
            "nullable": true
          },
          "LocalNetCash": {
            "type": "number",
            "description": "The total cash inflows minus outflows within a given period.",
            "format": "double",
            "nullable": true
          },
          "LocalNetPayable": {
            "type": "number",
            "description": "The total amount payable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNetReceivable": {
            "type": "number",
            "description": "The total amount receivable after all deductions and credits are applied.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholding": {
            "type": "number",
            "description": "Taxes withheld for Non-Resident Aliens under U.S. tax law.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingByUs": {
            "type": "number",
            "description": "Amount of NRA withholding managed directly by U.S. entities.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingSource": {
            "type": "number",
            "description": "The origin or the income source subject to NRA withholding.",
            "format": "double",
            "nullable": true
          },
          "LocalNraWithholdingTax": {
            "type": "number",
            "description": "The tax amount withheld under NRA regulations.",
            "format": "double",
            "nullable": true
          },
          "LocalOtherExpenses": {
            "type": "number",
            "description": "Any other miscellaneous expenses not categorized under specific heads.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedGainLoss": {
            "type": "number",
            "description": "Overall gains or losses realized from various transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalReclaimableTax": {
            "type": "number",
            "description": "Tax that has been paid and is eligible to be reclaimed.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionLongGainLoss": {
            "type": "number",
            "description": "Long-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalRegionShortGainLoss": {
            "type": "number",
            "description": "Short-term gain or loss from investments specific to a region.",
            "format": "double",
            "nullable": true
          },
          "LocalSecFee": {
            "type": "number",
            "description": "Fee associated with the buying or selling of securities.",
            "format": "double",
            "nullable": true
          },
          "LocalSection988GainLoss": {
            "type": "number",
            "description": "Gain or loss under IRS Section 988 involving foreign  transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalSettlementAmount": {
            "type": "number",
            "description": "The amount settled on the settlement date of a trade.",
            "format": "double",
            "nullable": true
          },
          "LocalShortTermGainLoss": {
            "type": "number",
            "description": "Gain or loss on assets held for a short period (typically less than one year).",
            "format": "double",
            "nullable": true
          },
          "LocalTaxWithheld": {
            "type": "number",
            "description": "Total amount of taxes withheld on investment income.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalGain": {
            "type": "number",
            "description": "The cumulative gain from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalLoss": {
            "type": "number",
            "description": "The cumulative loss from all investments and transactions.",
            "format": "double",
            "nullable": true
          },
          "LocalTotalWithholding": {
            "type": "number",
            "description": "The total amount withheld for taxes from various sources.",
            "format": "double",
            "nullable": true
          },
          "LocalPrice": {
            "type": "number",
            "description": "The purchasing or selling price of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalPurchasedSoldInterest": {
            "type": "number",
            "description": "Interest accrued from the date of purchase or sale of a security.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedCapitalGainLoss": {
            "type": "number",
            "description": "The gain or loss realized from the sale of a capital asset.",
            "format": "double",
            "nullable": true
          },
          "LocalRealizedFxGainLoss": {
            "type": "number",
            "description": "The gain or loss resulting from foreign exchange rate fluctuations.",
            "format": "double",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TransactionUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TransactionUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TreeDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Code": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "ParentId": {
            "type": "string",
            "nullable": true
          },
          "Path": {
            "type": "string",
            "nullable": true
          },
          "NodeType": {
            "type": "string",
            "nullable": true
          },
          "Parent": {
            "$ref": "#/components/schemas/TreeDto"
          },
          "Children": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/TreeDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TreeMergeRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Code": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "ParentId": {
            "type": "string",
            "nullable": true
          },
          "Path": {
            "type": "string",
            "nullable": true
          },
          "NodeType": {
            "type": "string",
            "nullable": true
          },
          "Parent": {
            "$ref": "#/components/schemas/TreeDto"
          },
          "Children": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/TreeDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TreeMergeResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Code": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "ParentId": {
            "type": "string",
            "nullable": true
          },
          "Path": {
            "type": "string",
            "nullable": true
          },
          "NodeType": {
            "type": "string",
            "nullable": true
          },
          "Parent": {
            "$ref": "#/components/schemas/TreeDto"
          },
          "Children": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/TreeDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TreeMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TreeMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TreeMetaCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Entity": {
            "type": "string",
            "description": "Entity",
            "nullable": true,
            "example": ""
          },
          "Path": {
            "type": "string",
            "description": "Path",
            "nullable": true,
            "example": ""
          },
          "SubType": {
            "type": "string",
            "description": "SubType",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TreeMetaCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Entity": {
            "type": "string",
            "description": "Entity",
            "nullable": true,
            "example": ""
          },
          "Path": {
            "type": "string",
            "description": "Path",
            "nullable": true,
            "example": ""
          },
          "SubType": {
            "type": "string",
            "description": "SubType",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TreeMetaCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TreeMetaCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TreeMetaDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TreeMetaDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Entity": {
            "type": "string",
            "description": "Entity",
            "nullable": true,
            "example": ""
          },
          "Path": {
            "type": "string",
            "description": "Path",
            "nullable": true,
            "example": ""
          },
          "SubType": {
            "type": "string",
            "description": "SubType",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TreeMetaDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TreeMetaDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TreeMetaDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Entity": {
            "type": "string",
            "description": "Entity",
            "nullable": true,
            "example": ""
          },
          "Path": {
            "type": "string",
            "description": "Path",
            "nullable": true,
            "example": ""
          },
          "SubType": {
            "type": "string",
            "description": "SubType",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TreeMetaDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/TreeMetaDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TreeMetaDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TreeMetaDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TreeMetaGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Entity": {
            "type": "string",
            "nullable": true
          },
          "Path": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TreeMetaGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Entity": {
            "type": "string",
            "description": "Entity",
            "nullable": true,
            "example": ""
          },
          "Path": {
            "type": "string",
            "description": "Path",
            "nullable": true,
            "example": ""
          },
          "SubType": {
            "type": "string",
            "description": "SubType",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TreeMetaGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TreeMetaGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TreeMetaQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "TreeMetaUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Entity": {
            "type": "string",
            "description": "Entity",
            "nullable": true,
            "example": ""
          },
          "Path": {
            "type": "string",
            "description": "Path",
            "nullable": true,
            "example": ""
          },
          "SubType": {
            "type": "string",
            "description": "SubType",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TreeMetaUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Entity": {
            "type": "string",
            "description": "Entity",
            "nullable": true,
            "example": ""
          },
          "Path": {
            "type": "string",
            "description": "Path",
            "nullable": true,
            "example": ""
          },
          "SubType": {
            "type": "string",
            "description": "SubType",
            "nullable": true,
            "example": ""
          }
        },
        "additionalProperties": false
      },
      "TreeMetaUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/TreeMetaUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UIMetadataCreateRequest": {
        "type": "object",
        "properties": {
          "ComponentId": {
            "type": "string",
            "nullable": true
          },
          "InputObject": {
            "nullable": true
          },
          "ReplaceParentReferences": {
            "type": "boolean",
            "nullable": true
          },
          "OutputObject": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UIMetadataCreateResponse": {
        "type": "object",
        "properties": {
          "ComponentId": {
            "type": "string",
            "nullable": true
          },
          "InputObject": {
            "nullable": true
          },
          "ReplaceParentReferences": {
            "type": "boolean",
            "nullable": true
          },
          "OutputObject": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UIMetadataCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UIMetadataCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserCredential": {
        "required": [
          "CredentialType",
          "Email",
          "TenantId",
          "TreeId",
          "Type",
          "UserName",
          "UserProfile",
          "UserProfileId"
        ],
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "CreatedBy": {
            "type": "string",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time"
          },
          "UpdatedBy": {
            "type": "string",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeletedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeletedBy": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "nullable": true
          },
          "UserProfile": {
            "$ref": "#/components/schemas/UserProfile"
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "EmailConfirmed": {
            "type": "boolean"
          },
          "Type": {
            "type": "string",
            "nullable": true
          },
          "CredentialType": {
            "type": "string",
            "nullable": true
          },
          "Password": {
            "type": "string",
            "nullable": true
          },
          "Token": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserCredentialMergeRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "nullable": true
          },
          "UserProfile": {
            "$ref": "#/components/schemas/UserProfile"
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "EmailConfirmed": {
            "type": "boolean",
            "nullable": true
          },
          "Type": {
            "type": "string",
            "nullable": true
          },
          "CredentialType": {
            "type": "string",
            "nullable": true
          },
          "Password": {
            "type": "string",
            "nullable": true
          },
          "Token": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserCredentialMergeResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "nullable": true
          },
          "UserProfile": {
            "$ref": "#/components/schemas/UserProfile"
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "EmailConfirmed": {
            "type": "boolean",
            "nullable": true
          },
          "Type": {
            "type": "string",
            "nullable": true
          },
          "CredentialType": {
            "type": "string",
            "nullable": true
          },
          "Password": {
            "type": "string",
            "nullable": true
          },
          "Token": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserCredentialMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserCredentialMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserCredentialUserGroupMergeRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "UserGroupId": {
            "type": "string",
            "nullable": true
          },
          "UserCredentialId": {
            "type": "string",
            "nullable": true
          },
          "StartDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "EndDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserCredentialUserGroupMergeResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "UserGroupId": {
            "type": "string",
            "nullable": true
          },
          "UserCredentialId": {
            "type": "string",
            "nullable": true
          },
          "StartDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "EndDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserCredentialUserGroupMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserCredentialUserGroupMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserEntitlement": {
        "required": [
          "EndDate",
          "Path",
          "StartDate",
          "TenantId",
          "UserCredentialId"
        ],
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "CreatedBy": {
            "type": "string",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time"
          },
          "UpdatedBy": {
            "type": "string",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeletedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeletedBy": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Path": {
            "type": "string",
            "nullable": true
          },
          "UserCredentialId": {
            "type": "string",
            "nullable": true
          },
          "UserCredential": {
            "$ref": "#/components/schemas/UserCredential"
          },
          "StartDate": {
            "type": "string",
            "format": "date"
          },
          "EndDate": {
            "type": "string",
            "format": "date"
          },
          "CanUpdate": {
            "type": "boolean"
          },
          "CanDelete": {
            "type": "boolean"
          },
          "CanShare": {
            "type": "boolean"
          }
        },
        "additionalProperties": false
      },
      "UserEntitlementDeleteRequest": {
        "type": "object",
        "properties": {
          "UserProfileIds": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Entity": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "DocumentType": {
            "type": "string",
            "nullable": true
          },
          "StartDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "EndDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ForcePath": {
            "type": "boolean"
          },
          "Path": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserEntitlementDeleteResponse": {
        "type": "object",
        "properties": {
          "UserProfileIds": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Entity": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "DocumentType": {
            "type": "string",
            "nullable": true
          },
          "StartDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "EndDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ForcePath": {
            "type": "boolean"
          },
          "Path": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserEntitlementDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserEntitlementDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserEntitlementDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Path": {
            "type": "string",
            "nullable": true
          },
          "UserCredentialId": {
            "type": "string",
            "nullable": true
          },
          "UserCredential": {
            "$ref": "#/components/schemas/UserCredential"
          },
          "StartDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "EndDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "CanUpdate": {
            "type": "boolean",
            "nullable": true
          },
          "CanDelete": {
            "type": "boolean",
            "nullable": true
          },
          "CanShare": {
            "type": "boolean",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserEntitlementDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserEntitlementDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserEntitlementDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserEntitlementDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserEntitlementMergeRequest": {
        "type": "object",
        "properties": {
          "UserProfileIds": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Entity": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "DocumentType": {
            "type": "string",
            "nullable": true
          },
          "StartDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "EndDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ForcePath": {
            "type": "boolean"
          },
          "Path": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserEntitlementMergeResponse": {
        "type": "object",
        "properties": {
          "UserProfileIds": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Entity": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "DocumentType": {
            "type": "string",
            "nullable": true
          },
          "StartDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "EndDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "ForcePath": {
            "type": "boolean"
          },
          "Path": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserEntitlementMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserEntitlementMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserEntitlementQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserGroup": {
        "required": [
          "EndDate",
          "Name",
          "StartDate",
          "TenantId",
          "TreeId"
        ],
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "CreatedBy": {
            "type": "string",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time"
          },
          "UpdatedBy": {
            "type": "string",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeletedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeletedBy": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "StartDate": {
            "type": "string",
            "format": "date"
          },
          "EndDate": {
            "type": "string",
            "format": "date"
          }
        },
        "additionalProperties": false
      },
      "UserGroupEntitlementMergeRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Path": {
            "type": "string",
            "nullable": true
          },
          "GroupId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "$ref": "#/components/schemas/UserGroup"
          },
          "StartDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "EndDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "CanUpdate": {
            "type": "boolean",
            "nullable": true
          },
          "CanDelete": {
            "type": "boolean",
            "nullable": true
          },
          "CanShare": {
            "type": "boolean",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserGroupEntitlementMergeResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Path": {
            "type": "string",
            "nullable": true
          },
          "GroupId": {
            "type": "string",
            "nullable": true
          },
          "Group": {
            "$ref": "#/components/schemas/UserGroup"
          },
          "StartDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "EndDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "CanUpdate": {
            "type": "boolean",
            "nullable": true
          },
          "CanDelete": {
            "type": "boolean",
            "nullable": true
          },
          "CanShare": {
            "type": "boolean",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserGroupEntitlementMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserGroupEntitlementMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserGroupMergeRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "StartDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "EndDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserGroupMergeResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "StartDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          },
          "EndDate": {
            "type": "string",
            "format": "date",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserGroupMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserGroupMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserLoginActivityDto": {
        "type": "object",
        "properties": {
          "Date": {
            "type": "string",
            "format": "date-time"
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "Action": {
            "type": "string",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserLoginActivityDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserLoginActivityDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserLoginActivityDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserLoginActivityDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserLoginActivityQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserNeverLoggedOnDto": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          },
          "PortalAcceptance": {
            "type": "boolean",
            "nullable": true
          },
          "PortalAcceptanceDate": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "PortfolioShortName": {
            "type": "string",
            "nullable": true
          },
          "PortfolioLongName": {
            "type": "string",
            "nullable": true
          },
          "IsPortfolioActive": {
            "type": "boolean",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "HasQuarterlyAccess": {
            "type": "boolean",
            "nullable": true
          },
          "HasInvoiceAccess": {
            "type": "boolean",
            "nullable": true
          },
          "HasStandardAccess": {
            "type": "boolean",
            "nullable": true
          },
          "HasAdHocAccess": {
            "type": "boolean",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserNeverLoggedOnDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserNeverLoggedOnDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserNeverLoggedOnDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserNeverLoggedOnDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserNeverLoggedOnQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfile": {
        "required": [
          "Email",
          "Name",
          "TenantId",
          "TreeId"
        ],
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "CreatedBy": {
            "type": "string",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time"
          },
          "UpdatedBy": {
            "type": "string",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeletedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeletedBy": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "IdentityId": {
            "type": "string",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean"
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreement": {
        "required": [
          "TenantId",
          "TreeId"
        ],
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "CreatedBy": {
            "type": "string",
            "nullable": true
          },
          "CreatedOn": {
            "type": "string",
            "format": "date-time"
          },
          "UpdatedBy": {
            "type": "string",
            "nullable": true
          },
          "UpdatedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeletedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "DeletedBy": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Unique identifier for the user profile.",
            "nullable": true,
            "example": "2345w92q-3ggg-29y8-bf30-23w3454q34989rk8"
          },
          "UserName": {
            "type": "string",
            "description": "Username associated with the profile.",
            "nullable": true,
            "example": ""
          },
          "LanguageCode": {
            "type": "string",
            "description": "Language code that the user viewed and accepted the agreement in, e.g. 'en-US' for US English.",
            "nullable": true,
            "example": ""
          },
          "AgreementType": {
            "type": "string",
            "nullable": true
          },
          "AcceptanceDate": {
            "type": "string",
            "description": "Date and time that user accepted the agreement, will be NULL in the event that the user declined the agreement.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "AcceptanceExpiryDate": {
            "type": "string",
            "description": "Date on which the user's acceptance expires and they need to review the agreement again, will be NULL if the acceptance does not expire.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeviceType": {
            "type": "string",
            "description": "Mobile (iPhone, Andriod etc.), Desktop (Mac, PC etc.), Tablet.",
            "nullable": true,
            "example": ""
          },
          "Location": {
            "type": "string",
            "description": "User login location.",
            "nullable": true,
            "example": ""
          },
          "AgreementDocumentUrl": {
            "type": "string",
            "description": "URL link to the agreement document.",
            "nullable": true,
            "example": ""
          },
          "IsExpired": {
            "type": "boolean",
            "description": "Flag to indicate if the agreement has been expired, can be used where users want to expire the agreement if to the scheduled expiry date.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Unique identifier for the user profile.",
            "nullable": true,
            "example": "2345w92q-3ggg-29y8-bf30-23w3454q34989rk8"
          },
          "UserName": {
            "type": "string",
            "description": "Username associated with the profile.",
            "nullable": true,
            "example": ""
          },
          "LanguageCode": {
            "type": "string",
            "description": "Language code that the user viewed and accepted the agreement in, e.g. 'en-US' for US English.",
            "nullable": true,
            "example": ""
          },
          "AgreementType": {
            "type": "string",
            "nullable": true
          },
          "AcceptanceDate": {
            "type": "string",
            "description": "Date and time that user accepted the agreement, will be NULL in the event that the user declined the agreement.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "AcceptanceExpiryDate": {
            "type": "string",
            "description": "Date on which the user's acceptance expires and they need to review the agreement again, will be NULL if the acceptance does not expire.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeviceType": {
            "type": "string",
            "description": "Mobile (iPhone, Andriod etc.), Desktop (Mac, PC etc.), Tablet.",
            "nullable": true,
            "example": ""
          },
          "Location": {
            "type": "string",
            "description": "User login location.",
            "nullable": true,
            "example": ""
          },
          "AgreementDocumentUrl": {
            "type": "string",
            "description": "URL link to the agreement document.",
            "nullable": true,
            "example": ""
          },
          "IsExpired": {
            "type": "boolean",
            "description": "Flag to indicate if the agreement has been expired, can be used where users want to expire the agreement if to the scheduled expiry date.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Unique identifier for the user profile.",
            "nullable": true,
            "example": "2345w92q-3ggg-29y8-bf30-23w3454q34989rk8"
          },
          "UserName": {
            "type": "string",
            "description": "Username associated with the profile.",
            "nullable": true,
            "example": ""
          },
          "LanguageCode": {
            "type": "string",
            "description": "Language code that the user viewed and accepted the agreement in, e.g. 'en-US' for US English.",
            "nullable": true,
            "example": ""
          },
          "AgreementType": {
            "type": "string",
            "nullable": true
          },
          "AcceptanceDate": {
            "type": "string",
            "description": "Date and time that user accepted the agreement, will be NULL in the event that the user declined the agreement.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "AcceptanceExpiryDate": {
            "type": "string",
            "description": "Date on which the user's acceptance expires and they need to review the agreement again, will be NULL if the acceptance does not expire.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeviceType": {
            "type": "string",
            "description": "Mobile (iPhone, Andriod etc.), Desktop (Mac, PC etc.), Tablet.",
            "nullable": true,
            "example": ""
          },
          "Location": {
            "type": "string",
            "description": "User login location.",
            "nullable": true,
            "example": ""
          },
          "AgreementDocumentUrl": {
            "type": "string",
            "description": "URL link to the agreement document.",
            "nullable": true,
            "example": ""
          },
          "IsExpired": {
            "type": "boolean",
            "description": "Flag to indicate if the agreement has been expired, can be used where users want to expire the agreement if to the scheduled expiry date.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileAgreementCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Unique identifier for the user profile.",
            "nullable": true,
            "example": "2345w92q-3ggg-29y8-bf30-23w3454q34989rk8"
          },
          "UserName": {
            "type": "string",
            "description": "Username associated with the profile.",
            "nullable": true,
            "example": ""
          },
          "LanguageCode": {
            "type": "string",
            "description": "Language code that the user viewed and accepted the agreement in, e.g. 'en-US' for US English.",
            "nullable": true,
            "example": ""
          },
          "AgreementType": {
            "type": "string",
            "nullable": true
          },
          "AcceptanceDate": {
            "type": "string",
            "description": "Date and time that user accepted the agreement, will be NULL in the event that the user declined the agreement.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "AcceptanceExpiryDate": {
            "type": "string",
            "description": "Date on which the user's acceptance expires and they need to review the agreement again, will be NULL if the acceptance does not expire.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeviceType": {
            "type": "string",
            "description": "Mobile (iPhone, Andriod etc.), Desktop (Mac, PC etc.), Tablet.",
            "nullable": true,
            "example": ""
          },
          "Location": {
            "type": "string",
            "description": "User login location.",
            "nullable": true,
            "example": ""
          },
          "AgreementDocumentUrl": {
            "type": "string",
            "description": "URL link to the agreement document.",
            "nullable": true,
            "example": ""
          },
          "IsExpired": {
            "type": "boolean",
            "description": "Flag to indicate if the agreement has been expired, can be used where users want to expire the agreement if to the scheduled expiry date.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileAgreementDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Unique identifier for the user profile.",
            "nullable": true,
            "example": "2345w92q-3ggg-29y8-bf30-23w3454q34989rk8"
          },
          "UserName": {
            "type": "string",
            "description": "Username associated with the profile.",
            "nullable": true,
            "example": ""
          },
          "LanguageCode": {
            "type": "string",
            "description": "Language code that the user viewed and accepted the agreement in, e.g. 'en-US' for US English.",
            "nullable": true,
            "example": ""
          },
          "AgreementType": {
            "type": "string",
            "nullable": true
          },
          "AcceptanceDate": {
            "type": "string",
            "description": "Date and time that user accepted the agreement, will be NULL in the event that the user declined the agreement.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "AcceptanceExpiryDate": {
            "type": "string",
            "description": "Date on which the user's acceptance expires and they need to review the agreement again, will be NULL if the acceptance does not expire.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeviceType": {
            "type": "string",
            "description": "Mobile (iPhone, Andriod etc.), Desktop (Mac, PC etc.), Tablet.",
            "nullable": true,
            "example": ""
          },
          "Location": {
            "type": "string",
            "description": "User login location.",
            "nullable": true,
            "example": ""
          },
          "AgreementDocumentUrl": {
            "type": "string",
            "description": "URL link to the agreement document.",
            "nullable": true,
            "example": ""
          },
          "IsExpired": {
            "type": "boolean",
            "description": "Flag to indicate if the agreement has been expired, can be used where users want to expire the agreement if to the scheduled expiry date.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreementDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileAgreementDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Unique identifier for the user profile.",
            "nullable": true,
            "example": "2345w92q-3ggg-29y8-bf30-23w3454q34989rk8"
          },
          "UserName": {
            "type": "string",
            "description": "Username associated with the profile.",
            "nullable": true,
            "example": ""
          },
          "LanguageCode": {
            "type": "string",
            "description": "Language code that the user viewed and accepted the agreement in, e.g. 'en-US' for US English.",
            "nullable": true,
            "example": ""
          },
          "AgreementType": {
            "type": "string",
            "nullable": true
          },
          "AcceptanceDate": {
            "type": "string",
            "description": "Date and time that user accepted the agreement, will be NULL in the event that the user declined the agreement.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "AcceptanceExpiryDate": {
            "type": "string",
            "description": "Date on which the user's acceptance expires and they need to review the agreement again, will be NULL if the acceptance does not expire.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeviceType": {
            "type": "string",
            "description": "Mobile (iPhone, Andriod etc.), Desktop (Mac, PC etc.), Tablet.",
            "nullable": true,
            "example": ""
          },
          "Location": {
            "type": "string",
            "description": "User login location.",
            "nullable": true,
            "example": ""
          },
          "AgreementDocumentUrl": {
            "type": "string",
            "description": "URL link to the agreement document.",
            "nullable": true,
            "example": ""
          },
          "IsExpired": {
            "type": "boolean",
            "description": "Flag to indicate if the agreement has been expired, can be used where users want to expire the agreement if to the scheduled expiry date.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileAgreementGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Unique identifier for the user profile.",
            "nullable": true,
            "example": "2345w92q-3ggg-29y8-bf30-23w3454q34989rk8"
          },
          "UserName": {
            "type": "string",
            "description": "Username associated with the profile.",
            "nullable": true,
            "example": ""
          },
          "LanguageCode": {
            "type": "string",
            "description": "Language code that the user viewed and accepted the agreement in, e.g. 'en-US' for US English.",
            "nullable": true,
            "example": ""
          },
          "AgreementType": {
            "type": "string",
            "nullable": true
          },
          "AcceptanceDate": {
            "type": "string",
            "description": "Date and time that user accepted the agreement, will be NULL in the event that the user declined the agreement.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "AcceptanceExpiryDate": {
            "type": "string",
            "description": "Date on which the user's acceptance expires and they need to review the agreement again, will be NULL if the acceptance does not expire.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeviceType": {
            "type": "string",
            "description": "Mobile (iPhone, Andriod etc.), Desktop (Mac, PC etc.), Tablet.",
            "nullable": true,
            "example": ""
          },
          "Location": {
            "type": "string",
            "description": "User login location.",
            "nullable": true,
            "example": ""
          },
          "AgreementDocumentUrl": {
            "type": "string",
            "description": "URL link to the agreement document.",
            "nullable": true,
            "example": ""
          },
          "IsExpired": {
            "type": "boolean",
            "description": "Flag to indicate if the agreement has been expired, can be used where users want to expire the agreement if to the scheduled expiry date.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Unique identifier for the user profile.",
            "nullable": true,
            "example": "2345w92q-3ggg-29y8-bf30-23w3454q34989rk8"
          },
          "UserName": {
            "type": "string",
            "description": "Username associated with the profile.",
            "nullable": true,
            "example": ""
          },
          "LanguageCode": {
            "type": "string",
            "description": "Language code that the user viewed and accepted the agreement in, e.g. 'en-US' for US English.",
            "nullable": true,
            "example": ""
          },
          "AgreementType": {
            "type": "string",
            "nullable": true
          },
          "AcceptanceDate": {
            "type": "string",
            "description": "Date and time that user accepted the agreement, will be NULL in the event that the user declined the agreement.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "AcceptanceExpiryDate": {
            "type": "string",
            "description": "Date on which the user's acceptance expires and they need to review the agreement again, will be NULL if the acceptance does not expire.",
            "format": "date-time",
            "nullable": true,
            "example": ""
          },
          "DeviceType": {
            "type": "string",
            "description": "Mobile (iPhone, Andriod etc.), Desktop (Mac, PC etc.), Tablet.",
            "nullable": true,
            "example": ""
          },
          "Location": {
            "type": "string",
            "description": "User login location.",
            "nullable": true,
            "example": ""
          },
          "AgreementDocumentUrl": {
            "type": "string",
            "description": "URL link to the agreement document.",
            "nullable": true,
            "example": ""
          },
          "IsExpired": {
            "type": "boolean",
            "description": "Flag to indicate if the agreement has been expired, can be used where users want to expire the agreement if to the scheduled expiry date.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileAgreementUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileAgreementUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileCreateRequest": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          },
          "Password": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileCreateResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileDto": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileGetResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMergeRequest": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMergeResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMergeResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileMergeResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Communify internal system User Profile Id.",
            "nullable": true,
            "example": "b5747393-d500-4672-87a2-314f86d2c9ca"
          },
          "HasAccounts": {
            "type": "boolean",
            "description": "Flag indicating of the user profile records has entitlement to accounts.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Communify internal system User Profile Id.",
            "nullable": true,
            "example": "b5747393-d500-4672-87a2-314f86d2c9ca"
          },
          "HasAccounts": {
            "type": "boolean",
            "description": "Flag indicating of the user profile records has entitlement to accounts.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileMetricCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Communify internal system User Profile Id.",
            "nullable": true,
            "example": "b5747393-d500-4672-87a2-314f86d2c9ca"
          },
          "HasAccounts": {
            "type": "boolean",
            "description": "Flag indicating of the user profile records has entitlement to accounts.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileMetricDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Communify internal system User Profile Id.",
            "nullable": true,
            "example": "b5747393-d500-4672-87a2-314f86d2c9ca"
          },
          "HasAccounts": {
            "type": "boolean",
            "description": "Flag indicating of the user profile records has entitlement to accounts.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileMetricDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileMetricDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Communify internal system User Profile Id.",
            "nullable": true,
            "example": "b5747393-d500-4672-87a2-314f86d2c9ca"
          },
          "HasAccounts": {
            "type": "boolean",
            "description": "Flag indicating of the user profile records has entitlement to accounts.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileMetricGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Communify internal system User Profile Id.",
            "nullable": true,
            "example": "b5747393-d500-4672-87a2-314f86d2c9ca"
          },
          "HasAccounts": {
            "type": "boolean",
            "description": "Flag indicating of the user profile records has entitlement to accounts.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "description": "Communify internal system User Profile Id.",
            "nullable": true,
            "example": "b5747393-d500-4672-87a2-314f86d2c9ca"
          },
          "HasAccounts": {
            "type": "boolean",
            "description": "Flag indicating of the user profile records has entitlement to accounts.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileMetricUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileMetricUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileQueryInEntityRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "EntityId": {
            "type": "string",
            "nullable": true
          },
          "EntityType": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileQueryInEntityResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "type": "object",
              "additionalProperties": {
                "$ref": "#/components/schemas/JsonNode"
              }
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileQueryInEntityResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileQueryInEntityResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileQueryLegacyRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "Ids": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Emails": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "Role": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileQueryLegacyResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileQueryLegacyResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileQueryLegacyResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileQueryWithoutAccountsRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileQueryWithoutAccountsResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "type": "object",
              "additionalProperties": {
                "$ref": "#/components/schemas/JsonNode"
              }
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileQueryWithoutAccountsResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileQueryWithoutAccountsResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileUpdateDisclaimerAcceptedRequest": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileUpdateDisclaimerAcceptedResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileUpdateDisclaimerAcceptedResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileUpdateDisclaimerAcceptedResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileUpdateLockRequest": {
        "type": "object",
        "properties": {
          "UserId": {
            "type": "string",
            "nullable": true
          },
          "Lock": {
            "type": "boolean"
          }
        },
        "additionalProperties": false
      },
      "UserProfileUpdateLockResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileUpdateLockResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileUpdateLockResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileUpdateRequest": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileUpdateResponse": {
        "type": "object",
        "properties": {
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "TreeId": {
            "type": "string",
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean",
            "nullable": true
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Agreements": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserProfileAgreement"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserProfileUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserProfileUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserRequestHistoryCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "UserId": {
            "type": "string",
            "nullable": true
          },
          "Service": {
            "type": "string",
            "nullable": true
          },
          "Verb": {
            "type": "string",
            "nullable": true
          },
          "Parameters": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserRequestHistoryCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "UserId": {
            "type": "string",
            "nullable": true
          },
          "Service": {
            "type": "string",
            "nullable": true
          },
          "Verb": {
            "type": "string",
            "nullable": true
          },
          "Parameters": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserRequestHistoryCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserRequestHistoryCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserRequestHistoryDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "UserId": {
            "type": "string",
            "nullable": true
          },
          "Service": {
            "type": "string",
            "nullable": true
          },
          "Verb": {
            "type": "string",
            "nullable": true
          },
          "Parameters": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserRequestHistoryDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserRequestHistoryDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserRequestHistoryDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserRequestHistoryDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserRequestHistoryQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserWithAccessDto": {
        "required": [
          "Email",
          "Name"
        ],
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "UserProfileId": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Email": {
            "type": "string",
            "nullable": true
          },
          "UserName": {
            "type": "string",
            "nullable": true
          },
          "FirstName": {
            "type": "string",
            "nullable": true
          },
          "LastName": {
            "type": "string",
            "nullable": true
          },
          "Salutation": {
            "type": "string",
            "nullable": true
          },
          "Title": {
            "type": "string",
            "nullable": true
          },
          "Image": {
            "type": "string",
            "nullable": true
          },
          "Cellphone": {
            "type": "string",
            "nullable": true
          },
          "Details": {
            "nullable": true
          },
          "CurrentMetadataBranch": {
            "type": "string",
            "nullable": true
          },
          "CurrentLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "LastLogin": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          },
          "UseOtpEmail": {
            "type": "boolean",
            "nullable": true
          },
          "UseOtpSms": {
            "type": "boolean",
            "nullable": true
          },
          "IdentityId": {
            "type": "string",
            "nullable": true
          },
          "ExternalId": {
            "type": "string",
            "nullable": true
          },
          "IsEnabled": {
            "type": "boolean"
          },
          "Comment": {
            "type": "string",
            "nullable": true
          },
          "AccountLongName": {
            "type": "string",
            "nullable": true
          },
          "AccountId": {
            "type": "string",
            "nullable": true
          },
          "AccountShortName": {
            "type": "string",
            "nullable": true
          },
          "AccountStatus": {
            "type": "string",
            "nullable": true
          },
          "HasInvoiceAccess": {
            "type": "boolean"
          },
          "HasQuarterlyAccess": {
            "type": "boolean"
          },
          "HasStandardAccess": {
            "type": "boolean"
          },
          "HasAdhocAccess": {
            "type": "boolean"
          },
          "Roles": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Groups": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "DisclaimerAcceptedOn": {
            "type": "string",
            "format": "date-time",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserWithAccessDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/UserWithAccessDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserWithAccessDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/UserWithAccessDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "UserWithAccessQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Tickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "AddTicker": {
            "type": "string",
            "nullable": true
          },
          "RemoveTicker": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Tickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "AddTicker": {
            "type": "string",
            "nullable": true
          },
          "RemoveTicker": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WatchlistCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Tickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "AddTicker": {
            "type": "string",
            "nullable": true
          },
          "RemoveTicker": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WatchlistDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Tickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "AddTicker": {
            "type": "string",
            "nullable": true
          },
          "RemoveTicker": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/WatchlistDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WatchlistDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Tickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "AddTicker": {
            "type": "string",
            "nullable": true
          },
          "RemoveTicker": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WatchlistGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "UserId": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Tickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "AddTicker": {
            "type": "string",
            "nullable": true
          },
          "RemoveTicker": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "Name": {
            "type": "string",
            "nullable": true
          },
          "Tickers": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "AddTicker": {
            "type": "string",
            "nullable": true
          },
          "RemoveTicker": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WatchlistUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WatchlistUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowType": {
            "type": "string",
            "description": "Name of the Workflow e.g. Document Publishing Approval",
            "nullable": true,
            "example": ""
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity associated with this workflow step (e.g., Task, Process).",
            "nullable": true,
            "example": ""
          },
          "EntityFilterCriteria": {
            "type": "string",
            "description": "The filter used to select specific entities relevant to this step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if the workflow is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          },
          "WorkflowSteps": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/WorkflowStepDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowType": {
            "type": "string",
            "description": "Name of the Workflow e.g. Document Publishing Approval",
            "nullable": true,
            "example": ""
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity associated with this workflow step (e.g., Task, Process).",
            "nullable": true,
            "example": ""
          },
          "EntityFilterCriteria": {
            "type": "string",
            "description": "The filter used to select specific entities relevant to this step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if the workflow is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          },
          "WorkflowSteps": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/WorkflowStepDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WorkflowCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowType": {
            "type": "string",
            "description": "Name of the Workflow e.g. Document Publishing Approval",
            "nullable": true,
            "example": ""
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity associated with this workflow step (e.g., Task, Process).",
            "nullable": true,
            "example": ""
          },
          "EntityFilterCriteria": {
            "type": "string",
            "description": "The filter used to select specific entities relevant to this step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if the workflow is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          },
          "WorkflowSteps": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/WorkflowStepDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WorkflowDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowType": {
            "type": "string",
            "description": "Name of the Workflow e.g. Document Publishing Approval",
            "nullable": true,
            "example": ""
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity associated with this workflow step (e.g., Task, Process).",
            "nullable": true,
            "example": ""
          },
          "EntityFilterCriteria": {
            "type": "string",
            "description": "The filter used to select specific entities relevant to this step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if the workflow is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          },
          "WorkflowSteps": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/WorkflowStepDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/WorkflowDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WorkflowDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowType": {
            "type": "string",
            "description": "Name of the Workflow e.g. Document Publishing Approval",
            "nullable": true,
            "example": ""
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity associated with this workflow step (e.g., Task, Process).",
            "nullable": true,
            "example": ""
          },
          "EntityFilterCriteria": {
            "type": "string",
            "description": "The filter used to select specific entities relevant to this step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if the workflow is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          },
          "WorkflowSteps": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/WorkflowStepDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WorkflowGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowQueryEndStatusRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "EntityType": {
            "type": "string",
            "nullable": true
          },
          "Entities": {
            "type": "array",
            "items": {},
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowQueryEndStatusResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowQueryEndStatusResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WorkflowQueryEndStatusResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepCreateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowId": {
            "type": "string",
            "description": "The identifier linking the step to a specific workflow.",
            "nullable": true,
            "example": ""
          },
          "UserRoleCriteria": {
            "type": "string",
            "description": "The criteria used to determine which user roles can perform or view this step.",
            "nullable": true,
            "example": ""
          },
          "StartingRecordStatus": {
            "type": "string",
            "description": "The status of the record at the beginning of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "EndingRecordStatus": {
            "type": "string",
            "description": "The status of the record upon completion of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if this workflow step is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepCreateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowId": {
            "type": "string",
            "description": "The identifier linking the step to a specific workflow.",
            "nullable": true,
            "example": ""
          },
          "UserRoleCriteria": {
            "type": "string",
            "description": "The criteria used to determine which user roles can perform or view this step.",
            "nullable": true,
            "example": ""
          },
          "StartingRecordStatus": {
            "type": "string",
            "description": "The status of the record at the beginning of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "EndingRecordStatus": {
            "type": "string",
            "description": "The status of the record upon completion of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if this workflow step is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepCreateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WorkflowStepCreateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepDeleteRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepDeleteResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowId": {
            "type": "string",
            "description": "The identifier linking the step to a specific workflow.",
            "nullable": true,
            "example": ""
          },
          "UserRoleCriteria": {
            "type": "string",
            "description": "The criteria used to determine which user roles can perform or view this step.",
            "nullable": true,
            "example": ""
          },
          "StartingRecordStatus": {
            "type": "string",
            "description": "The status of the record at the beginning of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "EndingRecordStatus": {
            "type": "string",
            "description": "The status of the record upon completion of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if this workflow step is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepDeleteResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WorkflowStepDeleteResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepDto": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowId": {
            "type": "string",
            "description": "The identifier linking the step to a specific workflow.",
            "nullable": true,
            "example": ""
          },
          "UserRoleCriteria": {
            "type": "string",
            "description": "The criteria used to determine which user roles can perform or view this step.",
            "nullable": true,
            "example": ""
          },
          "StartingRecordStatus": {
            "type": "string",
            "description": "The status of the record at the beginning of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "EndingRecordStatus": {
            "type": "string",
            "description": "The status of the record upon completion of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if this workflow step is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepDtoQueryResponse": {
        "type": "object",
        "properties": {
          "Items": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/WorkflowStepDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepDtoQueryResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WorkflowStepDtoQueryResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepGetEvaluateRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          },
          "EntityType": {
            "type": "string",
            "nullable": true
          },
          "StartingRecordStatus": {
            "type": "string",
            "nullable": true
          },
          "EndingRecordStatus": {
            "type": "string",
            "nullable": true
          },
          "Entity": {
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepGetEvaluateResponse": {
        "type": "object",
        "properties": {
          "Success": {
            "type": "boolean"
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepGetEvaluateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WorkflowStepGetEvaluateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepGetRequest": {
        "type": "object",
        "properties": {
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Id": {
            "type": "string",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepGetResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowId": {
            "type": "string",
            "description": "The identifier linking the step to a specific workflow.",
            "nullable": true,
            "example": ""
          },
          "UserRoleCriteria": {
            "type": "string",
            "description": "The criteria used to determine which user roles can perform or view this step.",
            "nullable": true,
            "example": ""
          },
          "StartingRecordStatus": {
            "type": "string",
            "description": "The status of the record at the beginning of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "EndingRecordStatus": {
            "type": "string",
            "description": "The status of the record upon completion of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if this workflow step is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepGetResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WorkflowStepGetResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepQueryRequest": {
        "type": "object",
        "properties": {
          "Filter": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/Filter"
            },
            "nullable": true
          },
          "GroupBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Aggregate": {
            "type": "object",
            "additionalProperties": {
              "type": "array",
              "items": {
                "type": "string"
              }
            },
            "nullable": true
          },
          "OrderBy": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "Top": {
            "type": "integer",
            "format": "int32"
          },
          "Skip": {
            "type": "integer",
            "format": "int32"
          },
          "Select": {
            "type": "array",
            "items": {
              "type": "string"
            },
            "nullable": true
          },
          "OData": {
            "type": "object",
            "additionalProperties": {
              "type": "string"
            },
            "nullable": true
          },
          "ImpersonateAs": {
            "type": "string",
            "description": "UserId to impersonate as.\r\nIf informed but the user does not have the necessary permissions\r\nthe request will use the logged user for the query ignoring this value.",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowId": {
            "type": "string",
            "description": "The identifier linking the step to a specific workflow.",
            "nullable": true,
            "example": ""
          },
          "UserRoleCriteria": {
            "type": "string",
            "description": "The criteria used to determine which user roles can perform or view this step.",
            "nullable": true,
            "example": ""
          },
          "StartingRecordStatus": {
            "type": "string",
            "description": "The status of the record at the beginning of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "EndingRecordStatus": {
            "type": "string",
            "description": "The status of the record upon completion of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if this workflow step is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowId": {
            "type": "string",
            "description": "The identifier linking the step to a specific workflow.",
            "nullable": true,
            "example": ""
          },
          "UserRoleCriteria": {
            "type": "string",
            "description": "The criteria used to determine which user roles can perform or view this step.",
            "nullable": true,
            "example": ""
          },
          "StartingRecordStatus": {
            "type": "string",
            "description": "The status of the record at the beginning of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "EndingRecordStatus": {
            "type": "string",
            "description": "The status of the record upon completion of this workflow step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if this workflow step is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowStepUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WorkflowStepUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowUpdateRequest": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowType": {
            "type": "string",
            "description": "Name of the Workflow e.g. Document Publishing Approval",
            "nullable": true,
            "example": ""
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity associated with this workflow step (e.g., Task, Process).",
            "nullable": true,
            "example": ""
          },
          "EntityFilterCriteria": {
            "type": "string",
            "description": "The filter used to select specific entities relevant to this step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if the workflow is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          },
          "WorkflowSteps": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/WorkflowStepDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowUpdateResponse": {
        "type": "object",
        "properties": {
          "Id": {
            "type": "string",
            "nullable": true
          },
          "TenantId": {
            "type": "string",
            "nullable": true
          },
          "WorkflowType": {
            "type": "string",
            "description": "Name of the Workflow e.g. Document Publishing Approval",
            "nullable": true,
            "example": ""
          },
          "EntityType": {
            "type": "string",
            "description": "The type of entity associated with this workflow step (e.g., Task, Process).",
            "nullable": true,
            "example": ""
          },
          "EntityFilterCriteria": {
            "type": "string",
            "description": "The filter used to select specific entities relevant to this step.",
            "nullable": true,
            "example": ""
          },
          "IsActive": {
            "type": "boolean",
            "description": "A boolean field indicating if the workflow is currently active (TRUE) or inactive (FALSE).",
            "nullable": true
          },
          "WorkflowSteps": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/WorkflowStepDto"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      },
      "WorkflowUpdateResponsePlatformResult": {
        "type": "object",
        "properties": {
          "success": {
            "type": "boolean"
          },
          "data": {
            "$ref": "#/components/schemas/WorkflowUpdateResponse"
          },
          "reasons": {
            "type": "array",
            "items": {
              "$ref": "#/components/schemas/PlatformReason"
            },
            "nullable": true
          }
        },
        "additionalProperties": false
      }
    },
    "securitySchemes": {
      "Bearer": {
        "type": "apiKey",
        "description": "JWT Authorization header using the Bearer scheme. \r\n\r\n Enter 'Bearer' [space] and then your token in the text input below.\r\n\r\nExample: \"Bearer jhfdkj.jkdsakjdsa.jkdsajk\"",
        "name": "Authorization",
        "in": "header"
      }
    }
  },
  "security": [
    {
      "Bearer": []
    }
  ]
};
export { serviceSampleData };