import { MouseEvent, useEffect, useRef } from "react";
import { Document, Page } from "react-pdf";
import { batch, useComputed, useSignal, useSignalEffect } from "@preact/signals-react";
import { SxProps, Theme } from "@mui/system";
import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import NGIcon from "../NGIcon/NGIcon";
import NGImage from "../NGImage/NGImage";
import { INGDocumentViewerProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { getTestId, getsxObject, getClassName, generateUID } from "../../library/utils";
import { isNil, isString, toNumber } from "lodash-es";
import { useWindowWidth } from "../../hooks/useWindowWidth";
import { log } from "../../library/logger";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.mjs", import.meta.url).toString();

const defaultStyle = (width = "fit-content") => ({
  width,
  position: "relative",
  "& .react-pdf__Page": {
    width,
  },
  "& .react-pdf__Document": {
    width,
  },
});

const tag = "NGDocumentViewer";

export default function NGDocumentViewer({ config, context }: INGDocumentViewerProps) {
  const local = setupLocalState(
    config,
    {
      NumPages: useSignal(0), // Initialize with 0, updated on document load
      CurrentPage: useSignal(1), // Signal to track the current page
      CurrentDocument: useSignal(-1), // Signal to track the current document
      PreviewMode: useSignal(config.PreviewMode ?? false),
      Scale: useSignal(config.Scale ?? 1),
      Visible: useSignal(config.Visible != false ? true : config.Visible),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Value: useSignal(config.Value ?? ""),
      Documents: useSignal(config.Documents ?? []),
      ButtonStyle: useSignal(config.ButtonStyle ?? {}),
      ButtonClasses: useSignal(config.ButtonClasses ?? []),
      DocumentFormat: useSignal(config.DocumentFormat ?? "pdf"),
      ErrorMessage: useSignal(config.ErrorMessage ?? "Preview Unavailable"),
      MaxLoadingTimeSeconds: useSignal(config.MaxLoadingTimeSeconds ?? 6),
      PathExpression: useSignal(config.PathExpression ?? ""),
      ValueExpression: useSignal(config.ValueExpression ?? ""),
    },
    context
  );
  const paginationRef = useRef<HTMLDivElement>(null);
  const zoomRef = useRef<HTMLDivElement>(null);
  const handlers = setupHandlers(config, context);
  const width = useWindowWidth();
  const loadingInterval = useSignal<number | null>(null);

  function handleOnClick(event: MouseEvent<HTMLDivElement>) {
    if (!isNil(handlers["onClick"])) handlers["onClick"](new Event("click"), event);
  }

  function onDocumentLoadSuccess({ numPages }) {
    batch(() => {
      local.NumPages.value = numPages; // Set the total number of pages once the document is loaded
      local.CurrentPage.value = 1; // Reset the current page to 1
      loadingInterval.value = null;
    });
  }

  function onDocumentError(error: Error) {
    log.error(tag, "Error loading document", error);

    batch(() => {
      local.NumPages.value = 0;
      local.CurrentPage.value = 1;
      loadingInterval.value = null;
    });
  }

  function goToPrevPage() {
    if (local.CurrentPage.value > 1) {
      local.CurrentPage.value -= 1;
    }
  }

  function goToNextPage() {
    if (local.CurrentPage.value < local.NumPages.value) {
      local.CurrentPage.value += 1;
    }
  }

  function setDocument(index: number) {
    if (local.Value.value !== null)
      local.Value.value = local.Documents.value[index];
  }

  function goToNextDocument() {
    if (local.CurrentDocument.value < local.Documents.value.length - 1) {
      local.CurrentDocument.value += 1;
      setDocument(local.CurrentDocument.value);
    }
  }

  function goToPrevDocument() {
    if (local.CurrentDocument.value > 0) {
      local.CurrentDocument.value -= 1;
      setDocument(local.CurrentDocument.value);
    }
  }

  function zoomIn() {
    if (local.Scale.value <= 5) {
      local.Scale.value += 0.1;
    }
  }

  function zoomOut() {
    if (local.Scale.value >= 0.3) {
      local.Scale.value -= 0.1;
    }
  }

  const selected = useComputed(() => {
    const vex = local.ValueExpression.value;
    const pex = local.PathExpression.value;
    if (isString(local.Value.value) && local.Value.value) return local.Value.value;
    if (!vex) return "";

    if (!local.Documents.value || local.Documents.value.length === 0) {
      if (!local.Value.value) return "";
      // value is object
      // Extract the value expression and path expression
      const valueExpression = local.Value.value?.[vex];
      // Combine the path expression and value expression
      return `${pex}${valueExpression}`;
    }
    if (!local.Value.value) {
      setDocument(0)
    }
    // get the index of the selected document
    const selectedDocumentIndex = local.Documents.value.findIndex((doc) => doc?.[vex] === local.Value.value?.[vex]);
    // if selected document is not found
    if (selectedDocumentIndex === -1 && local.Documents.value.length > 0) {
      if (local.CurrentDocument.value > 0) {
        // if the current document is not the first document
        // set previous document as the selected document
        goToPrevDocument();
        return;
      }
      // set the first document as the selected document
      setDocument(0);
      return;
    }
    // document found
    // when selected document is not the current document
    const selectedDocument = local.Documents.value[selectedDocumentIndex];
    local.CurrentDocument.value = selectedDocumentIndex;
    return `${pex}${selectedDocument?.[vex]}`;
  });

  useEffect(() => {
    if (!selected.value) return;
    if (toNumber(local.MaxLoadingTimeSeconds.value === 0)) return;

    const timeoutId = setTimeout(() => {
      loadingInterval.value = toNumber(local.MaxLoadingTimeSeconds.value);
    }, toNumber(local.MaxLoadingTimeSeconds.value) * 1000);

    // Cleanup function to clear the timeout on unmount
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    local.Visible.value && (
      <Stack
        direction="column"
        data-testid={getTestId(config)}
        data-type={config.__typename}
        width="100%"
        position="relative"
        onMouseEnter={() => zoomRef.current?.style.setProperty("opacity", "1")}
        onMouseLeave={() => zoomRef.current?.style.setProperty("opacity", "0")}
        overflow={local.PreviewMode.value ? "hidden" : "auto"}
      >
        {!local.PreviewMode.value && (
          <Stack
            direction="row"
            position="absolute"
            top={config.ZoomVerticalPosition ?? "10px"}
            bgcolor="white"
            left={config.ZoomHorizontalPosition ?? "55px"}
            sx={{
              transform: "translate(-50%)",
              transition: "opacity ease-in-out .2s",
              opacity: 0,
            }}
            boxShadow="0 30px 40px 0 rgba(16, 36, 94, .2)"
            borderRadius="4px"
            border="1px solid #E0E0E0"
            zIndex={3}
            ref={zoomRef}
          >
            <Button onClick={zoomOut} disabled={local.Scale.value <= 0.3}>
              <NGIcon config={{ IconName: "ZoomOut" }} context={context} />
            </Button>
            <Button onClick={zoomIn} disabled={local.Scale.value >= 5}>
              <NGIcon config={{ IconName: "ZoomIn" }} context={context} />
            </Button>
          </Stack>
        )}
        <Box
          sx={getsxObject(local.Style.value, defaultStyle(local.Style.value?.width)) as SxProps<Theme>}
          className={getClassName(local.Classes, context)}
          onClick={handleOnClick}
        >
          {local.DocumentFormat.value !== "pdf" ? (
            <NGImage config={{
              Id: generateUID(),
              Bindings: {
                Value: config.FallbackImageUrl
                  ? `'${config.FallbackImageUrl}'`
                  : `'${local.DocumentFormat.value}'.toLowerCase() + '' + '.png'`,
              }
            }}
              context={context}
            />
          ) : (loadingInterval.value === toNumber(local.MaxLoadingTimeSeconds.value) && !local.NumPages.value) ? (
            <Typography>
              {local.ErrorMessage.value}
            </Typography>
          ) : (
            <Document
              file={selected.value}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={onDocumentError}
              error={local.ErrorMessage.value}
              loading={<Skeleton variant="rectangular" width="100%" height={200} />}
              className={getClassName(local.Classes, context)}
              onMouseEnter={() => paginationRef.current?.style.setProperty("opacity", "1")}
              onMouseLeave={() => paginationRef.current?.style.setProperty("opacity", "0")}
            >
              <Page
                pageNumber={local.CurrentPage.value}
                scale={local.Scale.value}
                renderAnnotationLayer={!local.PreviewMode.value}
                renderTextLayer={!local.PreviewMode.value}
                width={config.DocumentMaxWidth ? Math.min(width * 0.9, config.DocumentMaxWidth) : undefined} // width: 90vw; max-width: 400px
              />
              {!local.PreviewMode.value && (
                <Stack
                  direction="row"
                  position="absolute"
                  bottom="5%"
                  bgcolor="white"
                  left="50%"
                  sx={{
                    transform: "translate(-50%)",
                    transition: "opacity ease-in-out .2s",
                    opacity: 0,
                  }}
                  boxShadow="0 30px 40px 0 rgba(16, 36, 94, .2)"
                  borderRadius="4px"
                  border="1px solid #E0E0E0"
                  zIndex={2}
                  ref={paginationRef}
                >
                  <Button onClick={goToPrevPage} disabled={local.CurrentPage.value <= 1}>
                    <NGIcon config={{ IconName: "ChevronLeft" }} context={context} />
                  </Button>
                  <Typography component="p" marginBlockStart="1rem" marginBlockEnd="1rem">
                    Page {local.CurrentPage.value} of {local.NumPages.value}
                  </Typography>
                  <Button onClick={goToNextPage} disabled={local.CurrentPage.value >= local.NumPages.value}>
                    <NGIcon config={{ IconName: "ChevronRight" }} context={context} />
                  </Button>
                </Stack>
              )}
            </Document>
          )}

        </Box>
        {local.Documents.value.length > 1 && local.CurrentDocument.value > -1 && (
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Button
              onClick={goToPrevDocument}
              disabled={local.CurrentDocument.value === 0}
              className={getClassName(local.ButtonClasses)}
              sx={getsxObject(local.ButtonStyle.value)}
            >
              {"<<"} Previous Document
            </Button>
            <Typography>
              Document {local.CurrentDocument.value + 1} of {local.Documents.value.length}
            </Typography>
            <Button
              onClick={goToNextDocument}
              disabled={local.CurrentDocument.value === local.Documents.value.length - 1}
              className={getClassName(local.ButtonClasses)}
              sx={getsxObject(local.ButtonStyle.value)}
            >
              Next Document {">>"}
            </Button>
          </Stack>
        )}
      </Stack>
    )
  );
}
