import { IAccordionLayoutProps } from "../library/NGFieldExtensions";
import { useSignal } from "@preact/signals-react";
import { setupLocalState } from "../library/dataService";
import { getClassName, getsxObject, getTestId } from "../library/utils";
import { AccordionGroup } from "../../resolvers-types";
import { containerWithPaper } from "../generators/GeneratorUtils";
import NGAccordionGroup from "../components/NGAccordionGroup/NGAccordionGroup";

export default function NGAccordionLayout({
  layoutItem,
  config,
  context,
}: IAccordionLayoutProps) {
  const defaultLayout: AccordionGroup = {
    __typename: "AccordionGroup",
    Id: "default",
    Label: "default",
    Items: [],
  };

  const local = setupLocalState(
    config,
    {
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Visible: useSignal(config.Visible ?? true),
      Groups: useSignal(config.Groups ?? defaultLayout),
      LayoutItem: useSignal(layoutItem ?? defaultLayout),
      Id: useSignal(config.Id ?? ""),
    },
    context
  );

  const renderContainer = () => (
    <div
      data-testid={getTestId(config)}
      data-type={config.__typename}
      style={local.Style.value}
      className={getClassName(local.Classes)}
    >
      {local.Groups.value?.map((group, key) => {
        return (
          <NGAccordionGroup
            config={group}
            context={context}
            key={key}
            layoutItem={local.LayoutItem.value}
          />
        );
      })}
    </div>
  );
  return containerWithPaper(local, config, renderContainer, context);
}
