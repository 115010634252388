import React from "react";
import { Signal } from "@preact/signals-react";
import * as MuiIcons from "@mui/icons-material";
import { FormControlLabel } from "@mui/material";
import { Dictionary, isNil, toNumber } from "lodash";
import NGModalPopup from "./NGModalPopup/NGModalPopup";
import {
  findDialogs,
  generateUID,
  getEnvironmentBaseUrl,
  getTestId,
  getsxObject,
  isNullOrEmpty,
  normalizeSessionUrl,
} from "../library/utils";
import { Component, Dialog, Maybe, Page, TestHarness } from "../../resolvers-types";
import { getApiUrl, getRepositoryAssetsBaseUrl } from "../library/utils";
import { RuntimeContext } from "../library/NGFieldExtensions";

export function createDynamicMUIIcon(config: any, parent: any) {
  if (isNil(parent.Id)) parent.Id = config.IconName;

  return React.createElement(MuiIcons[config.IconName], {
    "data-testid": getTestId(parent),
    variant: config.Variant,
    color: config.Color,
    fontSize: config.Size,
    sx: getsxObject(config.Style),
    className: config.ClassName,
  });
}

interface CreateControlWithLabelProps {
  local: any;
  handlers: any;
  config: any;
  label: any;
  createControl: any;
  className?: any;
  labelPlacement?: "bottom" | "top" | "end" | "start";
}

interface CreateControlWithoutLabelProps {
  local: any;
  handlers: any;
  config: any;
  createControl: any;
  className?: any;
}

export function createControlWithLabel({
  local,
  handlers,
  config,
  label,
  createControl,
  className,
  labelPlacement = "end",
}: CreateControlWithLabelProps): JSX.Element {
  if (isNullOrEmpty(config.Label) && isNullOrEmpty(config.LabelWhenOn) && isNullOrEmpty(config.LabelWhenOff))
    return createControl(local, handlers, config, className, true);

  const st: any = isNil(config.LabelStyle) ? undefined : { "& .MuiFormControlLabel-label": config.LabelStyle };

  return (
    <FormControlLabel
      data-testid={getTestId(config)}
      data-type={config.__typename}
      sx={getsxObject(st, { marginLeft: 0, marginRight: 0 })}
      control={createControl(local, handlers, config, "", false)}
      label={label}
      className={className}
      labelPlacement={labelPlacement}
    />
  );
}

export function createControlWithoutLabel({
  local,
  handlers,
  config,
  createControl,
  className,
}: CreateControlWithoutLabelProps): JSX.Element {
  return (
    <div data-testid={getTestId(config)} data-type={config.__typename} className={className}>
      {createControl(local, handlers, config, "", true)}
    </div>
  );
}

export function convertToSizedImageFilename(name: string, size: string | undefined | Maybe<string>): string {
  if (isNullOrEmpty(name)) return name;

  if (size && !isNullOrEmpty(size)) {
    const sc: Dictionary<string> = { large: "l", medium: "m", small: "s" };
    const dotIndex = name.lastIndexOf(".");
    const sizeAbbrv = sc[size.toLowerCase()];

    if (!isNil(sizeAbbrv)) {
      return name.substring(0, dotIndex) + "." + sizeAbbrv + name.substring(dotIndex);
    }
  }
  return name;
}

export function getSizeAsNumber(size?: string) {
  if (!size) return undefined;
  return parseFloat(size);
}

export function setupDocumentViewerSource(value: string, source: Signal<string>): string {
  // result should be fileSource if it is not null or empty, otherwise value
  const result = value;
  // if starts with http, return result
  if (result.startsWith("http")) return (source.value = result);
  // get relative path from environment base url
  return (source.value = getEnvironmentBaseUrl() + result);
}

const appendUrlSegments = (base: string, segment: string): string => {
  // Remove trailing slash from base and leading slash from segment, then concatenate
  return `${base.replace(/\/$/, "")}/${segment.replace(/^\//, "")}`;
};

export function setupImageDetails(
  local: any,
  value: any,
  url,
  convertToSizedImageFilename: (name: string, size: string | undefined | Maybe<string>) => string
) {
  if (isNil(local.Source.value)) {
    if (value.startsWith("http")) local.Source.value = "External";
    else local.Source.value = "Asset";
  }

  switch (local.Source.value) {
    case "Asset":
      {
        // Because this is a public asset, we don't pass any /u/d/ session information
        let v = appendUrlSegments(getRepositoryAssetsBaseUrl() as string, "Images/");
        if (!isNullOrEmpty(local.Path.value)) v += `${local.Path.value}/`;

        if (local.Size.value.toLocaleLowerCase == "original") url.value = v + value;
        else url.value = v + convertToSizedImageFilename(value, local.Size.value);
      }
      break;
    case "Vault":
      url.value = getFileUrlFromVault(value);
      break;
    case "External":
      url.value = value;
      break;
  }
}

export function setupModalPopup(dialog: Dialog, context: RuntimeContext) {
  // const stateObjectNameForModal = getStateObjectNameForModal(dialog.Id);
  // createBindingsForPopup(dialog, stateObjectNameForModal);
  return <NGModalPopup key={"MP_" + dialog.Id + generateUID()} config={dialog} context={context} />;
}

export function getFileUrlFromVault(value: any): any {
  return getApiUrl() + normalizeSessionUrl("/files/vault/" + value);
}

export function renderModalPopups(component: Component | Page | TestHarness | null, context: RuntimeContext) {
  if (isNil(component)) return;

  const popups = findDialogs(component);

  if (isNil(component.ModalPopups)) component.ModalPopups = [];

  popups.forEach((dialog) => {
    if (isNil(dialog)) return;

    if (component.ModalPopups?.find((p) => p?.Id === dialog.Id)) return;

    component.ModalPopups?.push(dialog);
  });

  if (isNil(component.ModalPopups) || component.ModalPopups.length === 0) {
    return;
  }

  return (
    <>
      {component.ModalPopups.map((dialog, key) => {
        if (isNil(dialog)) return;

        return setupModalPopup(dialog, context);
      })}
    </>
  );
}
