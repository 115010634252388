import { useState, useEffect, useCallback } from 'react';
import { Checkbox } from '@mui/material'; // Assuming you're using MUI for checkbox
import { CustomHeaderProps } from 'ag-grid-react';

interface CustomHeaderCheckboxProps extends CustomHeaderProps {
  showHeaderCheckbox?: boolean;
}

const CustomHeaderCheckbox = ({ api, showHeaderCheckbox }: CustomHeaderCheckboxProps) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);

  const setCheckboxState = useCallback(() => {
    const selectedNodes = api?.getSelectedNodes();
    const renderedNodes = api?.getRenderedNodes();
    let currentRenderedSelectedNodeSize = 0;
    renderedNodes.forEach((renderedNode) => {
      const currentRenderedSelectedNodeFound = selectedNodes.find((selectedNode) => selectedNode.id === renderedNode.id);
      if (currentRenderedSelectedNodeFound) {
        currentRenderedSelectedNodeSize += 1;
      }
    });
    const allChecked = currentRenderedSelectedNodeSize > 0 && currentRenderedSelectedNodeSize === renderedNodes.length;
    const isNewSelectionIndeterminate = currentRenderedSelectedNodeSize > 0 && !allChecked;
    setIsChecked(allChecked);
    setIsIndeterminate(isNewSelectionIndeterminate);
  }, [api]);

  useEffect(() => {
    if (showHeaderCheckbox) {

      api.addEventListener('selectionChanged', setCheckboxState);
      api.addEventListener('paginationChanged', setCheckboxState);

      return () => {
        api.removeEventListener('selectionChanged', setCheckboxState);
        api.removeEventListener('paginationChanged', setCheckboxState);
      }
    }
  }, [api, showHeaderCheckbox, setCheckboxState]);

  const handleSelectAll = (e: any) => {
    const selectedState = e.target.checked;
    // get the page size
    const pageSize = api?.getDisplayedRowCount();
    // render page size nodes in order to select all
    api.setGridOption("rowBuffer", pageSize);
    const renderedNodes = api.getRenderedNodes();

    let nodeSelected = 0;
    api.forEachNode((node) => {
      if (renderedNodes.find((renderedNode) => renderedNode.id === node.id)) {
        node.setSelected(selectedState);
        if (selectedState) {
          nodeSelected += 1;
        }
      }
    });

    if (nodeSelected === renderedNodes.length) {
      setIsChecked(true);
    } else if (nodeSelected > 0) {
      setIsIndeterminate(true);
    } else {
      // reset the row buffer to the default value
      api.setGridOption("rowBuffer", 10);
      setIsChecked(false);
    }
  };

  if (!showHeaderCheckbox) return false;

  return (
    <Checkbox
      checked={isChecked}
      onChange={handleSelectAll}
      size="small"
      sx={{
        padding: 0,
        borderRadius: 0,
        '&.Mui-checked': {
          color: "transparent",
        },
        '&.MuiCheckbox-indeterminate': {
          color: "transparent",
        },
        'svg': {
          display: 'none',
        }
      }}
      indeterminate={isIndeterminate}
      inputProps={{ className: 'ag-input-field-input ag-checkbox-input' }}
      className={`ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper ${isChecked ? 'ag-checked' : ''} ${isIndeterminate ? 'ag-indeterminate' : ''}`}
    />
  );
};

export default CustomHeaderCheckbox;