import { useSignalEffect } from "@preact/signals-react";
import { log } from "./logger";
import { client } from "./nats-client";

const tag = "finapp";
let loaded = false;

export function useFinApp(onSearch) {
  useSignalEffect(() => {
    if (loaded) return;

    (async () => {
      try {
        const m = await import("https://fwc-ui.markitqa.com/fwc-load-app-fwc-all-apps.js");
        let token;
        let renewingTokenPromise: Promise<any> | null;

        const tokenProvider = async (renewToken) => {
          if (renewToken || token === undefined) {
            if (!renewingTokenPromise) {
              renewingTokenPromise = (async () => {
                try {
                  token = await requestToken();
                } finally {
                  renewingTokenPromise = null;
                }
                return token;
              })();
            }
            return renewingTokenPromise;
          }

          return token;
        };

        const renewToken = async () => {
          if (!renewingTokenPromise) {
            renewingTokenPromise = (async () => {
              try {
                token = await requestToken();
              } finally {
                renewingTokenPromise = null;
              }
              return token;
            })();
          }
          return renewingTokenPromise;
        };

        const finctx = await m.registerFinApps({
          tokenProvider: async (renewToken) => {
            // console.log("fin token", renewToken);
            // if (renewToken || token === undefined) {
            //   token = await requestToken();
            //   console.log("fin token requested ", renewToken);
            // }
            // return token;
            // return "DCvt8Kk1xeYn834f3qDVRtHGmmv0"; // Expires 2/12/2025 10:52:12 PM (ET)
            // return "HXwVmvLGpeAFBQ3mZA3q4RNnAiJl" // Long living Fincentric token with wider entitlement for Fin Apps Stock Stories
            return "gBR0zAyGQDvVxBg70VXsmUd8zcAH" // 3w window
          },
          // renewToken: async (renewToken) => {
          //   // console.log("renew fin token", renewToken);
          //   token = await requestToken();
          //   return token;
          // }
        }
);
        log.info(tag, "Fin App Context", finctx);

        finctx.addEventListener("search-selection", (e) => {
          const data = e?.detail?.data;
          if (!data) return;
          if (onSearch) onSearch(data);
        });
        loaded = true;
      } catch (ex) {
        log.error(tag, "Failed to load fin apps", ex);
      }
    })();
  });
}



async function requestToken() {
  const finAppTokenCreate = await client.request<{ Token: string }>("finapptoken.create", {});

  if (!finAppTokenCreate.success || finAppTokenCreate.data === undefined) {
    log.error(tag, "finapp create token failed", { finAppTokenCreate });

    // Temporary token, Fincentric auth is only setup for Communify Test
    return "DCvt8Kk1xeYn834f3qDVRtHGmmv0"; // Expires 2/12/2025 10:52:12 PM (ET)
  }

  return finAppTokenCreate.data.Token;
}