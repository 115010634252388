
import { callServiceInternal, getParams, getScope, getServiceResult, getState } from "./dataService";
import { signal } from "@preact/signals-react";
import { sleep } from "./utils";

export interface ODataQueryOptions {
  $filter?: string;
  $orderby?: string;
  $top?: number;
  $skip?: number;
  $select?: string;
  $apply?: string;
}

function parseQueryString(query: string): Record<string, unknown> {
  query = query.replace("/aggregate($count as count)", '');

  return Object.fromEntries(new URLSearchParams(query).entries());
}

export async function callOdataQuery({ options, context, serviceConfig, config }) {
  //get params from query string
  const odataParams = parseQueryString(options);

  // Take the params that are non part of OData and add them to the service param
  const nonODataFields = config.Service.Fields
    .filter(f => !options.includes(f.Name?.toLowerCase()));

  //const serviceParams = { OData: { ...params }, ...nonODataParamsFlat };

  const serviceConfigWithoutOdata = { ...serviceConfig, Fields: nonODataFields };

  //get service
  const service = { Loading: signal(false), State: signal(null) }

  const { state, form, parentState } = getState(context);
  // initialize grid service into NGService state
  state["NGService"][serviceConfig.Name] = service;

  //get scope
  const scope = getScope(context, serviceConfigWithoutOdata, state, form, {}, parentState);
  const [params, isValid] = getParams(serviceConfigWithoutOdata, scope);
  if (!isValid) return [];

  params.OData = odataParams;
  service.Loading.value = true;

  // call service
  const response = await callServiceInternal(serviceConfigWithoutOdata, params, service, context);
  // get service result based on service bindings
  const result = getServiceResult(serviceConfigWithoutOdata, scope, response.data);
  // map rows based on bindings
  service.Loading.value = false;

  return result["Rows"] ?? [];
}
