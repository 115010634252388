import { INGTabItemProps } from "../library/NGFieldExtensions";
import { setupHandlers, setupLocalState, updateItemContext } from "../library/dataService";
import { useSignal, useSignalEffect } from "@preact/signals-react";
import { Tab } from "@mui/material";
import { getClassName, getStyleObject, getTestId } from "../library/utils";

export default function NGTabItem({ config, context, selected }: INGTabItemProps) {
  const tag = "NGTabItem";

  const local = setupLocalState(
    config,
    {
      Title: useSignal(config.Title ?? ""),
      Disabled: useSignal(config.Disabled ?? false),
      Visible: useSignal(config.Visible ?? true),
      Style: useSignal(config.Style ?? []),
      TabClasses: useSignal(config.TabClasses ?? []),
      TabIndex: useSignal(config.TabIndex ?? 0),
    },
    context
  );

  const handlers = setupHandlers(config, context);
  return (
    <>
      {local.Visible.value && (
        <Tab
          data-type={config.__typename}
          data-testid={getTestId(config)}
          selected={selected}
          disabled={local.Disabled.value}
          value={local.TabIndex.value}
          label={local.Title.value}
          sx={getStyleObject(local.Style.value, { textTransform: "none" })}
          onClick={(e) => handlers?.["onClick"]?.(e, local.TabIndex.value)}
          className={getClassName(local.TabClasses)}
        />
      )}
    </>
  );
}
