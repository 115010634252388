import { memo } from "react";
import { isEmpty, isNil, isEqual } from "lodash-es";
import { INGFormProps } from "../library/NGFieldExtensions";
import { FlexLayout, LinearLayout, GridStackLayout, Form, TableLayout, LayoutItem, FragmentLayout } from "../../resolvers-types";
import NGGridStackLayout from "../layouts/NGGridStackLayout";
import { setupForm, setupLocalState, updateItemContext } from "../library/dataService";
import { useSignal } from "@preact/signals-react";
import { generateUID, getsxObject } from "../library/utils";
import NGFlexLayout from "../layouts/NGFlexLayout";
import { Box, Table, TableCell, TableContainer, TableRow } from "@mui/material";
import NGTableCell from "../layouts/NGTableCell";
import { containerWithPaper } from "./GeneratorUtils";
import NGLayoutItem from "./NGLayoutItem";
import NGFragmentLayout from "../layouts/NGFragmentLayout";

const NGForm = memo(
  ({ config, context }: INGFormProps) => {
    config.Id = config?.FormId || config.Id;
    const currentContext = updateItemContext(context, config);

    config.ContextId = config.Id;

    if (isNil(config.Layout)) {
      config.Layout = {
        __typename: "LinearLayout",
        Id: "GLO_" + config.Id,
        DirectionExtraSmall: "column",
      } as LinearLayout;
    }

    const isCell = config.Layout?.__typename === "FormInCell";

    const local = setupLocalState(
      config,
      {
        Visible: useSignal(config.Visible ?? true),
        Components: useSignal(config.Items || []),
        Data: useSignal(config.Data || {}),
        InDesignMode: useSignal(config.InDesignMode || false),
        Style: useSignal(config.Style ?? {}),
      },
      currentContext
    );

    currentContext.Form = { Data: local.Data, Config: config };
    setupForm(currentContext);

    const FormWithLayout = (props: { form: Form }) => {
      const form = props.form;

      // console.log("FormWithLayout", form);

      switch (form.Layout?.__typename) {
        case "GridStackLayout": {
          const ll: GridStackLayout = form.Layout as GridStackLayout;
          (ll as any).ContextId = form.Id;
          return (
            <NGGridStackLayout
              key={ll.Id}
              config={form}
              gridStackLayout={ll}
              inDesignMode={local.InDesignMode.value}
              context={currentContext}
            />
          );
        }

        case "FormInCell": {
          return (
            <>
              {form?.Items?.map((x) => {
                (x as any).ContextId = form.Id;
                return <NGTableCell key={generateUID()} item={x} context={currentContext} />;
              })}
            </>
          );
        }

        /* case "LinearLayout": {
        const fl: LinearLayout = form.Layout as LinearLayout;
        fl.Id = form.Id;
        return (
          <NGLinearLayout
            key={fl.Id}
            layoutItem={form}
            appDictionary={appDictionary}
            inDesignMode={local.InDesignMode.value}
            linearLayout={fl}
          />
        );
      } */
        case "LinearLayout":
        case "FlexLayout": {
          const fl: FlexLayout = form.Layout as FlexLayout;
          fl.ContextId = form.Id;
          return (
            <NGFlexLayout
              key={fl.Id}
              layoutItem={form}
              config={fl}
              inDesignMode={local.InDesignMode.value}
              context={currentContext}
            />
          );
        }

        case "FragmentLayout": {
          const fl: FragmentLayout = form.Layout as FragmentLayout;
          fl.ContextId = form.Id;
          return (
            <NGFragmentLayout
              key={fl.Id}
              layoutItem={form}
              config={fl}
              inDesignMode={local.InDesignMode.value}
              context={currentContext}
            />
          );
        }

        case "TableLayout": {
          const tl: TableLayout = form.Layout as TableLayout;
          tl.ContextId = form.Id;

          if (isNil(tl.RowsItems)) return null;

          return (
            <TableContainer sx={getsxObject(tl.TableStyle)}>
              <Table>
                {tl.RowsItems.map((v) => {
                  return createRow(tl, v, form);
                })}
              </Table>
            </TableContainer>
          );
        }

        default:
          throw new Error(`Layout type ${form.Layout?.__typename} not implemented yet`);
      }
    };

    function createRow(tl: TableLayout, rowItemIds: any, form: Form) {
      return (
        <TableRow sx={getsxObject(tl.RowStyle)}>
          {rowItemIds?.map((id, i) => {
            const x = form.Items?.find((x) => (x as any).Id === id);
            if (isNil(x)) return null;

            (x as any).ContextId = form.Id;
            return (
              <TableCell key={i}>
                <NGLayoutItem config={x as LayoutItem} context={currentContext} />
              </TableCell>
            );
          })}
        </TableRow>
      );
    }

    function formWithContext() {
      return (
        // <FormContext.Provider value={formCtx}>
        <FormWithLayout form={config} />
        // </FormContext.Provider>
      );
    }

    // const formCtx: any = {
    //   Form: form,
    //   Data: local.Data,
    // };

    // useEffect(() => {
    //   setupForm(formCtx);
    // });

    function formWithStyle() {
      return (
        <>
          {isEmpty(local.Style.value) || isCell ? (
            formWithContext()
          ) : (
            <Box sx={getsxObject(local.Style.value)}>{formWithContext()}</Box>
          )}
        </>
      );
    }

    return <>{local.Visible.value && containerWithPaper(local, config, formWithStyle, context)}</>;
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps, nextProps);
  }
);

export default NGForm;
