import * as graphql from "../../../resolvers-types";
import { ODataBaseQueryForm, ODataQueryForm } from "./odata-query-form";
import { serviceSampleData } from "./service-sample-data";

const requestTabStyle = {
  display: "table",
  "& .prop-editor-layouts-container": {
    display: "table-row",
    "&>div": {
      display: "table-cell",
      verticalAlign: "middle"
    }
  },
}

const ODataFieldsForm = {
  __typename: "SimpleContainer",
  Id: "751360A",
  Bindings: {
    Visible: "State.ODataRequestEnabled",
  },
  UniqueName: "ODataQueryForm",
  Items: [
    {
      __typename: "TabContainer",
      Id: "46147C7",
      Bindings: {
        SelectedTabTitle: "State.ODataTabSelected",
      },
      Actions: [
        {
          // create a onChange trigger, the set State.ODataTabId to the selected tab id
          Trigger: "onChange",
          CommandSet: {
            FirstCommandId: "1",
            ExecuteCommandsInParallel: false,
            Commands: [
              {
                Id: "1",
                Instruction: {
                  Name: "SetState",
                },
                Parameters: [
                  {
                    Name: "Bindings",
                    Value: {
                      "State.ODataQueryTab": "!!Event.Index && State.ODataTabSelected == 'Odata Query'"
                    },
                  },
                ],
              },
            ],
          },
        }
      ],
      Items: [
        {
          __typename: "TabItem",
          Id: "odataBaseQueryTab",
          Title: "Base Query",
          Items: [ODataBaseQueryForm]
        },
        {
          __typename: "TabItem",
          Id: "odataQueryTab",
          Title: "Odata Query",
          Items: [ODataQueryForm]
        }
      ],
      Classes: [],
      ContentClasses: [],
      TabClasses: [],
      Style: {}
    },
  ],
} as graphql.SimpleContainer;

export const historyDialog: graphql.Dialog = {
  __typename: "Dialog",
  Id: "HistoryDialog",
  Title: "History",
  FullScreen: 'true',
  ContentContainer: {
    __typename: "SimpleContainer",
    Id: "dxznnzmcah",
    Items: [
      {
        __typename: "SimpleContainer",
        Id: "vax3cepskr",
        Items: [
          {
            Id: "uldol3nk1u",
            __typename: "Button",
            Label: "Open as Request",
            Classes: [
              "form-save-button"
            ],
            Actions: [
              {
                // create a onChange trigger, the set State.ODataTabId to the selected tab id
                Trigger: "onClick",
                CommandSet: {
                  FirstCommandId: "0",
                  ExecuteCommandsInParallel: false,
                  Commands: [
                    {
                      Id: "0",
                      Instruction: {
                        Name: "CloseModalPopup",
                      },
                      Parameters: [{ Name: "ModalPopupId", Value: "HistoryDialog" }],
                      NextCommandIdOnSuccess: "1",
                    },
                    {
                      Id: "1",
                      Instruction: {
                        Name: "SetState",
                      },
                      Parameters: [
                        {
                          Name: "Bindings",
                          Value: {
                            "State.FieldsAsPropertiesType": "State.ServiceResultDataForPropertiesEditor",
                            "State.SelectedTabId": "'Request'",
                          },
                        },
                      ],
                    },
                  ],
                },
              }
            ],
            StartIcon: {
              IconName: "SwapHorizontalCircle",
              ContextId: "uldol3nk1u",
            },
            Style: {
              width: "12%",
              maxWidth: "150px"
            },
          }
        ],
        IgnoreLayout: true,
        Style: {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          minWidth: "20px",
          minHeight: "20px"
        },
        Classes: [],
        Visible: true
      },
      {
        __typename: "List",
        Id: "yr4ds3dmfn",
        ShowExport: true,
        ShowFloatingFilter: true,
        ShowQuickFilter: true,
        ShowCheckboxSelection: true,
        SelectionMode: "singleRow",
        EnableRowClickSelection: true,
        Style: {
          width: "100%"
        },
        GridLayout: "normal",
        ListColumns: [
          {
            __typename: "ListColumn",
            Id: "5hmp3jzkng",
            HeaderName: "Id",
            Name: "Id",
            IsPrimaryKey: true,
            Visible: false,
            FloatingFilter: true,
            EnableRowGroup: true,
            Editable: false,
            Filter: "agTextColumnFilter",
            Flex: 0.3333333333333333,
            EnableValue: true,
            EnablePivot: true,
            EnableCellChangeFlash: true,
            InitialWidth: 150,
            CellClass: [],
            HeaderClass: [],
            CellDataType: "text",
            ValueParser: null,
            Field: "Id",
            ValueFormatter: null,
            Hide: true,
            SuppressHeaderMenuButton: false,
            ColId: "5hmp3jzkng",
            DefaultAggFunc: "first",
            FilterParams: {
              comparator: null
            },
            ColumnFixedWidth: 246,
            RowGroup: false,
            RowGroupIndex: null,
            Pivot: false,
            PivotIndex: null,
            AggFunc: null,
            Pinned: null,
            Sort: null,
            SortIndex: null
          },
          {
            HeaderName: "Date Time",
            Id: "7jj70cnh2n",
            Name: "CreatedOn",
            IsPrimaryKey: false,
            __typename: "ListColumn",
            FloatingFilter: true,
            EnableRowGroup: true,
            Editable: false,
            Filter: "agTextColumnFilter",
            Flex: 0.25,
            EnableValue: true,
            EnablePivot: true,
            EnableCellChangeFlash: true,
            InitialWidth: 150,
            CellClass: [],
            HeaderClass: [],
            CellDataType: false,
            Field: "CreatedOn",
            Visible: true,
            SuppressHeaderMenuButton: false,
            ColId: "7jj70cnh2n",
            DefaultAggFunc: "first",
            FilterParams: {
              comparator: null
            },
            ColumnFixedWidth: 197,
            RowGroup: false,
            Pivot: false,
            Style: {},
            Classes: [],
            CellRendererItems: [],
            ChipClasses: [],
            DataType: "date"
          },
          {
            __typename: "ListColumn",
            Id: "mzdhwydtuk",
            HeaderName: "Service",
            Name: "Service",
            Editable: false,
            Filter: "agTextColumnFilter",
            Flex: 0.3333333333333333,
            EnableValue: true,
            EnablePivot: true,
            EnableCellChangeFlash: true,
            InitialWidth: 150,
            CellClass: [],
            HeaderClass: [],
            CellDataType: "text",
            Field: "Service",
            Visible: true,
            SuppressHeaderMenuButton: false,
            ColId: "mzdhwydtuk",
            DefaultAggFunc: "first",
            FilterParams: {
              comparator: null
            },
            ColumnFixedWidth: 263,
            RowGroup: false,
            Pivot: false,
            Style: {},
            Classes: [],
            CellRendererItems: [],
            ChipClasses: [],
            ValueParser: null,
            ValueFormatter: null,
            CellStyle: {},
            RowGroupIndex: null,
            PivotIndex: null,
            AggFunc: null,
            Pinned: null,
            Sort: null,
            SortIndex: null
          },
          {
            __typename: "ListColumn",
            Id: "ppyxf5ghbb",
            HeaderName: "Verb",
            Name: "Verb",
            Editable: false,
            Flex: 0.3333333333333333,
            Visible: true,
            ColumnFixedWidth: 263,
            RowGroup: false,
            Pivot: false,
            Style: {},
            Classes: [],
            CellRendererItems: [],
            ChipClasses: [],
          },
          {
            __typename: "ListColumn",
            Id: "nqlj68m8yy",
            HeaderName: "Parameters",
            Name: "Parameters",
            Editable: false,
            Flex: 0.3333333333333333,
            Visible: true,
            ColumnFixedWidth: 264.361,
            RowGroup: false,
            Pivot: false,
            Style: {},
            Classes: [],
            CellRendererItems: [],
            ChipClasses: []
          }
        ],
        Rows: [
          {
            Id: "dysg6azjv1",
            Name: "NVIDIA",
            Ticker: "NVDA",
            Quote: 67.87,
            FileExtension: "pdf"
          },
          {
            Id: "kh7fd8dwwz",
            Name: "Microsoft",
            Ticker: "MSFT",
            Quote: 152.17,
            FileExtension: "png"
          },
          {
            Id: "sbhrhz9klx",
            Name: "Apple",
            Ticker: "AAPL",
            Quote: 200.15,
            FileExtension: "docx"
          },
          {
            Id: "acqyrnia70",
            Name: "Meta",
            Ticker: "META",
            Quote: 537.95,
            FileExtension: "xlsx"
          }
        ],
        Bindings: {
          Rows: "_orderBy(State.History, 'CreatedOn', 'desc')",
        },
        ColumnsClasses: [],
        ColumnsHeaderClasses: [],
        GroupingClasses: [],
        GroupingHeaderClasses: [],
        ResizeColumnsIds: []
      }
    ],
    Layout: {
      __typename: "FlexLayout",
      Direction: "column"
    }
  },
};

export const testHarnessTemplate = {
  __typename: "TestHarness",
  Id: "5h6wei",
  Items: [
    {
      __typename: "SimpleContainer",
      Id: "eowofz",
      Items: [
        {
          __typename: "SimpleContainer",
          Id: "hzmck3",
          Items: [
            {
              __typename: "SimpleContainer",
              BlockDelete: true,
              Id: "dzkgs1",
              Items: [
                {
                  Id: "0vwpko",
                  __typename: "Label",
                  Value: "Test Harness",
                  Layout: null,
                  Classes: [
                    "TitleLevel2"
                  ],
                  ChipClasses: [],
                  getData: null,
                  Label: null
                }
              ],
              Layout: {
                __typename: "FlexLayout",
                Direction: "row",
                AlignItems: "center",
                Style: {},
                Classes: [],
                JustifyContent: "space-between"
              },
              ChipClasses: [],
              Classes: [],
              Style: {},
              StartIcon: {
                IconName: null
              }
            }
          ],
          Layout: {
            __typename: "FlexLayout",
            Direction: "column",
            Style: {},
            Classes: []
          }
        },
        {
          __typename: "SimpleContainer",
          Id: "pph3rdpbd2",
          Items: [],
          IgnoreLayout: true,
          Style: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            minWidth: "20px",
            minHeight: "20px"
          }
        },
        {
          __typename: "SimpleContainer",
          Id: "heil13",
          Items: [
            {
              Id: "unr66v",
              __typename: "MultiSelect",
              Label: "Service",
              Classes: [],
              ChipClasses: [],
              Style: {
                width: "400px"
              },
              StartIcon: {
                IconName: null
              },
              ContentClasses: [],
              TabClasses: [],
              Items: [],
              Services: [],
              Tags: [],
              TitleClasses: [],
              Bindings: {
                MultiSelectPossibleValues: "State.OpenAPIServices",
                // SearchFilter: "State.OpenAPIServicesFilter"
              },
              g456u657tnyh: [
                {
                  Id: {
                    v: 0,
                    i: 1
                  },
                  Name: {
                    v: "State.OpenAPIServices",
                    i: 1
                  }
                }
              ],
              LabelExpression: "serviceName",
              ValueExpression: "methods",
              Actions: [
                {
                  Trigger: "onChange",
                  CommandSet: {
                    FirstCommandId: "1",
                    ExecuteCommandsInParallel: false,
                    Commands: [
                      {
                        Id: "1",
                        Instruction: {
                          Name: "SetState"
                        },
                        Parameters: [
                          {
                            Name: "Bindings",
                            Value: {
                              "State.PossibleVerbs": "Event.methods",
                              "State.RequestForm": "{}",
                              "State.ODataRequestEnabled": "null",
                              "State.FieldsAsPropertiesType": "{}",
                              "State.SelectedVerb": "null",
                              "State.SelectedTabId": "'Request'",
                              "State.ServiceResult": "null",
                              "State.ResultType": "null",
                              "State.ServiceResultDataForPropertiesEditor": "null",
                              "State.ServiceResultFieldsAsProperties": "null"
                            }
                          }
                        ],
                        NextCommandIdOnSuccess: "2",
                      },
                      {
                        Id: "2",
                        Instruction: {
                          Name: "ClearForm",
                        },
                        Parameters: [{ Name: "Form", Value: "RequestPropEditor" }],
                      },
                    ]
                  }
                }
              ],
              Options: [],
              DefaultValue: null
            },
            {
              Id: "av4psb",
              __typename: "Button",
              Label: "History",
              Classes: [
                "form-save-button"
              ],
              ChipClasses: [],
              StartIcon: {
                IconName: "History",
                ContextId: "av4psb",
              },
              Actions: [
                {
                  Trigger: "onClick",
                  CommandSet: {
                    FirstCommandId: "1",
                    ExecuteCommandsInParallel: false,
                    Commands: [
                      {
                        Id: "1",
                        Instruction: {
                          Name: "OpenModalPopup",
                        },
                        Parameters: [
                          { Name: "Title", Value: "History" },
                          { Name: "ModalPopupId", Value: "HistoryDialog" },
                        ],
                      },
                    ],
                  },
                },
              ],
              Style: {
                width: "12%",
                maxWidth: "150px"
              }
            },
            {
              Id: "ng9l3lnd60",
              __typename: "Button",
              Label: "Copy as Service Call",
              Classes: [
                "form-save-button"
              ],
              Actions: [
                {
                  Trigger: "onClick",
                  CommandSet: {
                    FirstCommandId: "0",
                    ExecuteCommandsInParallel: false,
                    Commands: [
                      {
                        Id: "0",
                        Instruction: {
                          Name: "CopyToClipboard",
                        },
                        Parameters: [
                          {
                            Name: "TransformMethod",
                            Value: "ObjectToJSONString",
                          },
                          {
                            Name: "Form",
                            Value: "RequestPropEditor",
                          },
                          {
                            Name: "Bindings",
                            Value: {
                              ClipboardContent: "{ \
                                Service: State.SelectedPath, \
                                Fields: getFieldsFromForm(Form, State.SendEmptyFields, State.ODataRequestEnabled, State.ODataQueryTab, State.BaseQueryFields), \
                                Type: 'Internal', \
                                Name: State.SelectedPath, \
                                Id: State.SelectedPath \
                              }",
                            },
                          },
                        ],
                      },
                    ],
                  },
                },
              ],
              ChipClasses: [],
              StartIcon: {
                IconName: "MiscellaneousServices",
                ContextId: "ng9l3lnd60",
                Id: "MiscellaneousServices"
              },
              Style: {
                width: "12%",
                maxWidth: "150px"
              },
              originalId: "av4psb",
              id: "ng9l3lnd60"
            },
            // {
            //   Id: "impomxw07h",
            //   __typename: "Button",
            //   Label: "Open in Postman",
            //   Classes: [
            //     "form-save-button"
            //   ],
            //   ChipClasses: [],
            //   StartIcon: {
            //     IconName: "Signpost",
            //     ContextId: "impomxw07h",
            //     Id: "Signpost"
            //   },
            //   Style: {
            //     width: "12%",
            //     maxWidth: "150px"
            //   },
            //   originalId: "av4psb",
            //   id: "impomxw07h"
            // },
            // {
            //   Id: "tku3hmfkty",
            //   __typename: "Button",
            //   Label: "Open in Swagger",
            //   Classes: [
            //     "form-save-button"
            //   ],
            //   ChipClasses: [],
            //   StartIcon: {
            //     IconName: "SwapHoriz",
            //     ContextId: "tku3hmfkty",
            //     Id: "SwapHoriz"
            //   },
            //   Style: {
            //     width: "12%",
            //     maxWidth: "150px"
            //   },
            //   originalId: "impomxw07h",
            //   id: "tku3hmfkty"
            // },
            // {
            //   Id: "d8kkquqqrs",
            //   __typename: "Button",
            //   Label: "Service Info",
            //   Classes: [
            //     "form-save-button"
            //   ],
            //   ChipClasses: [],
            //   StartIcon: {
            //     IconName: "Info",
            //     ContextId: "d8kkquqqrs",
            //     Id: "Info"
            //   },
            //   Style: {
            //     width: "12%",
            //     maxWidth: "150px"
            //   },
            //   originalId: "impomxw07h",
            //   id: "d8kkquqqrs"
            // },
            // {
            //   Id: "ynqwl72l6p",
            //   __typename: "Button",
            //   Label: "Generate Component",
            //   Classes: [
            //     "form-save-button"
            //   ],
            //   ChipClasses: [],
            //   StartIcon: {
            //     IconName: "Computer",
            //     ContextId: "ynqwl72l6p",
            //     Id: "Computer"
            //   },
            //   Style: {
            //     width: "12%",
            //     maxWidth: "150px"
            //   },
            //   originalId: "d8kkquqqrs",
            //   id: "ynqwl72l6p"
            // }
          ],
          Layout: {
            __typename: "FlexLayout",
            Direction: "row",
            AlignItems: "center",
            JustifyContent: "space-between",
            Style: {},
            Classes: [
              "padding-all"
            ]
          },
          Classes: [],
          Value: null,
          ChipClasses: [],
          Width: null,
          Label: null,
          Style: {
            flexDirection: "row",
            alignItems: "center"
          },
          IgnoreLayout: true
        },
        {
          __typename: "TabContainer",
          Id: "3og91588yf",
          Items: [
            {
              __typename: "TabItem",
              Id: "fi4n0f0y8i",
              Title: "Request",
              Items: [
                {
                  __typename: "SimpleContainer",
                  Id: "wjp0vbihdr",
                  Items: [
                    {
                      __typename: "SimpleContainer",
                      Id: "t4uzuxmhvq",
                      Items: [
                        {
                          __typename: "SimpleContainer",
                          Id: "hbkeayi2ii",
                          Items: [
                            {
                              Id: "ck9l73c4vx",
                              __typename: "RadioGroup",
                              MultiSelectPossibleValues: [
                                "Initizalize",
                                "Get",
                                "Query",
                                "Create",
                                "Update",
                                "Merge",
                                "Delete",
                                "Describe",
                                "Validate"
                              ],
                              Bindings: {
                                MultiSelectPossibleValues: "State.PossibleVerbs",
                                // add binding to value
                                Value: "State.SelectedVerb"
                              },
                              LabelExpression: "shortPath",
                              ValueExpression: "path",
                              Actions: [
                                {
                                  Trigger: "onChange",
                                  CommandSet: {
                                    FirstCommandId: "1",
                                    ExecuteCommandsInParallel: false,
                                    Commands: [
                                      {
                                        Id: "1",
                                        Instruction: {
                                          Name: "SetState"
                                        },
                                        Parameters: [
                                          {
                                            Name: "Bindings",
                                            Value: {
                                              "State.SelectedPath": "getOpenAPIServiceFromPath(Event)",
                                              "State.FieldsAsProperties": "getOpenAPIFields(State.OpenAPIData,Event)",
                                              "State.FieldsAsPropertiesType": "{ __typename: 'FieldList'}",
                                              "State.SelectedRequestVerb": "getVerb(Event, State.PossibleVerbs)",
                                              "State.ODataRequestEnabled": "getRequestProtocolType(State.OpenAPIData,Event)",
                                            }
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                }
                              ]
                            },
                            {
                              Id: "wctzllf4da",
                              __typename: "Switch",
                              Label: "Send Empty Fields",
                              Classes: [],
                              Value: "State.SendEmptyFields"
                            },
                            {
                              Id: "h7aupbyurx",
                              __typename: "Button",
                              Label: "Run Request",
                              Classes: [
                                "form-save-button"
                              ],
                              Style: {},
                              Actions: [
                                {
                                  Trigger: "onClick",
                                  CommandSet: {
                                    FirstCommandId: "1",
                                    ExecuteCommandsInParallel: false,
                                    Commands: [
                                      {
                                        Id: "1",
                                        Instruction: {
                                          Name: "CallService"
                                        },
                                        Parameters: [
                                          {
                                            Name: "ServiceName",
                                            Value: "THService"
                                          },
                                          {
                                            Name: "Bindings",
                                            Value: {
                                              Form: "State.ODataRequestEnabled ? 'ODataRequestForm' : 'RequestPropEditor'",
                                            }
                                          }
                                        ],
                                        NextCommandIdOnFailure: "3",
                                        NextCommandIdOnSuccess: "4"
                                      },
                                      {
                                        Id: "3",
                                        Instruction: {
                                          Name: "ShowMessage"
                                        },
                                        Parameters: [
                                          {
                                            Name: "Message",
                                            Value: "Service Call Failed."
                                          },
                                          {
                                            Name: "ShowOkButton",
                                            Value: true
                                          }
                                        ]
                                      },
                                      {
                                        Id: "4",
                                        Instruction: {
                                          Name: "CallService"
                                        },
                                        Parameters: [
                                          { Name: "ServiceName", Value: "UserRequestHistoryCreate" },
                                        ],
                                        NextCommandIdOnSuccess: "5"
                                      },
                                      {
                                        Id: "5",
                                        Instruction: {
                                          Name: "CallService"
                                        },
                                        Parameters: [
                                          { Name: "ServiceName", Value: "UserRequestHistoryQuery" },
                                        ]
                                      }
                                    ]
                                  } as graphql.CommandSet
                                }
                              ]
                            }
                          ],
                          IgnoreLayout: true,
                          Style: {
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "20px",
                            minHeight: "20px"
                          }
                        },
                        {
                          __typename: "SimpleContainer",
                          Id: "1z1r1kv1te",
                          Items: [
                            {
                              __typename: "PropertiesEditor",
                              isSubComponent: true,
                              WrapperLayoutType: "FragmentLayout",
                              Id: "is1hx551sl",
                              Bindings: {
                                Data: "State.FieldsAsPropertiesType",
                                FieldTypes: "State.FieldsAsProperties",
                                Visible: "!State.ODataRequestEnabled",
                              },
                              IgnoreAncillaryButtons: true,
                              Actions: [
                                {
                                  Trigger: "onSave",
                                  CommandSet: {
                                    FirstCommandId: "1",
                                    ExecuteCommandsInParallel: false,
                                    Commands: [
                                      {
                                        Id: "1",
                                        Instruction: {
                                          Name: "SetState"
                                        },
                                        Parameters: [
                                          {
                                            Name: "Form",
                                            Value: "RequestPropEditor",
                                          },
                                          {
                                            Name: "Bindings",
                                            Value: {
                                              "State.RequestForm": "Form"
                                            }
                                          }
                                        ]
                                      }
                                    ]
                                  }
                                }
                              ],
                              Style: requestTabStyle,
                              FieldsReadOnly: [],
                              IgnoreFieldsDefaults: true,
                              FormId: "RequestPropEditor"
                            },
                            ODataFieldsForm
                          ],
                          IgnoreLayout: true,
                          Style: {
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "20px",
                            minHeight: "20px",
                            maxHeight: "700px",
                            flex: 1,
                            overflow: "auto",
                            width: "100%"
                          },
                          Classes: []
                        }
                      ],
                      IgnoreLayout: true,
                      Style: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        minWidth: "25px",
                        minHeight: "20px",
                        width: "100%"
                      },
                      Classes: []
                    }
                  ],
                  Layout: {
                    __typename: "FlexLayout",
                    Direction: "column",
                    Style: {},
                    Classes: []
                  },
                  Classes: [],
                  Style: {
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start"
                  },
                  IgnoreLayout: true
                }
              ]
            },
            {
              __typename: "TabItem",
              Id: "gt3cv02vep",
              Title: "Response (List)",
              Items: [
                {
                  __typename: "SimpleContainer",
                  Id: "dkmi86ypaa",
                  Items: [
                    {
                      __typename: "SimpleContainer",
                      Id: "g3adjvahpk",
                      Items: [
                        {
                          Id: "nx2clai4qi",
                          __typename: "Button",
                          Label: "Copy as JSON",
                          Actions: [
                            {
                              Trigger: "onClick",
                              CommandSet: {
                                FirstCommandId: "0",
                                ExecuteCommandsInParallel: false,
                                Commands: [
                                  {
                                    Id: "0",
                                    Instruction: {
                                      Name: "CopyToClipboard",
                                    },
                                    Parameters: [
                                      {
                                        Name: "Bindings",
                                        Value: {
                                          ClipboardContent: "jsonStringify(State.ServiceResult,null,2)",
                                        },
                                      },
                                    ],
                                  },
                                ],
                              },
                            },
                          ],
                          Classes: [
                            "form-save-button"
                          ],
                          ChipClasses: [],
                          StartIcon: {
                            IconName: "Code",
                            ContextId: "nx2clai4qi",
                            Id: "Code"
                          },
                          Style: {
                            width: "12%",
                            maxWidth: "150px"
                          },
                          originalId: "eou452fnrh",
                          id: "nx2clai4qi"
                        },
                        {
                          Id: "lltea3yae4",
                          __typename: "Button",
                          Label: "Export as CSV",
                          Classes: [
                            "form-save-button"
                          ],
                          Actions: [
                            {
                              Trigger: "onClick",
                              CommandSet: {
                                FirstCommandId: "0",
                                ExecuteCommandsInParallel: false,
                                Commands: [
                                  {
                                    Id: "0",
                                    Instruction: {
                                      Name: "TriggerAction",
                                    },
                                    Parameters: [
                                      {
                                        Name: "Bindings",
                                        Value: {
                                          ClipboardContent: "downloadAsCSV(State.ServiceResult.Items,State.SelectedPath)",
                                        },
                                      },
                                    ],
                                    NextCommandIdOnSuccess: "1",
                                  },
                                ],
                              },
                            },
                          ],
                          ChipClasses: [],
                          StartIcon: {
                            IconName: "UploadFile",
                            ContextId: "lltea3yae4",
                            Id: "UploadFile"
                          },
                          Style: {
                            width: "12%",
                            maxWidth: "150px"
                          },
                          originalId: "eou452fnrh",
                          id: "lltea3yae4"
                        }
                      ],
                      IgnoreLayout: true,
                      Style: {
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        minWidth: "20px",
                        minHeight: "20px"
                      }
                    },
                    {
                      __typename: "SimpleContainer",
                      Id: "a1gvx8674e",
                      Items: [
                        {
                          __typename: "List",
                          Id: "4ldrch5stk",
                          Toolbar: {
                            ShowExport: false,
                            ShowFilter: true
                          },
                          ShowFloatingFilter: true,
                          Style: {
                            width: "100%"
                          },
                          Bindings: {
                            Rows: "State.ServiceResult.Items",
                            ListColumns: "generateListColumn(State.ServiceResult)"
                          },
                          Columns: [
                            {
                              __typename: "ListColumn",
                              Id: "7",
                              HeaderName: "",
                              Name: "Edit",
                              Width: "50",
                              DisableFiltering: true,
                              CellLayout: {
                                __typename: "Button",
                                Id: "1",
                                StartIcon: { IconName: "Edit" },
                                Actions: [
                                  {
                                    Trigger: "onClick",
                                    CommandSet: {
                                      FirstCommandId: "1",
                                      ExecuteCommandsInParallel: false,
                                      Commands: [
                                        {
                                          Id: "1",
                                          Instruction: {
                                            Name: "SetState",
                                          },
                                          Parameters: [
                                            {
                                              Name: "Bindings",
                                              Value: {
                                                "State.SelectedPath": "State.SelectedPath.replace(State.SelectedRequestVerb.toLowerCase(),'get')",
                                                "State.SelectedVerb": "State.SelectedVerb.replace(State.SelectedRequestVerb.toLowerCase(),'get')",
                                                "State.ODataRequestEnabled": false,
                                                "State.NGForm.RequestPropEditor.Id": "Event.Id",
                                                "State.SelectedRequestVerb": "'Get'",
                                              },
                                            },
                                          ],
                                          NextCommandIdOnSuccess: "2"
                                        },
                                        {
                                          Id: "2",
                                          Instruction: {
                                            Name: "CallService"
                                          },
                                          Parameters: [
                                            {
                                              Name: "ServiceName",
                                              Value: "THService"
                                            },
                                            {
                                              Name: "Form",
                                              Value: "RequestPropEditor"
                                            }
                                          ],
                                        }
                                      ],
                                    },
                                  },
                                ]
                              },
                              Bindings: {
                                Visible: "_map(State.PossibleVerbs, 'shortPath').includes('Get')"
                              }
                            } as graphql.ListColumn,
                          ],
                          Rows: [],
                          RowsAutoSizeStrategy: "fitCellContents"
                        }
                      ],
                      IgnoreLayout: true,
                      Style: {
                        display: "flex",
                        flexDirection: "column",
                        minWidth: "20px",
                        minHeight: "20px"
                      },
                      Classes: [],
                      Visible: false,
                      Bindings: {
                        Visible: "State.ResultType==\"MultipleRows\""
                      }
                    }
                  ],
                  Layout: {
                    __typename: "FlexLayout",
                    Direction: "column",
                    Style: {},
                    Classes: []
                  },
                  Classes: [],
                  Style: {},
                  IgnoreLayout: true
                }
              ]
            },
            {
              __typename: "TabItem",
              Id: "ejekvgoxn5",
              Title: "Response (Singleton)",
              Items: [
                {
                  __typename: "SimpleContainer",
                  Id: "v9hq5typlb",
                  Items: [
                    {
                      Id: "bkyrgrvpza",
                      __typename: "Button",
                      Label: "Open as Request",
                      Actions: [
                        {
                          Trigger: "onClick",
                          CommandSet: {
                            FirstCommandId: "1",
                            ExecuteCommandsInParallel: false,
                            Commands: [
                              {
                                Id: "1",
                                Instruction: {
                                  Name: "SetState",
                                },
                                Parameters: [
                                  {
                                    Name: "Bindings",
                                    Value: {
                                      "State.FieldsAsPropertiesType": "State.ServiceResultDataForPropertiesEditor",
                                      "State.SelectedVerb": "State.SelectedVerb.replace(State.SelectedRequestVerb.toLowerCase(),'update')",
                                      "State.SelectedPath": "State.SelectedPath.replace(State.SelectedRequestVerb.toLowerCase(),'update')",
                                      "State.SelectedRequestVerb": "'Update'",
                                      "State.SelectedTabId": "'Request'",
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        }
                      ],
                      Classes: [
                        "form-save-button"
                      ],
                      ChipClasses: [],
                      StartIcon: {
                        IconName: "SwapHorizontalCircle",
                        ContextId: "bkyrgrvpza",
                        Id: "SwapHorizontalCircle"
                      },
                      Style: {
                        width: "12%",
                        maxWidth: "150px"
                      },
                      originalId: "av4psb",
                      id: "bkyrgrvpza"
                    },
                    {
                      Id: "eou452fnrh",
                      __typename: "Button",
                      Label: "Copy as JSON",
                      Classes: [
                        "form-save-button"
                      ],
                      Actions: [
                        {
                          Trigger: "onClick",
                          CommandSet: {
                            FirstCommandId: "0",
                            ExecuteCommandsInParallel: false,
                            Commands: [
                              {
                                Id: "0",
                                Instruction: {
                                  Name: "CopyToClipboard",
                                },
                                Parameters: [
                                  {
                                    Name: "Bindings",
                                    Value: {
                                      ClipboardContent: "jsonStringify(State.ServiceResult,null,2)",
                                    },
                                  },
                                ],
                              },
                            ],
                          },
                        },
                      ],
                      ChipClasses: [],
                      StartIcon: {
                        IconName: "Code",
                        ContextId: "eou452fnrh",
                        Id: "Code"
                      },
                      Style: {
                        width: "12%",
                        maxWidth: "150px"
                      },
                      originalId: "bkyrgrvpza",
                      id: "eou452fnrh"
                    },
                    {
                      Id: "7uu9fa8xh0",
                      __typename: "Button",
                      Label: "Clear all data",
                      Classes: [
                        "form-save-button"
                      ],
                      Actions: [
                        {
                          Trigger: "onClick",
                          CommandSet: {
                            FirstCommandId: "0",
                            ExecuteCommandsInParallel: false,
                            Commands: [
                              {
                                Id: "0",
                                Instruction: {
                                  Name: "ClearForm",
                                },
                                Parameters: [{ Name: "Form", Value: "ResponsePropsEditor" }],
                              },
                            ],
                          },
                        },
                      ],
                      ChipClasses: [],
                      StartIcon: {
                        IconName: "DeleteForever",
                        ContextId: "7uu9fa8xh0",
                        Id: "DeleteForever"
                      },
                      Style: {
                        width: "12%",
                        maxWidth: "150px"
                      },
                      originalId: "eou452fnrh",
                      id: "7uu9fa8xh0"
                    }
                  ],
                  IgnoreLayout: true,
                  Style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    minWidth: "20px",
                    minHeight: "20px"
                  }
                },
                {
                  __typename: "SimpleContainer",
                  Id: "oa6slvdlri",
                  Items: [
                    {
                      __typename: "SimpleContainer",
                      Id: "8whaqc9w3p",
                      Items: [
                        {
                          Id: "z1af2nbdb1",
                          __typename: "Button",
                          Label: "Export to Excel",
                          Classes: [
                            "form-save-outlined-button"
                          ],
                          Style: {
                            margin: "10px",
                            width: "15%"
                          }
                        }
                      ],
                      Layout: {
                        __typename: "FlexLayout",
                        Direction: "row",
                        AlignItems: "center",
                        Style: {},
                        Classes: [],
                        AlignContent: "",
                        JustifyContent: "flex-end"
                      },
                      Classes: [],
                      Style: {},
                      IgnoreLayout: true
                    },
                    {
                      __typename: "PropertiesEditor",
                      Style: requestTabStyle,
                      WrapperLayoutType: "FragmentLayout",
                      Id: "jqrhx8o0az",
                      isSubComponent: true,
                      Bindings: {
                        Data: "State.ServiceResultDataForPropertiesEditor",
                        FieldTypes: "State.ServiceResultFieldsAsProperties"
                      },
                      FieldsReadOnly: [],
                      FormId: "ResponsePropsEditor",
                      UseFieldGroups: false
                    }
                  ],
                  IgnoreLayout: true,
                  Style: {
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "20px",
                    minHeight: "20px"
                  },
                  Bindings: {
                    Visible: "State.ResultType==\"SingleRecord\""
                  },
                  Classes: []
                },
              ]
            },
            // {
            //   __typename: "TabItem",
            //   Id: "cbkire4c0m",
            //   Title: "MUP",
            //   Items: [
            //     {
            //       __typename: "SimpleContainer",
            //       Id: "xjtmqgsnyt",
            //       Items: [
            //         {
            //           __typename: "SimpleContainer",
            //           Id: "g2tnwl3xxf",
            //           Items: [
            //             {
            //               __typename: "SimpleContainer",
            //               Id: "nu5ffjr0b9",
            //               Items: [
            //                 {
            //                   Id: "xzs69ju9bn",
            //                   __typename: "Button",
            //                   Label: "GET",
            //                   Classes: [
            //                     "form-save-outlined-button"
            //                   ]
            //                 },
            //                 {
            //                   Id: "rkh4mub2oi",
            //                   __typename: "Button",
            //                   Label: "MUP-C",
            //                   Classes: [
            //                     "form-save-outlined-button"
            //                   ]
            //                 },
            //                 {
            //                   Id: "e6o77mdcdr",
            //                   __typename: "Button",
            //                   Label: "MUP-D",
            //                   Classes: [
            //                     "form-save-outlined-button"
            //                   ]
            //                 },
            //                 {
            //                   Id: "50x13s7g6y",
            //                   __typename: "Button",
            //                   Label: "MUP-U",
            //                   Classes: [
            //                     "form-save-outlined-button"
            //                   ]
            //                 }
            //               ],
            //               Layout: {
            //                 __typename: "FlexLayout",
            //                 Direction: "row",
            //                 AlignItems: "center",
            //                 Style: {},
            //                 Classes: [
            //                   "padding-all"
            //                 ]
            //               },
            //               Classes: [
            //                 "padding-all"
            //               ]
            //             }
            //           ],
            //           Layout: {
            //             __typename: "FlexLayout",
            //             Direction: "column",
            //             Style: {},
            //             Classes: []
            //           }
            //         }
            //       ],
            //       Layout: {
            //         __typename: "FlexLayout",
            //         Direction: "column",
            //         Style: {},
            //         Classes: []
            //       }
            //     }
            //   ]
            // }
          ],
          Bindings: {
            SelectedTabTitle: "State.SelectedTabId"
          },
          Classes: [],
          ContentClasses: [],
          TabClasses: [],
          Style: {}
        }
      ],
      Layout: {
        __typename: "FlexLayout",
        Direction: "column",
        Style: {},
        Classes: [
          "background-white",
          "padding-all"
        ],
        JustifyContent: "space-around",
        AlignItems: "stretch",
        AlignContent: ""
      },
      Classes: [
        "background-white",
        "padding-all"
      ],
      Style: null,
    }
  ],
  Layout: {
    __typename: "FlexLayout",
    Id: "wa0k6h",
    JustifyContent: "space-between",
    AlignItems: "stretch",
    AlignContent: "",
    Direction: "column",
    Style: {},
    Classes: [
      "padding-all"
    ]
  },
  Classes: [
    "padding-all"
  ],
  ContentClasses: [],
  TabClasses: [],
  Title: null,
  Value: null,
  ChipClasses: [],
  Name: null,
  HideTitle: null,
  Tags: [],
  TitleClasses: [],
  Label: null,
  StartIcon: {
    IconName: null
  },
  Style: null,
  Services: [
    {
      Name: "THService",
      Trigger: "Action",
      ConfigBindings: {
        Service: "State.SelectedPath",
        Fields: "getFieldsFromForm(Form, State.SendEmptyFields, State.ODataRequestEnabled, State.ODataQueryTab, State.BaseQueryFields)"
      },
      "Bindings": {
        "State.ServiceResult": "Result",
        "State.ResultType": "getNGResultType(Result)",
        "State.ServiceResultDataForPropertiesEditor": "getPropertiesEditorDataFromServiceResult(Result)",
        "State.ServiceResultFieldsAsProperties": "getFieldsFromServiceResult(Result)",
        "State.ServiceFieldsQueried": "formatFields(Form, State.SendEmptyFields, State.ODataRequestEnabled, State.ODataQueryTab, State.BaseQueryFields)",
        "State.SelectedTabId": "getNGResultType(Result) === 'MultipleRows' ? \"Response (List)\" : \"Response (Singleton)\"",
      },
      "UseSampleData": false,
      "SampleData": {
        "Items": [
          { "Id": 1, "ColumnX": "X1 - 1.1", "ColumnY": "Y2 - 2.1" },
          { "Id": 2, "ColumnX": "X1 - 1.2", "ColumnY": "Y2 - 2.2" },
          { "Id": 3, "ColumnX": "X1 - 1.3", "ColumnY": "Y2 - 2.3" },
        ]
      }
    },
    {
      Type: "Internal",
      Name: "OpenAPIQuery",
      Id: "OpenAPIQuery",
      Service: "openapi.query",
      Fields: [],
      Bindings: {
        "State.OpenAPIData": "Result",
        "State.OpenAPIServices": "parseOpenAPIServices(Result)"
      },
      UseSampleData: false,
      SampleData: serviceSampleData
    },
    {
      Type: "Internal",
      Name: "UserRequestHistoryCreate",
      Id: "UserRequestHistoryCreate",
      Service: "userrequesthistory.create",
      Trigger: "Action",
      Fields: [
        {
          Required: true,
          Name: "Service",
          Bindings: {
            Value: "State.SelectedPath.split('.')[0]",
          },
        },
        {
          Required: true,
          Name: "UserId",
          Bindings: {
            Value: "Global.User.Id",
          },
        },
        {
          Required: true,
          Name: "Verb",
          Bindings: {
            Value: "State.SelectedRequestVerb",
          },
        },
        {
          Required: true,
          Name: "Parameters",
          Bindings: {
            Value: "State.ServiceFieldsQueried"
          }
        }
      ],
      Bindings: {
        // "State.History": "addToArray(State.History,Result)",
        // "State.OpenAPIServices": "parseOpenAPIServices(Result)"
      },
    },
    {
      Type: "Internal",
      Name: "UserRequestHistoryQuery",
      Id: "userrequesthistoryQuery",
      Service: "userrequesthistory.query",
      Fields: [
        {
          "Name": "OData",
          "Bindings": {
            "Value": "{\"$filter\": \"UserId eq '\" + Global.User.Id + \"'\", \"$orderby\": \"CreatedOn DESC\", $select: \"*\"}"
          }
        }
      ],
      Bindings: {
        "State.History": "Result.Items",
      },
    },
  ],
  ModalPopups: [
    historyDialog
  ]
} as graphql.TestHarness;