import { useRef } from "react";
import { Tab } from "@mui/material";
import { draggable } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { useSignalEffect } from "@preact/signals-react";

import {
  Label,
  InputField,
  RadioGroup,
  MultiSelect,
  Checkbox,
  Button,
  Slider,
  Switch,
  AiDialog,
  ComponentLibrary,
  IFrame,
  Image,
  FileUpload,
  ResolutionButtons,
  Icon,
  Form,
  TreeList,
  Feed,
  Chart,
  PropertiesEditor,
  Repeater,
  List,
  SimpleContainer,
  Page,
  AudioPlayer,
  AudioRecorder,
  ContextMenu,
  DateTimePicker,
  DatePicker,
  Component,
  Reference,
  TimePicker,
  CodeEditor,
  Avatar,
  Html,
  Markdown,
  Snackbar,
  ProportionalBar,
  StyleEditor,
  ActionEditor,
  IconSelector,
  TabContainer,
  TabItem,
  ConfigurationEditor,
  GridEditor,
  UndoRedoToolbar,
  ModalPopupEditor,
  ButtonGroup,
  DocumentViewer,
  ListQuickFilter,
  ListExportButton,
  MultiFilter,
  BindingEditor,
  Link,
  TypedBindingEditor,
  RichTextEditor,
  ContextAutocomplete,
  Overlay,
  AccordionLayout,
  AccordionGroup,
  PageTemplate,
} from "../../resolvers-types";

import { getState, updateItemContext } from "../library/dataService";
import { getItemIdentifier, getTypename } from "../library/metadataUtils";
import { INGLayoutItemProps } from "../library/NGFieldExtensions";

import NGLabel from "../components/NGLabel/NGLabel";
import NGInputField from "../components/NGInputField/NGInputField";
import NGRadioGroup from "../components/NGRadioGroup/NGRadioGroup";
import NGMultiSelect from "../components/NGMultiSelect/NGMultiSelect";
import NGCheckbox from "../components/NGCheckbox/NGCheckbox";
import NGButton from "../components/NGButton/NGButton";
import NGSlider from "../components/NGSlider/NGSlider";
import NGSwitch from "../components/NGSwitch/NGSwitch";
import NGLibrary from "./NGLibrary";
import { NGIFrame } from "../components/NGIFrame/NGIFrame";
import NGAIDialog from "../components/NGAIDialog/NGAIDialog";
import NGSimpleRepeater from "../components/NGRepeater/NGSimpleRepeater";
import NGImage from "../components/NGImage/NGImage";
import NGFileUpload from "../components/NGFileUpload/NGFileUpload";
import NGCodeEditor from "../components/NGCodeEditor/NGCodeEditor";
import NGSnackbar from "../components/NGSnackbar/NGSnackbar";
import NGResolutionButtons from "../components/NGResolutionButtons/NGResolutionButtons";
import NGIcon from "../components/NGIcon/NGIcon";
import NGAudioRecorder from "../components/NGAudioRecorder/NGAudioRecorder";
import NGForm from "./NGForm";
import NGList from "../components/NGList/NGList";
import NGRepeater from "../components/NGRepeater/NGRepeater";
import NGReadOnlyTreeList from "../components/NGReadOnlyTreeList/NGReadOnlyTreeList";
import compTestPage from "../sampleData/comp-test/comp-test";
import NGFeed from "./NGFeed";
import NGChart from "../components/NGChart/NGChart";
import NGPropertiesEditor from "./NGPropertiesEditor";
import NGSimpleContainer from "./NGSimpleContainer";
import NGAudioPlayer from "../components/NGAudioPlayer/NGAudioPlayer";
import NGProportionalBar from "../components/NGProportionalBar/NGProportionalBar";
import NGAvatar from "../components/NGAvatar/NGAvatar";
import NGHtml from "../components/NGHtml/NGHtml";
import NGRichTextEditor from "../components/NGRichTextEditor/NGRichTextEditor";
import NGDateTimePicker from "../components/NGDateTimePicker/NGDateTimePicker";
import NGDatePicker from "../components/NGDatePicker/NGDatePicker";
import NGTimePicker from "../components/NGTimePicker/NGTimePicker";
import NGContextMenu from "../components/NGContextMenu/NGContextMenu";
import NGComponent from "./NGComponent";
import NGStyleEditor from "../components/NGStyleEditor/NGStyleEditor";
import NGIconSelector from "../components/NGIconSelector/NGIconSelector";
import NGTabContainer from "./NGTabContainer";
import NGReference from "./NGReference";
import NGRichTreeList from "../components/NGRichTreeList/NGRichTreeList";
import { NGErrorBoundary } from "./NGErrorBoundary";
import NGConfigurationEditor from "../components/NGStyleEditor/NGConfigurationEditor";
import NGGridEditor from "../components/NGGridEditor/NGGridEditor";
import NGUndoRedoToolbar from "../components/NGUndoRedoToolbar/NGUndoRedoToolbar";
import NGModalPopupEditor from "../components/NGModalPopupEditor/NGModalPopupEditor";
import NGFinApp from "../components/NGFinApp/NGFinApp";
import NGToggleButtonGroup from "../components/NGToggleButton/NGToggleButton";
import NGActionEditor from "../components/NGActionEditor/NGActionEditor";
import NGBindingEditor from "../components/NGBindingEditor/NGBindingEditor";
import NGVisibleMenu from "../components/NGVisibleMenu/NGVisibleMenu";
import NGLink from "../components/NGLink/NGLink";
import NGDocumentViewer from "../components/NGDocumentViewer/NGDocumentViewer";
import NGTestHarness from "../components/NGTestHarness/NGTestHarness";
import { NGListQuickFilter } from "../components/NGList/NGListQuickFilter";
import { NGListExportButton } from "../components/NGList/NGListExportButton";
import NGMultiFilter from "../components/NGMultiFilter/NGMultiFilter";
import NGTypedBindingEditor from "../components/NGBindingEditor/NGTypedBindingEditor";
import { NGContextAutocomplete } from "../components/NGContextAutocomplete/NGContextAutocomplete";
import NGOverlay from "../components/NGOverlay/NGOverlay";
import NGMarkdown from "../components/NGMarkdown/NGMarkdown";
import NGAccordionLayout from "../layouts/NGAccordionLayout";
import NGAccordionGroup from "../components/NGAccordionGroup/NGAccordionGroup";
import NGPageTemplate from "../components/NGPageTemplate/NGPageTemplate";
import NGTabItem from "./NGTabItem";
const tag = "NGLayoutItem";

export default function NGLayoutItem({ config, context }: INGLayoutItemProps) {
  const tn = getTypename(config);

  const nonLeaves = [
    "Component",
    "PageTemplate",
    "SimpleContainer",
    "Form",
    "List",
    "Repeater",
    "Reference",
    "PropertiesEditor",
    "GridEditor",
    "TestHarness",
    "TabContainer"
  ];
  const currentContext = nonLeaves.includes(tn) ? context : updateItemContext(context, config);
  // const componentFromReference = useSignal<Component | null>(null);

  // useSignalEffect(() => {
  //   if (tn !== "Reference") return;

  //   const fetchComponents = async () => {
  //     log.info(tag, "useSignalEffect", config);

  //     retrieveReferenceMetadata(config as Reference, context).then((c) => {
  //       const inputs = (config as Reference).Inputs;
  //       if (!isNil(inputs)) {
  //         const childContext = updateItemContext(context, c);

  //         const { state, form, parentState } = getState(childContext);
  //         const scope = getScope(childContext, config, state, form, {}, parentState);

  //         Object.entries(inputs).forEach(([k, v]) => {
  //           const ast: any = getAst(v);
  //           const returnSignal = shouldReturnSignal(ast);

  //           const src = getExprValue(v as string, scope, null, returnSignal);
  //           state[k] = src;
  //         });
  //       }

  //       componentFromReference.value = c;

  //       log.info(tag, "retrieveReferenceMetadata", c);
  //     });
  //   };
  //   fetchComponents();
  // });

  const ref = useRef(null);
  const isDraggable = (config as any).Draggable;

  useSignalEffect(() => {
    if (!isDraggable) return;

    const el = ref.current;
    if (!el) return;

    return draggable({
      element: el,
      getInitialDataForExternal: () => ({
        "application/json": JSON.stringify(config),
      }),
    });
  });

  function getItem() {
    switch (tn) {
      case "Page": {
        const page: Page = config as Page;
        //iterate through the components and return an NGComponent
        return (
          <>
            {page.Items?.map((container) => {
              return <NGLayoutItem key={(container as any)?.Id} config={container as any} context={currentContext} />;
            })}
          </>
        );
      }
      case "PageTemplate": {
        const pageTemplate: PageTemplate = config as PageTemplate;
        //iterate through the components and return an NGComponent
        const { global } = getState(currentContext);

        return (
          <NGPageTemplate
            key={(pageTemplate as PageTemplate).Id}
            config={pageTemplate}
            menu={global.Menu.value}
            context={currentContext}
          />
        );
      }
      case "SimpleContainer":
        // TODO: cater for other types of containers, such as tabs
        return (
          <NGSimpleContainer
            key={(config as SimpleContainer).Id}
            config={config as SimpleContainer}
            context={currentContext}
          />
        );
      case "TestHarness":
        // TODO: cater for other types of containers, such as tabs
        return (
          <NGTestHarness
            key={(config as SimpleContainer).Id}
            config={config as SimpleContainer}
            context={currentContext}
          />
        );
      case "AccordionGroup":
        return (
          <NGAccordionGroup
            key={(config as AccordionGroup).Id}
            config={config as AccordionGroup}
            context={currentContext}
          />
        );
      case "AccordionLayout":
        return (
          <NGAccordionLayout
            key={(config as AccordionLayout).Id}
            context={currentContext}
            config={config as AccordionLayout}            
          />
        );
      case "Component":
        return <NGComponent key={(config as any).Id} context={currentContext} config={config as Component} />;
      case "Reference":
        return <NGReference key={(config as any).Id} context={currentContext} config={config as Reference} />;
      //{
      // Get the reference, then inject it

      // if (isNil(componentFromReference.value)) return null;

      // return (
      //   <NGLayoutItem
      //     key={(config as any).Id}
      //     context={currentContext}
      //     config={componentFromReference.value as LayoutItem}
      //   />
      // );
      //}
      case "Form":
        return <NGForm key={(config as any).Id} context={currentContext} config={config as Form} />;
      case "List":
        return <NGList key={(config as any).Id} context={currentContext} config={config as List} />;
      case "Repeater":
        return <NGRepeater key={(config as any).Id} context={currentContext} config={config as Repeater} />;
      case "ContextMenu":
        return <NGContextMenu key={(config as any).Id} context={currentContext} config={config as ContextMenu} />;

      case "TreeList":
        // return (
        //   <NGTreeList key={control.Id} context={currentContext} config={control as TreeList} />
        // );
        return <NGReadOnlyTreeList context={currentContext} config={config as TreeList} data={compTestPage} />;
      case "RichTreeList":
        return <NGRichTreeList key={(config as any).Id} context={currentContext} config={config as TreeList} />;
      case "Feed":
        return <NGFeed key={(config as any).Id} context={currentContext} config={config as Feed} />;
      case "Chart":
        return <NGChart key={(config as any).Id} context={currentContext} config={config as Chart} />;
      case "PropertiesEditor":
        return (
          <NGPropertiesEditor key={(config as any).Id} context={currentContext} config={config as PropertiesEditor} />
        );

      case "SimpleList":
        return <NGSimpleRepeater key={(config as any).Id} context={currentContext} config={config as Repeater} />;
      case "FileUpload":
        return <NGFileUpload key={(config as any).Id} context={currentContext} config={config as FileUpload} />;
      case "InputField":
        return <NGInputField key={(config as any).Id} context={currentContext} config={config as InputField} />;
      case "RadioGroup":
        return <NGRadioGroup key={(config as any).Id} context={currentContext} config={config as RadioGroup} />;
      case "ButtonGroup":
        return <NGToggleButtonGroup key={(config as any).Id} context={currentContext} config={config as ButtonGroup} />;
      case "MultiSelect":
        return <NGMultiSelect key={(config as any).Id} context={currentContext} config={config as MultiSelect} />;
      case "MultiFilter":
        return <NGMultiFilter key={(config as any).Id} context={currentContext} config={config as MultiFilter} />;
      case "Overlay":
        return <NGOverlay key={(config as any).Id} context={currentContext} config={config as Overlay} />;
      case "Checkbox":
        return <NGCheckbox key={(config as any).Id} context={currentContext} config={config as Checkbox} />;
      case "DateTimePicker":
        return <NGDateTimePicker key={(config as any).Id} context={currentContext} config={config as DateTimePicker} />;
      case "DatePicker":
        return <NGDatePicker key={(config as any).Id} context={currentContext} config={config as DatePicker} />;
      case "TimePicker":
        return <NGTimePicker key={(config as any).Id} context={currentContext} config={config as TimePicker} />;
      case "Button":
        return <NGButton key={(config as any).Id} context={currentContext} config={config as Button} />;
      case "VisibleMenu":
        return <NGVisibleMenu key={(config as any).Id} context={currentContext} config={config as any} />;
      case "Icon":
        return <NGIcon key={(config as any).Id} context={currentContext} config={config as Icon} />;
      case "Slider":
        return <NGSlider key={(config as any).Id} context={currentContext} config={config as Slider} />;
      case "Switch":
        return <NGSwitch key={(config as any).Id} context={currentContext} config={config as Switch} />;
      case "Label":
        return <NGLabel key={(config as any).Id} context={currentContext} config={config as Label} />;
      case "AIDialog":
        return <NGAIDialog key={(config as any).Id} context={currentContext} config={config as AiDialog} />;
      case "Image":
        return <NGImage key={(config as any).Id} context={currentContext} config={config as Image} />;
      case "AudioPlayer":
        return <NGAudioPlayer key={(config as any).Id} context={currentContext} config={config as AudioPlayer} />;
      case "ComponentLibrary":
        return <NGLibrary key={(config as any).Id} context={currentContext} config={config as ComponentLibrary} />;
      case "IFrame":
        return <NGIFrame key={(config as any).Id} context={currentContext} config={config as IFrame} />;

      case "ListExportButton":
        return (
          <NGListExportButton key={(config as any).Id} context={currentContext} config={config as ListExportButton} />
        );
      case "ListQuickFilter":
        return (
          <NGListQuickFilter key={(config as any).Id} context={currentContext} config={config as ListQuickFilter} />
        );

      case "FinApp":
        return <NGFinApp key={(config as any).Id} context={currentContext} config={config} />;
      case "CodeEditor":
        return <NGCodeEditor key={(config as any).Id} context={currentContext} config={config as CodeEditor} />;
      case "Avatar":
        return <NGAvatar key={(config as any).Id} context={currentContext} config={config as Avatar} />;
      case "Html":
        return <NGHtml key={(config as any).Id} context={currentContext} config={config as Html} />;
      case "Markdown":
        return <NGMarkdown key={(config as any).Id} context={currentContext} config={config as Markdown} />;

      case "Snackbar":
        return <NGSnackbar key={(config as any).Id} context={currentContext} config={config as Snackbar} />;
      case "ResolutionButtons":
        return (
          <NGResolutionButtons key={(config as any).Id} context={currentContext} config={config as ResolutionButtons} />
        );
      case "UndoRedoToolbar":
        return (
          <NGUndoRedoToolbar key={(config as any).Id} context={currentContext} config={config as UndoRedoToolbar} />
        );
      case "AudioRecorder":
        return <NGAudioRecorder key={(config as any).Id} context={currentContext} config={config as AudioRecorder} />;
      case "ProportionalBar":
        return (
          <NGProportionalBar key={(config as any).Id} context={currentContext} config={config as ProportionalBar} />
        );
      case "StyleEditor":
        return <NGStyleEditor key={(config as any).Id} context={currentContext} config={config as StyleEditor} />;
      case "ActionEditor":
        return <NGActionEditor key={(config as any).Id} context={currentContext} config={config as ActionEditor} />;
      case "BindingEditor":
        return <NGBindingEditor key={(config as any).Id} context={currentContext} config={config as BindingEditor} />;
      case "TypedBindingEditor":
        return (
          <NGTypedBindingEditor
            key={(config as any).Id}
            context={currentContext}
            config={config as TypedBindingEditor}
          />
        );
      case "ModalPopupEditor":
        return (
          <NGModalPopupEditor key={(config as any).Id} context={currentContext} config={config as ModalPopupEditor} />
        );
      case "GridEditor":
        return <NGGridEditor key={(config as any).Id} context={currentContext} config={config as GridEditor} />;
      case "ConfigurationEditor":
        return (
          <NGConfigurationEditor
            key={(config as any).Id}
            context={currentContext}
            config={config as ConfigurationEditor}
          />
        );
      case "IconSelector":
        return <NGIconSelector key={(config as any).Id} context={currentContext} config={config as IconSelector} />;

      case "TabContainer":
        return <NGTabContainer key={(config as any).Id} context={currentContext} config={config as TabContainer} />;

      case "Link":
        return <NGLink key={(config as any).Id} context={currentContext} config={config as Link} />;

      case "RichTextEditor":
        return <NGRichTextEditor key={(config as any).Id} context={currentContext} config={config as RichTextEditor} />;
      case "ContextAutocomplete":
        return (
          <NGContextAutocomplete
            key={(config as any).Id}
            context={currentContext}
            config={config as ContextAutocomplete}
          />
        );

      case "TabItem":
        return <NGTabItem key={(config as any).Id} context={currentContext} config={config as TabItem} />

      case "DocumentViewer":
        return <NGDocumentViewer key={(config as any).Id} context={currentContext} config={config as DocumentViewer} />;

      default:
        return <div>Unknown component type: {config.__typename}</div>;
    }
  }

  return (
    <NGErrorBoundary
      fallback={
        <div
          style={{ background: "red", color: "white", padding: "0.5rem" }}
          data-testid={config.Id}
          data-type={config.__typename}
          className="ng-rendering-error"
        >
          Error in {getItemIdentifier(config)}.
        </div>
      }
    >
      {isDraggable && <div ref={ref}>{getItem()}</div>}
      {!isDraggable && getItem()}
    </NGErrorBoundary>
  );
}
