import { Stack } from '@mui/material'
import NGLabel from '../NGLabel/NGLabel'
import { Button as MUIButton } from "@mui/material";
import { setupHandlers, setupLocalState } from '../../library/dataService'
import { useSignal } from '@preact/signals-react';
import { generateUID } from '../../library/utils';
import { INGMultiFilterProps } from '../../library/NGFieldExtensions';
import NGLayoutItem from '../../generators/NGLayoutItem';
import NGIcon from '../NGIcon/NGIcon';

export default function NGMultiFilter({ config, context }: INGMultiFilterProps) {
  const local = setupLocalState(
    config,
    {
      Data: useSignal(config.Data || []),
      Fields: useSignal(config.Fields || []),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  const addItem = () => {
    const newData = [...(local.Data.value ?? []), { key: generateUID() }];
    local.Data.value = newData;
  }

  const removeItem = (index) => {
    const newData = [...(local.Data.value ?? [])]
    newData.splice(index, 1);
    local.Data.value = newData;
    handlers["onChange"]?.("onChange", local.Data.value);
  }

  const handleChange = (
    data: any,
    item,
    index
  ) => {
    const updatedData = [...(local.Data.value ?? [])];

    let value = data;
    if (item.ValueExpression) {
      value = data[item.ValueExpression];
    }
    // create a object as [item.Name]: value and merge it with the current data
    const newData = { Format: data?.["format"], ...(local.Data.value ?? [])[index], [item.Name]: value };

    // substitute local.Data.value[index] with the new data
    updatedData[index] = newData;
    local.Data.value = updatedData;

    handlers["onChange"]?.("onChange", local.Data.value);
  }

  return (
    <Stack direction="column">
      <Stack direction="row">
        <NGLabel config={{ Id: generateUID(), DefaultValue: config.Label, __typename: "Label" }} context={context} />
        <MUIButton onClick={addItem}>
          <NGIcon config={{ IconName: "Add" }} context={context} />
        </MUIButton>
      </Stack>
      {(local.Data?.value ?? []).map((data, idx) => {
        return (
          <Stack key={idx} direction="row" columnGap={3}>
            {(config.Items ?? [])?.map((item, index) => {
              return (
                <NGLayoutItem
                  config={{
                    ...item,
                    Name: `${data.key}-${item.Name}`,
                    Id: `${data.key}-${item.Name}-${item.__typename}`,
                    __typename: (item.__typename === "InputField" && data?.Format === "date") ? "DatePicker" : item.__typename,
                    Actions: [
                      {
                        Trigger: "onChange",
                        preHandler: (
                          handlerName: string,
                          action: any,
                          e: any,
                          data: any,
                          formCtx: any
                        ) => handleChange(data, item, idx),
                      },
                    ],
                  }}
                  context={context}
                  key={index}
                />
              )
            })}
            <MUIButton onClick={() => removeItem(idx)}>
              <NGIcon config={{ IconName: "Delete" }} context={context} />
            </MUIButton>
          </Stack>
        )
      })}
    </Stack>
  )
}