import { useRef } from "react";
import { AppBar, Drawer, IconButton, Toolbar, Typography } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";

import { INGAppBarProps, MenuStyle } from "../../library/NGFieldExtensions";
import NGVisibleMenu from "../NGVisibleMenu/NGVisibleMenu";
import NGButton from "../NGButton/NGButton";
import { Button } from "../../../resolvers-types";
import { useSignal } from "@preact/signals-react";
import jbiLogo from "../../images/jbi-logo.svg";
import { Link } from "react-router-dom";
import { setupLocalState } from "../../library/dataService";
import { getsxObject } from "../../library/utils";
import NGImage from "../NGImage/NGImage";
import { GetSettingFromSite } from "../../library/dataService";

export default function NGAppBar({ config, context, menu }: INGAppBarProps) {
  const local = setupLocalState(
    config,
    {
      Style: useSignal(config.Style ?? {}),
    },
    context
  );
  const open = useSignal(false);
  const appBarRef = useRef<HTMLHeadingElement>(null);

  const handleClose = () => {
    console.log("handleClose, closing drawer");
    open.value = false;
  };

  const buttonRefreshConfig: Button = {
    __typename: "Button",
    Id: "topMenu-btnRefresh",
    Name: "topMenu-btnRefresh",
    StartIcon: { IconName: "Refresh" },
    Style: { color: "white" }, // TODO: get from theme
    Actions: [
      {
        Trigger: "onClick",
        CommandSet: {
          FirstCommandId: "1",
          ExecuteCommandsInParallel: false,
          Commands: [
            {
              Id: "1",
              Instruction: {
                Name: "ReloadPage",
              },
              Parameters: [],
            },
          ],
        },
      },
    ],
  };

  const logo = GetSettingFromSite("Logo");

  return (
    <>
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          width: config.Width,
          height: config.Height,
          marginLeft: config.MarginLeft,
          marginRight: config.MarginRight,
          marginTop: config.MarginTop,
          marginBottom: config.MarginBottom,
        }}
        variant={config.Variant as any}
        ref={appBarRef}
      >
        <Toolbar sx={getsxObject(local.Style.value)}>
          <Link to="/" target={config.LogoLinkTarget ?? undefined}>
            {config.LogoLink || logo ? (
              <NGImage
                config={{
                  Id: "mofqksmmbh",
                  __typename: "Image",
                  Value: config.LogoLink || logo,
                  Style: { height: config.LogoHeight ?? "22px", width: config.LogoWidth ?? "auto" },
                  AlternativeText: config.LogoAltText,
                  Classes: GetSettingFromSite("LogoClassName"),
                }}
                context={context}
              />
            ) : (
              <img src={jbiLogo} alt="SVG description" />
            )}
          </Link>
          <Typography variant="h6" sx={{ flexGrow: 1 }}></Typography>

          <Box sx={{ display: { xs: "none", sm: "flex" } }}>
            {menu && (
              <NGVisibleMenu
                config={{ Data: menu }}
                handleClose={handleClose}
                menuStyle={MenuStyle.Horizontal}
                context={context}
              />
            )}
          </Box>
          <Box sx={{ display: { xs: "flex", sm: "none" } }}>
            <NGButton config={buttonRefreshConfig} context={context} />
            <IconButton
              color="inherit"
              size="large"
              onClick={() => (open.value = open.value === true ? false : true)}
              sx={{ display: { xs: "flex", sm: "none" }, color: "white" }}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        open={open.value}
        anchor="right"
        onClose={handleClose}
        sx={{
          display: { xs: "flex", sm: "none" },
        }}
      >
        <Box
          sx={{
            width: 250,
            marginTop: `${appBarRef.current?.clientHeight}px`,
          }}
        >
          {menu && (
            <NGVisibleMenu
              config={{ Data: menu }}
              handleClose={handleClose}
              menuStyle={MenuStyle.Vertical}
              context={context}
            />
          )}
        </Box>
      </Drawer>
    </>
  );
}
