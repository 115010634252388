import { Location } from "react-router-dom";
import { isArray, isNil, isObject, last } from "lodash-es";
import { generateUID } from "./utils";
import { log } from "./logger";
import { controlLibrary } from "../sampleData/editor-gui/controlLibrary";
import { getBaseComponent } from "../generators/codeGeneration/generationMethods";
import { INGTreeListItems, RuntimeContext } from "./NGFieldExtensions";

export function cloneJsonWithIDs(
  obj: object,
  replacements?: { [key: string]: string },
  keepers?: object
): any {
  let result: any = null;
  const ids: { [key: string]: string } = {};

  try {
    result = cloneJsonNode(obj, replacements, ids);
  } catch (error) {
    console.error("Error cloning JSON node:", error);
  }

  result = replaceRemainingIds(result, ids);
  if (keepers) {
    return { ...result, ...keepers };
  }
  return result;
}

function replaceRemainingIds(
  component: any,
  ids: { [key: string]: string }
): any {
  let jsonString = JSON.stringify(component ?? {});
  for (const id in ids) {
    jsonString = jsonString.replace(new RegExp(`"${id}"`, "g"), `"${ids[id]}"`);
  }

  return JSON.parse(jsonString ?? "{}");
}

function replaceWithCaseSensitivity(
  originalText: string,
  search: string,
  replace: string
): string {
  if (!search || replace === null) return originalText;

  const isFirstLetterUppercase = search[0] === search[0].toUpperCase();
  const adjustedReplace = isFirstLetterUppercase
    ? replace[0].toUpperCase() + replace.substring(1)
    : replace;

  return originalText.replace(new RegExp(search, "gi"), adjustedReplace);
}

function cloneJsonNode(
  node: any,
  replacements: { [key: string]: string } | undefined,
  ids: { [key: string]: string },
  propertyName: string | null = null
): any {
  if (isObject(node) && !isArray(node)) {
    const clonedObject: { [key: string]: any } = {};
    for (const property in node) {
      if (property === "Id") {
        const oldId = node[property] as string;
        const newId = ids[oldId] || generateUID();
        ids[oldId] = newId;
        clonedObject[property] = newId;
      } else {
        clonedObject[property] = cloneJsonNode(
          node[property],
          replacements,
          ids,
          property
        );
      }
    }
    return clonedObject;
  } else if (isArray(node)) {
    const clonedArray: any[] = [];
    for (const element of node) {
      if (propertyName === "Items" && typeof element === "string") {
        const oldId = element as string;
        const newId = ids[oldId] || generateUID();
        ids[oldId] = newId;
        clonedArray.push(newId);
      } else {
        clonedArray.push(
          cloneJsonNode(element, replacements, ids, propertyName)
        );
      }
    }
    return clonedArray;
  } else if (typeof node === "string") {
    if (replacements) {
      for (const key in replacements) {
        if (node.toLowerCase().includes(key.toLowerCase())) {
          const newValue = replaceWithCaseSensitivity(
            node,
            key,
            replacements[key]
          );
          return newValue;
        }
      }
    }
    return node;
  }
  return node;
}

export type ItemOperations = {
  canDrag: boolean;
  canReparent: boolean;
  canReorder: boolean;
  canDelete: boolean;
  canBeParent: boolean;
};

export type ItemNode = {
  Id: string;
  __typename: string | null;
  isHorizontal: boolean;
  level: number;
  parent: string;
  path: number[];
  index: number;
  pathIds: string[];
  config: any;
  context?: RuntimeContext;
  operations: ItemOperations;
};

export function buildInitialDataForTree(data: any) {
  const clonedData = cloneJsonWithIDs(data.Template);
  return {
    ...buildTreeItems(clonedData)[0],
    Template: clonedData,
  };
}

export function buildMetadataMap(
  indexMap: Map<string, ItemNode>,
  node: any,
  parent: any,
  level: number,
  path: number[],
  pathIds: string[],
  key = "Items"
): Map<string, ItemNode> {
  if (node == null) return indexMap;

  // Create and store the current node
  const designNode = createItemNode(node, parent, level, path, pathIds);
  const type = designNode.__typename;
  indexMap.set(node.Id, designNode);

  // If this node can hold children under `key` but doesn't have any array,
  // ensure there's at least an empty array for consistency
  const isComponent = type === "Component";
  const isPage = type === "Page";
  const isMenuOrFeature = type === "Feature" || type === "Menu";
  if ((isMenuOrFeature || isPage) && !node[key]) {
    node[key] = [];
  }

  // 1. Traverse child container if `node` is a Component
  if (isComponent && node.ComponentContainer) {
    buildMetadataMap(
      indexMap,
      node.ComponentContainer,
      node,
      level + 1,
      [...path, 0],
      [...pathIds, node.ComponentContainer.Id],
      key
    );
  }

  // 2. Traverse normal children in `node[key]`
  if (Array.isArray(node[key])) {
    node[key].forEach((child, i) => {
      buildMetadataMap(
        indexMap,
        child,
        node,
        level + 1,
        [...path, i],
        [...pathIds, child?.Id],
        key
      );
    });
  }

  // 3. Special handling for AccordionLayout => traverse Groups
  if (type === "AccordionLayout" && Array.isArray(node.Groups)) {
    node.Groups.forEach((group, i) => {
      buildMetadataMap(
        indexMap,
        group,
        node,
        level + 1,
        [...path, i],
        [...pathIds, group?.Id],
        "Items"
      );
    });
  }

  return indexMap;
}


export function createItemNode(
  config: any,
  parent: any,
  level: number,
  path: number[],
  pathIds: string[],
  context?: RuntimeContext
) {
  if (config.Id == null) {
    config.Id = generateUID();
  }

  const type = getTypename(config);

  const itemNode: ItemNode = {
    Id: config.Id,
    __typename: type,
    level: level,
    parent: parent?.Id,
    path,
    pathIds,
    isHorizontal:
      config.Layout?.Direction == "row" ||
      (config.IgnoreLayout && config.Style.flexDirection == "row"),
    index: last(path) ?? 0,
    config: config,
    operations: getItemOperations(config, parent),
    context,
  };

  return itemNode;
}

const isAccordionGroup = (node) => node.__typename === "AccordionGroup";

export function getItemOperations(node: any, parent: any): ItemOperations {
  const containersWithRequiredChild = [
    "Repeater",
    "TabContainer",
    "Component",
    "TabItem",
  ];
  const isRequiredContainer =
    isContainer(node) &&
    containersWithRequiredChild.includes(parent?.__typename);
  const isRequiredTab =
    node.__typename == "TabItem" && parent?.__typename == "TabContainer";
  const isTopLevelComponent =
    node.__typename == "Component" &&
    (parent == null || parent.__typename == "Page");

  const ops: ItemOperations = {
    canDelete: !isRequiredContainer && !isTopLevelComponent,
    canReparent: !isRequiredContainer && !isRequiredTab && !isTopLevelComponent,
    canReorder: !isRequiredContainer && !isTopLevelComponent,
    canBeParent:
      containersWithRequiredChild.includes(node.__typename) ||
      isContainer(node) ||
      isMenuOrFeature(node) ||
      isPage(node) ||
      isAccordionGroup(node),
    canDrag: false,
  };

  ops.canDrag = ops.canReorder || ops.canReparent;

  return ops;
}

export function getItemIdentifier(item, type) {
  return formatLabel(
    item,
    item.UniqueName ?? item.Name ?? item.Label ?? item.Value ?? item.Id,
    type
  );
}

export function getItemLabel(item, type, root) {
  const label =
    item.UniqueName ?? item.Label ?? item.Name ?? item.Value ?? item.Id;
  let node = findParentAndIndex(root, { Id: item.Id });

  if (type === "TabItem") return formatLabel(item, label, item.Title ?? type);

  if (!isContainer(item)) return formatLabel(item, label, type);
  // handle for first component wrapper
  if (!node) {
    node = { parent: root, index: 0, item };
  }

  if (
    node &&
    (node as any)?.item &&
    isContainer((node as any)?.item) &&
    !!(node as any)?.item.Style
  ) {
    const isVerticalContainer =
      (node as any)?.item.Style.display == "flex" &&
      (node as any)?.item.Style.flexDirection == "column";
    const isHorizontalContainer =
      (node as any)?.item.Style.display == "flex" &&
      (node as any)?.item.Style.flexDirection == "row";
    const isGridContainer = (node as any)?.item.Style.display == "grid";

    if (isVerticalContainer) return "Vertical Container" + " - " + item.Id;
    if (isHorizontalContainer) return "Horizontal Container" + " - " + item.Id;
    if (isGridContainer) return "Grid Container" + " - " + item.Id;
  }

  return formatLabel(item, label, type);
}

function formatLabel(item, label, identifier) {
  if (label == null) return `${identifier} - `;
  const result = label?.length > 50 ? label.substring(0, 50) + "..." : label;
  if (!identifier) return result;
  return `${identifier} - ${result}`;
}

// Refactored buildTreeItems function with childrenIdentifiers as an array of strings
export function buildTreeItems(
  node: any,
  childrenIdentifiers: string[] = ["Items"]
): any[] {
  const keys = childrenIdentifiers.length > 0 ? childrenIdentifiers : ["Items"];

  const mapFn = (
    item: any,
    parent: any,
    level: number,
    index: number,
    parentNode: any
  ) => {
    const ctrl = getLibraryControl(item);
    const type = getTypename(item);

    if (item?.Id == null || !item?.Id) {
      item.Id = generateUID();
    }
    const label = getItemLabel(item, ctrl?.Name ?? type, parent);
    return {
      id: item.Id,
      type,
      item,
      label,
      itemType: "TreeItem",
      icon: getTreeItemIcon(item),
      level,
      parentRestrictions: getNodeParentRestrictions(item),
      childRestrictions: getNodeChildRestrictions(item),
      parent: parentNode,
      index,
      children: [],
      expanded: true,
      operations: getItemOperations(item, parent),
    };
  };

  return node == null
    ? []
    : [mapMetadataRec(node, keys, mapFn, null, 0, 0, null)];
}

export function mapMetadataRec(
  node: any,
  keys: string[],
  mapFn: (
    item: any,
    parent: any,
    level: number,
    index: number,
    parentNode: any
  ) => any,
  parent: any = null,
  level = 0,
  idx = 0,
  parentNode: any = null
): any {
  let children: any[] = [];

  if (isComponent(node)) {
    if (node.ComponentContainer) {
      children = [node.ComponentContainer];
    }
  } else {
    keys.forEach((key) => {
      if (Array.isArray(node[key])) {
        children = children.concat(node[key]);
      } else if (node[key] !== undefined && node[key] !== null) {
        children.push(node[key]);
      }
    });
  }

  const newNode = mapFn(node, parent, level, idx, parentNode);

  newNode.children = children.map((child, index) =>
    mapMetadataRec(child, keys, mapFn, node, level + 1, index, newNode)
  );

  return newNode;
}

export function getTreeItemIcon(node: any) {
  if (!node) return null;
  const item = getLibraryControl(node);
  if (!item && isComponent(node)) {
    return getBaseComponent("", null).Icon;
  }
  return item?.Icon;
}

function getLibraryControl(node: any) {
  const typename = getTypename(node);
  return controlLibrary.Components.find((component) =>
    node.Type
      ? component?.Template?.["Type"] === node.Type
      : component?.Template?.__typename === typename
  );
}

export function getFilteredTree(
  data: INGTreeListItems[],
  searchInput: string
): any[] {
  const result = [...data];
  return result.filter((item, idx) => {
    const searchMatchLabel = ((item.label as string) ?? "")
      .toLowerCase()
      .includes(searchInput.toLowerCase());
    const searchMatchType = ((item.type as string) ?? "")
      .toLowerCase()
      .includes(searchInput.toLowerCase());
    if (item.children) {
      const newChildren = getFilteredTree(item.children, searchInput);
      const hasMathcingChildren = newChildren.length > 0;
      result[idx].children = newChildren;
      return hasMathcingChildren || searchMatchLabel || searchMatchType;
    } else return searchMatchLabel || searchMatchType;
  });
}

export function getNodeParentRestrictions(node: {
  __typename: string;
}): string | null {
  const restrictionMap = {
    TabItem: "TabContainer",
    // add more
    default: null,
  };

  return restrictionMap[node.__typename] ?? restrictionMap.default;
}
export function getNodeChildRestrictions(node: {
  __typename: string;
}): string | null {
  const restrictionMap = {
    TabContainer: "TabItem",
    // add more
    default: null,
  };

  return restrictionMap[node.__typename] ?? restrictionMap.default;
}

export function getTypename(control: any): string | null {
  if (isNil(control)) return null;
  return control.__typename ?? control.Typename ?? control.TypeName ?? null;
}

export function getTypenameUrl(control: any): string | null {
  const editorPages = ["component", "page", "feature", "menu", "template"];
  const editor = editorPages.find((path) => pathnameIncludes(path));
  switch (editor) {
    case "component":
      return "Component";
    case "page":
      return "Page";
    case "feature":
      return "Feature";
    case "menu":
      return "Menu";
    case "template":
      return "PageTemplate";
    default:
      return getTypename(control);
  }
}

export const filterEmpty = (obj: any | null) => {
  if (!obj) return false;
  const newObj = Object.fromEntries(
    Object.entries(obj).filter(([key, value]) => {
      if (isObject(value)) {
        return Object.keys(value).length > 0;
      }
      return value;
    })
  );
  return Object.keys(newObj).length > 0;
};

export function pathnameIncludes(text: string) {
  return window.location.pathname.includes(text);
}

export function isLayout(c: any) {
  return getTypename(c)?.endsWith("Layout") ?? false;
}

export function findParentAndIndex(
  root: any,
  needle: object
): { parent: any | null; index: number | null; item?: any } | null {
  function search(node: any | null, parent, level: number, path: number[]) {
    if (!node) return null;
    const isComponent = getTypename(node) == "Component";
    const items = isComponent ? node.ComponentContainer.Items : node.Items;
    if (isArray(items)) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];

        if (Object.entries(needle).every(([k, v]) => item[k] == v)) {
          return {
            parent: isComponent ? node.ComponentContainer : node,
            index: i,
            level,
            path,
            item,
            itemPath: [...path, i],
          };
        }
        const result = search(item, node, level + 1, [...path, i]);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  return search(root, null, 0, []);
}

export function matchTypename(match: string, config: unknown): boolean {
  if (isNil(match) || isNil(config)) return false;
  return getTypename(config)?.includes(match) ?? false;
}

export function isContainer(config): boolean {
  if (isNil(config)) return false;
  return getTypename(config)?.includes("Container") ?? false;
}

export function isMenuOrFeature(config): boolean {
  if (isNil(config)) return false;
  return isMenu(config) || isFeature(config);
}

export function isMenu(config): boolean {
  if (isNil(config)) return false;
  return getTypename(config)?.includes("Menu") ?? false;
}

export function isFeature(config): boolean {
  if (isNil(config)) return false;
  return getTypename(config)?.includes("Feature") ?? false;
}

export function isComponentGuiRoute(location?: Location<any>) {
  if (isNil(location)) return false;
  return location.pathname === "/ngeditor/component";
}

export function isComponent(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "Component";
}

export function isRepeater(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "Repeater";
}

export function isList(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "List";
}

export function isTabs(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "TabContainer";
}

export function isPage(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "Page";
}

export function isReference(config) {
  if (isNil(config)) return false;
  return getTypename(config) == "Reference";
}

export function getItems(config, key = "Items") {
  return config[key] ?? config.ComponentContainer?.[key] ?? config.Content?.[0];
}

export function findParentPropEditorFormId(context) {
  for (let i = context.Path.length - 1; i >= 0; i--) {
    if (
      context.Path[i - 1].__typename === "PropertiesEditor" &&
      context.Path[i].__typename === "Form"
    )
      return context.Path[i].Id;
  }

  log.error(
    `NG${context.Path[context.Path.length - 1].__typename}`,
    "Error, could not find parent PropertiesEditor Form "
  );

  return null;
}

export function getGridContext(context) {
  for (let i = context.Path.length - 1; i >= 0; i--) {
    if (context.Path[i].__typename === "List") return context.Path[i];
  }

  return null;
}
