import { IAccordionGroupProps } from "../../library/NGFieldExtensions";
import { isNil } from "lodash-es";
import { useSignal } from "@preact/signals-react";
import { setupHandlers, setupLocalState } from "../../library/dataService";
import { getClassName, getsxObject, getTestId } from "../../library/utils";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import NGLayout from "../../layouts/NGLayout";
import NGLayoutItem from "../../generators/NGLayoutItem";
import { log } from "../../library/logger";
import NGIcon from "../../components/NGIcon/NGIcon";
import { isLayout } from "../../library/metadataUtils";

const tag = "NGAccordionGroup";

export default function NGAccordionGroup({ config, context, layoutItem }: IAccordionGroupProps) {
  const local = setupLocalState(
    config,
    {
      Style: useSignal(config.Style ?? {}),
      AccordionContainerStyle: useSignal(config.AccordionContainerStyle ?? {}),
      Classes: useSignal(config.Classes ?? []),
      Visible: useSignal(config.Visible ?? true),
      Items: useSignal(config.Items ?? []),
      Expanded: useSignal(config.Expanded ?? false),
      Label: useSignal(config.Label ?? "Label"),
      LayoutItem: useSignal(layoutItem),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  const handleAccordionChange = (event: React.SyntheticEvent, expanded: boolean) => {
    local.Expanded.value = expanded;
  };

  return (
    <Accordion
      data-testid={getTestId(config)}
      data-type={config.__typename}
      aria-label={"accordion"}
      className={getClassName(local.Classes)}
      sx={getsxObject(local.Style.value)}
      expanded={local.Expanded.value}
      onChange={handleAccordionChange}
    >
      <AccordionSummary
        data-testid={`accordion-group-${local.Label.value}`}
        aria-controls={`accordion-group-${local.Label.value}`}
        id={`panel-${config.Id}-header`}
        expandIcon={<NGIcon config={{ IconName: "ExpandLess" }} context={context} />}
      >
        {local.Label.value}
      </AccordionSummary>
      <AccordionDetails sx={getsxObject(local.AccordionContainerStyle.value)}>
        {local.Items.value?.map((item, itemKey) => {
          // const item = isString(x) ? itemMap[x] : x;
          if (isNil(item)) {
            log.error(tag, `Item with id '${item}' could not be found`);
            return <></>;
            0;
          }
          return (
            <Box key={`${local.Label.value}-${itemKey}`}>
              {!isLayout(item) ? (
                <NGLayoutItem config={item} context={context} />
              ) : (
                <NGLayout layoutItem={local.LayoutItem.value} layout={item} inDesignMode={false} context={context} />
              )}
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
}
