import React, { cloneElement, useRef } from "react";
import { createRoot } from "react-dom/client";
import { ButtonProps } from "@mui/material/Button";
import { TooltipProps } from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { SaveAlt } from "@mui/icons-material";
import MenuList from "@mui/material/MenuList";
import { Button, CircularProgress, Menu } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { CsvCell, ExcelRow, GridApi } from "ag-grid-charts-enterprise";
import { GridCsvExportProps, GridPrintExportProps, INGListExportButtonProps } from "../../library/NGFieldExtensions";
import { GetCustomLabelForListFromSiteSettings, GetSettingFromSite, setupLocalState } from "../../library/dataService";
import { useSignal } from "@preact/signals-react";
import { getClassNameFromString, getsxObject, getTestId } from "../../library/utils";
import { getGridContext } from "../../library/metadataUtils";
import { ListExportButton } from "../../../resolvers-types";
import { getExportDataDefaults, IAnyObject } from "./NGListUtils";
import NGList from "./NGList";
import { BrowserRouter } from "react-router-dom";

const exceedsMaxSizePhrase = "There are more than 5000 rows in this export. If you would like to see more data, please contact support"

export interface GridExportDisplayOptions {
  /**
   * If `true`, this export option will be removed from the GridToolbarExport menu.
   * @default false
   */
  disableToolbarButton?: boolean;
}

export interface GridExportMenuItemProps<Options> {
  hideMenu?: () => void;
  options?: Options & GridExportDisplayOptions;
  apiRef: React.RefObject<AgGridReact<any>>;
  exportFunction?: (callback: (maxSize: boolean, onlySelected: boolean) => void) => void;
  loading?: boolean;
}

export type GridCsvExportMenuItemProps = GridExportMenuItemProps<GridCsvExportProps>;

export type GridPrintExportMenuItemProps = GridExportMenuItemProps<GridPrintExportProps>;

export interface GridToolbarExportProps {
  csvOptions?: GridCsvExportProps & GridExportDisplayOptions;
  printOptions?: GridPrintExportProps & GridExportDisplayOptions;
  /**
   * The props used for each slot inside.
   * @default {}
   */
  slotProps?: { button?: Partial<ButtonProps>; tooltip?: Partial<TooltipProps> };
  apiRef: React.RefObject<AgGridReact<any>>;
  [key: string]: any;
}

export function GridCsvExportMenuItem(props: GridCsvExportMenuItemProps) {
  const { hideMenu, options, apiRef, exportFunction, loading, ...other } = props;

  const getRows: () => CsvCell[][] = () => [
    [],
    [
      {
        data: {
          value: exceedsMaxSizePhrase,
          type: "String",
        },
      },
    ],
    [],
  ];

  const exportToCsv = async (maxSize = false, onlySelected = false) => {
    apiRef.current?.api.exportDataAsCsv({
      ...getExportDataDefaults(apiRef.current?.api),
      onlySelected: onlySelected,
      prependContent: maxSize ? getRows() : undefined,
      ...options,
    });
    hideMenu?.();
  }
  return (
    <MenuItem
      onClick={() => exportFunction?.(exportToCsv)}
      sx={{ minWidth: 175, alignItems: "center", justifyContent: "center" }}
      {...other}
    >
      {loading ? <CircularProgress size={20} /> : "Download as CSV"}
    </MenuItem>
  );
}

export function GridPrintExportMenuItem(props: GridPrintExportMenuItemProps) {
  const { hideMenu, apiRef, ...other } = props;

  function onBtPrint() {
    setPrinterFriendly(apiRef.current?.api);

    setTimeout(() => {
      print();
      setNormal(apiRef.current?.api);
    }, 500);
  }

  function setPrinterFriendly(api?: GridApi) {
    api?.setGridOption("domLayout", "print");
  }

  function setNormal(api?: GridApi) {
    api?.setGridOption("domLayout", undefined);
  }

  return (
    <MenuItem
      onClick={() => {
        onBtPrint();
        hideMenu?.();
      }}
      sx={{ minWidth: 175, alignItems: "center", justifyContent: "center" }}
      {...other}
    >
      Print
    </MenuItem>
  );
}

export function GridExcelExportMenuItem(props: GridPrintExportMenuItemProps) {
  const { hideMenu, options, apiRef, exportFunction, loading, ...other } = props;

  const getRows: () => ExcelRow[] = () => [
    { cells: [] },
    {
      cells: [
        {
          data: {
            value: exceedsMaxSizePhrase,
            type: "String",
          },
        },
      ],
    },
    { cells: [] },
  ];

  const exportToExcel = async (maxSize = false, onlySelected = false) => {

    apiRef.current?.api.exportDataAsExcel({
      ...getExportDataDefaults(apiRef.current?.api),
      ...options,
      prependContent: maxSize ? getRows() : undefined,
      onlySelected: onlySelected,
    });
    hideMenu?.();
  }

  return (
    <MenuItem
      onClick={() => exportFunction?.(exportToExcel)}
      {...other}
      sx={{ minWidth: 175, alignItems: "center", justifyContent: "center" }}
    >
      {loading ? <CircularProgress size={20} /> : "Download as Excel"}
    </MenuItem>
  );
}

interface GridToolbarExportContainerProps {
  slotProps?: { button?: Partial<ButtonProps>; tooltip?: Partial<TooltipProps> };
  children?: React.ReactNode;
  config: ListExportButton;
}

function GridToolbarExportContainer(props: GridToolbarExportContainerProps) {
  const { children, slotProps = {}, config } = props;
  const buttonProps = slotProps.button || {};
  const [open, setOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen((prevOpen) => !prevOpen);
    buttonProps.onClick?.(event);
  };

  const handleMenuClose = () => setOpen(false);

  if (children == null) {
    return null;
  }

  return (
    <React.Fragment>
      <Button
        sx={getsxObject(config.Style, {
          textTransform: "none",
        })}
        className={getClassNameFromString(config.Classes)}
        data-testid={props["data-testid"]}
        data-type={props["data-type"]}
        ref={buttonRef}
        size="small"
        startIcon={GetSettingFromSite("HideListExportButton") !== true && <SaveAlt />}
        aria-expanded={open}
        aria-haspopup="menu"
        aria-controls={open ? "export1" : undefined}
        id="export1"
        {...buttonProps}
        onClick={handleMenuOpen}
      >
        {GetCustomLabelForListFromSiteSettings("Export")}
      </Button>
      <Menu open={open} anchorEl={buttonRef.current} onClose={handleMenuClose}>
        <MenuList id="export1" autoFocusItem={open}>
          {React.Children.map(children, (child) => {
            if (!React.isValidElement(child)) {
              return child;
            }
            return React.cloneElement<any>(child, { hideMenu: handleMenuClose });
          })}
        </MenuList>
      </Menu>
    </React.Fragment>
  );
}

function NGListExportButton({ config, context }: INGListExportButtonProps) {
  const local = setupLocalState(
    config,
    {
      Visible: useSignal(config.Visible ?? true),
    },
    context
  );

  const { Config, GridRef } = getGridContext(context);
  const clonedContainerId = 'cloned-grid-container';
  const AgGridRef = useRef<AgGridReact<IAnyObject>>(null);
  const [loading, setLoading] = React.useState(false);

  const exportTo = async (callback: (maxSize: boolean, onlySelected: boolean) => void) => {
    setLoading(true);
    // Get the filter model from the original grid
    const filterModel = (GridRef as React.RefObject<AgGridReact<any>>).current?.api.getFilterModel() || null;
    // Get selected rows
    const selectedNodes = (GridRef as React.RefObject<AgGridReact<any>>).current?.api.getSelectedNodes();
    // Dynamically clone the AgGridReact component
    const clonedConfig = { ...Config, RowsPerPage: 5001, Visible: false, Bindings: { ...Config?.Bindings, RowsPerPage: "5001" } };
    const clonedGrid = cloneElement(
      <BrowserRouter>
        <NGList config={clonedConfig} context={context} ref={AgGridRef} />
      </BrowserRouter>
    );

    // Append the cloned grid to the DOM and use its ref
    await appendGridToDOM(clonedGrid);

    // Use the gridRef to access grid API, for example
    if (AgGridRef.current) {
      // Set the filter model on the cloned grid
      AgGridRef.current.api.setFilterModel(filterModel);
      let onlySelected = false;
      if (selectedNodes && selectedNodes.length > 0) {
        // Set the selected rows on the cloned grid
        AgGridRef.current.api.setNodesSelected({ nodes: selectedNodes, newValue: true });
        onlySelected = true;
      }
      // Perform operations on the grid, e.g., export data
      AgGridRef.current?.api.setGridOption("onFirstDataRendered", () => {
        const datasource = AgGridRef.current?.api.getGridOption("rowData");
        let maxSize = false;
        if (datasource && datasource.length > 5000) {
          // Limit the export to 5000 rows
          AgGridRef.current?.api.setGridOption("rowData", datasource.slice(0, 5000));
          maxSize = true;
        }
        callback(maxSize, onlySelected);

        // Remove the cloned grid from the DOM after the export operation is complete
        document.body.removeChild(document.getElementById(clonedContainerId) as HTMLElement);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  // Function to dynamically append the cloned grid to the DOM
  const appendGridToDOM = async (clonedGrid: React.ReactNode) => {
    // Create a container where you want to append the grid (could be a div or another container)
    const container = document.createElement('div');
    container.id = clonedContainerId;
    container.style.display = 'none'; // You can choose visibility settings as needed

    // Append the container to the body (or another DOM element)
    document.body.appendChild(container);
    // Render the cloned grid inside the container
    // ReactDOM.render() is used to render the element dynamically into the container
    const root = createRoot(container);
    root.render(clonedGrid);
  };

  return local.Visible.value ? (
    <GridToolbarExportContainer data-testid={getTestId(config)} data-type={config.__typename} config={config}>
      {config.EnableCSV && <GridCsvExportMenuItem apiRef={AgGridRef} exportFunction={exportTo} loading={loading} />}
      {config.EnableExcel && <GridExcelExportMenuItem apiRef={AgGridRef} exportFunction={exportTo} loading={loading} />}
      {config.EnablePrint && <GridPrintExportMenuItem apiRef={AgGridRef} loading={loading} />}
    </GridToolbarExportContainer>
  ) : null;
}

export { NGListExportButton, GridToolbarExportContainer };
