import { INGFlexLayoutProps } from "../library/NGFieldExtensions";
import { getStyleObject, keyByRec } from "../library/utils";
import { useSignal } from "@preact/signals-react";
import { isNil } from "lodash-es";
import { setupHandlers, setupLocalState } from "../library/dataService";
import { renderAsLayoutItem, renderAsLayoutItemCollection } from "./LayoutHelpers";

export default function NGFragmentLayout({ config, context, layoutItem, children }: INGFlexLayoutProps) {
  const itemMap = keyByRec((layoutItem as any).Items, "Items", "Id", {});

  const local = setupLocalState(
    config,
    {
      Style: useSignal(config.Style ?? (layoutItem as any).Style ?? {}),
      Classes: useSignal(config.Classes ?? (layoutItem as any).Classes ?? []),
      Visible: useSignal(config.Visible ?? true),
    },
    context
  );

  const handlers = setupHandlers(config, context);

  function itemWithLayoutStyle(item: any) {
    if (isNil(config.ItemStyles)) return;

    if (isNil(config.ItemStyles[item.Id])) return;

    if (item.__typename.includes("Container")) {
      item.Layout.Style = getStyleObject(config.ItemStyles[item.Id], item.Layout.Style);
    } else {
      item.Style = getStyleObject(config.ItemStyles[item.Id], item.Style);
    }
  }

  return (
    <>
      {local.Visible.value && (
        <>
          {!children && !config.Items && renderAsLayoutItem(layoutItem, itemWithLayoutStyle, context, handlers)}

          {!children && renderAsLayoutItemCollection(config, itemMap, layoutItem, itemWithLayoutStyle, context)}

          {children}
        </>
      )}
    </>
  );
}
