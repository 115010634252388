import { useSignal } from "@preact/signals-react";
import { Stack } from "@mui/material";
import { INGTestHarnessProps } from "../../library/NGFieldExtensions";
import { setupHandlers, setupLocalState, setupServices } from "../../library/dataService";
import { generateUID, getClassName, getTestId } from "../../library/utils";
import NGLayoutItem from "../../generators/NGLayoutItem";
import NGReviewDialog from "../NGReviewDialog/NGReviewDialog";
import NGLayout from "../../layouts/NGLayout";
import { containerWithPaper } from "../../generators/GeneratorUtils";
import { renderModalPopups } from "../ComponentUtils";

export default function NGTestHarness({ config, context, children }: INGTestHarnessProps) {

  const local = setupLocalState(
    config,
    {
      Visible: useSignal(config.Visible ?? true),
      Resizable: useSignal(config.Resizable ?? false),
      InDesignMode: useSignal(config.InDesignMode ?? false),
      InReviewMode: useSignal(config.InReviewMode ?? false),
      Style: useSignal(config.Style ?? {}),
      Classes: useSignal(config.Classes ?? []),
    },
    context
  );

  setupServices(config, context);

  if (!config.IgnoreLayout && !config.Layout) {
    config.Layout = {
      __typename: "FlexLayout",
      Id: generateUID(),
    };
  }

  const handlers = setupHandlers(config, context);

  function renderContainer(addTestId: boolean) {
    return (
      <>
        {renderModalPopups(config, context)}
        {config.IgnoreLayout &&
          <Stack
            data-testid={addTestId ? getTestId(config) : undefined}
            data-type={addTestId ? config.__typename : undefined}
            sx={local.Style.value}
            className={getClassName(local.Classes)}
            {...handlers}
          >
            {config.Items?.map((item) => (
              <NGLayoutItem key={(item as any).Id} config={item} context={context} />
            ))}
            {children}
            {local.InReviewMode.value && (
              <NGReviewDialog config={config.ReviewDialogOptions ?? {}} context={context}></NGReviewDialog>
            )}
          </Stack>
        }
        {!config.IgnoreLayout &&
          config.Layout &&
          <NGLayout
            layout={config.Layout}
            layoutItem={config}
            inDesignMode={local.InDesignMode.value}
            context={context}
          />
        }
      </>
    );
  }

  return containerWithPaper(local, config, renderContainer, context);
}
