import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import "./index.css";
import { RouterProvider } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import { NGRouter } from "./NGRouter.tsx";
import "./library/native";
import tracing from "./library/tracing.ts";
import { OidcProvider } from "./library/auth.ts";
import { fixNodeRemoveBug } from "./library/utils.ts";
import { SnackbarProvider } from "notistack";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import { themes } from "./theme.ts";

tracing.createAnnonymousId();

// trigger

fixNodeRemoveBug();

function applyThemeMode() {
  const currentTheme = localStorage.getItem("app-theme");
  if (currentTheme && !document.body.classList.contains(`${currentTheme}-theme`)) {
    document.body.classList.add(`${currentTheme}-theme`);
  }
}

applyThemeMode();

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <OidcProvider>
      <DndProvider backend={HTML5Backend}>
        <StyledEngineProvider injectFirst>
          {/* <ThemeProvider theme={currentTheme}> */}
          <SnackbarProvider maxSnack={10}>
            {/* <CssBaseline enableColorScheme /> */}
            <RouterProvider router={NGRouter} />
          </SnackbarProvider>
          {/* </ThemeProvider> */}
        </StyledEngineProvider>
      </DndProvider>
    </OidcProvider>
  </StrictMode>
);
