import type { ConnectionOptions, MsgHdrs, NatsConnection } from "nats.ws";
import { JSONCodec, headers, jwtAuthenticator } from "nats.ws";
import { connect } from "nats.ws";
import type { Result } from "../models/result";
// import { createContext } from "react";
import { signal } from "@preact/signals-react";
import { generateUID, parseAuthCookies, parseCookies } from "./utils";
import { json } from "react-router-dom";
import { LogTag, log } from "./logger";

export enum Status {
  Online,
  Offline,
  Connecting,
  NotAvailable,
}

const useHttp = true;
const serverUrl = import.meta.env.VITE_NATS_SERVER_URL;
const httpServerUrl = import.meta.env.VITE_BACKEND_HOST;
const useJwtAuth = import.meta.env.VITE_NATS_AUTH_JWT == "true";

const jc = JSONCodec();
let nc: NatsConnection | null = null;

const msgHeaders = headers();

let connectPromise: Promise<NatsConnection> | null = null;

export const status = signal(Status.Offline);

const tag: LogTag = "nats";

export function ensureConnected(): Promise<NatsConnection | null> {
  if (status.value == Status.NotAvailable) return Promise.resolve(null);
  if (status.value == Status.Online) return Promise.resolve(nc);
  if (status.value == Status.Connecting && connectPromise) return connectPromise;

  const id = generateUID();
  status.value = Status.Connecting;

  const opts: ConnectionOptions = { servers: serverUrl };

  const getCookiesWithRetry = (retryCount = 100): Promise<{ jwt: string; seed: string; token: string }> => {
    return new Promise((resolve, reject) => {
      const cookies = parseAuthCookies();
      const jwt = cookies["Api.NatsJwt"];
      const seed = cookies["Api.NatsSeed"];
      const token = cookies["Api.Token"];

      if (jwt && seed && token) {
        resolve({ jwt, seed, token });
      } else if (retryCount > 0) {
        setTimeout(() => resolve(getCookiesWithRetry(retryCount - 1)), 50);
      } else {
        reject(json({ Message: "Unauthorized" }, { status: 401 }));
      }
    });
  };

  return getCookiesWithRetry()
    .then(({ jwt, seed, token }) => {
      msgHeaders.set("Token", token);
      opts.authenticator = jwtAuthenticator(jwt, new TextEncoder().encode(seed));

      setBranchHeader();

      log.info(tag, `Connecting to: ${opts.servers}, jwt: ${useJwtAuth}`, id);
      connectPromise = connect(opts)
        .then((conn) => {
          nc = conn;
          status.value = Status.Online;
          log.info(tag, `Connected to: ${nc.getServer()}`, id);

          (async () => {
            for await (const s of nc.status()) {
              switch (s.type) {
                case "reconnect":
                  status.value = Status.Online;
                  break;
                case "disconnect":
                  status.value = Status.Offline;
                  break;
                case "reconnecting":
                  status.value = Status.Connecting;
                  break;
                case "update":
                default:
                  // Do nothing
                  break;
              }
              log.info(tag, `Status ${s.type}: ${s.data}`, s, status.value);
            }
          })();

          nc.closed().then((e) => {
            log.info(tag, "Connection closed", e);
            status.value = Status.Offline;
          });

          return nc;
        })
        .catch((e) => {
          log.error(tag, `Error connecting: ${e}`, id);
          status.value = Status.NotAvailable;
          return null;
        });

      return connectPromise;
    })
    .catch((error) => {
      log.error(tag, `Failed to retrieve cookies: ${error}`);
      status.value = Status.NotAvailable;
      return Promise.reject(error);
    });
}

function setBranchHeader() {
  const branch = parseAuthCookies()["Api.Branch"];
  if (branch) {
    msgHeaders.set("Branch", branch);
  }
}

export async function subscribe(subject: string, action: (message: unknown) => void) {
  const nc = await ensureConnected();
  if (!nc) return;

  const sub = nc.subscribe(subject);

  (async () => {
    for await (const m of sub) {
      const message = jc.decode(m.data);
      log.info(tag, `[${sub.getProcessed()}]: ${m.subject} - ${message}`);
      action(message);
    }
  })();
}

export async function subscribeStream(subject: string, action: (message: unknown) => void) {
  const nc = await ensureConnected();
  if (!nc) return;

  const consumer = await nc.jetstream().consumers.get(subject);
  const cm = await consumer.consume();

  (async () => {
    for await (const m of cm) {
      const message = jc.decode(m.data);
      log.info(tag, `[${cm.getProcessed()}]: ${m.subject} - ${message}`);
      action(message);
    }
  })();
}

export function disconnect() {
  log.info(tag, "Disconnecting");
  nc?.drain();
  nc?.close();
  status.value = Status.Offline;
}

export async function publish(subject, data, headers: object = {}) {
  const nc = await ensureConnected();
  if (!nc) return;

  const baseHeaders = getHeaders();

  nc.publish(subject, jc.encode(data), {
    headers: addCustomHeaders(baseHeaders, headers),
  });
}

function getHeaders() {
  setBranchHeader();
  return msgHeaders;
}

function toHttpHeaders(natsHeaders: MsgHdrs, type = "application/json") {
  const httpHeaders = {};

  natsHeaders.keys().forEach((key) => {
    httpHeaders[key] = natsHeaders.get(key);
  });

  httpHeaders["Content-Type"] = type;

  return httpHeaders;
}

function addCustomHeaders(baseHeaders: MsgHdrs, headers: object) {
  for (const key in headers) {
    if (Object.prototype.hasOwnProperty.call(headers, key)) {
      baseHeaders.set(key, headers[key]);
    }
  }

  return baseHeaders;
}

export async function request<T>(subject: string, data: object, headers: object = {}): Promise<Result<T>> {
  const nc = await ensureConnected();

  const baseHeaders = getHeaders();
  const customHeaders = addCustomHeaders(baseHeaders, headers);

  let msg;

  try {
    if (status.value == Status.NotAvailable) {
      const resp = await fetch(`/api/proxy/${subject}`, {
        method: "POST",
        headers: toHttpHeaders(customHeaders),
        body: JSON.stringify(data),
      });
      msg = await resp.json();
      return msg;
    }
    if (!nc) return { success: false, reasons: [{ message: "Not connected" }] };

    msg = await nc.request(subject, jc.encode(data), {
      timeout: 300000,
      headers: customHeaders,
    });
    if (msg === undefined) {
      log.error(tag, `No response from ${subject}`, customHeaders);
      return { success: false, reasons: [{ message: "No response" }] };
    }
    const resp = jc.decode(msg.data) as Result<T>;
    return resp;
  } catch (err: any) {
    log.error(tag, `Error: ${err}`, subject, data, msg, customHeaders);
    return { success: false, reasons: [err.message] };
  }
}

async function getObjectStore(bucket) {
  if (nc == null || status.value != Status.Online) return { success: false, reasons: [{ message: "Not connected" }] };

  const js = nc.jetstream();
  const os = await js.views.os(bucket);

  return { success: true, data: os };
}

async function streamToBlob(stream, mimeType = "application/octet-stream") {
  const reader = stream.getReader();
  const chunks = [];

  while (true) {
    const { done, value } = await reader.read();
    if (done) break; // Exit the loop when stream is finished
    chunks.push(value); // Collect each chunk
  }

  // Combine the chunks into a single Blob
  return new Blob(chunks, { type: mimeType });
}

export async function objPut(
  bucket: string,
  name: string,
  description: string,
  fileSize: number,
  stream: ReadableStream<Uint8Array>
) {
  if (status.value == Status.NotAvailable) {
    const baseHeaders = getHeaders();
    const customHeaders = addCustomHeaders(baseHeaders, headers);
    const blob = await streamToBlob(stream);
    const resp = await fetch(`/api/files/nats/${bucket}/${name}`, {
      method: "PUT",
      headers: toHttpHeaders(customHeaders, blob.type),
      body: blob,
    });
    return await resp.json();
  }
  const res = await getObjectStore(bucket);
  if (!res.success || res.data == null) return res;
  const os = res.data;

  const osStatus = await os.status();
  const curSize = osStatus.size;
  const maxBytes = osStatus.streamInfo.config.max_bytes;
  log.info(
    tag,
    "~~status: ",
    osStatus,
    "size: ",
    osStatus.size,
    "max:",
    maxBytes,
    "curSize + fileSize: ",
    curSize + fileSize
  );
  // maxBytes = -1 means no limit
  if (maxBytes > 0 && curSize + fileSize >= maxBytes) {
    return {
      success: false,
      reasons: [{ message: "Max store size exceeded" }],
    };
  }

  try {
    const info = await os.put({ name: name, description }, stream, {
      timeout: 5 * 60 * 1000, // 5 minutes
    });
    return { success: true, data: info, reasons: [] };
  } catch (e) {
    return { success: false, reasons: [{ message: "Could not upload file" }] };
  }
}

export async function objGet(bucket: string, name: string) {
  if (status.value == Status.NotAvailable) {
    const baseHeaders = getHeaders();
    const customHeaders = addCustomHeaders(baseHeaders, headers);
    const resp = await fetch(`/api/files/nats/${bucket}/${name}`, {
      method: "GET",
      headers: toHttpHeaders(customHeaders),
    });
    if (!resp.ok) {
      return { success: false, reasons: [{ message: "Failed to fetch file", status: resp.status }] };
    }
    const blob = await resp.blob();
    console.log("objGet", blob);
    return { success: true, data: blob };
  }

  const res = await getObjectStore(bucket);
  if (!res.success || res.data == null) return res;
  const os = res.data;

  const data = await os.getBlob(name);
  if (data == null) return { success: false, reasons: [{ message: "Failed to get file" }] };
  return { success: true, data: new Blob([data.buffer]) };
}

export async function objDelete(bucket: string, name: string) {
  if (status.value == Status.NotAvailable) {
    const baseHeaders = getHeaders();
    const customHeaders = addCustomHeaders(baseHeaders, headers);
    const resp = await fetch(`/api/files/nats/${bucket}/${name}`, {
      method: "DELETE",
      headers: toHttpHeaders(customHeaders),
    });
    return await resp.json();
  }
  const res = await getObjectStore(bucket);
  if (!res.success || res.data == null) return res;
  const os = res.data;

  const result = await os.delete(name);
  return { success: result.success, reasons: [] };
}

export const client = {
  connect: ensureConnected,
  request: request,
  publish: publish,
  disconnect: disconnect,
  subscribe: subscribe,
  subscribeStream: subscribeStream,
  objPut: objPut,
  objGet: objGet,
  objDelete: objDelete,
};
